import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, StackDivider, VStack } from '@chakra-ui/react';
import RedemptionSelector from './RedemptionSections/RedemptionSelector';
import RedemptionActionLabel from './RedemptionSections/RedemptionActionLabel';
import RedemptionActionOptionalFields from './RedemptionSections/RedemptionActionOptionalFields';
import OfferValidationSelection from './OfferValidationSelection';
import RedemptionOtherDetails from './RedemptionSections/RedemptionOtherDetails';
import RedemptionLimits from './RedemptionSections/RedemptionLimits';

const LimitedRedemption = ({ name }) => {
  return (
    <Box w='100%'>
      <Heading color='gray.700' mb={10} pb={3} borderBottomWidth='1px' borderColor='gray.300'>Redemption</Heading>
      <VStack spacing={10} m={0} minW='100%' w='100%' divider={<StackDivider />}>
        <Box w='100%'>
          <RedemptionSelector name={name} mb={5} />
          <RedemptionActionLabel name={name} mb={5} />
          <RedemptionActionOptionalFields name={name} mb={5} />
        </Box>
        <RedemptionLimits name={name} mb={5} w='100%' />
        <OfferValidationSelection name='flow.vars' chakra={true} w='100%' />
        <RedemptionOtherDetails name={name} />
      </VStack >
    </Box>
  );
};

LimitedRedemption.propTypes = {
  name: PropTypes.string,
};

export default LimitedRedemption;
