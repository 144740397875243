// React
import {
  Box,
  Select,
  Text
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import SuiForm from '../../components/collections/Form';
import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

// web-react-ui
import './SimpleSelectField.css';

const getDisabledOption = (inputValue, defaultValue, options) => {
  let targetValue;
  if (inputValue !== undefined && inputValue !== '') {
    targetValue = inputValue;
  } else if (defaultValue !== null && defaultValue !== undefined) {
    targetValue = defaultValue;
  } else {
    targetValue = options[0].value;
  }

  return options.find(x => x.value === targetValue);
};

const SelectField = ({
  name, label, values, options, format, parse, wrapLabel, info, optional, disabled, defaultValue, fieldColor, validate, chakra
}) => {
  const fieldName = name;


  if (!options) {
    // eslint-disable-next-line no-param-reassign
    options = values.map(v => ({ value: v, text: v }));
  }

  const FieldCmp = chakra ? Box : SuiForm.Field;
  const SelectCmp = chakra ? Select : 'select';

  return (
    <Field component={SelectCmp} name={fieldName} format={format} parse={parse} validate={validate}>
      {({ input }) => {
        // Set the default value of the select element
        useEffect(() => {
          if (!disabled && (input.value === undefined || input.value === '')) {
            input.onChange(defaultValue);
          }
        }, [input.value]);

        const onChange = (event, { value }) => {
          input.onChange(value);
        };

        // If the control is disabled we either want to use the existing value or the default value
        const disabledOption = disabled ? getDisabledOption(input.value, defaultValue, options) : null;

        return (
          <FieldCmp disabled={disabled}>
            <FieldLabel label={label} wrapLabel={wrapLabel} optional={optional} disabled={disabled}>
              {disabledOption ? (
                <SelectCmp className={chakra ? undefined : 'disabled text--muted'} disabled={disabled} {...input}>
                  <option
                    key={disabledOption.text}
                    value={disabledOption.value}>
                    {disabledOption.text}
                  </option>
                </SelectCmp>
              ) : (
                <SelectCmp
                  {...input}
                  className={chakra ? undefined : `select--color-${fieldColor}`}
                  disabled={disabled}
                >
                  {options.map(o => (
                    <option onChange={onChange} key={o.text} value={o.value} disabled={o.disabled}>
                      {o.text}
                    </option>
                  ))}
                </SelectCmp>
              )}
              <FieldError name={fieldName} chakra={chakra} />
            </FieldLabel>
          </FieldCmp>
        );
      }}
    </Field>
  );
};
SelectField.propTypes = {
  name: PropTypes.string,
  values: PropTypes.array,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  format: PropTypes.func,
  parse: PropTypes.func,
  wrapLabel: PropTypes.bool,
  info: PropTypes.string,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any,
  fieldColor: PropTypes.string,
  chakra: PropTypes.bool
};

export default SelectField;
