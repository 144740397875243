export const NAME = 'business';

export const types = {
  BUSINESS_LIST_REQUEST: 'business/LIST_REQUEST',
  BUSINESS_LIST_SUCCESS: 'business/LIST_SUCCESS',
  BUSINESS_LIST_FAILURE: 'business/LIST_FAILURE',
  BUSINESS_DETAILS_REQUEST: 'business/DETAILS_REQUEST',
  BUSINESS_DETAILS_SUCCESS: 'business/DETAILS_SUCCESS',
  BUSINESS_DETAILS_FAILURE: 'business/DETAILS_FAILURE',
  BUSINESS_INVALIDATE: 'business/INVALIDATE',
};
