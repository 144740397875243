import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Search from '../components/modules/Search';

export default class SearchBase extends Component {
  static propTypes = {
    onSearchChange: PropTypes.func,
    onChange: PropTypes.func,
    mapResult: PropTypes.func
  };

  state = {
    results: [],
    isLoading: false
  };

  componentDidMount() {
    this.setState({ debouncedSearch: _.debounce(this.onSearchChange.bind(this), 500) });
  }

  onResultSelect(event, data) {
    const { onChange } = this.props;
    if (!onChange) return;

    const { result } = data;

    onChange(result.value);
  }

  onSearchChange(event, data) {
    const { value } = data;
    this.setState({ value, isLoading: true });

    return Promise.resolve(this.props.onSearchChange(value))
      .then(r => this.setState({
        results: r,
        isLoading: false
      }));
  }

  render() {
    const {
      onSearchChange, // eslint-disable-line no-unused-vars
      mapResult = r => r,
      ...other
    } = this.props;
    const { results, isLoading } = this.state;
    const items = results && results.items;
    const searchResults = items && items.map(mapResult);
    return (
      <Search
        fluid
        input={{ fluid: true }}
        onSearchChange={this.state.debouncedSearch}
        onResultSelect={this.onResultSelect.bind(this)}
        results={searchResults}
        showNoResults={!isLoading}
        loading={isLoading}
        {...other}
      />
    );
  }
}
