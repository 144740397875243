import React from 'react';
import PropTypes from 'prop-types';

import ImageRenderer from './ImageRenderer';

const PropertyProfileImage = ({ imageUrl, propertyName }) => (
  <ImageRenderer imageUrl={imageUrl} name={propertyName} size="lg" />
);

PropertyProfileImage.propTypes = {
  imageUrl: PropTypes.string,
  propertyName: PropTypes.string
};

export default PropertyProfileImage;
