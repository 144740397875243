import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Message from 'web-react-ui/src/components/collections/Message';
import List from 'web-react-ui/src/components/elements/List';
import EmptyList from 'web-react-ui/src/components/entities/EmptyList';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';


import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../services/client';

const LocationListItem = ({ location }) => (
  <List.Item>
    <List.Content>
      <List.Header>{location.name}</List.Header>
      <List.Description>{location.address.label}</List.Description>
    </List.Content>
  </List.Item>
);

LocationListItem.propTypes = {
  location: PropTypes.object
};

class Presenter extends Component {
  state = {};

  render() {
    const { fetchLocations, fetchDisabledLocations, offer } = this.props;
    return (
      <React.Fragment>
        <PagedList fetchList={fetchLocations} params={{ offer }}>
          {({
            items, loading, isEmpty, error
          }) => (
            <WaitFor waitFor={!loading}>
              <List selection>
                {isEmpty && <EmptyList message='No Locations' />}
                {items.map(location => <LocationListItem location={location} key={location.id} />)}
                <ErrorMessage error={error} />
              </List>
            </WaitFor>
          )}
        </PagedList>
        <PagedList fetchList={fetchDisabledLocations} params={{ offer }}>
          {({
            items, loading, isEmpty, error
          }) => (
            <WaitFor waitFor={!loading}>
              <List selection>
                {isEmpty && null}
                {!isEmpty && (
                  <Message info>
                    <Message.Header>
                      <div className='mb1'>Disabled Locations</div>
                    </Message.Header>
                    <p>The following disabled locations are attached to the offer.</p>
                    <p>They will not be part of the Offer in this Property.</p>
                    <p>They <em>will</em> be part of the Offer when syndicated.</p>
                    <hr className='mv1' />
                    <div className='p1'>
                      {items.map(location => <LocationListItem location={location} key={location.id} />)}
                    </div>
                  </Message>
                )}
                <ErrorMessage error={error} />
              </List>
            </WaitFor>
          )}
        </PagedList>
      </React.Fragment>
    );
  }
}

Presenter.propTypes = {
  fetchLocations: PropTypes.func,
  fetchDisabledLocations: PropTypes.func,
  offer: PropTypes.object
};

class OfferLocationsList extends Component {
  fetchLocations({ offer }) {
    const { property, business } = this.props;
    if (!offer) return null;
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offer.id)
      .locations.search({ attached: true });
  }

  fetchDisabledLocations({ offer }) {
    const { property, business } = this.props;
    if (!offer) return null;
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offer.id)
      .locations.search({ attached: false });
  }

  render() {
    return (
      <Presenter
        fetchLocations={this.fetchLocations.bind(this)}
        fetchDisabledLocations={this.fetchDisabledLocations.bind(this)}
        offer={this.props.offer}
      />
    );
  }
}

OfferLocationsList.propTypes = {
  property: PropTypes.object,
  basePath: PropTypes.string,
  business: PropTypes.object,
  offer: PropTypes.object
};

export default OfferLocationsList;
