import {
  useHistory,
  useLocation
} from 'react-router-dom';
import querystring from 'query-string';

/** Hook to Get/Set values in the querystring
 *
 * Merges updates with the existing querystring
 *
 * eg:
 *   const [querystring, updateQuerystring] = useQuerystring();
 *   ...
 *   <h1>{querystring.paramname}</h1> // <h1></h1>
 *
 *   updateQuerystring({paramname: 'A Value!'});
 *   ...
 *   <h1>{querystring.paramname}</h1> // <h1>A Value!</h1>
 *
 * @returns [
 *   {[String]: String},        // parsed querystring as Object
 *   ({[String]: String})=>void // function to update querystring
 * ]
 */
function useQuerystring() {
  const location = useLocation();
  const history = useHistory();
  const qs = querystring.parse(location.search);

  const update = (newValues) => {
    const newQs = Object.assign({}, qs, newValues);
    history.replace(`?${querystring.stringify(newQs)}`);
  };

  return [qs, update];
}

export default useQuerystring;
