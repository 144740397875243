/* eslint max-len: 0 */
import {
  Button,
  Container,
  Flex,
  Heading,
} from '@chakra-ui/react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import React, { useMemo } from 'react';
import useRequest from 'shared/src/hooks/useRequest';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import GeneratingSuggestions from './GeneratingSuggestions';
import Suggestion from './Suggestion.interface';
import SuggestionCard from './SuggestionCard';
import businessModule from '../../../modules/businesses';
import client from '../../../services/client';

const fetchSuggestions = async ({ businessId }: { businessId: string }) => {
  const results = await client
    .businesses.for(businessId)
    .generateSuggestions();

  return results;
};

const SuggestModal = () => {
  const business = useSelector((state: RootStateOrAny) => businessModule.selectors.business.getData(state));
  const suggestionsRequest = useRequest(
    fetchSuggestions,
    { businessId: business.id },
    { discardError: true },
  );

  const retry = useMemo(
    () => () => {
      return suggestionsRequest.run({ businessId: business.id });
    },
    [business],
  );

  return (
    <BigModal open>
        <Container className="c-big-modal" maxW="container.xl">
          <BigModal.Header>
            <PageBackButton to="../overview">Back</PageBackButton>
            <Flex alignItems="top" gap="1em" p="1em" pb="0">
              <Heading w="100%" pb="2">Promotions AI!</Heading>
              <Button
                onClick={retry}
                flexShrink={0}
                isLoading={suggestionsRequest.loading}
                variant="outline"
              >
                Regenerate Suggestions
              </Button>
            </Flex>
          </BigModal.Header>
          <BigModal.Body pb="5em">
            <ErrorAlert
              error={suggestionsRequest.error}
              title="Error Generating Suggestions"
              message={<Button onClick={retry} variant="link">Please try again</Button>}
            />
            {suggestionsRequest.loading
              ? (
                <GeneratingSuggestions />
              )
              : (
                <Flex
                  direction={['column', 'column', 'row']}
                  gap="1em"
                  justifyItems="stretch"
                  wrap='wrap'
                >
                  {suggestionsRequest.result?.map(
                    (s: Suggestion, idx: number) => <SuggestionCard suggestion={s} key={idx} />,
                  )}
                </Flex>
              )
            }
          </BigModal.Body>
        </Container>
    </BigModal>
  );
};

export default SuggestModal;
