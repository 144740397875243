import { useControllableState } from '@chakra-ui/hooks';
import React, {
  useMemo,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react';
import { BiSearch } from 'react-icons/bi';

const SearchInput = ({ value: valueProp, onChange, placeholder, defaultValue, size, bg }) => {
  const [value, setValue] = useControllableState({
    value: valueProp,
    defaultValue,
    onChange,
  });

  const inputRef = useRef();
  const clearQuery = useMemo(
    () => () => {
      setValue('');
      inputRef.current?.focus();
    },
    [setValue],
  );

  return (
    <InputGroup w="100%" zIndex={1} size={size} bg={bg}>
      <InputLeftElement color="gray.300">
        <Icon as={BiSearch} h={5} w={5} />
      </InputLeftElement>
      <Input
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={e => setValue(e.target.value)}
        ref={inputRef}
      />
      {value && (
        <InputRightElement>
          <CloseButton borderRadius="full" size="sm" onClick={clearQuery} />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  bg: PropTypes.string
};

SearchInput.defaultProps = {
  placeholder: 'Search',
};

export default SearchInput;
