/* eslint camelcase: 0, class-methods-use-this: 0 */
import React, {
  useCallback,
  useState,
} from 'react';
import { connect } from 'react-redux';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import {
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import usePagedList from 'shared/src/hooks/usePagedList';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Button from 'web-react-ui/src/components/elements/Button';
import Icon from 'web-react-ui/src/components/elements/Icon';
import {
  List,
  Item,
  Header,
  Body,
  Loading,
} from 'web-react-ui/src/chakra/List';
import Loader from 'web-react-ui/src/components/elements/Loader';
import View from 'web-react-ui/src/components/layout/View';
import Property from 'web-react-ui/src/types/Property.interface';
import Business from 'web-react-ui/src/types/Business.interface';
import Location from 'web-react-ui/src/types/Location.interface';

import businessModule from '../../modules/businesses';
import propertyModule from '../../modules/property';
import client from '../../services/client';
import LocationsMap from '../locations/LocationsMap';
import SupportLink from '../support/SupportLink';

type ListItemProps = {
  property: Property;
  business: Business;
  location: Location;
  busy: boolean;
  handleEnable: (loc: Location) => void;
}
const LocationListItem = ({ property, business, location, busy, handleEnable }: ListItemProps) => {
  return (
    <Item
      as={location.isEnabled ? Link : undefined}
      to={`/${property.id}/businesses/${business.id}/locations/${location.id}`}
      align="center"
    >
      <VStack flexGrow={1} align="start">
        <Header>{location.name}</Header>
        <Body>{location.address.label}</Body>
      </VStack>

      {
        busy
          ? (
            <Loader active inline />
          )
          : (
            <React.Fragment>
              {location.isEnabled && <Icon className="long arrow alternate right" />}
              {!location.isEnabled && (
                <ActionMenu
                  size="sm"
                  actions={[{
                    label: 'Enable',
                    action: () => handleEnable(location),
                  }]}
                />
              )}
            </React.Fragment>
          )
      }
    </Item>
  );
};


const fetchLocations = ({ propertyId, businessId }: { propertyId: string, businessId: string }) => {
  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .locations.listAdmin();
};

const LocationsView = (
  { property, business, showView }
    : { property: Property, business: Business, showView: string },
) => {
  const [refetchId, setRefetchId] = useState(0);
  const locationsList = usePagedList(fetchLocations, { propertyId: property.id, businessId: business.id, refetchId });
  const history = useHistory();
  const [busyLocations, setBusyLocations] = useState<Array<string>>([]);

  const setView = useCallback(
    (view) => {
      if (view === 'list') return history.replace(`/${property.id}/businesses/${business.id}/locations/list`);
      if (view === 'map') return history.replace(`/${property.id}/businesses/${business.id}/locations/map`);
      return null;
    },
    [],
  );


  const handleEnable = useCallback(
    (location) => {
      setBusyLocations(v => [...v, location.id]);

      client
        .properties.for(property.id)
        .businesses.for(business.id)
        .locations.for(location.id)
        .enable()
        // FIXME: Hack to hopefully wait for search index to be updated...
        .then(() => new Promise(res => setTimeout(res, 1000)))
        .then(() => {
          setRefetchId(v => v + 1);
          setBusyLocations(v => v.filter(id => id !== location.id));
        });
    },
    [],
  );

  const handlePopupClick = useCallback(
    ({ data }) => {
      if (data.isEnabled) return history.push(`/${property.id}/businesses/${business.id}/locations/${data.id}`);
      return handleEnable(data);
    },
    [],
  );


  return (
    <View>
      <View.Section className="mt3">
        <div className="flex">
          <Button.Group className="pb1" style={{ marginLeft: 'auto' }}>
            <Button active={showView === 'map'} name="Map" onClick={() => setView('map')}>Map</Button>
            <Button active={showView === 'list'} name="List" onClick={() => setView('list')}>List</Button>
          </Button.Group>
        </div>
        {(showView === 'list') && (
          <List>
            <Loading isLoading={locationsList.loading} />
            {!locationsList.loading && locationsList.items.map(
              (location: Location) => (
                <LocationListItem
                  location={location}
                  property={property}
                  business={business}
                  key={location.id}
                  handleEnable={handleEnable}
                  busy={busyLocations.includes(location.id)}
                />
              ),
            )}
          </List>
        )}
        {(showView === 'map') && (
          <div className="mt4">
            <Skeleton isLoaded={!locationsList.loading}>
              <LocationsMap
                locations={locationsList.items}
                property={property}
                business={business}
                onPopupClick={handlePopupClick}
              />
            </Skeleton>
          </div>
        )}

        <div className="mt1 mb3 pull-right">
          <SupportLink label={'Can\'t Find The Right Location?'} />
        </div>
      </View.Section>
    </View>
  );
};
const mapState = (state: any) => ({
  property: propertyModule.selectors.property.getData(state),
  business: businessModule.selectors.business.getData(state),
});

export default connect(mapState)(LocationsView);
export { LocationsView as Presenter };
