import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import client from '../../services/client';

import PropertyCreateView from './PropertyCreateView';

class PropertyCreateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit(formData) {
    this.setState({ error: null, isSaving: true });
    client.properties.create(formData)
      .then(newProperty => this.setState({ newProperty, isSaving: false }))
      .catch(error => this.setState({ error, isSaving: false }));
  }

  handleCancel() {
    this.setState({
      redirectTo: {
        pathname: `/properties`,
        state: { skipRedirect: true }
      }
    });
  }

  render() {
    const {
      newProperty, error, isSaving, redirectTo
    } = this.state;
    if (redirectTo) return <Redirect to={redirectTo} />;
    if (newProperty) return <Redirect to={`/${newProperty.id}`} />;
    return (
      <PropertyCreateView
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.handleCancel.bind(this)}
        error={error}
        isSaving={isSaving}
      />
    );
  }
}

export default PropertyCreateContainer;
