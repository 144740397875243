import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@chakra-ui/react';
import { FormSpy } from 'react-final-form';

const FormSubmit = props => (
  <FormSpy subscription={{ hasValidationErrors: true }}>
    {({ form, hasValidationErrors }) => {
      const {
        label, name, value, primary, ignoreValidity, ...rest
      } = props;
      return (
        <Button
          disabled={hasValidationErrors && !ignoreValidity}
          variant={primary ? 'solid' : 'outline'}
          colorScheme={primary ? 'blue' : undefined}
          primary={primary !== false}
          type='submit'
          onClick={() => {
            // Declarative way to distinguish between multiple Submit buttons in a form
            if (name) form.change(name, value);
          }}
          {...rest}
        >
          {label}
        </Button>
      );
    }}
  </FormSpy>
);

FormSubmit.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  primary: PropTypes.bool,
  ignoreValidity: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default FormSubmit;
