import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import Menu from 'web-react-ui/src/components/collections/Menu';
import Button from 'web-react-ui/src/components/elements/Button';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import TextField from 'web-react-ui/src/reactFinalForm/fields/TextField';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import { required } from 'web-react-ui/src/reactFinalForm/validators';
import client from '../../services/client';

class TokenCreateModal extends Component<{}> {
  static propTypes = {
    propertyId: PropTypes.string,
    history: PropTypes.object
  };

  state = {};

  async createToken(values) {
    const { propertyId } = this.props;
    this.setState({ error: null });
    try {
      const token = await client.properties.for(propertyId).tokens.create({ name: values.name });
      this.setState({ token });
    } catch (error) {
      this.setState({ error });
    }
  }

  goBack() {
    const { history } = this.props;
    history.push('./');
  }

  render() {
    const goBack = this.goBack.bind(this);
    const { token, error } = this.state;
    return (
      <BigModal open={true}>
        <SimpleForm onSubmit={this.createToken.bind(this)}>
          <BigModal.Contents>
            <BigModal.Header className='p1'>
              <div className='flex'>
                <div className='mla'>
                  <CancelButton onClick={goBack}/>
                </div>
              </div>
            </BigModal.Header>
            <BigModal.Body>
              <View>
                <View.Section>
                  <h1>Adding a new API Token</h1>
                  <p>Once an API Token has been created, the API Generated String will never be shown again.</p>
                  <h3>API Name</h3>
                  <div className='flex'>
                    <div className='fg1 mr1'>
                      <TextField name='name' validate={required} className='mb0' wrapLabel disabled={!!token}/>
                    </div>
                    <FormSubmit label='Generate API Token' disabled={!!token}/>
                  </div>
                  <ErrorMessage error={error}/>
                </View.Section>
                {token && (
                  <View.Section>
                    <h3>API Generated String</h3>
                    <div className='c-display__keys p1 bg--gray'>{token.apiToken}</div>
                    <small>Make sure to copy this code somewhere safe. This is the only time it will be shown.</small>
                  </View.Section>
                )}
              </View>
            </BigModal.Body>
            <BigModal.Footer>
              <View>
                <View.Section>
                  <Menu secondary>
                    <Menu.Menu position='right'>
                      {token && (
                        <Menu.Item fitted primary>
                          <Button onClick={goBack} primary>Done</Button>
                        </Menu.Item>
                      )}
                      <Menu.Item fitted primary>
                        <Button onClick={goBack}>Cancel</Button>
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                </View.Section>
              </View>
            </BigModal.Footer>
          </BigModal.Contents>
        </SimpleForm>
      </BigModal>
    );
  }
}

export default TokenCreateModal;
