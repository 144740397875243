import { Text } from '@chakra-ui/react';
import fr from 'date-fns/locale/fr';
import React from 'react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import Offer from '../../../types/Offer.interface';
import getDateRangeStr from '../utils/getDateRangeStr';

const ScheduleDate = ({ offer }: { offer: Offer }) => {
  const { strings, default: { language: i18nLang } } = useI18Next();
  const { discoverableSchedules = [], usableSchedules = [] } = offer;
  const discoverableSchedule = discoverableSchedules[0];
  const usableSchedule = usableSchedules[0];

  // TODO: This will need to updated to a lookup as more supported locales are added
  const dateLocale = i18nLang === 'fr' ? { locale: fr } : null;
  const scheduleToUse = discoverableSchedule || usableSchedule || null;

  return (
    <Text fontSize="lg">
      {scheduleToUse ? (
        getDateRangeStr(scheduleToUse, dateLocale, strings)
      ) : (
        strings('ui.component.offerListItem.noSchedule')
      )}
    </Text>
  );
};

export default ScheduleDate;
