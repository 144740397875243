const VIEW_TYPES = {
  all: 'all', // Not used in Portal or Dashboard but we should have this available
  new: 'new',
  live: 'live',
  expiring: 'expiring',
  expired: 'expired',
  recentlyExpired: 'recentlyExpired',
  disabled: 'disabled'
};

// TODO: Consider setting `includeHistoric: true` for `expired` view

const getStatus = (viewType) => {
  switch (viewType) {
    case VIEW_TYPES.live:
      return ['live', 'scheduled'];
    case VIEW_TYPES.new:
    case VIEW_TYPES.expiring:
      return ['live'];
    case VIEW_TYPES.expired:
    case VIEW_TYPES.recentlyExpired:
      return ['expired'];
    case VIEW_TYPES.disabled:
      return ['disabled', 'draft'];
    case VIEW_TYPES.all:
    default:
      return undefined;
  }
};

// Note the date ranges are from the current local time
const getTimeLimits = (viewType) => {
  if (viewType === VIEW_TYPES.new) {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    const formattedDate = date.toISOString();
    return {
      minStartTime: formattedDate
    };
  }

  if (viewType === VIEW_TYPES.expiring) {
    const date = new Date();
    date.setDate(date.getDate() + 10);
    const formattedDate = date.toISOString();
    return {
      maxEndTime: formattedDate
    };
  }

  if (viewType === VIEW_TYPES.recentlyExpired) {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    const formattedDate = date.toISOString();
    const now = new Date();
    const formattedNow = now.toISOString();
    return {
      minEndTime: formattedDate,
      maxEndtime: formattedNow
    };
  }
  return undefined;
};

const getIsVisible = viewType => (viewType === VIEW_TYPES.disabled ? undefined : true);

const getIsOwned = viewType => (viewType === VIEW_TYPES.disabled ? true : undefined);

const getSort = (viewType) => {
  switch (viewType) {
    case VIEW_TYPES.live:
    case VIEW_TYPES.expiring:
      return 'nextend-prevstart';
    case VIEW_TYPES.new:
    case VIEW_TYPES.disabled:
      return 'prevstart-nextend';
    case VIEW_TYPES.expired:
      return 'prevend-prevstart';
    case VIEW_TYPES.all:
    default:
      return undefined;
  }
};

const offerListViewParamsFactory = viewType => ({
  status: getStatus(viewType),
  timeLimits: getTimeLimits(viewType),
  isVisible: getIsVisible(viewType),
  isOwned: getIsOwned(viewType),
  sort: getSort(viewType)
});

export {
  offerListViewParamsFactory,
  VIEW_TYPES
};
