import React from 'react';
import { Button } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const SelectButton = ({
  onClick,
  isSelected,
  label,
  value,
}: {
  onClick: (value: number) => void,
  isSelected: boolean,
  label: string,
  value: number,
}): JSX.Element => (
  <Button
    bg='white'
    borderColor='gray.200'
    borderWidth='1px'
    _active={{
      bg: 'blue.50',
      borderColor: 'blue.500'
    }}
    _hover={{
      bg: 'blue.50'
    }}
    onClick={() => onClick(value)}
    isActive={isSelected}
    leftIcon={isSelected ? <CheckIcon /> : undefined}
  >
    {label}
  </Button>
);

export default SelectButton;
