import PropTypes from 'prop-types';
import React, {
  useCallback,
  useState
} from 'react';
import { Link } from 'react-router-dom';

import useIncrement from 'shared/src/hooks/useIncrement';
import usePromise from 'shared/src/hooks/usePromise';
import {
  transport,
  useQuerystringParam
} from 'shared/src/hooks/useQuerystringParam';

import Message from 'web-react-ui/src/components/collections/Message';
import Button from 'web-react-ui/src/components/elements/Button';
import Divider from 'web-react-ui/src/components/elements/Divider';
import Icon from 'web-react-ui/src/components/elements/Icon';
import List from 'web-react-ui/src/components/elements/List';
import View from 'web-react-ui/src/components/layout/View';
import Request from 'web-react-ui/src/data/Request';
import TagEditor from 'web-react-ui/src/components/tags/TagEditor';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../services/client';
import '../property/propertyDetailsView/PropertySettingsGeneral.css';

const IntegrationEventItem = ({ event, propertyId, integrationId }) => {
  const [expanded, setExpanded] = useState(false);
  const [fetchDetails, isLoading, eventDetails, error] = usePromise(
    () => {
      if (!event.key || !integrationId || !propertyId) return null;
      return client.properties.for(propertyId).stats.getEvent(integrationId, event.key);
    },
    []
  );
  const toggle = useCallback(
    () => {
      setExpanded(!expanded);
      if (!eventDetails && !isLoading) fetchDetails();
    },
    [expanded, eventDetails, isLoading]
  );

  return (
    <div className="flex aifs">
      <List.Content>
        <List.Header>{event.valid ? '✔' : '❌'} {event.name}</List.Header>
        <List.Description style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
          <small>{event.key}</small>
        </List.Description>
        {expanded && (
          <WaitFor waitFor={!isLoading}>
            {error && <Message error>{error.message}</Message>}
            {eventDetails && (<pre>
              {JSON.stringify(eventDetails, null, 2)}
            </pre>)}
          </WaitFor>
        )}
      </List.Content>
      <Button onClick={toggle} className="mla" icon>
        <Icon name={`caret ${expanded ? 'up' : 'down'}`} />
      </Button>
    </div>
  );
};

IntegrationEventItem.propTypes = {
  event: PropTypes.object,
  propertyId: PropTypes.string,
  integrationId: PropTypes.string
};

const IntegrationEvents = ({ integration, property }) => {
  const [refetchKey, increment] = useIncrement(0);
  const [fetchList] = usePromise(
    ({ propertyId, integrationId }) => {
      if (!integrationId || !propertyId) return null;
      return client.properties.for(propertyId).stats.tailEvents(integrationId);
    },
    []
  );

  return (
    <React.Fragment>
      <div className="flex mb1 jcsb">
        <h1>Events</h1>
        <Button onClick={increment}>Refresh</Button>
      </div>
      <Request request={fetchList} params={{ refetchKey, propertyId: property.id, integrationId: integration.id }}>
        {({ data, loading }) => (
          <WaitFor waitFor={!loading}>
            <List celled relaxed>
              {data?.events?.length
                ? (data.events.map(event => (
                  <List.Item key={event.key}>
                    <IntegrationEventItem event={event} propertyId={property.id} integrationId={integration.id} />
                  </List.Item>
                )))
                : (
                  <em>no recent events</em>
                )
              }
            </List>
          </WaitFor>
        )}
      </Request>
    </React.Fragment>
  );
};

IntegrationEvents.propTypes = {
  property: PropTypes.object,
  integration: PropTypes.object,
};

const CorsDomains = ({ integration }) => {
  if (!integration?.corsDomains?.length) return null;
  return (
    <React.Fragment>
      <h6>CORS Domains</h6>
      {(integration.corsDomains || []).map(origin => (
        <div key={origin}><code>{origin}</code></div>
      ))}
    </React.Fragment>
  );
};

CorsDomains.propTypes = {
  integration: PropTypes.shape({
    corsDomains: PropTypes.arrayOf(PropTypes.string)
  })
};

const Scopes = ({ integration }) => {
  if (!integration?.scopes?.length) return null;
  return (
    <React.Fragment>
      <h6>Scopes</h6>
      {(integration.scopes || []).map(scope => (
        <div key={scope}><code>{scope}</code></div>
      ))}
    </React.Fragment>
  );
};

Scopes.propTypes = {
  integration: PropTypes.shape({
    scopes: PropTypes.arrayOf(PropTypes.string)
  })
};

const Lifespan = ({ integration }) => {
  if (!integration?.lifespan) return null;
  return (
    <React.Fragment>
      <h6>Lifespan</h6>
      <div><code>{integration.lifespan}s</code></div>
    </React.Fragment>
  );
};

Lifespan.propTypes = {
  integration: PropTypes.shape({
    lifespan: PropTypes.number
  })
};

const renderWriteKey = (integration, isLoading) => {
  if (integration?.writeKey) return integration.writeKey;
  if (!isLoading && integration && !integration.writeKey) return 'none';
  return '';
};

const IntegrationDetails = ({
  integration,
  property,
  deleteIntegration,
  fetchTags,
  saveTags,
  isLoading,
}) => {
  const [events] = useQuerystringParam(
    'events',
    false,
    { transport: transport.boolean, squash: true }
  );

  return (
    <View>
      <View.Section fluid className="bg--gray brdr--b">
        <View.Section>
          <Link to={`/${property.id}/property-settings/integrations`} className="flex">
            <Icon className="long arrow alternate left" />
            <h6 className="m0">Integrations</h6>
          </Link>
        </View.Section>
      </View.Section>
      <WaitFor waitFor={integration}>
        <View.Section>
          <h1 className="mt0">{integration && integration.name}</h1>
          <h2 className="mr1">{integration && integration.target}</h2>

          <TagEditor fetchTags={fetchTags} onSave={saveTags} />

          <div className="flex fww mt2">
            <div className="fg1 mr2" style={{ maxWidth: '450px' }}>
              <h6>Write Key</h6>
              <div className="c-display__keys mb3 p1 bg--gray">
                {renderWriteKey(integration, isLoading)}
              </div>
              <CorsDomains integration={integration} />
              <Scopes integration={integration} />
              <Lifespan integration={integration} />
            </div>
            <div className="fg1">
              <h6>Installation &amp; Trouble Shooting</h6>
              <a
                href={process.env.REACT_APP_DOCS}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex">
                  <p className="mb0 small mr1">View Documentation</p>
                  <Icon className="long arrow alternate right" />
                </div>
              </a>
            </div>
          </div>
          <Divider className="pb2" />
          <Button primary basic negative onClick={deleteIntegration}>Delete</Button>
        </View.Section>
        {events && (
          <View.Section>
            <Divider className="pb2" />
            <IntegrationEvents property={property} integration={integration} />
          </View.Section>
        )}
      </WaitFor>
    </View>
  );
};

IntegrationDetails.propTypes = {
  integration: PropTypes.object,
  writeKey: PropTypes.object,
  property: PropTypes.object,
  deleteIntegration: PropTypes.func,
  fetchTags: PropTypes.func,
  saveTags: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default IntegrationDetails;
