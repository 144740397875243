import React from 'react';
import {
  NumberField,
  TextField
} from 'web-react-ui/src/reactFinalForm/fields';
import SelectField from 'web-react-ui/src/reactFinalForm/fields/SimpleSelectField';
import FormError from 'web-react-ui/src/reactFinalForm/FormError';
import {
  compose,
  int,
  maxValue,
  minValue,
  required
} from 'web-react-ui/src/reactFinalForm/validators';

const TYPE_OPTIONS = [
  {
    text: 'Webhook',
    value: 'webhook'
  }
];

const DestinationEditForm = () => (
  <React.Fragment>
    <FormError />
    <SelectField
      name="type"
      defaultValue={TYPE_OPTIONS[0].value}
      label="Destination Type"
      options={TYPE_OPTIONS}
      validate={required}
    />
    <TextField name="url" label="Destination Url" placeholder="https://..." validate={required} />
    <TextField name="config.secret" label="Secret Key" placeholder="" />
    <NumberField name="config.batchSize"
                 label="Batch Size"
                 placeholder="25"
                 validate={compose(required, minValue(1), maxValue(10000), int)}
                 info='Number of events per batch'
    />
    <NumberField name="config.batchWindowInSeconds"
                 label="Batch Window"
                 placeholder="60"
                 validate={compose(required, minValue(0), maxValue(300), int)}
                 info='Duration in seconds'
    />
  </React.Fragment>
);

export default DestinationEditForm;
