/* global navigator */
import { useState } from 'react';
import { useI18Next } from '../components/contexts/I18NextContext';

const useCopyOfferShareUrl = () => {
  const { strings } = useI18Next();
  const [showMessage, setShowMessage] = useState(false);

  const actionLabel = strings('ui.label.copyOfferUrl');
  const message = strings('ui.component.offerDetails.message.offerUrlCopied');

  const handleCloseMessage = () => {
    setShowMessage(!showMessage);
  };

  const handleCopyUrl = (offerShareUrl) => {
    navigator.clipboard.writeText(offerShareUrl); // '.clipboard' is not supported in IE
    setShowMessage(true);
  };

  return [
    actionLabel,
    message,
    showMessage,
    handleCloseMessage,
    handleCopyUrl
  ];
};

export default useCopyOfferShareUrl;
