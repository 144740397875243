const EMPTY_ID = '00000000-0000-0000-0000-000000000000';

/**
 * Takes an array of role entries and creates a nested object of property, business, and location roles for a user
 */
export const getNestedRoles = (items: any[], propertyId: string) => {
  const accessRoles: { roleIds: string[], businesses: any } = { roleIds: [], businesses: {} };
  if (!items) {
    return accessRoles;
  }

  items.forEach((item: any) => {
    const { businessId, locationId, roleId } = item;
    /* eslint-disable-next-line no-prototype-builtins */
    if (!accessRoles.businesses.hasOwnProperty(businessId) && businessId !== EMPTY_ID) {
      accessRoles.businesses[businessId] = { roleIds: [], locations: {} };
    }
    if (locationId && locationId !== EMPTY_ID) {
      /* eslint-disable-next-line no-prototype-builtins */
      if (!accessRoles.businesses[businessId].locations.hasOwnProperty(locationId) && locationId !== EMPTY_ID) {
        accessRoles.businesses[businessId].locations[locationId] = { roleIds: [] };
      }
      accessRoles.businesses[businessId] = {
        ...accessRoles.businesses[businessId],
        locations: {
          ...accessRoles.businesses[businessId].locations,
          [locationId]: {
            /* @ts-ignore */
            roleIds: [...new Set([...accessRoles.businesses[businessId].locations[locationId].roleIds, roleId])],
          }
        }
      };
    } else if (businessId && businessId !== EMPTY_ID) {
      accessRoles.businesses[businessId] = {
        ...accessRoles.businesses[businessId],
        /* @ts-ignore */
        roleIds: [...new Set([...accessRoles.businesses[businessId].roleIds, roleId])],
      };
    } else if (propertyId !== EMPTY_ID) {
      /* @ts-ignore */
      accessRoles.roleIds = [...new Set([...accessRoles.roleIds, roleId])];
    }
  });

  return accessRoles;
};

/**
 * count all of the assigned roles for this property at all levels
 */
export const getTotalRoles = (accessRoles: any) => {
  let totalRoles = accessRoles.roleIds.length;
  Object.values(accessRoles?.businesses).forEach((biz: any) => {
    totalRoles += biz.roleIds.length;
    Object.values(biz.locations).forEach((loc: any) => {
      totalRoles += loc.roleIds.length;
    });
  });

  return totalRoles;
};
