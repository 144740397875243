/* eslint camelcase: 0, class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Message from 'web-react-ui/src/components/collections/Message';
import Button from 'web-react-ui/src/components/elements/Button';
import List from 'web-react-ui/src/components/elements/List';
import EmptyList from 'web-react-ui/src/components/entities/EmptyList';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';

import View from 'web-react-ui/src/components/layout/View';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../../services/client';
import RelativeLink from '../../routing/RelativeLink';


const listDestinations = ({ propertyId }) => client
  .properties.for(propertyId)
  .destinations.list();

class PropertySettingsDestinations extends Component {
  static propTypes = {
    propertyId: PropTypes.string
  };

  render() {
    const { propertyId } = this.props;
    return (
      <View>
        <View.Section className='mt2'>
          <Message warning>
            <Message.Header>Event Destinations are an experimental feature.</Message.Header>
            <p>
              The Event Destinations feature is not quite ready for primetime.
              We&apos;ve tested it ourselves and would love your feedback.
              Note that this feature may change, break, or disappear at any time.
            </p>
          </Message>
          <h1>Manage Destinations</h1>
          <PagedList fetchList={listDestinations} params={{ propertyId }}>
            {({
              items, isEmpty, loading, error
            }) => (
              <WaitFor waitFor={!loading}>
                {isEmpty && <EmptyList message='No Destinations' />}
                <ErrorMessage error={error} />
                <List celled selection>
                  {items.map(
                    destination => (
                      <List.Item as={RelativeLink} to={`./${destination.id}`} key={destination.id}>
                        <div className='flex aic mv1'>
                          <List.Content>
                            <List.Header className='mh1'>{destination.type}</List.Header>
                          </List.Content>
                          <List.Content>
                            <List.Description className='mh1'>{destination.url}</List.Description>
                          </List.Content>
                          <List.Content>
                            <List.Description className='mh1'>{destination.meta}</List.Description>
                          </List.Content>
                          <div className='mla'>
                            <Button primary>EDIT</Button>
                          </div>
                        </div>
                      </List.Item>
                    )
                  )}
                </List>
              </WaitFor>
            )}
          </PagedList>
        </View.Section>
        <View.Section>
          <Button
            as={Link}
            basic
            className='text'
            floated='right'
            to={`/${propertyId}/property-settings/destinations/add`}
          >
            + Add Destination
          </Button>
        </View.Section>
      </View>
    );
  }
}

export default PropertySettingsDestinations;
