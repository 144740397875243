import {
  Collapse,
  FormLabel,
  Heading,
  Spinner,
  Stack,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { useField } from 'react-final-form';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import usePagedList from 'shared/src/hooks/usePagedList';
import Card from '../../chakra/forms/Card';
import RadioButtonGroup from '../../chakra/forms/RadioButtonGroup/RadioButtonGroup';
import { ListSelectorField } from '../../reactFinalForm/fields';
import FieldError from '../../reactFinalForm/fields/FieldError';
import { requiredArray } from '../../reactFinalForm/validators';

const getLocations = async ({ client, propertyId, businessId }) => {
  const locations = await client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .locations.search({
      onlyEnabled: true,
      hasRoles: true,
      accessContext: 'implicit'
    })
    .then(client.pagination.loadRest);

  return locations;
};

const LocationSelectorField = ({ options, name, validate }) => {
  const groups = _.groupBy(options, 'address.state');
  const groupsArray = Object.keys(groups).map(key => ({ name: key, value: groups[key] }));
  return (
    <Card>
      <VStack alignItems="stretch" divider={<StackDivider />} spacing="6">
        {groupsArray.map(group => (
          <div key={group.name}>
            <Heading size="md" mb={5}>{group.name}</Heading>
            <ListSelectorField
              name={name}
              descriptionKey={'address.label'}
              options={group.value}
              suppressError
              labelKey="name"
              validate={validate}
              chakra
            />
          </div>
        ))}
      </VStack>
      <FieldError name={name} />
    </Card>
  );
};

const OfferLocations = ({ options, name, validate }) => {
  const { input } = useField(name);
  const { strings } = useI18Next();
  const [selectAll, setSelectAll] = useState(
    () => !!input.value && options.every(op => input.value.includes(op.id))
  );
  const radioButtonOptions = [
    { value: true, label: strings('dashboard.component.editOfferForm.field.label.allLocations') },
    { value: false, label: strings('dashboard.component.editOfferForm.field.label.selectLocations') }
  ];
  const handleSelectAll = useCallback((value) => {
    if (value) {
      const newValue = _.union(input.value, options.map(o => o.id));
      input.onChange(newValue);
    }
    setSelectAll(value);
  }, []);

  return (
    <Stack direction="column" spacing={5}>
      <FormLabel m={0}>{strings('dashboard.component.editOfferForm.field.label.availableIn')}</FormLabel>
      <RadioButtonGroup options={radioButtonOptions} value={selectAll} setValue={handleSelectAll} />
      <Collapse in={!selectAll}>
        <LocationSelectorField options={options} name={name} validate={validate} />
      </Collapse>
    </Stack>
  );
};

const useShouldHide = (name, locationsList) => {
  const { input } = useField(name);
  const [hide, setHide] = useState(false);
  useEffect(
    () => {
      if (!locationsList.settled) return;
      if (locationsList.items.length !== 1) return;
      if (input.value.length !== 1) return;
      if (input.value[0] !== locationsList.items[0].id) return; // not sure if this could happen...
      setHide(true);
    },
    [locationsList.settled]
  );
  return hide;
};

const OfferLocationsSection = ({ propertyId, businessId, dashboard }) => {
  const { strings } = useI18Next();
  const client = React.useContext(ClientContext);
  const locationsList = usePagedList(
    getLocations,
    { client, propertyId, businessId }
  );

  const shouldHide = useShouldHide('locations', locationsList);

  if (locationsList.loading) return <Spinner />;
  if (shouldHide) return null;


  return (
    <>
      <Heading color="gray.700" mb={10} pb={3} borderBottomWidth="1px" borderColor="gray.300">
        {strings('ui.component.exclusiveOffer.locations')}
      </Heading>
      <OfferLocations
        name="locations"
        options={locationsList.items}
        validate={dashboard && requiredArray}
      />
    </>
  );
};

export default OfferLocationsSection;
