import PropTypes from 'prop-types';
import React from 'react';

import { Field } from '../../reactFinalForm';
import { FieldError } from '../../reactFinalForm/fields';
import SuiForm from '../collections/Form';
import ImageWell from '../imageWell/ImageWell';

const ImageField = (props) => {
  const {
    name,
    label,
    validate,
    fluid = true,
    skipUpload,
    disabled,
    ...rest
  } = props;

  return (
    <Field name={name} validate={validate}>
      {({ input }) => (
        <SuiForm.Field disabled={disabled}>
          <label className='field-label'>
            {label}
          </label>
          <ImageWell {...input} fluid={fluid} type='text' skipUpload={skipUpload} {...rest} />
          <FieldError name={name}/>
        </SuiForm.Field>
      )}
    </Field>
  );
};

ImageField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  fluid: PropTypes.bool,
  skipUpload: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ImageField;
