import regionCache from '../../../services/regionCache';

const fetchRegions = async (arr) => {
  let editableRegions = {
    included: [],
    excluded: []
  };

  // EditCollectionForm Form Values ie. values.regionFilters.filters
  if (arr && arr.filters) {
    let discoverable = [];
    let notDiscoverable = [];

    ((arr.filters) || []).map((region) => {
      if (region.operation === 'discoverableIn') {
        discoverable = [...discoverable, ...region.args];
      } else if (region.operation === 'notDiscoverableIn') {
        notDiscoverable = [...notDiscoverable, ...region.args];
      }
      return region;
    });

    const included = discoverable.map(r => r.id);

    const excluded = notDiscoverable.map(r => r.id);

    editableRegions = {
      included,
      excluded
    };
  }

  const includedRegions = await regionCache.fetchMultiple(arr.included || editableRegions.included);

  const excludedRegions = await regionCache.fetchMultiple(arr.excluded || editableRegions.excluded);

  const discoverable = includedRegions.reduce((previousValue, currentValue) => {
    const newObj = {
      ...currentValue,
      style: { color: 'green' },
      area: currentValue.area.map(([lat, long]) => [long, lat])
    };
    previousValue.push(newObj);
    return previousValue;
  }, []);

  const notDiscoverable = excludedRegions.map((region) => {
    return {
      ...region,
      area: region.area.map(([lat, long]) => [long, lat])
    };
  });

  const regions = [...discoverable, ...notDiscoverable].filter(el => el !== null); // Removes empty elements

  return [regions, discoverable, notDiscoverable];
};

export default fetchRegions;
