import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Markdown from 'react-markdown';
import Button from '../../components/elements/Button';
import { Field } from '../index';
import './MarkdownField.css';
import TextArea from './TextArea';

const MarkdownField = ({ name, ...rest }) => {
  const [preview, setPreview] = useState(true);
  return (
    <React.Fragment>
      <TextArea label="Source" name={name} {...rest} />
      <Field name={name}>
        {({ input }) => input.value && (
          <React.Fragment>
            {preview && <div className='c-markdown-field__preview p1'>
              <Markdown source={input.value}/>
            </div>}
            <Button
              type='button'
              basic
              className='text'
              floated='right'
              onClick={() => setPreview(!preview)}
            >
              {preview ? '-' : '+'} Preview
            </Button>
          </React.Fragment>
        )}
      </Field>
    </React.Fragment>
  );
};

MarkdownField.propTypes = {
  name: PropTypes.string
};


export default MarkdownField;
