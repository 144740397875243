import React, { useContext } from 'react';
import { Box, Flex, FormLabel, Heading, VStack } from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import AddSectionButton from '../../../chakra/forms/RevealField/AddSectionButton';
import { TextField } from '../../../reactFinalForm/fields';
import LocalizableTextArea from '../../../reactFinalForm/fields/localizableField/LocalizableTextArea';
import LocalizationWrapper from '../../../reactFinalForm/fields/localizableField/LocalizationWrapper';
import RemoveSectionButton from '../../../chakra/forms/RevealField/RemoveSectionButton';
import RevealField from '../../../chakra/forms/RevealField/RevealField';
import {
  forAllLocales,
  max,
} from '../../../reactFinalForm/validators';

const RedemptionOtherDetails = () => {
  const { strings } = useI18Next();
  const form = useForm();
  const { locale } = useContext(LocalizationWrapper.context);

  return (
    <Box w='100%'>
      <Heading size="md" mb={5}>{strings('dashboard.component.editOfferForm.field.label.otherDetails')}</Heading>
      <VStack w='100%' spacing={5}>
        <Box w='100%'>
          <AddSectionButton name='showPromoCode'>
            {strings('ui.component.offerDetails.field.label.promoCode')}
          </AddSectionButton>
          <RevealField name='showPromoCode' initialize={values => !!values.promoCode}>
            <FormLabel>
              <Flex justify='space-between' align='center'>
                {strings('ui.component.offerDetails.field.label.promoCode')}
                <RemoveSectionButton name='showPromoCode' onRemove={() => form.change('promoCode', undefined)} />
              </Flex>
            </FormLabel>
            <TextField
              parse={null}
              name='promoCode'
              wrapLabel
              validate={max(32)}
              placeholder={strings('ui.component.offerDetails.field.info.promoCode')}
              chakra
            />
          </RevealField>
        </Box>
        <Box w='100%'>
          <AddSectionButton name='showLegalLocalized'>
            {strings('ui.component.offerDetails.field.label.termsConditions')}
          </AddSectionButton>
          <RevealField name='showLegalLocalized' inialize={values => !!values.legalLocalized}>
            <FormLabel>
              <Flex justify='space-between' align='center'>
                {strings('ui.component.offerDetails.field.label.termsConditions')}
                <RemoveSectionButton
                  name='showLegalLocalized'
                  onRemove={() => form.change('legalLocalized', {
                    ...form.getState().values.legalLocalized,
                    [locale]: undefined
                  })}
                />
              </Flex>
            </FormLabel>
            <LocalizableTextArea
              name='legalLocalized'
              wrapLabel
              validateLocale={forAllLocales(max(800))}
              placeholider={strings('dashboard.component.editOfferForm.field.label.termsAndConditionsTooltip')}
              parse={null}
              chakra
            />
          </RevealField>
        </Box>
      </VStack>
    </Box>
  );
};

export default RedemptionOtherDetails;
