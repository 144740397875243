/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';

import Grid from 'web-react-ui/src/components/collections/Grid';
import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import Menu from 'web-react-ui/src/components/collections/Menu';
import Table from 'web-react-ui/src/components/collections/Table';
import Pagination from 'web-react-ui/src/addons/Pagination';
import Image from 'web-react-ui/src/components/elements/Image';
import View from 'web-react-ui/src/components/layout/View';

import fakeGraph from '../../assets/fakeGraph.png';

const orderOptions = [
  { key: 1, text: 'Choice 1', value: 1 },
  { key: 2, text: 'Choice 2', value: 2 },
  { key: 3, text: 'Choice 3', value: 3 }
];

export default class AudienceView extends Component {
  render() {
    return (
      <View>
        <View.Section>
          <h1>Audience</h1>
        </View.Section>
        <View.Section className='brdr--b'>
          <h2>Activity</h2>
          <Grid columns={ 3 }>
            <Grid.Row>
              <Grid.Column>
                <div className='flex'>
                  <h5 className='fg1'>Daily</h5>
                  <p className='pl4'>123456</p>
                </div>
                <Image fluid src={ fakeGraph } alt="fakeGraph"/>
              </Grid.Column>
              <Grid.Column>
                <div className='flex'>
                  <h5 className='fg1'>Weekly</h5>
                  <p className='pl4'>123456</p>
                </div>
                <Image fluid src={ fakeGraph } alt="fakeGraph"/>
              </Grid.Column>
              <Grid.Column>
                <div className='flex'>
                  <h5 className='fg1'>Monthly</h5>
                  <p className='pl4'>123456</p>
                </div>
                <Image fluid src={ fakeGraph } alt="fakeGraph"/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </View.Section>
        <View.Section>
          <div className='mt4 mb1 flex pull-right'>
            <p className='pr2 pt2'>Order By</p>
            <Menu compact size='small'>
              <Dropdown text='Something' options={ orderOptions } simple item />
            </Menu>
          </div>
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Person</Table.HeaderCell>
                <Table.HeaderCell>Last Seen</Table.HeaderCell>
                <Table.HeaderCell>Last Location</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>John</Table.Cell>
                <Table.Cell>5 minutes ago</Table.Cell>
                <Table.Cell>Kelowna, BC. Canada</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Jamie</Table.Cell>
                <Table.Cell>5 minutes ago</Table.Cell>
                <Table.Cell>Victoria, BC. Canada</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Jill</Table.Cell>
                <Table.Cell>5 minutes ago</Table.Cell>
                <Table.Cell>Vancouver, BC. Canada</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Pagination
            className='pull-right'
            defaultActivePage={ 1 }
            totalPages={ 3 }
            secondary
            borderless
          />
        </View.Section>
      </View>
    );
  }
}
