import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import SuiForm from '../../components/collections/Form';
import SearchBase from '../../components/newSearchBase/SearchBase';

import FieldError from './FieldError';
import FieldLabel from "./FieldLabel";

const SearchBaseField = (props) => {
  const {
    name,
    label,
    validate,
    format,
    parse,
    optional,
    wrapLabel,
    ...rest
  } = props;

  return (
    <Field name={name} validate={validate} format={format} parse={parse}>
      {({ input }) => (
        <SuiForm.Field>
          <FieldLabel label={label} optional={optional} wrapLabel={wrapLabel}>
            <SearchBase {...input} defaultSelectedItem={input.value} {...rest}/>
            <FieldError name={name}/>
          </FieldLabel>
        </SuiForm.Field>
      )}
    </Field>
  );
};
SearchBaseField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  fluid: PropTypes.bool,
  inputProps: PropTypes.object,
  optional: PropTypes.bool,
  wrapLabel: PropTypes.bool
};

export default SearchBaseField;
