import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  Link,
  HStack,
  Icon,
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  useBreakpointValue,
  IconButton
} from '@chakra-ui/react';
import { BsChevronLeft, BsThreeDots } from 'react-icons/bs';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { FormSpy, useForm } from '../../reactFinalForm';
import LocalizationWrapper from '../../reactFinalForm/fields/localizableField/LocalizationWrapper';
import FormError from '../../reactFinalForm/FormError';
import FormSubmit from '../../reactFinalForm/FormSubmit';
import OfferLimitErrorMessage from './OfferLimitErrorMessage';

// this is only duplicated here so we don't alter other existing uses we're not changing now
const LocaleSelector = () => {
  const { locale, setLocale, locales } = useContext(LocalizationWrapper.context);
  const { strings, lookupExists } = useI18Next();

  const onChange = useCallback(
    e => setLocale(e.target.value),
    [setLocale]
  );

  if (!(locales && locales.length > 1)) return null;

  return (
    <Select
      onChange={onChange}
      value={locale}
      flexGrow={0}
      w={100}
    >
      {locales.map((loc) => {
        // If the lookup cannot be found use the locale instead of the lookup string
        const localeLabel = lookupExists(`shared.languages.${loc}`) ? strings(`shared.languages.${loc}`) : loc;
        return <option
          key={loc}
          value={loc}
        >
          {localeLabel}
        </option>;
      })}
    </Select>
  );
};

const MobileMenu = ({ isEdit, wasEnabled, isPersisted, strings, form }) => {
  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Menu" icon={<BsThreeDots />} variant='outline' borderRadius={6} />
      <MenuList>
        {!isEdit && (
          <MenuItem onClick={() => form.change('offerType', null)}>
            {strings('ui.component.editOfferMenu.changeType')}
          </MenuItem>
        )}
        {!wasEnabled && !isPersisted && (
          <MenuItem
            onClick={() => {
              form.change('enabled', false);
              form.submit();
            }}
          >
            {strings('ui.component.editOfferMenu.field.label.saveAsDraft')}
          </MenuItem>
        )}
        {!wasEnabled && isPersisted && (
          <MenuItem
            onClick={() => {
              form.submit();
            }}
          >
            {strings('ui.component.editOfferMenu.field.label.saveAndPublish')}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

MobileMenu.propTypes = {
  isEdit: PropTypes.bool,
  wasEnabled: PropTypes.bool,
  isPersisted: PropTypes.bool,
  strings: PropTypes.func,
  form: PropTypes.object,
};

const renderSaveButtons = (isPersisted, wasEnabled, offerType, isMobile, isEdit) => {
  const { strings } = useI18Next();
  const form = useForm();

  return (
    <HStack spacing={5} w="100%" justify="flex-end">
      <LocaleSelector />
      {!isPersisted && (
        <>
          {!isMobile && (
            <FormSubmit
              disabled={!offerType}
              label={strings('ui.component.editOfferMenu.field.label.saveAsDraft')}
              name='enabled'
              value={false}
              primary={false}
              ignoreValidity
            />
          )}

          <FormSubmit
            disabled={!offerType}
            label={strings('ui.component.editOfferMenu.field.label.publish')}
            name='enabled'
            primary={true}
            value={true}
            ignoreValidity
          />
        </>
      )}
      {wasEnabled && form.getState().values.id && (
        <FormSubmit
          label={strings('ui.component.editOfferMenu.field.label.save')}
          primary={true}
          ignoreValidity
        />
      )}
      {isPersisted && !wasEnabled && (
        <>
          <FormSubmit
            label={strings('ui.component.editOfferMenu.field.label.saveAsDraft')}
            name='enabled'
            value={false}
            primary={true}
            ignoreValidity
          />
          {!isMobile && (
            <FormSubmit
              label={strings('ui.component.editOfferMenu.field.label.saveAndPublish')}
              name='enabled'
              value={true}
              primary={false}
              ignoreValidity
            />
          )}
        </>
      )}
      {isMobile && (
        <MobileMenu
          isEdit={isEdit}
          wasEnabled={wasEnabled}
          isPersisted={isPersisted}
          strings={strings}
          form={form}
        />
      )}
    </HStack>
  );
};

renderSaveButtons.propTypes = {
  isPersisted: PropTypes.bool,
  wasEnabled: PropTypes.bool,
  offerType: PropTypes.string,
  strings: PropTypes.func.isRequired
};

const EditOfferMenu = ({
  handleCancel, isEdit, existingOfferType, dashboard, isFreePlan
}) => {
  const [wasEnabled, setWasEnabled] = useState(null);
  const { strings } = useI18Next();
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box mb={5}>
      <FormSpy subscription={{ dirty: true, values: true }}>
        {({ values, dirty, form }) => {
          const offerType = existingOfferType || (values && values.offerType);
          const isEnabled = values.enabled;
          // Track initial enabled state so the buttons don't change as soon as you "Save and Publish"
          if (wasEnabled == null) setWasEnabled(isEnabled);
          const isPersisted = values.id;
          const labelContext = isEdit ? 'ui.component.editOfferMenu.edit' : 'ui.component.editOfferMenu.create';

          function getOfferTypeLabel(type) {
            switch (type) {
              case 'limited':
                return strings(`${labelContext}.limited`);
              case 'punchcard':
                return strings(`${labelContext}.punchCard`);
              case 'exclusive':
                return strings(`${labelContext}.exclusive`);
              case 'reach':
                return strings(`${labelContext}.reach`);
              default:
                return strings('ui.component.editOfferMenu.selectOfferType');
            }
          }

          return (
            <Flex flexDir="column">
              <Flex mb={5}>
                <Link
                  color="blue.600"
                  onClick={() => handleCancel(dirty)}
                  as={Flex}
                  align="center"
                >
                  <Icon as={BsChevronLeft} mr={3} />
                  {strings('ui.label.cancel')}
                </Link>
              </Flex>
              <Flex justify="space-between" align="center" flexDir={{ base: 'column', md: 'row' }}>
                <Heading
                  w="100%"
                  mb={{ base: 5, md: 0 }}
                  fontWeight={700}
                  fontSize="30px"
                  color="gray.700"
                >
                  {getOfferTypeLabel(offerType)}
                </Heading>
                {offerType && renderSaveButtons(isPersisted, wasEnabled, offerType, isMobile, isEdit)}
              </Flex>
              <FormError render={({ error }) => (
                <OfferLimitErrorMessage error={error} dashboard={dashboard} isFreePlan={isFreePlan} />
              )} />
            </Flex>
          );
        }}
      </FormSpy>
    </Box >
  );
};

EditOfferMenu.propTypes = {
  canSubmit: PropTypes.bool,
  handleCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  existingOfferType: PropTypes.string,
  dashboard: PropTypes.bool,
  isFreePlan: PropTypes.bool
};

export default EditOfferMenu;
