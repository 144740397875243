import React from 'react';
import TextArea from './TextArea';

const formatJson = (value) => {
  try {
    return JSON.stringify(value, 0, 2);
  } catch (err) {
    return undefined;
  }
};

const parseJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return undefined;
  }
};

const JsonTextArea = (args) => {
  return <TextArea parse={parseJson} format={formatJson} {...args} />;
};

export default JsonTextArea;
