import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import badImage1 from '../../../assets/image-guides/bad-1.png';
import badImage2 from '../../../assets/image-guides/bad-2.png';
import goodImage from '../../../assets/image-guides/good-1.png';
import { getBase64StringFromBlob } from '../imageUtils';

import './FileSelector.css';

class FileSelector extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    accept: PropTypes.string,
    showImageGuidelines: PropTypes.bool
  };

  state = {
    active: false
  };

  async handleChange(event) {
    event.preventDefault();

    this.setState({ active: false });

    const file = (event.target.files && event.target.files[0]) || (event.dataTransfer.files[0]);

    const base64Url = await getBase64StringFromBlob(file);

    this.props.onChange(base64Url);
  }

  handleDragEnter(event) {
    event.preventDefault();
    this.setState({ active: true });
  }

  handleDragLeave(event) {
    event.preventDefault();
    this.setState({ active: false });
  }

  render() {
    const { active } = this.state;
    const { showImageGuidelines, children } = this.props;

    return (
      <I18NextContext.Consumer>
        {({ strings }) => (

          <label
            className={`tac c-file-selector ${active ? 'c-file-selector--drag-over' : ''}`}
            onDrop={this.handleChange.bind(this)}
            onDragOver={e => e.preventDefault()} // Not needed in real life, but onDrop doesn't work without it
            onDragEnter={this.handleDragEnter.bind(this)}
            onDragLeave={this.handleDragLeave.bind(this)}
          >
            {children}
            <h1 className='p1 m1'>
              {strings('ui.component.fileSelector.dropFileHereOr')}&nbsp;
              <u>{strings('ui.component.fileSelector.browse')}</u>.
            </h1>
            {showImageGuidelines && (
              <div className='mt4'>
                <h3>{strings('ui.component.fileSelector.whatMakesAGoodImage')}</h3>
                <div className='wrap--contents unwrap--contents mla mra' style={{ maxWidth: 'fit-content' }}>
                  <div className='fg1 mt2' style={{ width: '300px' }}>
                    <img src={goodImage}/>
                    <h6>{strings('ui.component.fileSelector.good')}</h6>
                    <p>{strings('ui.component.fileSelector.brightAndColorful')}</p>
                  </div>
                  <div className='fg1 mt2' style={{ width: '300px' }}>
                    <img src={badImage1}/>
                    <h6>{strings('ui.component.fileSelector.bad')}</h6>
                    <p>{strings('ui.component.fileSelector.smallBlurryDull')}</p>
                  </div>
                  <div className='fg1 mt2' style={{ width: '300px' }}>
                    <img src={badImage2}/>
                    <h6>{strings('ui.component.fileSelector.bad')}</h6>
                    <p>{strings('ui.component.fileSelector.textAboveImage')}</p>
                  </div>
                </div>
              </div>
            )}
            <input type="file" onChange={this.handleChange.bind(this)} accept={this.props.accept} hidden/>
          </label>
        )}
      </I18NextContext.Consumer>
    );
  }
}

export default FileSelector;
