import React from 'react';
import { Button, Modal, ModalContent, ModalBody, ModalOverlay, ModalHeader, ModalFooter, HStack, Text } from '@chakra-ui/react';
import { useForm } from 'react-final-form';

const ChangeBusinessAccessModal = ({
  isOpen,
  onClose,
  activeTab,
  setActiveTab,
  canUpdateRoles,
}: {
  isOpen: boolean,
  onClose: VoidFunction,
  activeTab: number,
  canUpdateRoles: boolean,
  setActiveTab: (tab: number) => void,
}): JSX.Element => {
  const form = useForm();

  const onConfirmClick = () => {
    if (canUpdateRoles) {
      if (!activeTab) {
        form.change('roles', []);
      } else {
        form.change('locations', {});
        form.change('locationRoles', {});
      }
    }

    setActiveTab(activeTab ? 0 : 1);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Business Access</ModalHeader>
        <ModalBody>
          <Text fontSize='lg' fontWeight={400}>
            {activeTab ? (
              `Changing this user a business role will remove location level roles. Are you sure you want to continue?`
            ) : (
              `Changing this user to a location role will remove business level access. Are you sure you want to continue?`
            )}
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={5}>
            <Button onClick={onClose}>Cancel</Button>
            <Button colorScheme='blue' onClick={onConfirmClick}>Continue</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeBusinessAccessModal;
