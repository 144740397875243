/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import View from 'web-react-ui/src/components/layout/View';

import CollectionsList from './CollectionsList';
import RelativeLink from '../routing/RelativeLink';

class CollectionsView extends Component {
  static propTypes = {
    property: PropTypes.object
  };

  render() {
    const { property } = this.props;
    return (
      <div>
        <View className='bg--gray brdr--b'>
          <View.Section className='flex'>
            <RelativeLink
              style={{ marginLeft: 'auto' }}
              className='ui button primary'
              to={'./add'}>
              Add Collection
            </RelativeLink>
          </View.Section>
        </View>
        <View>
          <View.Section className='mt3'>
            <CollectionsList property={property}/>
          </View.Section>
        </View>
      </div>
    );
  }
}

export default CollectionsView;
