import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Flex,
  MenuDivider,
  MenuGroup,
  MenuItem,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const PropertyHeader = ({ property }) => {
  const statusColor = property.status === 'live' ? 'green' : 'orange';
  return (
    <Box mb="1em">
      <Flex>
        <Text fontWeight="bold">{property.name}</Text>
        {property.status && (
          <Badge ml="auto" colorScheme={statusColor} p="1">{property.status}</Badge>
        )}
      </Flex>
      <Text fontColor="silver" fontSize="sm">Current Property</Text>
    </Box>
  );
};

const PropertyMenu = ({ property }) => {
  if (!property) return null;

  return (
    <>
      <MenuDivider />
      <MenuGroup title={
        <PropertyHeader property={property} />
      }>
        <MenuItem as={Link} to={`/${property.id}/property-settings`}>Property Settings</MenuItem>
        <MenuItem as={Link} to="/properties?no-redirect">Change Property <ArrowForwardIcon ml="auto" /></MenuItem>
      </MenuGroup>
    </>
  );
};

export default PropertyMenu;
