// Based on /GetintheLoop/integrations-firebase/blob/dev/functions/shared/syndication/content-filter/keywords.js

exports.isDealPhrases = [
  '%',
  '$',
  '¢',
  'cent',
  'save',
  'sale',
  'free',
  'complimentary',
  'bogo',
  'buy one, get one',
  'buy one get one',
  /buy \d get \d/gm, // buy 5 get 2
  'for the price of',
  '1/2 price',
  'half-price',
  'half price',
  '1/2 off',
  /\d for \d/gm, // 2 for 1,
  'win ',
  'win a',
  'entered to win',
  'enter to win',
  'giveaway',
  'gift with purchase',
  'bonus',
  'bonus points',
  'x points', // 2x points, 4x points
  'earn double points',
  // non-offer but relevent
  'happy hour',
  'happy-hour',
  // very specific cases found in some offers
  'member discount', // https://getintheloop.ca/deal/member-discount
  //
  // -- FRENCH --
  //
  'cent',
  'épargnez',
  'vente',
  'gratuit',
  'complémentaire',
  'achetez-en un, obtenez-en un',
  'achetez-en un obtenez-en un',
  /acheter \d obtenez d/, // acheter 5 obtenez 2
  'au prix de',
  'prix 1/2',
  'sauvez',
  'économiser',
  'économisez',
  'gagnez',
  'promotion',
  'promotions',
  'prix',
  'au prix de',
  'sans frais',
  'aubaine',
  'aubaines',
  'mega-vente',
  'mega vente',
  'moitié-prix',
  'moitié prix',
  '1/2 rabais',
  /\d pour \d/, // 2 pour 1,
  'gagner',
  'gagner un',
  'entré pour gagner',
  'entrer pour gagner',
  'cadeau',
  'cadeau avec achat',
  'gagner deux points',
  // non-offer but relevent
  'l\'apéritif',
  'apéritif',
  // very specific cases found in some offers
  'rabais pour les membres', // member discount
];
