/**
 * FIXME: Change ImageWell to load fileTypeInfo before it can be interacted with.
 * Then remove ImageUploadModal's handling for loading fileTypeInfo.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { injectClient } from 'shared/src/components/contexts/ClientContext';
import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import BigModal from '../../../BigModal/BigModal';
import TagInput from '../../../reactFinalForm/fields/TagInput';
import Message from '../../collections/Message';
import Button from '../../elements/Button';
import SuiImage from '../../elements/Image';
import Loader from '../../elements/Loader';
import ErrorMessage from '../../entities/ErrorMessage';
import {
  formatFileDimensions,
  formatFileSize,
  formatFileTypes,
  getBase64StringFromBlob,
  getImageFileUrlAsFile,
  getImageFileUrlDimensions,
  getMimeTypeFromFileUrl
} from '../imageUtils';
import withFileType from '../withFileType';
import FileSelector from './FileSelector';
import ImageCropModal from './ImageCropModal2';


function validateFileRestrictions(fileSize, fileDimensions, mimeType, fileTypeInfo) {
  if (!fileTypeInfo) return false;
  const {
    restrictions: {
      maxSize, types, dimensions: {
        maxWidth, minWidth, maxHeight, minHeight
      }
    }
  } = fileTypeInfo;
  const { width, height } = fileDimensions || {};
  if (width < minWidth || width > maxWidth) return false;
  if (height < minHeight || height > maxHeight) return false;
  if (!types.includes(mimeType)) return false;
  return fileSize <= maxSize;
}

const formatImageInfo = (fileSize, fileDimensions, mimeType) => {
  const { width, height } = fileDimensions;
  return `${formatFileTypes(mimeType)} • ${width}x${height} • ${formatFileSize(fileSize)}`;
};

const formatFileTypeInfo = (fileTypeInfo) => {
  if (!fileTypeInfo) return '';
  const {
    restrictions: {
      maxSize, types, dimensions: {
        maxWidth, minWidth, maxHeight, minHeight
      }
    }
  } = fileTypeInfo;
  const fileTypes = formatFileTypes(types);
  const fileDimensions = formatFileDimensions([{ w: minWidth, h: minHeight }, { w: maxWidth, h: maxHeight }]);
  const fileSize = formatFileSize(maxSize);

  return `${fileTypes} • ${fileDimensions} • ≤${fileSize}`;
};

const UploadMessage = ({
  fileSize, fileDimensions, mimeType, fileTypeInfo, strings
}) => {
  const isValid = validateFileRestrictions(fileSize, fileDimensions, mimeType, fileTypeInfo);

  const info = formatImageInfo(fileSize, fileDimensions, mimeType);

  const restrictions = formatFileTypeInfo(fileTypeInfo);

  return isValid
    ? null
    : (<Message warning>
      {strings('ui.component.imageUploadModal2.doesNotMeetRequirements')}
      <br/>
      <em>{info}</em>&nbsp;-&nbsp;
      {strings('ui.component.imageUploadModal2.shouldBe')}&nbsp;-&nbsp;
      <em>{restrictions}</em>
    </Message>);
};

UploadMessage.propTypes = {
  fileSize: PropTypes.number,
  fileDimensions: PropTypes.object,
  mimeType: PropTypes.string,
  fileTypeInfo: PropTypes.object,
  strings: PropTypes.func.isRequired
};

class CropErrorBoundary extends Component {
  state = {};

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <I18NextContext.Consumer>
          {({ strings }) => (
            <h1>
              {strings('ui.component.imageUploadModal2.somethingWentWrong')}
            </h1>
          )}
        </I18NextContext.Consumer>
      );
    }
    return children;
  }
}

class ImageUploadModal extends Component {
  static propTypes = {
    client: PropTypes.object,
    fileType: PropTypes.string,
    fileTypeInfo: PropTypes.object,
    initialImage: PropTypes.string,
    enableCrop: PropTypes.bool,
    skipUpload: PropTypes.bool,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    property: PropTypes.string,
    business: PropTypes.string,
    location: PropTypes.string,
    showImageGuidelines: PropTypes.bool,
    aspectRatio: PropTypes.number
  };

  state = {
    image: null,
    keywords: []
  };

  componentDidMount() {
    const { initialImage } = this.props;
    if (initialImage) this.handleFileChange(initialImage);
  }

  componentDidUpdate(prevProps) {
    const { fileTypeInfo } = this.props;
    if (fileTypeInfo && fileTypeInfo !== prevProps.fileTypeInfo) this.handleFileChange();
  }

  // FIXME: All this needs to re-evaluated when fileTypeInfo is passed in as a prop...
  async handleFileChange(newValue) {
    if (newValue === null) {
      return this.setState({
        image: null, dimensions: null, mimeType: null, imageFile: null, canUpload: false
      });
    }

    const image = newValue || this.state.image;
    if (!image) return null;

    this.setState({ isProcessing: true });

    const dimensions = await getImageFileUrlDimensions(image);
    const imageFile = await getImageFileUrlAsFile(image);
    const mimeType = getMimeTypeFromFileUrl(image);

    const canUpload = validateFileRestrictions(imageFile.size, dimensions, mimeType, this.props.fileTypeInfo);
    if (!canUpload && this.props.fileTypeInfo && this.props.enableCrop) this.startCrop();

    return this.setState({
      image,
      dimensions,
      mimeType,
      imageFile,
      canUpload,
      isProcessing: false
    });
  }

  async startCrop() {
    // HACK: Let component render once before rendering the Cropper
    // (which can tie up the main thread if the image is very large)
    this.setState({ isPreparingToCrop: true });
    await new Promise(res => setTimeout(res));
    this.setState({ isPreparingToCrop: false, isCropping: true });
  }

  handleCropDone(newValue) {
    this.setState({ isCropping: false });
    if (newValue) this.handleFileChange(newValue);
  }

  handleUpload() {
    const {
      client, fileType, onChange, property, business, location
    } = this.props;
    const { imageFile, dimensions: { width, height }, keywords } = this.state;

    this.setState({ isUploading: true, error: null });
    client.images.create(imageFile, fileType, imageFile.type, width, height, imageFile.size)
      .then(
        async (uploadedImage) => {
          if (property) {
            let context = client.properties.for(property);
            if (business) context = context.businesses.for(business);
            if (location) context = context.locations.for(location);
            try {
              await context.images.link(uploadedImage.id, { keywords });
            } catch (err) {
              // If we couldn't add it to the library, we still want to be able to use the image
            }
          }

          this.setState({ isUploading: false });
          onChange(uploadedImage.id);
        },
        (err) => {
          this.setState({ isUploading: false, error: err });
        }
      );
  }

  async handleReturnRaw() {
    const { onChange } = this.props;
    const { imageFile } = this.state;
    onChange(await getBase64StringFromBlob(imageFile));
  }

  onKeywordUpdate(keywords) {
    this.setState({ keywords });
  }

  render() {
    const {
      fileTypeInfo, enableCrop, skipUpload, onCancel, property, showImageGuidelines, aspectRatio
    } = this.props;
    const {
      image, imageFile, dimensions, mimeType, keywords, error,
      canUpload, isCropping, isUploading, isProcessing, isPreparingToCrop
    } = this.state;

    const isReady = !!fileTypeInfo;
    const types = fileTypeInfo && fileTypeInfo.restrictions.types;
    const canLink = !!property;

    return (
      <I18NextContext.Consumer>
        {({ strings }) => (
          <React.Fragment>
            <BigModal open>
              <BigModal.Contents>
                <BigModal.Header>
                  <BigModal.CloseButton
                    className="c-cancel-button--floating"
                    onClick={onCancel}
                  />
                </BigModal.Header>
                <BigModal.Body>
                  {isReady && (
                    image
                      ? <SuiImage src={image} centered rounded style={{ maxHeight: '100%' }}/>
                      : (
                        <FileSelector
                          onChange={this.handleFileChange.bind(this)}
                          accept={types ? types.join(',') : null}
                          showImageGuidelines={showImageGuidelines}
                        />
                      )
                  )}
                </BigModal.Body>
                <BigModal.Footer className="p1">

                  {isReady && imageFile && !isProcessing && !isPreparingToCrop &&
                  <UploadMessage
                    fileSize={imageFile.size}
                    fileDimensions={dimensions}
                    mimeType={mimeType}
                    fileTypeInfo={fileTypeInfo}
                    strings={strings}
                    className="mb1"
                  />}

                  {(isProcessing || isPreparingToCrop) && <Message>
                    <Loader size="mini" active inline/>&nbsp;
                    {strings('ui.component.imageUploadModal2.processingImage')}...
                  </Message>}

                  <ErrorMessage error={error}/>

                  {this.state.tagging && (
                    <TagInput
                      value={keywords}
                      onChange={this.onKeywordUpdate.bind(this)}
                      className="mb1"
                      placeholder={strings('ui.component.imageUploadModal2.field.placeholder.tag')}
                    />
                  )}

                  <div className="flex">
                    {enableCrop && (
                      <Button
                        disabled={!image || !isReady}
                        onClick={this.startCrop.bind(this)}
                      >
                        {strings('ui.component.imageUploadModal2.crop')}.
                      </Button>
                    )}

                    {canLink && (
                      <Button
                        onClick={() => this.setState({ tagging: !this.state.tagging })}
                      >
                        {strings('ui.component.imageUploadModal2.tag')}.
                      </Button>
                    )}

                    {skipUpload ? (
                      <Button
                        className='mla'
                        disabled={!canUpload}
                        onClick={this.handleReturnRaw.bind(this)}
                        primary
                      >
                        {strings('ui.component.imageUploadModal2.done')}
                      </Button>
                    ) : (
                      <Button
                        className='mla'
                        disabled={!canUpload}
                        onClick={this.handleUpload.bind(this)}
                        loading={isUploading}
                        primary
                      >
                        {strings('ui.component.imageUploadModal2.upload')}
                      </Button>
                    )}
                  </div>


                </BigModal.Footer>
              </BigModal.Contents>
            </BigModal>
            {
              isCropping && <CropErrorBoundary>
                <ImageCropModal
                  src={image}
                  onDone={this.handleCropDone.bind(this)}
                  fileTypeInfo={fileTypeInfo}
                  aspectRatio={aspectRatio}
                />
              </CropErrorBoundary>
            }
          </React.Fragment>
        )}
      </I18NextContext.Consumer>
    );
  }
}

export default injectClient(withFileType(ImageUploadModal));
