import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormLabel,
  Input,
  Select,
  VStack
} from '@chakra-ui/react';
import { Field } from 'react-final-form';

const RedemptionPunches = ({ name, dashboard, ...boxProps }) => {
  const punchOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  if (!dashboard) punchOptions.unshift(1);
  return (
    <Box {...boxProps}>
      <VStack spacing={5}>
        <Box w="100%">
          <FormLabel>Total Number of Punches</FormLabel>
          <Field name={`${name}.punches`} parse={parseInt}>
            {({ input }) => {
              return (
                <Select value={input.value} onChange={input.onChange} w="50%">
                  {punchOptions.map(option => (
                    <option value={option} key={option}>{option}</option>
                  ))}
                </Select>
              );
            }}
          </Field>
        </Box>
        <Box w="100%">
          <FormLabel>Wait Time Between Each Punch (Hours)</FormLabel>
          <Field name={`${name}.punchUnfrozenAfter`}>
            {({ input }) => {
              return (
                <Input type="number" value={input.value} onChange={input.onChange} min="0" w="50%" />
              );
            }}
          </Field>
        </Box>
      </VStack>


    </Box>
  );
};

RedemptionPunches.propTypes = {
  name: PropTypes.string,
  dashboard: PropTypes.bool,
};

export default RedemptionPunches;
