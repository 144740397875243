import React from 'react';
import { parse } from 'mini-querystring';
/* eslint-env browser */
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Shell from 'web-react-ui/src/chakra/shell-admin/Shell';
import AuthExpiry from 'web-react-ui/src/components/auth/AuthExpiry';
import AuthReceiver from 'web-react-ui/src/components/auth/AuthReceiver';
import { ToastContainer } from 'web-react-ui/src/components/toasts';

import gitInfo from './.git-info.json';

import './App.css';

import HomeView from './components/home/HomeView';
import InitialState from './components/InitialState';
import LoginView from './components/login/LoginView';
import PrivateRoute from './components/PrivateRoute';
import Profile from './components/Profile';
import PropertyCreateContainer from './components/property/PropertyCreateContainer';
import PropertySelectView from './components/property/PropertySelectView';
import SiteFooter from './components/siteFooter/SiteFooter';
import Sitenav from './components/sitenav/Sitenav';
import TrackPageView from './components/TrackPageview';
import PropertyDetailsRoutes from './routes/PropertyDetailsRoutes';


function App() {
  // eslint-disable-next-line no-console
  console.info(`${gitInfo.name} v${gitInfo.version} (build ${gitInfo.hash})`);
  return (
    <AuthReceiver sitePermission="*user.portal.access">
      <ToastContainer />
      <InitialState>
        <InitialState.Loading>
          <Shell>
            <Shell.Header><Sitenav/></Shell.Header>
            <Shell.Footer><SiteFooter/></Shell.Footer>
          </Shell>
        </InitialState.Loading>
        <Router>
          <Shell>
            <Shell.Header>
              <Switch>
                <Route path="/login" />
                <Route path="/" component={Sitenav} />
              </Switch>
            </Shell.Header>
            <Shell.Footer>
              <Switch>
                <Route path="/login" />
                <Route path="/" component={SiteFooter} />
              </Switch>
            </Shell.Footer>
            <AuthExpiry />
            <TrackPageView gtag={window.gtag} gaKey={process.env.REACT_APP_GA_KEY} />
            <Switch>
              <Route path="/" exact component={HomeView} />
              <Route path="/login" component={LoginView} />
              <PrivateRoute path="/properties/new" component={PropertyCreateContainer} />
              <PrivateRoute
                exact
                render={({ location }) => {
                  const skipRedirect = parse(location.search)['no-redirect'];
                  return <PropertySelectView skipRedirect={skipRedirect} />;
                }}
                path="/properties"
              />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute
                path="/:propertySlug"
                render={props => <PropertyDetailsRoutes
                  propertyId={props.match.params.propertySlug} {...props} />}
              />
            </Switch>
          </Shell>
        </Router>
      </InitialState>
    </AuthReceiver>
  );
}

App.propTypes = {
  match: PropTypes.shape({
    params: {
      propertySlug: PropTypes.string,
    }
  })
};

export default App;
