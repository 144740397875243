// React
import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

// web-react-ui
import OfferPriorityEnum from '../../data/enums/offers/OfferPriorityEnum';
import highestPriorityIcon from '../../assets/offer-priority-icons/highest_priority.png';
import highPriorityIcon from '../../assets/offer-priority-icons/high_priority.png';
import defaultPriorityIcon from '../../assets/offer-priority-icons/default_priority.png';
import lowPriorityIcon from '../../assets/offer-priority-icons/low_priority.png';
import lowestestPriorityIcon from '../../assets/offer-priority-icons/lowest_priority.png';

const priorityImages = [
  {
    id: OfferPriorityEnum.HIGHEST, src: highestPriorityIcon, alt: '', toolTip: ''
  },
  {
    id: OfferPriorityEnum.HIGH, src: highPriorityIcon, alt: '', toolTip: ''
  },
  {
    id: OfferPriorityEnum.DEFAULT, src: defaultPriorityIcon, alt: '', toolTip: ''
  },
  {
    id: OfferPriorityEnum.LOW, src: lowPriorityIcon, alt: '', toolTip: ''
  },
  {
    id: OfferPriorityEnum.LOWEST, src: lowestestPriorityIcon, alt: '', toolTip: ''
  },
];

const iconStyle = {
  width: '17px',
  height: '17px',
};

const getPriorityText = (priority, strings) => {
  let text = '';

  switch (priority) {
    case OfferPriorityEnum.HIGHEST:
      text = strings('ui.component.offerPriority.highestPriority');
      break;
    case OfferPriorityEnum.HIGH:
      text = strings('ui.component.offerPriority.highPriority');
      break;
    case OfferPriorityEnum.LOW:
      text = strings('ui.component.offerPriority.lowPriority');
      break;
    case OfferPriorityEnum.LOWEST:
      text = strings('ui.component.offerPriority.lowestPriority');
      break;
    case OfferPriorityEnum.DEFAULT:
    default:
      text = strings('ui.component.offerPriority.defaultPriority');
      break;
  }

  return text;
};

const OfferPriority = ({ priority }) => {
  const { strings } = useI18Next();

  const offerPriorityImg = priorityImages.find(image => image.id === priority);

  if (offerPriorityImg) {
    // Build and assign the tooltip/alt text
    offerPriorityImg.toolTip = getPriorityText(priority, strings);
    offerPriorityImg.alt = `${offerPriorityImg.toolTip}${strings('ui.component.offerPriority.icon')}`;

    return (
      <img
        alt={offerPriorityImg.alt}
        src={offerPriorityImg.src}
        style={iconStyle}
        title={offerPriorityImg.toolTip}
      />
    );
  }

  return null;
};

OfferPriority.propTypes = {
  priority: PropTypes.number
};

OfferPriority.defaultProps = {
  priority: OfferPriorityEnum.DEFAULT,
};

export default OfferPriority;
