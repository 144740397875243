import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import SuiForm from '../../components/collections/Form';

import FieldError from './FieldError';

const BooleanField = (props) => {
  const {
    name,
    label,
    validate,
    format,
    parse,
    checked,
    info,
    ...rest
  } = props;

  return (
    <Field name={name} validate={validate} format={format} parse={parse}>
      {({ input: { onChange: inputOnChange, value, ...inputRest } }) => {
        const onChange = () => {
          inputOnChange(!value);
        };
        const isChecked = checked ? checked(value) : !!value;
        return (
          <SuiForm.Field>
            <SuiForm.Checkbox label={label} {...inputRest} {...rest} onChange={onChange} checked={isChecked}/>
            { info && <p className='text--muted'>{info}</p> }
            <FieldError name={name}/>
          </SuiForm.Field>
        );
      }}
    </Field>
  );
};
BooleanField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  checked: PropTypes.func,
  info: PropTypes.string
};

export default BooleanField;
