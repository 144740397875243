import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  FormLabel,
  Heading,
  Link,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import Card from '../../chakra/forms/Card';
import RadioButtonGroupField from '../../chakra/forms/RadioButtonGroup/RadioButtonGroupField';
import AddSectionButton from '../../chakra/forms/RevealField/AddSectionButton';
import RemoveSectionButton from '../../chakra/forms/RevealField/RemoveSectionButton';
import RevealField from '../../chakra/forms/RevealField/RevealField';

import {
  Field,
  useFormState
} from '../../reactFinalForm';
import {
  DateField,
  TimeField
} from '../../reactFinalForm/fields';
import ToggleGroupField from '../../reactFinalForm/fields/ToggleGroupField';
import {
  compose,
  required,
  requiredArray
} from '../../reactFinalForm/validators';
import { PerformanceCheckAlert } from './PerformanceCheckAlert';

const validateTimeRange = (startTime, strings) => {
  const timeValidationError = strings('ui.component.offerScheduleFields.validateTimeRange');

  const [startH, startM] = startTime && startTime.split(':').map(token => parseInt(token, 10));
  const startMinutes = (startH * 60) + startM;
  return (endTime) => {
    const [endH, endM] = endTime && endTime.split(':').map(token => parseInt(token, 10));
    const endMinutes = (endH * 60) + endM;
    if (startMinutes > endMinutes) return timeValidationError;
    return null;
  };
};

const OfferScheduleFields = ({ name, dashboard, endDateWarning, plan, plansHref }) => {
  const { strings } = useI18Next();
  const { values } = useFormState();

  const allowsAdvancedSchedule = plan?.settings.offer__feature__misc__allow_advanced_scheduling;
  const advanced = values.schedules?.[0]?.advanced;

  const weekDays = [
    { label: strings('ui.shared.weekDays.letrSu'), value: 0 },
    { label: strings('ui.shared.weekDays.letrM'), value: 1 },
    { label: strings('ui.shared.weekDays.letrT'), value: 2 },
    { label: strings('ui.shared.weekDays.letrW'), value: 3 },
    { label: strings('ui.shared.weekDays.letrTh'), value: 4 },
    { label: strings('ui.shared.weekDays.letrF'), value: 5 },
    { label: strings('ui.shared.weekDays.letrS'), value: 6 }
  ];

  const validateDate = startDate => (endDate) => {
    if (endDate < startDate) return strings('ui.component.offerScheduleFields.validateDate');
    return null;
  };

  return (
    <VStack spacing={4} align='stretch'>
      {!allowsAdvancedSchedule && advanced && (
        <Text color='red.800' fontWeight={600} fontSize={11} bg='red.100' p={2} mb={5} borderRadius={4}>
          {strings('ui.component.offerScheduleFields.legacyOfferWarning')}
        </Text>
      )}
      <Box>
        <DateField
          name={`${name}.startDate`}
          label={strings('ui.component.offerScheduleFields.field.label.startDate')}
          validate={required}
          validateFields={[`${name}.endDate`]}
          wrapLabel
          disabled={Boolean(!allowsAdvancedSchedule && advanced)}
          chakra
        />
      </Box>

      {!allowsAdvancedSchedule && plansHref && (
        <Link
          href={plansHref}
          my={8}
          color='green.500'
          fontWeight={500}
          fontSize={13}
          textDecorationLine='underline'
          isExternal
        >
          {strings('ui.component.offerScheduleFields.upgradeNow')} <ExternalLinkIcon ml={1} />
        </Link>
      )}

      <AddSectionButton name='schedules[0].advanced' alignSelf='flex-start' disabled={!allowsAdvancedSchedule}>
        {strings('ui.component.offerScheduleFields.allowAdvancedScheduling')}
      </AddSectionButton>
      <RevealField name='schedules[0].advanced'>
        <Card>
          <FormLabel htmlFor='advanced-scheduling' m={0}>
            <Heading fontSize={14} fontWeight={500}>
              {strings('ui.component.offerScheduleFields.advancedScheduling')}
              <RemoveSectionButton name='schedules[0].advanced' disabled={!allowsAdvancedSchedule} />
            </Heading>
          </FormLabel>
          <VStack my={4} spacing={4} align='stretch'>
            <RadioButtonGroupField
              name='schedules[0].evergreen'
              disabled={!allowsAdvancedSchedule}
              predicate={(value, optionValue) => Boolean(value) === optionValue}
              options={[
                { label: strings('ui.component.offerScheduleFields.noEndDate'), value: true },
                { label: strings('ui.component.offerScheduleFields.specifyEndDate'), value: false }
              ]}
            />

            <RevealField name='schedules[0].evergreen' predicate={v => !v}>
              <Stack direction='column' spacing={5}>
                {endDateWarning && <PerformanceCheckAlert endDateWarning={endDateWarning}/>}
                <DateField
                  name={`${name}.endDate`}
                  label={strings('ui.component.offerScheduleFields.field.label.endDate')}
                  validate={compose(required, validateDate(values.schedules?.[0]?.startDate))}
                  disabled={!allowsAdvancedSchedule}
                  chakra
                  inputProps={{
                    isInvalid: endDateWarning,
                    errorBorderColor: 'orange.400'
                  }}
                  wrapLabel
                />
              </Stack>
            </RevealField>


            <h3 className='mb0 mt3'>{strings('ui.component.offerScheduleFields.thisOfferIsUsable')}</h3>

            <RadioButtonGroupField
              name={`${name}.allWeekDays`}
              disabled={!allowsAdvancedSchedule}
              predicate={(value, optionValue) => Boolean(value) === optionValue}
              options={[
                { label: strings('ui.component.offerScheduleFields.field.label.allWeekDays'), value: true },
                { label: strings('ui.component.offerScheduleFields.field.label.specificDaysOfWeek'), value: false }
              ]}
            />

            <RevealField name={`${name}.allWeekDays`} predicate={v => !v}>
              <div className='mt1 mb2'>
                <ToggleGroupField
                  name={`${name}.weekDays`}
                  options={weekDays}
                  validate={requiredArray}
                  disabled={!allowsAdvancedSchedule}
                />
              </div>
            </RevealField>


            <RadioButtonGroupField
              name={`${name}.allTimes`}
              disabled={!allowsAdvancedSchedule}
              predicate={(value, optionValue) => Boolean(value) === optionValue}
              options={[
                { label: strings('ui.component.offerScheduleFields.field.label.allBusinessHours'), value: true },
                { label: strings('ui.component.offerScheduleFields.field.label.onlyCertainTimes'), value: false }
              ]}
            />

            <div className='mt1'>
              <Field name={`${name}.allTimes`}>
                {({ input }) => {
                  // timeRanges is in an array: [['00:00', '23:59']]
                  const setTimes = !input.value;
                  if (setTimes) {
                    return (
                      <div className='flex fww'>
                        <div className='fg1 mr1'>
                          <TimeField
                            name={`${name}.timeRanges[0][0]`}
                            label={strings('ui.component.offerScheduleFields.field.label.startTime')}
                            wrapLabel
                            validate={required}
                            disabled={!allowsAdvancedSchedule}
                          />
                        </div>
                        <div className='fg1'>
                          <Field name={`${name}.timeRanges[0][0]`}>
                            {({ input }) => ( // eslint-disable-line no-shadow
                              <TimeField
                                name={`${name}.timeRanges[0][1]`}
                                label={strings('ui.component.offerScheduleFields.field.label.endTime')}
                                wrapLabel
                                validate={compose(required, validateTimeRange(input.value, strings))}
                                disabled={!allowsAdvancedSchedule}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              </Field>
            </div>
            {!dashboard && (
              <React.Fragment>
                <h3 className='mb0 mt3'>
                  {strings('ui.component.offerScheduleFields.thisOfferIsDiscoverable')}
                </h3>

                <RadioButtonGroupField
                  name={`${name}.partialDiscoverableSchedule`}
                  disabled={!allowsAdvancedSchedule}
                  predicate={(value, optionValue) => Boolean(value) === optionValue}
                  options={[
                    { label: strings('ui.component.offerScheduleFields.field.label.allDays'), value: false },
                    {
                      label: strings('ui.component.offerScheduleFields.field.label.onlyOnAvailableDayTime'),
                      value: true
                    }
                  ]}
                />
              </React.Fragment>
            )}
          </VStack>
        </Card>
      </RevealField>
    </VStack>
  );
};

OfferScheduleFields.propTypes = {
  name: PropTypes.string,
  dashboard: PropTypes.bool,
  endDateWarning: PropTypes.string,
  plan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    settings: PropTypes.shape({
      offer__feature__misc__allow_advanced_scheduling: PropTypes.bool
    })
  }),
  plansHref: PropTypes.string
};

export default OfferScheduleFields;
