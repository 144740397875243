import imgixParams from './imgixParams';

const iconizeImageUrl = (url, { size = 36 } = {}) => {
  if (!url) return url;

  return imgixParams(
    url, {
      fit: 'crop',
      w: size,
      h: size,
      auto: 'compress'
    }
  );
};

export default iconizeImageUrl;
