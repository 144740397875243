import React, {
  useCallback,
  useContext
} from 'react';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import SearchBaseField from './SearchBaseField';

const CategoryField = ({ leafOnly, ...rest }) => {
  const client = useContext(ClientContext);

  const search = useCallback(
    async (query) => {
      const result = await client.categories.search({ query, leafOnly });
      return result.items;
    },
    [client, leafOnly]
  );

  return (
    <SearchBaseField search={search} {...rest} />
  );
};

export default CategoryField;
