import {
  Box,
  Center,
  Heading,
  Image,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';
import BigModal from '../../../BigModal/BigModal';
import PageBackButton from '../../../chakra/page/PageBackButton';

const ImagePreviewModal = ({ src }: { src: string }) => {
  return (
    <BigModal open={!!src} size="full" motionPreset="none">
      <BigModal.Contents>
        <BigModal.Header>
          <PageBackButton disabled>Cancel</PageBackButton>
          <Box p="1em">
            <Heading w="100%">Add Photo</Heading>
          </Box>
        </BigModal.Header>
        <BigModal.Body>
          <Center w="100%" h="100%">
            <Image src={src} w="100%" h="100%" objectFit="contain" />
            <Center w="100%" h="100%" bgColor="#ffffff55" position="absolute">
              <Spinner />
            </Center>
          </Center>
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

export default ImagePreviewModal;
