import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import useRequest from 'shared/src/hooks/useRequest';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import EditOfferMenu from 'web-react-ui/src/components/offers/EditOfferMenu';
import OfferTypeSelection from 'web-react-ui/src/components/offers/OfferTypeSelection';
import { FormSpy } from 'web-react-ui/src/reactFinalForm';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import FormDebugger from 'web-react-ui/src/reactFinalForm/FormDebugger';
import { atLeastOneCompleteLocalization } from 'web-react-ui/src/reactFinalForm/validators';

import businessModule from '../../modules/businesses';
import propertyModule from '../../modules/property';
import plansModule from '../../modules/plan';
import client from '../../services/client';
import confirm from '../../services/confirm';
import pipelines from '../../services/pipelines';
import ConnectedTranslationWrapper from '../i18n/ConnectedTranslationWrapper';

const NewEditOfferContainer = ({
  offerId,
  redirectTo,
}: {
  offerId: string,
  redirectTo?: string
}): JSX.Element => {
  const business = useSelector(state => businessModule.selectors.business.getData(state));
  const property = useSelector(state => propertyModule.selectors.property.getData(state));
  const plan = useSelector(state => plansModule.selectors.plan.getData(state));
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState<string | undefined>(undefined);

  const getOfferInstance = () => client
    .properties.for(property.id)
    .businesses.for(business.id)
    .offers.for(offerId)
    .edit();

  const offerInstanceRequest = useRequest(getOfferInstance, { property, business, offerId });

  const handleCancel = (dirty: boolean) => {
    if (dirty && !confirm(`Are you sure you want to cancel? You will lose any unsaved changes.`)) return false;
    return setRedirect(redirectTo);
  };

  const handleSubmit = async (offer: { [key: string]: any }) => {
    try {
      setIsLoading(true);
      // TODO: Pull out EditOfferPipeline instance values individually?
      // Form EditOfferForm needs to be updated to look for instance elements separately instead of under `offer`
      await offerInstanceRequest.result.deprecatedUpdateWithOldOfferModel(offer);
      const offerDetailsUrl = `/${property.id}/businesses/${business.id}/offers/${offerId}`;
      setRedirect(offerDetailsUrl);
    } catch (err: any) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getOfferType = (flags: string[]): string => {
    if (flags.includes('punchcard')) return 'punchcard';
    if (flags.includes('limited')) return 'limited';
    if (flags.includes('exclusive')) return 'exclusive';
    return 'reach';
  };

  if (redirect) return <Redirect to={redirect} />;

  const initialValues = offerInstanceRequest.result
    ? offerInstanceRequest.result.deprecatedGetOldOfferModel()
    : null;

  return (
    <BigModal open>
      <ConnectedTranslationWrapper>
        <LocalizableSimpleForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={isLoading || offerInstanceRequest.loading}
          validate={pipelines.OfferUpdatePipeline.deprecatedValidateWithOldOfferModel}
          validateLocale={atLeastOneCompleteLocalization([
            'headlineLocalized', 'subHeadlineLocalized', 'detailsLocalized'
          ])}
          chakra
        >
          <FormSpy subscription={{ values: true }}>
            {({ values }: { [key: string]: any }) => {
              if (!Object.keys(values).length) return null;
              const existingOfferType = getOfferType(values.flags);
              return (
                <BigModal.Contents>
                  <BigModal.Header className='p1'>
                    <Box maxW='8xl' mx='auto' p={3}>
                      <EditOfferMenu
                        handleCancel={handleCancel}
                        isEdit={true}
                        existingOfferType={existingOfferType}
                      />
                    </Box>
                  </BigModal.Header>
                  <BigModal.Body>
                    <Box my={5} maxW='8xl' mx='auto'>
                      <OfferTypeSelection
                        propertyId={property.id}
                        businessId={business.id}
                        existingOfferType={existingOfferType}
                        plan={plan}
                      />
                    </Box>
                    <FormDebugger />
                  </BigModal.Body>
                </BigModal.Contents>
              );
            }}
          </FormSpy>
        </LocalizableSimpleForm>
      </ConnectedTranslationWrapper>
    </BigModal>
  );
};

export default NewEditOfferContainer;
