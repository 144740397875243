import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import RegionFilterSet from 'web-react-ui/src/components/filters/RegionFilterSet';
import { FormSpy } from 'web-react-ui/src/reactFinalForm';
import { FieldError } from 'web-react-ui/src/reactFinalForm/fields';
import JsonTextArea from 'web-react-ui/src/reactFinalForm/fields/JsonTextArea';
import regionCache from '../../services/regionCache';
import MapRegions from './regions/MapRegions';

// eslint-disable-next-line consistent-return
const validateCollectionPolygons = async (polygons = []) => {
  try {
    const warnings = await regionCache.validateCollectionPolygons(polygons);
    if (!warnings || !warnings.length) return null;

    switch (warnings[0].name) {
      case 'PerimeterPointCountExceeded':
      case 'PolygonPointCountExceeded':
        return 'The regions selected have exceeded the maximum polygon limit';
      default:
        return warnings[0].message;
    }
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.error('Error validating polygons: ', err);
  }
};

const RegionPolygonReducer = ({ name }) => {
  const { input: regionFilterSetInput, meta } = useField(name);
  const { input: polyInput } = useField('collection.polygons', { validate: validateCollectionPolygons });

  useEffect(
    () => {
      const effect = async () => {
        // Don't do anything if filter value isn't initialized
        if (!regionFilterSetInput.value.filters) return;
        // Only update polygons in response to filter change
        if (!meta.dirty) return;
        const { polygons } = await regionCache.mergeRegionFilters(regionFilterSetInput.value.filters);
        polyInput.onChange(polygons);
      };
      effect();
    },
    [regionFilterSetInput.value] // Assuming this is stable if the form value doesn't change
  );

  return (
    <React.Fragment>
      <FieldError alwaysShow name='collection.polygons' />
      <details>
        <summary>Edit Area</summary>
        <JsonTextArea name='collection.polygons' />
      </details>
    </React.Fragment>
  );
};

RegionPolygonReducer.propTypes = {
  name: PropTypes.string
};

const CollectionRegionFilters = ({ name }) => {
  return (
    <React.Fragment>
      <RegionFilterSet name={name} />
      <RegionPolygonReducer name={name} />
      <FormSpy subscription={{ values: true }}>
        {({ values }) => <MapRegions collection={values} />}
      </FormSpy>
    </React.Fragment>
  );
};

CollectionRegionFilters.propTypes = {
  name: PropTypes.string
};

export default CollectionRegionFilters;
