import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useState
} from 'react';

import usePromise from 'shared/src/hooks/usePromise';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import Markdown from 'web-react-ui/src/components/Markdown';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';

import client from '../../services/client';
import confirm from '../../services/confirm';

import {
  Flex,
  Radio,
  Text,
  Container,
  VStack,
  Box,
  Badge,
} from '@chakra-ui/react';

import {
  List as ChakraList,
  Item,
  Empty,
  Loading,
} from 'web-react-ui/src/chakra/List';

const PlanItem = ({ plan, selected, onClick }) => {
  const planId = plan && plan.id;
  const selectedId = selected && selected.id;

  return (
    <Item onClick={onClick}>
      <Flex gap={4}>
        <Radio isChecked={planId === selectedId} />
        <Flex direction="column">
          <Text>
            {plan.name}
            {plan.discoverable ? null : <Badge>Hidden</Badge>}
          </Text>
          <Markdown>{plan.description}</Markdown>
        </Flex>
      </Flex>
    </Item>
  );
};

PlanItem.propTypes = {
  plan: PropTypes.object,
  selected: PropTypes.object,
  onClick: PropTypes.func
};

const PlanList = ({ children }) => {
  return <ChakraList>{children}</ChakraList>;
};

const ChangePlanModal = ({
  business, property, onComplete, onCancel
}) => {
  // FIXME: Change to fetch business' plan when that API route is available
  const [fetchCurrentPlan, isFetchingCurrentPlan, currentPlan] = usePromise(
    () => client
      .properties.for(property.id)
      .businesses.for(business.id)
      .getPlan()
      .catch(() => null),
    [property, business]
  );

  const [selectedPlan, setSelectedPlan] = useState();

  const [fetchPlans] = usePromise(
    () => client
      .properties.for(property.id)
      .plans.search({ discoverable: null }),
    [property]
  );

  useEffect(() => {
    fetchCurrentPlan()
      .then((plan) => {
        if (!selectedPlan) setSelectedPlan(plan);
        return plan;
      });
  }, [fetchCurrentPlan]);

  const changePlan = useCallback(
    () => {
      const isConfirmed = confirm(
        `Changing the subscription plan will change the billing amount and feature access of this business.

Are you sure you want to change ${business.name}'s plan to ${selectedPlan.name}?`
      );
      if (!isConfirmed) return false;
      return client
        .properties.for(property.id)
        .businesses.for(business.id)
        .updatePlan(selectedPlan.id)
        .then(onComplete);
    },
    [property, business, selectedPlan]
  );

  return (
    <BigModal open>
      <SimpleForm onSubmit={changePlan}>
        <BigModal.Contents>
          <BigModal.Header className="p1">
            <div className="flex">
              <h1>Change Business Plan</h1>
              <div className="mla">
                <CancelButton onClick={onCancel} />
              </div>
            </div>
          </BigModal.Header>
          <BigModal.Body>
            <Container>
              <VStack spacing="24px" align="stretch">
                <Box>
                  <h3> Current Plan</h3>
                  <PlanList>
                    <Empty isEmpty={!isFetchingCurrentPlan && !currentPlan}>No plan selected</Empty>
                    <Loading isLoading={isFetchingCurrentPlan} repeat={1} />
                    {currentPlan && (
                      <PlanItem
                        plan={currentPlan}
                        selected={selectedPlan}
                        onClick={() => setSelectedPlan(currentPlan)}
                      />
                    )}
                  </PlanList>
                </Box>
                <Box>
                  <h3>Other Plans</h3>
                  <PagedList fetchList={fetchPlans} params={{ business }}>
                    {({ items, isEmpty, loading, settled }) => {
                      const plans = items.filter(plan => plan.id !== (currentPlan && currentPlan.id));
                      return (
                        <PlanList>
                          <Empty isEmpty={settled && !plans.length}>No other plans</Empty>
                          <Loading isLoading={loading} />
                          {plans.map(plan => (
                            <PlanItem
                              plan={plan}
                              selected={selectedPlan}
                              key={plan.id}
                              onClick={() => setSelectedPlan(plan)}
                            />
                          ))}
                        </PlanList>
                      );
                    }}
                  </PagedList>
                </Box>
              </VStack>
            </Container>
          </BigModal.Body>
          <BigModal.Footer>
            <div className="flex">
              <FormSubmit label="Change" className="mla" />
            </div>
          </BigModal.Footer>
        </BigModal.Contents>
      </SimpleForm>
    </BigModal>
  );
};

ChangePlanModal.propTypes = {
  business: PropTypes.object,
  property: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
};

export default ChangePlanModal;
