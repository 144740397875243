// React
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormLabel,
  Icon,
  Link,
  Text,
  Tooltip
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import AddSectionButton from '../../chakra/forms/RevealField/AddSectionButton';
import RemoveSectionButton from '../../chakra/forms/RevealField/RemoveSectionButton';
import RevealField from '../../chakra/forms/RevealField/RevealField';
import OfferPriorityEnum from '../../data/enums/offers/OfferPriorityEnum';

// web-react-ui
import './PrioritySelectField.css';

// dashboard
import SimpleSelectField from './SimpleSelectField';

const PriorityField = ({ disabled, plansHref }) => {
  const { strings } = useI18Next();

  const priorityOptions = [
    { text: strings('ui.component.prioritySelectField.highestPriority'), value: OfferPriorityEnum.HIGHEST },
    { text: strings('ui.component.prioritySelectField.highPriority'), value: OfferPriorityEnum.HIGH },
    { text: strings('ui.component.prioritySelectField.default'), value: OfferPriorityEnum.DEFAULT },
    { text: strings('ui.component.prioritySelectField.lowPriority'), value: OfferPriorityEnum.LOW },
    { text: strings('ui.component.prioritySelectField.lowestPriority'), value: OfferPriorityEnum.LOWEST }
  ];

  return (
    <Box>
      <AddSectionButton name='showPriority'>
        {strings('dashboard.component.editOfferForm.field.label.overrideOfferOrdering')}
      </AddSectionButton>
      <RevealField name='showPriority' initialize={values => !!values.priority}>
        <Flex>
          <FormLabel>
            <Flex align='center'>
              {strings('ui.component.prioritySelectField.field.label')}
              <Tooltip
                label={strings('ui.component.prioritySelectField.field.info')}
                placement='top'
                hasArrow
              >
                <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
              </Tooltip>
            </Flex>
          </FormLabel>
          <RemoveSectionButton name='showPriority' ml='auto' />
        </Flex>

        <SimpleSelectField
          name='priority'
          options={priorityOptions}
          parse={val => parseInt(val, 10) || 0} // (NaN || 0 will be falsy and we want to default to 0 anyway)
          wrapLabel
          disabled={disabled}
          defaultValue={OfferPriorityEnum.DEFAULT}
          chakra
        />
        {disabled && !!plansHref ?
          <Text color='green.500' mt={3}>{`${strings('ui.component.prioritySelectField.field.infoDisabled')} `}
            <Link
              href={plansHref}
              my={8}
              color='green.500'
              fontWeight={500}
              fontSize={13}
              textDecorationLine='underline'
              isExternal
            >
              {strings('ui.component.prioritySelectField.field.upgradeLinkInfo')} <ExternalLinkIcon ml={1} />
            </Link>
          </Text> :
          null
        }
      </RevealField>
    </Box>
  );
};

PriorityField.propTypes = {
  disabled: PropTypes.bool,
  plansHref: PropTypes.string
};

export default PriorityField;
