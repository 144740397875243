const SOCIAL_PROFILE_FIELDS = [
  { id: 'facebook-page', label: 'Facebook Page' },
  { id: 'instagram', label: 'Instagram' },
  { id: 'twitter', label: 'Twitter' }
];

const formatSocial = (value) => {
  console.log('format', value);
  if (!value) return [];
  return value.map(row => ({ value: row.id, field: row.type }));
};

const parseSocial = (value) => {
  if (!value) return value;
  return value.map(row => ({ id: row.value, type: row.field }));
};

const getLabelForType = (type) => {
  return SOCIAL_PROFILE_FIELDS.find(f => f.id === type)?.label;
};

export {
  SOCIAL_PROFILE_FIELDS,
  formatSocial,
  parseSocial,
  getLabelForType
};
