import {
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

import './BigModal.css';

const BigModal = ({ children, open, ...rest }) => {
  return (
    <Modal
      isOpen={open}
      scrollBehavior="inside"
      motionPreset="none"
      size="full"
      {...rest}
    >
      {/* ModalContent needs to fill its parent so children can be sized correctly */}
      <ModalContent h="100%">
        {children}
      </ModalContent>
    </Modal>
  );
};

BigModal.propTypes = {
  open: PropTypes.bool
};

const BigModalCloseButton = ({ floating, ...rest }) => (<CloseButton
  variant="outline"
  borderRadius="50%"
  m="1em"
  pos="absolute"
  top="0"
  right="0"
  zIndex
  {...rest}
/>);

BigModalCloseButton.propTypes = {
  floating: PropTypes.bool
};

// BigModal.Contents is a wrapper so we can have a Form context both
// a) inside the portal (so the browser sees the elements as actually nested for form submission purposes)
// b) outside of all the modal's contents
BigModal.Contents = ({ children }) => (<div className="c-big-modal">{children}</div>);

BigModal.Header = ModalHeader;
BigModal.Footer = ModalFooter;
BigModal.Body = ModalBody;
BigModal.CloseButton = BigModalCloseButton;

export default BigModal;
