const authState = {};
authState.register = function (callback) {
  this.callback = callback;
}.bind(authState);

authState.open = function () {
  if (this.callback) {
    this.callback();
  }
}.bind(authState);

export default authState;
