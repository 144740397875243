import React from 'react';
import RegionField from '../../reactFinalForm/fields/RegionField';
import FilterSet from './FilterSet';

const FilterRegionField = ({ name }) => {
  return (
    <RegionField name={name} />
  );
};

const OPERATIONS = {
  discoverable: [
    { text: 'Is Discoverable In', value: 'discoverableIn' },
    { text: 'Is Not Discoverable In', value: 'notDiscoverableIn' }
  ]
};

const rowConfig = {
  maxDepth: 0,
  operatorOptions: [{ text: 'AND', value: 'and' }],
  targets: [
    { text: 'Regions', value: 'regions' }
  ],
  getOperations: (target) => {
    switch (target) {
      case 'regions':
        return OPERATIONS.discoverable;
      default:
        return [];
    }
  },
  // eslint-disable-next-line react/display-name
  getComponent: (target, operation) => {
    switch (target) {
      case 'regions':
        return FilterRegionField;
      default:
        return null;
    }
  }
};

const EmptyView = () => (
  <div>
    <em>Will be discoverable in all Regions</em>
  </div>
);

const RegionFilterSet = ({ name }) => {
  return (
    <FilterSet name={name} rowConfig={rowConfig} empty={<EmptyView />} />
  );
};

export default RegionFilterSet;
