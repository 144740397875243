import { useI18Next } from '../components/contexts/I18NextContext';

const useValidationOptions = () => {
  const { strings } = useI18Next();

  return [{
    key: 'none',
    text: strings('shared.offer.validation.options.none.value'),
    info: strings('shared.offer.validation.options.none.info'),
    value: 'none'
  }, {
    key: 'location+fallback',
    text: strings('shared.offer.validation.options.byLocationAndFallback.value'),
    info: strings('shared.offer.validation.options.byLocationAndFallback.info'),
    value: 'location+fallback'
  }, {
    key: 'location',
    text: strings('shared.offer.validation.options.byLocation.value'),
    info: strings('shared.offer.validation.options.byLocation.info'),
    value: 'location'
  }, {
    key: 'location+pin',
    text: strings('shared.offer.validation.options.byLocationAndPin.value'),
    info: strings('shared.offer.validation.options.byLocationAndPin.info'),
    value: 'location+pin'
  }, {
    key: 'pin',
    text: strings('shared.offer.validation.options.byPin.value'),
    info: strings('shared.offer.validation.options.byPin.info'),
    value: 'pin'
  }];
};

export default useValidationOptions;
