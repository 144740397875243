import React, {
  useCallback,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import SearchBaseField from '../../reactFinalForm/fields/SearchBaseField';

const FilterPlanField = ({ name, context = {} }) => {
  const client = useContext(ClientContext);
  const search = useCallback(
    (query) => {
      return client
        .properties.for(context.propertyId)
        .plans.search({ name: query || undefined })
        .then(({ items }) => items);
    },
    [client, context.propertyId]
  );

  return (
    <div>
      <SearchBaseField
        name={name}
        search={search}
        idField='id'
        wrapLabel
        multiSelect
      />
    </div>
  );
};

FilterPlanField.propTypes = {
  name: PropTypes.string,
  context: PropTypes.object
};

export default FilterPlanField;
