import React from 'react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Button,
  HStack,
  Stack,
} from '@chakra-ui/react';
import propertyModule from '../../modules/property';

const NavButton = ({ children, to, ...props }: { children: React.ReactNode, to: string }): JSX.Element => {
  return (
    <Button
      color="gray.300"
      _hover={{
        color: 'white',
        textDecoration: 'none',
      }}
      variant="link"
      as={NavLink}
      to={to}
      {...props}
    >
      {children}
    </Button>
  );
};

const NavItems = ({ ...props }): JSX.Element => {
  const property = useSelector((state: RootStateOrAny) => propertyModule.selectors.property.getData(state));
  if (!property) return <></>;

  return (
    <Stack spacing={5} {...props}>
      <NavButton to={`/${property.id}/businesses`}>Businesses</NavButton>
      <NavButton to={`/${property.id}/offers`}>Offers</NavButton>
      <NavButton to={`/${property.id}/collections`}>Collections</NavButton>
    </Stack>
  );
};

// withRouter inside connect because redux breaks React Router updates
export default NavItems;
