import {
  Avatar,
  Box,
  Flex,
  Heading,
  Td,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import Offer from '../../../types/Offer.interface';
import iconizeImageUrl from '../../../utils/iconizeImageUrl';
import InfoBadge from './InfoBadge';

const OfferListingCell = ({ offer }: { offer: Offer }) => {
  return (
    <Flex flexDir="column">
      <Flex align="center">
        <Avatar src={iconizeImageUrl(offer.heroImageUrl)} mr={4} />
        <Box>
          <Heading fontSize="lg">{offer.headline}</Heading>
          <Text fontSize="lg">{offer.subHeadline}</Text>
          {offer.syndicated && (
            <InfoBadge type="syndicated" mt={2} />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default OfferListingCell;
