import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  Box,
  Heading,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { RootStateOrAny, useSelector } from 'react-redux';
import authModule from 'shared/src/modules/auth';
import User, { Role } from 'web-react-ui/src/types/User.interface';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import UserProfileInfo from 'web-react-ui/src/chakra/users/UserProfileInfo';
import UserProfileHeader from 'web-react-ui/src/chakra/users/UserProfileHeader';
import BackButton from './BackButton';
import SimpleForm from '../../reactFinalForm/SimpleForm';
import CheckboxField from '../../reactFinalForm/fields/CheckboxField';
import TextArea from '../../reactFinalForm/fields/TextArea';
import { FormSpy } from 'react-final-form';
import RoleTypeSelector from './RoleTypeSelector';
import Location from '../../types/Location.interface';
import LocationRolesField from './LocationRolesField';
import ChangeBusinessAccessModal from './ChangeBusinessAccessModal';
import { useLocation } from 'react-router';

interface Props {
  handleSave: ({ name, newRoles, context }: { name: string, newRoles: string[], context: string }) => void,
  handleCancel: () => void;
  handleDelete: () => void;
  isSaving: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  user: User;
  roles: Role[];
  secondaryRoles?: Role[];
  roleTypes?: string[];
  canUpdateRoles: boolean;
  initialRoles?: string[];
  initialLocationRoles?: { [x: string]: Role | Role[]; };
  initialContext?: string;
  canEditContext?: boolean | null;
  canEditUser: boolean;
  errors?: Record<string, string>;
  backUrl: string;
  context: 'platform' | 'property' | 'business' | 'location';
  operationError: string;
  locations?: Location[];
}

const EditUserView = ({
  handleSave,
  handleCancel,
  handleDelete,
  isSaving,
  isLoading,
  isDeleting,
  user,
  roles,
  secondaryRoles,
  roleTypes,
  canUpdateRoles,
  initialRoles,
  initialLocationRoles,
  initialContext,
  canEditContext,
  canEditUser,
  backUrl,
  context,
  operationError,
  locations,
}: Props): JSX.Element => {
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const [activeTab, setActiveTab] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state }: { state: { query: string } } = useLocation();

  useEffect(() => {
    if (initialLocationRoles && Object.keys(initialLocationRoles).length > 0) {
      setActiveTab(1);
    }
  }, [initialLocationRoles]);

  const initialValues = {
    name: user.name,
    roles: initialRoles,
    locations: initialLocationRoles,
    locationRoles: initialLocationRoles,
    context: initialContext,
  };

  const locationsByRegion = locations?.reduce((acc: Record<string, any>, item: Location) => {
    if (!acc[item.address.country]) {
      acc[item.address.country] = {};
    }
    if (!acc[item.address.country][item.address.state]) {
      acc[item.address.country][item.address.state] = [];
    }
    acc[item.address.country][item.address.state].push(item);
    return acc;
  }, {});

  const switchRoleTypes = (tab: number) => {
    if (!canUpdateRoles) {
      setActiveTab(activeTab ? 0 : 1);
      return false;
    }
    if (tab === activeTab) return false;
    onOpen();
  }

  return (
    <Box maxW='7xl' mx='auto' px={{ base: 2, md: 0 }}>
      <SimpleForm onSubmit={handleSave} initialValues={initialValues}>
        <FormSpy subscription={{ values: true }}>
          {({ values }: { values: Record<string, any> }) => (
            <>
              <BackButton baseUrl={`${backUrl}${state?.query || ''}`} />
              <UserProfileHeader
                user={user}
                isCurrentUser={currentUser.id === user.id}
                isDeleting={isDeleting}
                isLoading={isSaving}
                removeUser={handleDelete}
                onCancel={handleCancel}
                isEdit={true}
              />
              {operationError && (
                <ErrorMessage error={operationError} />
              )}
              <UserProfileInfo
                user={user}
                edit={true}
                canEdit={canEditUser}
              />
              <Heading fontSize='2xl' borderBottom='1px' borderColor='gray.200' pb={3} mb={3}>Roles</Heading>
              <WaitFor waitFor={!isLoading}>
                {secondaryRoles && roleTypes && (
                  <>
                    <ChangeBusinessAccessModal
                      activeTab={activeTab}
                      isOpen={isOpen}
                      onClose={onClose}
                      setActiveTab={setActiveTab}
                      canUpdateRoles={canUpdateRoles}
                    />
                    <RoleTypeSelector
                      label='Select'
                      roleTypes={roleTypes}
                      onClick={switchRoleTypes}
                      activeTab={activeTab}
                    />
                  </>
                )}

                <Box borderRadius={10} my={10} p={5} borderColor='gray.200' borderWidth='1px'>
                  <WaitFor waitFor={roles}>
                    {roles && roleTypes && (
                      <>
                        <Heading fontSize='lg' mb={5} style={{ textTransform: 'capitalize' }}>{roleTypes[activeTab]}</Heading>
                        {!canUpdateRoles && (
                          <Alert mb={5} status='warning'>
                            <AlertDescription>You do not have permission to edit this user&apos;s roles.</AlertDescription>
                          </Alert>
                        )}
                        {activeTab === 0 && roles.map(role => (
                          <Stack direction='column' spacing={5} key={role.id}>
                            <CheckboxField
                              name='roles'
                              value={role.id}
                              isDisabled={!canUpdateRoles || role.id === 'platform-basic' && values.roles.includes(role.id)}
                            >
                              <Box alignSelf='flex-start'>
                                <Text fontSize='lg' fontWeight={500}>{role.name}</Text>
                                <Text>{role.description}</Text>
                                {role.id === 'platform-basic' && (
                                  <Text>All users should be assigned this role, and it cannot be removed.</Text>
                                )}
                              </Box>
                            </CheckboxField>
                          </Stack>
                        ))}
                        {activeTab === 1 && secondaryRoles && locationsByRegion && (
                          <Stack direction='column' divider={<StackDivider />} spacing={5}>
                            {Object.entries(locationsByRegion).map(
                              ([country, locationsByState]: [country: string, locationsByState: Record<string, any>]) => (
                                <Box key={country}>
                                  <Heading fontSize='3xl' mb={3}>{country}</Heading>
                                  <Stack direction='column' divider={<StackDivider />} spacing={5}>
                                    {Object.entries(locationsByState).map(
                                      ([state, locations]: [state: string, locations: Location[]]) => (
                                        <Box key={state}>
                                          <Heading fontSize='2xl' mb={3}>{state}</Heading>
                                          <Stack spacing={5}>
                                            {locations.map((location: Location) => (
                                              <CheckboxField
                                                name={`locations.${location.id}`}
                                                key={location.id}
                                                isDisabled={!canUpdateRoles}
                                              >
                                                <LocationRolesField location={location} roles={secondaryRoles} />
                                              </CheckboxField>
                                            ))}
                                          </Stack>
                                        </Box>
                                      ))}
                                  </Stack>
                                </Box>
                              ))}
                          </Stack>
                        )}
                      </>
                    )}
                  </WaitFor>
                </Box>
              </WaitFor>

              {context === 'platform' && (
                <>
                  <Heading fontSize='2xl' borderBottom='1px' borderColor='gray.200' pb={3} mb={5}>
                    Referral Context
                  </Heading>
                  {canEditContext ? (
                    <TextArea
                      wrapLabel={true}
                      name='context'
                      rows={5}
                      placeholder='<JSON string>'
                    />
                  ) : (
                    <Text>You do not have the required access to edit this user&apos;s referral context.</Text>
                  )}
                </>
              )}
            </>
          )}
        </FormSpy>
      </SimpleForm>
    </Box>
  );
};

export default EditUserView;
