import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Downshift from 'downshift';
import _ from 'lodash';

import Label from 'semantic-ui-react/dist/commonjs/elements/Label/Label';

import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import Button from '../elements/Button';
import Icon from '../elements/Icon';
import MultiDownshift from './MultiDownshift';

const compareItemsFactory = idField => (a, b) => {
  if (!idField) return a === b;
  return (a && a[idField]) === (b && b[idField]);
};

const stateReducer = (state, changes) => {
  const isResettingInput = state.inputValue && state.inputValue !== changes.inputValue;
  const isLosingFocus = changes.type === Downshift.stateChangeTypes.blurButton
    || changes.type === Downshift.stateChangeTypes.mouseUp;
  if (isResettingInput && isLosingFocus) {
    return {
      ...changes,
      inputValue: state.inputValue
    };
  }

  return changes;
};

const filterItems = (items, selectedItems, compareItems) => {
  if (!items) return items;
  if (!selectedItems) return items;
  return items.filter(item => !selectedItems.some(selectedItem => compareItems(item, selectedItem)));
};

const DropdownButton = props => (
  <Button className='text' {...props} circular/>
);

class SearchBase extends Component {
  static propTypes = {
    search: PropTypes.func,
    onChange: PropTypes.func,
    defaultSelectedItem: PropTypes.any,
    idField: PropTypes.string,
    debounce: PropTypes.number,
    clearable: PropTypes.bool,
    placeholder: PropTypes.string,
    getLabel: PropTypes.func,
    multiSelect: PropTypes.bool,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    labelColor: PropTypes.string
  };

  state = {};

  constructor(props) {
    super(props);

    this.clock = 0;

    const { debounce = 500 } = props;
    this.debouncedSearch = _.debounce(this.onSearchChange, debounce);
  }

  onSearchChange(query, { force } = {}) {
    const { search } = this.props;

    if (!force && (query == null || query === '')) return;

    this.setState({ isLoading: true, isLoaded: false, items: [] });

    // Close over clock value
    ((snapshot) => {
      Promise.resolve(search(query))
        .then((items) => {
          // If another request has started since, do nothing
          if (snapshot !== this.clock) return null;
          return this.setState({ items, isLoading: false, isLoaded: true });
        });
    })(++this.clock); // eslint-disable-line no-plusplus
  }

  onStateChange(newState) {
    const { items, isLoading } = this.state;

    // Explicitly check if inputValue is defined
    // If it's empty, the user can clear the input and manually open the dropdown
    if (newState.inputValue) {
      return this.debouncedSearch(newState.inputValue);
    }

    // If dropdown is manually opened and the inputValue is empty, trigger a request with an empty query
    if (newState.isOpen && !items && !isLoading) {
      return this.onSearchChange('', { force: true });
    }

    return true;
  }

  // FIXME: There are a lot of inline styles in here...
  render() {
    const {
      clearable,
      onChange,
      defaultSelectedItem,
      placeholder,
      idField,
      getLabel = i => (i ? i.name : ''),
      multiSelect,
      value,
      disabled,
      labelColor = '' // Default label color
    } = this.props;
    const { items, isLoading, isLoaded } = this.state;

    const compareItems = compareItemsFactory(idField);
    const Controller = multiSelect ? MultiDownshift : Downshift;

    return (
      <Controller
        defaultSelectedItem={defaultSelectedItem}
        selectedItem={value}
        selectedItems={value}
        itemToString={getLabel}
        onChange={onChange}
        onStateChange={this.onStateChange.bind(this)}
        compare={compareItems}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRemoveButtonProps,
          getToggleButtonProps,
          highlightedIndex,
          isOpen,
          selectedItem,
          selectedItems,
          itemToString,
          clearSelection
        }) => {
          const showClearButton = clearable && selectedItem && !multiSelect;
          const displayItems = filterItems(items, selectedItems, compareItems);

          return (
            <div
              className={`ui fluid search selection dropdown icon ${disabled ? 'disabled' : ''}`}
              style={{
                padding: '0.25em',
                transform: 'none' // prevent stacking context from wrapping dropdown
              }}
            >
              <div className='flex'>
                <div className='flex fww aic fg1 ui input'>
                  {multiSelect && selectedItems && selectedItems.map && selectedItems.map(item => (
                    <Label
                      color={labelColor}
                      key={item && item[idField || 'id']}
                      style={{ margin: '2px', paddingRight: 0 }}
                    >
                      <span style={{ wordBreak: 'break-word' }}>
                        {itemToString(item)}
                      </span>
                      <span className='p1' {...getRemoveButtonProps({ item })}>
                        <Icon link fitted name='remove'/>
                      </span>
                    </Label>
                  ))}
                  <input
                    style={{
                      minWith: '3em',
                      alignSelf: 'stretch',
                      border: 'none',
                      margin: '-0.25em',
                      width: 'auto'
                    }}
                    disabled={disabled}
                    placeholder={placeholder}

                    {...getInputProps({
                      autoComplete: 'nope', // 'nope' because browsers ignore autocomplete="off"
                      onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                        }
                      }
                    })}
                  />
                </div>
                <div style={{ alignSelf: 'start' }}>
                  {showClearButton
                    ? <DropdownButton icon='delete' compact onClick={clearSelection}/>
                    : <DropdownButton
                      icon={isLoading ? { name: 'spinner', loading: true } : 'search'}
                      compact
                      {...getToggleButtonProps()}
                    />
                  }
                </div>
              </div>
              <div
                className={`menu transition ${isOpen ? 'visible' : ''}`}
                {...getMenuProps(undefined, { suppressRefError: true })}
              >
                {isLoading && (
                  <I18NextContext.Consumer>
                    {({ strings }) => (
                      <div className='item'>
                        <em>{strings('ui.component.searchBase.loading')}</em>
                      </div>
                    )}
                  </I18NextContext.Consumer>
                )}
                {displayItems && displayItems.map((item, index) => (
                  <div
                    key={item[idField || 'id']}
                    className={classNames(
                      'item',
                      { selected: highlightedIndex === index },
                      { active: compareItems(selectedItem, item) }
                    )}
                    {...getItemProps({ item })}
                  >
                    <span className='text'>{itemToString(item)}</span>
                  </div>
                ))}
                {isLoaded && (!items || !items.length) && !isLoading && (
                  <I18NextContext.Consumer>
                    {({ strings }) => (
                      <div className='item'>
                        <em>{strings('ui.component.searchBase.noResultsFound')}</em>
                      </div>
                    )}
                  </I18NextContext.Consumer>
                )}
              </div>
            </div>
          );
        }}
      </Controller>
    );
  }
}

export default SearchBase;
