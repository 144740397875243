import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Menu from 'web-react-ui/src/components/collections/Menu';
import Button from 'web-react-ui/src/components/elements/Button';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import Request from 'web-react-ui/src/data/Request';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import client from '../../services/client';
import confirm from '../../services/confirm';
import DestinationEditForm from './DestinationEditForm';

class DestinationDetails extends Component {
  static propTypes = {
    propertyId: PropTypes.string,
    destinationId: PropTypes.string,
    history: PropTypes.object
  };

  state = {};

  fetchDestination() {
    const { propertyId, destinationId } = this.props;
    return client.properties.for(propertyId).destinations.for(destinationId).details();
  }

  async updateDestination(data, updates) {
    const { propertyId, destinationId } = this.props;
    await client.properties.for(propertyId).destinations.for(destinationId).update(data, updates);
    this.goBack();
  }

  async deleteDestination() {
    if (!confirm(`Are you sure you want to delete this Destination?`)) return false;
    const { propertyId, destinationId } = this.props;
    this.setState({ deleting: true, error: null });
    try {
      await client.properties.for(propertyId).destinations.for(destinationId).delete();
      this.goBack();
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ deleting: true });
    }
    return true;
  }

  goBack() {
    const { history } = this.props;
    history.push('./');
  }

  render() {
    const { deleting, error } = this.state;
    return (
      <View>
        <Request request={this.fetchDestination.bind(this)}>
          {({ data, loading }) => (
            <View.Section className='mv1'>
              <SimpleForm
                onSubmit={updates => this.updateDestination(data, updates)}
                initialValues={data}
                loading={loading || deleting}
              >
                <DestinationEditForm/>
                <Menu secondary>
                  <Menu.Item fitted>
                    <Button type='button' negative basic onClick={this.deleteDestination.bind(this)}>Delete</Button>
                  </Menu.Item>
                  <Menu.Item fitted position='right'>
                    <FormSubmit label='Update'/>
                  </Menu.Item>
                </Menu>
                <ErrorMessage error={error}/>
              </SimpleForm>
            </View.Section>
          )}
        </Request>
      </View>
    );
  }
}

export default DestinationDetails;
