import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import useCopyOfferShareUrl from 'shared/src/hooks/useCopyOfferShareUrl';

import usePermissions from 'shared/src/hooks/usePermissions';
import {
  transport,
  useQuerystringParam
} from 'shared/src/hooks/useQuerystringParam';

import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Menu from 'web-react-ui/src/components/collections/Menu';
import Message from 'web-react-ui/src/components/collections/Message';
import Divider from 'web-react-ui/src/components/elements/Divider';
import Label from 'web-react-ui/src/components/elements/Label';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import Metrics from 'web-react-ui/src/components/metrics/Metrics';
import MetricsLoyalty from 'web-react-ui/src/components/metrics/MetricsLoyalty';
import MetricsLoyaltyTotal from 'web-react-ui/src/components/metrics/MetricsLoyaltyTotal';
import MetricsTimeSeries from 'web-react-ui/src/components/metrics/MetricsTimeSeries';
import TimeRangeSelector from 'web-react-ui/src/components/metrics/TimeRangeSelector';
import GetOfferRating from 'web-react-ui/src/components/offers/GetOfferRating';
import HiddenLabel from 'web-react-ui/src/components/offers/HiddenLabel';
import OfferLimitErrorMessage from 'web-react-ui/src/components/offers/OfferLimitErrorMessage';
import OfferPrintPreview from 'web-react-ui/src/components/offers/OfferPrintPreview';
import OfferPriority from 'web-react-ui/src/components/offers/OfferPriority';
import OfferRedeemLimitsInfo from 'web-react-ui/src/components/offers/OfferRedeemLimitsInfo';
import OfferRedeemValidationInfo from 'web-react-ui/src/components/offers/OfferRedeemValidationInfo';
import OfferScheduleInfo from 'web-react-ui/src/components/offers/OfferScheduleInfo';
import OfferStatus from 'web-react-ui/src/components/offers/OfferStatus';
import PinTable from 'web-react-ui/src/components/offers/PinTable';
import OfferPreview from 'web-react-ui/src/components/preview/OfferPreview';
import TagEditor from 'web-react-ui/src/components/tags/TagEditor';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import getOfferShareUrl from 'web-react-ui/src/utils/getOfferShareUrl';

import MyLocationsSelector from '../businesses/MyLocationsSelector';
import OfferLocationsList from './OfferLocationsList';

function OfferDetailsView(props) {
  const [showStats, setShowStats] = useQuerystringParam('stats', false, {
    transport: transport.boolean,
    squash: true
  });
  const [actionLabelCopyUrl, messageStr, showMessage, handleCloseMessage, handleCopyUrl] = useCopyOfferShareUrl();

  const {
    offer,
    fetchTags,
    saveTags,
    handleEnable,
    handleDisable,
    handleDelete,
    handleDuplicate,
    handleUnsyndicate,
    fetchStats,
    property,
    business,
    error
  } = props;

  const statsProps = {
    offer,
    fetchStats,
    propertyId: property && property.id,
    businessId: business && business.id
  };

  const canEdit = offer && offer.canEdit;
  const isSyndicated = offer && offer.syndicated;
  const isHidden = offer && offer.schedules.every(schedule => schedule.isHidden);

  const offerShareUrl = getOfferShareUrl(offer);
  const canUnsyndicate = usePermissions(
    {
      propertyId: property && property.id,
      businessId: business && business.id
    },
    [
      'property.offer.blacklist'
    ]
  );

  const renderSuccessMessage = () => {
    if (!showMessage) return null;
    return (
      <Message positive>
        <div className='flex fdr aic jcb'>
          <div className='flex fg1'>{messageStr}</div>
          <div style={{ cursor: 'pointer' }} onClick={handleCloseMessage}>X</div>
        </div>
      </Message>
    );
  };

  const actions = [
    {
      label: 'Edit',
      extraProps: {
        as: Link,
        to: `/${property.id}/businesses/${business.id}/offers/${offer.id}/edit`
      },
      hidden: !canEdit
    },
    {
      label: actionLabelCopyUrl,
      action: () => handleCopyUrl(offerShareUrl),
      hidden: !offerShareUrl
    },
    {
      label: 'Duplicate',
      action: handleDuplicate,
      hidden: !canEdit
    },
    {
      label: 'Enable', action: handleEnable, hidden: offer.enabled || !canEdit
    },
    {
      label: 'Disable', action: handleDisable, hidden: !offer.enabled || !canEdit
    },
    {
      label: 'Unsyndicate', action: handleUnsyndicate, hidden: !isSyndicated || !canUnsyndicate
    }
  ];

  return (
    <View>
      <View.Section>
        <OfferLimitErrorMessage error={error} />
        {renderSuccessMessage()}
        {(!canEdit && !isSyndicated) && (
          <Message info>
            You can&apos;t edit this offer because it&apos;s available at locations you don&apos;t manage.
          </Message>
        )}
        <div className='flex'>
          <div>
            <div className='flex aic'>
              <div>
                <h1 className='mt0'>{offer.headline}</h1>
              </div>
              <div>
                {isSyndicated && <Label color='black'>SYNDICATED</Label>}
              </div>
              <div className='ml2 mr1'>
                {isHidden && <HiddenLabel />}
              </div>
            </div>
            <h5 className='mt2'>{offer.subHeadline}</h5>
          </div>
          <div className='mla'>
            <div className='mt1'>
              <div className='pull-right'>
                <ActionMenu actions={actions} />
              </div>
            </div>
            <div className='pull-right mt1 mr1'>
              <OfferStatus offer={offer} />
            </div>
          </div>
        </div>
        <div className='flex mt2'>
          <TagEditor fetchTags={fetchTags} onSave={saveTags} />
          <div className='mla flex aic'>
            <GetOfferRating
              offerId={offer && offer.id}
              propertyId={property && property.id}
              businessId={business && business.id}
            />
            <div className='ml1 mr1'>Priority</div>
            <OfferPriority priority={offer.priority} />
          </div>
        </div>
        {isSyndicated && (
          <Message warning>
            <Message.Header>This is a syndicated offer!</Message.Header>
            <p>Edits can only be made in the original app.</p>
          </Message>
        )}
        <Menu pointing secondary>
          <Menu.Item name='Details' active={!showStats} onClick={() => setShowStats(false)} />
          <Menu.Item name='Stats' active={showStats} onClick={() => setShowStats(true)} />
        </Menu>
        {showStats && (
          <OfferDetailsStats {...statsProps} />
        )}
        {!showStats && (
          <OfferDetailsContents
            offer={offer}
            isSyndicated={isSyndicated}
            business={business}
            property={property}
          />
        )}

        <ErrorMessage error={error} />
        <div className='pull-right mt4'>
          <ActionMenu actions={[
            ...actions,
            {
              label: 'Delete Offer',
              action: handleDelete,
              negative: true,
              hidden: !offer.canEdit
            }
          ]} />
        </div>
      </View.Section>
    </View>
  );
}

OfferDetailsView.propTypes = {
  offer: PropTypes.object,
  business: PropTypes.object,
  property: PropTypes.object,
  getOfferRating: PropTypes.func,
  fetchTags: PropTypes.func,
  fetchStats: PropTypes.func,
  saveTags: PropTypes.func,
  handleEnable: PropTypes.func,
  handleDisable: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDuplicate: PropTypes.func,
  handleUnsyndicate: PropTypes.func,
  error: PropTypes.object,
  data: PropTypes.object
};

function OfferDetailsStats({
  fetchStats, offer, propertyId, businessId
}) {
  const [locationId, setLocationId] = useQuerystringParam('location', undefined, { squash: true });
  const [days, setDays] = useQuerystringParam('days', 30, { transport: transport.float });

  const isLoyalty = offer && offer.flags.includes('punchcard');

  const offerLocations = offer && offer.locationIds;
  return (
    <Request request={fetchStats} params={{ days, locationId, isLoyalty }}>
      {({ data: stats, loading, error: statsError }) => (
        <WaitFor waitFor={!loading} wrapContents>
          <div className='mt4'>
            <div className='flex fww'>
              <div style={{ width: '50%' }} />
              <div className='fg1 flex fww'>
                <div className='fg1 m1' style={{ minWidth: '200px' }}>
                  <MyLocationsSelector
                    locationId={locationId}
                    offerLocations={offerLocations}
                    onChange={setLocationId}
                  />
                </div>
                <div className='fg1 mt2 m1' style={{ minWidth: '200px' }}>
                  <TimeRangeSelector days={days} handleSetRange={rangeResult => setDays(rangeResult.days)} />
                </div>
              </div>
            </div>
            {statsError && <Message error>{statsError.message}</Message>}
            {isLoyalty
              ? (
                <React.Fragment>
                  <MetricsLoyalty data={stats} />
                  <MetricsLoyaltyTotal
                    propertyId={propertyId}
                    businessId={businessId}
                    locationId={locationId}
                    offerId={offer && offer.id}
                  />
                </React.Fragment>
              )
              : (
                <Metrics data={stats} />
              )
            }
            <MetricsTimeSeries data={stats} />
          </div>
        </WaitFor>
      )}
    </Request>
  );
}

OfferDetailsStats.propTypes = {
  fetchStats: PropTypes.func,
  offer: PropTypes.object,
  propertyId: PropTypes.string,
  businessId: PropTypes.string
};

function OfferDetailsContents({
  offer, business, property, isSyndicated
}) {
  return (
    <div className='flex fww mb4 mt4'>
      <div className='fg1 mr4' style={{ flexBasis: 0, minWidth: '300px' }}>
        <h2 className='mb0'>Details</h2>
        <Divider fitted className='pb2' />
        <p>{offer.details}</p>
        <h5>Terms & Conditions</h5>
        <p>{offer.legal}</p>
        {offer.promoCode && (
          <div>
            <h5>Promo Code</h5>
            <p>{offer.promoCode}</p>
          </div>
        )}
        <div>
          <h2 className='mb0 mt2'>Schedule</h2>
          <Divider fitted className='pb2' />
          {offer.schedules && (
            <div>
              <OfferScheduleInfo schedules={offer.schedules} />
            </div>
          )}
        </div>
        {!isSyndicated && (
          <div>
            <h2 className='mb0 mt2'>Redemption Action</h2>
            <Divider fitted className='pb2' />
            <OfferRedeemLimitsInfo offerFlowVars={offer.flow.vars} />
            <OfferRedeemValidationInfo offerFlow={offer.flow} />
            {!!(offer.pins && offer.pins.length) && (<div className='mt2'>
              <div className='flex'>
                <strong>Pin Verification</strong>
                <div className='mla'>
                  <OfferPrintPreview offer={offer} property={property} />
                </div>
              </div>
              <PinTable pins={offer.pins} />
            </div>)}
          </div>
        )}
        <h2 className='mb0'>Locations</h2>
        <Divider fitted className='pb2' />
        <OfferLocationsList
          offer={offer}
          business={business}
          property={property}
        />
        {!isSyndicated && (
          <div>
            <h2 className='mb0'>Regions</h2>
            <Divider fitted className='pb2' />
            <div>
              <h6>Discoverable</h6>
              {
                offer.discoverableRegions.length >= 1
                  ? <Label.Group>
                    {offer.discoverableRegions.map(region => <Label key={region.id}>{region.label}</Label>)}
                  </Label.Group>
                  : <em>none</em>
              }
            </div>
          </div>
        )}
      </div>
      <div className='fg1' style={{ flexBasis: 0, minWidth: '300px' }}>
        <OfferPreview offer={offer} image={offer.heroImage} />
      </div>
    </div>
  );
}

OfferDetailsContents.propTypes = {
  offer: PropTypes.object,
  business: PropTypes.string,
  property: PropTypes.string,
  isSyndicated: PropTypes.bool
};

export { OfferDetailsView as Presenter };
export default OfferDetailsView;
