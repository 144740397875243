import PropTypes from 'prop-types';
import React from 'react';
import TextField from './TextField';

// FIXME: Replace this with an actual Number control

const numberParse = (str) => {
  if (str == null || str === '') return undefined;
  const val = Number(str);
  if (Number.isNaN(val)) return undefined;
  return val;
};

const NumberField = (props) => {
  const { parse = v => v, ...rest } = props;
  const composedParse = str => parse(numberParse(str));
  return <TextField type='number' {...rest} parse={composedParse}/>;
};

NumberField.propTypes = {
  parse: PropTypes.func
};

export default NumberField;
