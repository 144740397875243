import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Container,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';

import { UseRequestResult } from 'shared/src/types/UseRequestResult.interface';
import { PagedList } from 'shared/src/types/PagedList.interface';
import Property from 'web-react-ui/src/types/Property.interface';
import useRequest from 'shared/src/hooks/useRequest';

import PropertySelector from './PropertySelector';
import client from '../../services/client';
import SupportLink from '../support/SupportLink';
import propertyModule from '../../modules/property';

type Props = {
  properties: UseRequestResult<PagedList<Property>>
};

const PropertySelectView = ({ properties }: Props): JSX.Element => (
  <Container maxW="container.lg" mt={12}>
    <Heading size="lg">Select Property</Heading>
    <PropertySelector baseUrl={''} properties={properties} />
  </Container>
);

const fetchProperties = () => client.properties.listMyProperties();

const PropertySelectContainer = ({ skipRedirect }: { skipRedirect: boolean }): JSX.Element => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(propertyModule.actions.invalidate());
  }, []);

  const properties = useRequest(fetchProperties, true);

  if (properties.loaded && !properties.result?.items?.length) {
    return (
      <Container maxW="container.lg" mt={12} textAlign="center">
        <h3>You don&apos;t have access to any properties.</h3>
        <Flex align="center" justify="center">
          <SupportLink label={'Contact Support'} />
          <Text>&nbsp;to set up your account.</Text>
        </Flex>
      </Container>
    );
  }

  if (properties.loaded && properties?.result?.items.length === 1 && skipRedirect === undefined) {
    return (
      <Redirect to={`${properties.items[0].id}`} />
    );
  }

  return (
    <PropertySelectView
      properties={properties}
    />
  );
};

export default PropertySelectContainer;
