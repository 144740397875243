import React from 'react';
import { FormSpyRenderProps, useFormState } from 'react-final-form';
import { Stack } from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { useHasPerformanceError } from 'shared/src/hooks/useHasPerformanceError';

import exclusiveImg from '../../assets/exclusive.svg';
import generalAwarenessImg from '../../assets/generalAwareness.svg';
import limitedQtyImg from '../../assets/limitedQty.svg';
import punchCardImg from '../../assets/punchCard.svg';
import WaitFor from '../../data/WaitFor';
import { FormSpy } from '../../reactFinalForm';
import ExclusiveOffer from './ExclusiveOffer';
import LimitedOffer from './LimitedOffer';
import OfferTypeCard from './OfferTypeCard';
import PunchCardOffer from './PunchCardOffer';
import Reach from './Reach';
import Plan from '../../types/Plan.interface';

const ensurePlanFlags = (plan: Plan, requiredFlags: Record<string, boolean | null>): boolean => {
  if (!plan || !plan.settings) return false;
  const settings = plan.settings || {};

  return ['exclusive', 'limited', 'punchcard'].every((flag: string) => {
    // @ts-ignore
    const planSetting = settings[`offer__feature__flag__control_${flag}`];
    const requiredSetting = requiredFlags[flag];
    if (planSetting === null) return true;
    if (requiredSetting === null) return false;
    return requiredSetting === planSetting;
  });
};

const customPriorityDisabled = (plan: Plan): boolean | null => {
  if (!plan) return null;

  const settings = plan.settings;

  if (!settings) return null;

  return !settings.offer__feature__misc__allow_custom_priority;
};

const OfferType = ({ type, props }: { type: string, props: any }): JSX.Element => {
  switch (type) {
    case 'punchcard':
      return <PunchCardOffer {...props} />;
    case 'limited':
      return <LimitedOffer {...props} />;
    case 'exclusive':
      return <ExclusiveOffer {...props} />;
    case 'reach':
      return <Reach {...props} />;
    default:
      return <></>;
  }
}

const OfferTypeSelection = ({
  dashboard,
  existingOfferType,
  propertyId,
  businessId,
  plansHref,
  plan,
  handlePriorityDisabled,
  offerTypes,
}: {
  dashboard?: boolean,
  existingOfferType?: string,
  propertyId: string,
  businessId: string,
  plansHref: string,
  plan: Plan,
  handlePriorityDisabled: (value: boolean | null) => void,
  offerTypes: string[],
}): JSX.Element => {
  const { strings } = useI18Next();
  const isPriorityDisabled = customPriorityDisabled(plan);
  const formState = useFormState();
  const [hasPerformanceError] = useHasPerformanceError(formState);

  const offerType: Record<string, any> = {
    exclusive: {
      title: strings('ui.component.offerTypeSelection.offerType.exclusive.title'),
      desc: strings('ui.component.offerTypeSelection.offerType.exclusive.desc'), // eslint-disable-line max-len
      disabledDesc: strings('ui.component.offerTypeSelection.offerType.exclusive.disabledDesc'),
      label: strings('ui.component.offerTypeSelection.offerType.exclusive.label'),
      color: '#BA1AD7',
      imgSrc: exclusiveImg,
      enabled: ensurePlanFlags(plan, { exclusive: true, limited: false, punchcard: false }),
    },
    limited: {
      title: strings('ui.component.offerTypeSelection.offerType.limited.title'),
      desc: strings('ui.component.offerTypeSelection.offerType.limited.desc'), // eslint-disable-line max-len
      disabledDesc: strings('ui.component.offerTypeSelection.offerType.limited.disabledDesc'),
      label: strings('ui.component.offerTypeSelection.offerType.limited.label'),
      color: '#ff9200',
      imgSrc: limitedQtyImg,
      enabled: ensurePlanFlags(plan, { exclusive: true, limited: true, punchcard: false }),
    },
    punchcard: {
      title: strings('ui.component.offerTypeSelection.offerType.punchcard.title'),
      desc: strings('ui.component.offerTypeSelection.offerType.punchcard.desc'), // eslint-disable-line max-len
      disabledDesc: strings('ui.component.offerTypeSelection.offerType.punchcard.disabledDesc'),
      label: strings('ui.component.offerTypeSelection.offerType.punchcard.label'),
      color: '#7DABFC',
      imgSrc: punchCardImg,
      enabled: ensurePlanFlags(plan, { exclusive: true, limited: null, punchcard: true }),
    },
    // @todo update translations -- this is only here to show new text until approved
    reach: {
      title: strings('ui.component.offerTypeSelection.offerType.reach.title'),
      desc: strings('ui.component.offerTypeSelection.offerType.reach.desc'),
      disabledDesc: strings('ui.component.offerTypeSelection.offerType.reach.disabledDesc'),
      label: strings('ui.component.offerTypeSelection.offerType.reach.label'),
      imgSrc: generalAwarenessImg,
      enabled: ensurePlanFlags(plan, { exclusive: false, limited: false, punchcard: false }),
    }
  };

  return (
    // @ts-ignore
    <WaitFor waitFor={(plan && (isPriorityDisabled !== null))}>
      <FormSpy subscription={{ values: true }}>
        {({ values, form }: FormSpyRenderProps & { values: Record<string, any> }) => {
          if (!Object.keys(values).length) return null;
          const offerCardType = existingOfferType || (values && values.offerType);
          const rest = { dashboard, isPriorityDisabled, plansHref, plan, hasPerformanceError };

          const handleOfferClick = (selected: string) => {
            handlePriorityDisabled(isPriorityDisabled);
            form.change('offerType', selected);
          };

          if (offerCardType) return <OfferType type={offerCardType} props={rest} />;

          return (
            <Stack spacing={5} px={{ base: 3, xl: 0 }}>
              {offerTypes.map(type => (
                <OfferTypeCard
                  offerType={offerType[type]}
                  enabled={offerType[type].enabled}
                  onClick={() => handleOfferClick(type)}
                  plansHref={plansHref}
                />
              ))}
            </Stack>
          );
        }}
      </FormSpy>
    </WaitFor>
  );
}

export default OfferTypeSelection;
