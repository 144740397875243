import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import Menu from 'web-react-ui/src/components/collections/Menu';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import View from 'web-react-ui/src/components/layout/View';
import LocaleSelector from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocaleSelector';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import { atLeastOneCompleteLocalization } from 'web-react-ui/src/reactFinalForm/validators';

import client from '../../services/client';
import ConnectedTranslationWrapper from '../i18n/ConnectedTranslationWrapper';
import EditCollectionForm from './EditCollectionForm';

const Buttons = ({ onCancel }) => (
  <Menu secondary>
    <Menu.Item fitted>
      <CancelButton onClick={onCancel}/>
    </Menu.Item>
    <Menu.Menu position='right'>
      <Menu.Item fitted>
        <FormSubmit label='Create' ignoreValidity/>
      </Menu.Item>
    </Menu.Menu>
  </Menu>
);

Buttons.propTypes = {
  onCancel: PropTypes.func
};

class AddCollectionModal extends Component {
  static propTypes = {
    property: PropTypes.object
  };

  state = {};

  componentDidMount() {
    this.initializeCollection();
  }

  initializeCollection() {
    const { property } = this.props;
    client.properties.for(property.id).collections.initialize()
      .then(collectionEdit => this.setState({ collectionEdit }));
  }

  handleCancel() {
    const propertyId = this.props.property.id;
    this.setState({ redirectTo: `/${propertyId}/collections` });
  }

  handleSubmit(fields) {
    const { property } = this.props;
    const { collectionEdit } = this.state;

    this.setState({ isSaving: true });
    return collectionEdit.update(fields)
      .then((updatedCollectionEdit) => {
        const collectionId = updatedCollectionEdit.instances.collection.id;
        this.setState({ redirectTo: `/${property.id}/collections/${collectionId}` });
        this.setState({ isSaving: false });
      })
      .catch((error) => {
        this.setState({ isSaving: false });
        return Promise.reject(error);
      });
  }

  render() {
    const propertyId = this.props.property.id;
    const { collectionEdit, isSaving, redirectTo } = this.state;
    if (redirectTo) return <Redirect to={redirectTo}/>;

    const initialValues = collectionEdit && collectionEdit.getFields();
    if (initialValues) initialValues.collectionScores = 'distance';

    return (
      <BigModal open>
        <ConnectedTranslationWrapper>
          <LocalizableSimpleForm
            onSubmit={this.handleSubmit.bind(this)}
            initialValues={initialValues}
            loading={!collectionEdit || isSaving}
            validateLocale={atLeastOneCompleteLocalization([
              'collection.nameLocalized', 'collection.shortNameLocalized'
            ])}
          >
            <BigModal.Contents>
              <BigModal.Header className='p1'>
                <Buttons onCancel={this.handleCancel.bind(this)}/>
              </BigModal.Header>
              <BigModal.Body>
                <View>
                  <View.Section>
                    <div className='flex'>
                      <h1>Create Collection</h1>
                      <LocaleSelector className='mla'/>
                    </div>
                    <EditCollectionForm propertyId={propertyId}/>
                  </View.Section>
                  <div className='p1'>
                    <Buttons onCancel={this.handleCancel.bind(this)}/>
                  </div>
                </View>
              </BigModal.Body>
            </BigModal.Contents>
          </LocalizableSimpleForm>
        </ConnectedTranslationWrapper>
      </BigModal>
    );
  }
}

export default AddCollectionModal;
