import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Flex, Heading, InputGroup, InputLeftElement, Input,
  Stack, useDisclosure, Button, useToast
} from '@chakra-ui/react';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import { useDebounce } from 'use-debounce';

import authModule from 'shared/src/modules/auth';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';

import User, { Role } from 'web-react-ui/src/types/User.interface';
import RoleFilter from 'web-react-ui/src/chakra/UserList/RoleFilter';
import UserListView from 'web-react-ui/src/chakra/users/UserListView';
import AddUserView from 'web-react-ui/src/chakra/users/AddUserView';

import propertyModule from '../../../modules/property';
import client from '../../../services/client';
import confirm from '../../../services/confirm';

const PropertySettingsUsers = () => {
  const currentUser = useSelector(state => authModule.selectors.getUser(state));
  const property = useSelector(state => propertyModule.selectors.property.getData(state));
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [refetchId, setRefetchId] = useState(0);
  const [operationError, setOperationError] = useState('');
  const [roles, setRoles] = useState<Role[]>();
  const [query, setQuery] = useQuerystringParam('query', '', { squash: true });
  const [debouncedQuery] = useDebounce(query, 750);
  const [selectedRoles, setSelectedRoles] = useQuerystringParam(
    'roles',
    '',
    {
      transport: {
        serialize: (v: []) => v && v.join(','),
        parse: (v: string) => v && v.split(',')
      },
      squash: true
    });

  useEffect(() => {
    if (!roles) {
      client.roles.list({ type: 'property' }).then((res: { items: Role[] }) => {
        setRoles(res.items);
      });
    }
  }, []);

  const loadUsers = async () => client.properties.for(property.id).users.searchUsers({
    query: debouncedQuery || undefined,
    roleIds: selectedRoles || undefined,
    context: ['P'],
  });

  const removeUser = async (user: User) => {
    const shouldDelete = confirm(`Are you sure you want to remove ${user.email}?`);
    if (!shouldDelete) return null;
    setIsDeleting(true);

    try {
      await client
        .properties.for(property.id)
        .users.for(user.id)
        .remove();
      setRefetchId(refetchId + 1);
      toast({
        title: 'User removed',
        // eslint-disable-next-line max-len
        description: 'This user has been removed. It may take a few minutes for the change to be reflected in our system.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      setOperationError(error);
    } finally {
      setIsDeleting(false);
    }

    return false;
  };

  const addUserSubmit = async (values: Record<string, any>) => {
    setIsInviting(true);
    setOperationError('');
    const { email, name, roles: addRoles = [] } = values;

    if (!addRoles?.length) {
      setIsInviting(false);
      setOperationError('No roles selected');
      return;
    }

    try {
      await client.properties.for(property.id).users.invite(
        { email, name },
        addRoles.map((roleId: string) => ({ id: roleId })),
        true,
      );
      toast({
        title: 'User invited',
        description: `An invitation has been sent to ${email} and the user has been added to this property.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setRefetchId(refetchId + 1);
    } catch (error: any) {
      setOperationError(error);
      throw new Error(error);
    } finally {
      setIsInviting(false);
    }
  };

  return (
    <Box maxW='7xl' mx='auto' px={{ base: 2, md: 0 }}>
      <AddUserView
        isOpen={isOpen}
        onClose={onClose}
        type='property'
        roleSectionHeading='Property Roles'
        primaryRoles={roles}
        onSubmit={addUserSubmit}
        isLoading={isInviting}
        error={operationError}
      />
      <Flex
        direction={['column', 'row']}
        justify={['flex-start', 'space-between']}
        align={['flex-start', 'center']}
        my={10}
      >
        <Heading mb={[3, 3, 0]} minW='50%'>Users</Heading>
        <Stack direction={['column', 'row']} align='center' justify='flex-end' spacing={5} w='100%'>
          <InputGroup flexGrow={1}>
            <InputLeftElement color='gray.300'>
              <SearchIcon />
            </InputLeftElement>
            <Input
              value={query}
              onChange={(e: any) => setQuery(e.target.value)}
              placeholder='Search'
            />
          </InputGroup>
          <RoleFilter
            key={selectedRoles}
            roles={roles}
            selectedRoles={selectedRoles}
            onSubmit={setSelectedRoles}
          />
          <Button colorScheme='blue' onClick={onOpen} leftIcon={<AddIcon />} minW='100px'>Add User</Button>
        </Stack>
      </Flex>
      <UserListView
        loadUsers={loadUsers}
        removeUser={removeUser}
        isDeleting={isDeleting}
        query={debouncedQuery}
        refetchId={refetchId}
        selectedRoles={selectedRoles}
        currentUser={currentUser}
        operationError={operationError}
        roles={roles}
        baseUrl={`/${property.id}/property-settings/users`}
        context='property'
      />
    </Box >
  );
};

export default PropertySettingsUsers;
