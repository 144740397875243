import _ from 'lodash';

import client from './client';

const fetchRegion = (id) => {
  return client.regions.detailsWithArea(id);
};

const cachedFetchRegion = _.memoize(fetchRegion, id => id);

const fetchMultiple = ids => Promise.all(ids.map(cachedFetchRegion));

const mergePolygons = _.memoize(
  async ({ include, exclude }) => {
    if (!include || !include.length) return [];
    return client.properties.for(null).collections.mergePolygons({
      include,
      exclude
    });
  },
  JSON.stringify
);


const getRegionMap = async (ids) => {
  const regions = await fetchMultiple(ids);
  return _.keyBy(regions, 'id');
};

const mergeRegionFilters = async (filters) => {
  if (!filters) return [];

  const includeIds = filters
    .filter(f => f.operation === 'discoverableIn')
    .map(f => f.args || [])
    .flat()
    .map(f => f.id);

  if (!includeIds.length) return { warnings: [], polygons: [] };

  const excludeIds = filters
    .filter(f => f.operation === 'notDiscoverableIn')
    .map(f => f.args || [])
    .flat()
    .map(f => f.id);

  const regionMap = await getRegionMap(includeIds.concat(excludeIds));

  return mergePolygons({
    include: includeIds.map(id => [regionMap[id].area]),
    exclude: excludeIds.map(id => [regionMap[id].area])
  });
};

const validateCollectionPolygons = async (polygons) => {
  const { warnings } = await mergePolygons({ include: polygons });
  return warnings;
};

const regionCache = {
  fetch: cachedFetchRegion,
  fetchMultiple,
  mergeRegionFilters,
  validateCollectionPolygons
};

export default regionCache;
