import PropTypes from 'prop-types';
import React from 'react';
import PlanSettingsFields from 'web-react-ui/src/components/plans/PlanSettingsFields';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import BooleanField from 'web-react-ui/src/reactFinalForm/fields/BooleanField';
import MarkdownField from 'web-react-ui/src/reactFinalForm/fields/MarkdownField';
import FormError from 'web-react-ui/src/reactFinalForm/FormError';
import { required } from 'web-react-ui/src/reactFinalForm/validators';

const PlanEditForm = ({ settingGroups }) => (
  <React.Fragment>
    <FormError/>
    <TextField name='plan.name' label='Name' wrapLabel validate={required}/>
    <MarkdownField name='plan.description' label='Description' wrapLabel validate={required}/>
    <BooleanField toggle name='plan.discoverable' label='Discoverable'/>
    <BooleanField toggle name='plan.enabled' label='Enabled'/>
    <h3>Settings</h3>
    <PlanSettingsFields settingGroups={settingGroups} baseName='plan.settings.'/>
  </React.Fragment>
);

PlanEditForm.propTypes = {
  settingGroups: PropTypes.array
};

export default PlanEditForm;
