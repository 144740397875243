import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useMemo,
  useEffect
} from 'react';
import { Textarea as ChakraTextarea, Text } from '@chakra-ui/react';
import {
  Field,
  useFormState
} from 'react-final-form';
import useIncrement from 'shared/src/hooks/useIncrement';

import SuiForm from '../../../components/collections/Form';

import FieldError from '../FieldError';
import LocalizationWrapper from './LocalizationWrapper';
import LocalizableTextFieldLabel from './LocalizableTextFieldLabel';

const LocalizableTextArea = (props) => {
  const {
    name,
    label,
    // validate, // TODO: Compose validate with validateLocale?
    validateLocale,
    optional,
    wrapLabel,
    info,
    chakra,
    ...rest
  } = props;

  const {
    locale, defaultLocale, isDefaultLocale, locales
  } = useContext(LocalizationWrapper.context);

  const formState = useFormState({ values: true });
  const fieldValue = _get(formState.values, name);

  const [key, invalidate] = useIncrement();

  const format = useCallback(
    (dictionary) => {
      if (!dictionary) return undefined; // Handle no form values (is there a better way to do this?)
      return dictionary[locale] || '';
    },
    [locale]
  );

  const parse = useCallback(
    str => ({
      ...fieldValue,
      [locale]: str
    }),
    [locale, fieldValue]
  );

  const validate = useMemo(
    () => (validateLocale
      ? validateLocale({ locale, locales })
      : undefined),
    [locale, locales, validateLocale]
  );

  useEffect(() => {
    invalidate();
  }, [locale]);

  const defaultValue = useMemo(
    () => (fieldValue && fieldValue[defaultLocale]),
    [fieldValue, defaultLocale]
  );

  const FieldCmp = chakra ? React.Fragment : SuiForm.Field;
  const InputCmp = chakra ? ChakraTextarea : SuiForm.TextArea;

  return (
    <Field name={name} validate={validate} format={format} parse={parse} info={info} key={key}>
      {({ input, meta }) => (
        <FieldCmp>
          <LocalizableTextFieldLabel
            label={label}
            optional={optional}
            wrapLabel={wrapLabel}
            locale={locale}
            locales={locales}
          >
            <InputCmp {...input} {...rest} autoHeight />
            {meta.active && !isDefaultLocale && (
              <div>{defaultLocale}: {defaultValue}</div>
            )}
            {info && <Text color='gray.500'>{info}</Text>}
            <FieldError name={name} chakra={chakra} />
          </LocalizableTextFieldLabel>
        </FieldCmp>
      )}
    </Field>
  );
};

LocalizableTextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validateLocale: PropTypes.func,
  optional: PropTypes.bool,
  wrapLabel: PropTypes.bool,
  info: PropTypes.string,
  chakra: PropTypes.bool,
};


export default LocalizableTextArea;
