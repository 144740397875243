/* eslint camelcase: 0, class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React from 'react';

import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import {
  offerListViewParamsFactory,
  VIEW_TYPES
} from 'shared/src/helpers/offerListViewParams';
import OffersListTable from 'web-react-ui/src/chakra/offers/OffersListTable';

import Input from 'web-react-ui/src/components/elements/Input';
import View from 'web-react-ui/src/components/layout/View';
import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';

import client from '../../../services/client';

const fetchOffers = ({ query, propertyId, businessId, locationId, view }) => {
  if (!propertyId || !businessId || !locationId) return null;
  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .locations.for(locationId)
    .offers.list({
      query, ...offerListViewParamsFactory(view)
    });
};

const buildHrefFactory = ({
  propertyId,
  locationId
}) => (offer) => `/${propertyId}/businesses/${offer.businessId}/offers/${offer.id}?location=${locationId}`;

const LocationOffers = ({ propertyId, businessId, locationId }) => {
  const [view, setView] = useQuerystringParam('view', VIEW_TYPES.live, { squash: true });
  const [query, debouncedQueryHandler] = useQueryDebounce(view, propertyId, locationId, businessId);

  const handleViewChange = (e) => {
    setView(e);
  };

  const options = [
    { key: 1, text: 'New', value: VIEW_TYPES.new },
    { key: 2, text: 'Live', value: VIEW_TYPES.live },
    { key: 3, text: 'Expiring Soon', value: VIEW_TYPES.expiring },
    { key: 4, text: 'Expired', value: VIEW_TYPES.expired },
    { key: 5, text: 'Draft', value: VIEW_TYPES.disabled }
  ];

  return (
    <View className="mt4">
      <View.Section className="flex fww">
        <Input
          placeholder="Find offer..."
          fluid
          icon="search"
          onChange={e => debouncedQueryHandler(e.target.value)}
          className="fg2 mr1"
        />
        <Dropdown
          onChange={(e, { value }) => handleViewChange(value)}
          options={options}
          selection
          value={view}
        />
      </View.Section>
      <View.Section>
        <PagedList
          fetchList={fetchOffers}
          params={{
            query,
            propertyId,
            businessId,
            locationId,
            view
          }}
        >
          {(offersList) => (
            <OffersListTable
              offersList={offersList}
              noOffersString="No Offers"
              buildHref={buildHrefFactory({ propertyId, locationId })}
              hideBusiness
            />
          )}
        </PagedList>
      </View.Section>
    </View>
  );
};

LocationOffers.propTypes = {
  businessId: PropTypes.string,
  propertyId: PropTypes.string,
  locationId: PropTypes.string
};

export default LocationOffers;
