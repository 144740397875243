/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Menu from 'web-react-ui/src/components/collections/Menu';
import Button from 'web-react-ui/src/components/elements/Button';
import Divider from 'web-react-ui/src/components/elements/Divider';

import View from 'web-react-ui/src/components/layout/View';
import {
  Field,
  FormSpy
} from 'web-react-ui/src/reactFinalForm';
import {
  FieldError,
  ListSelectorField,
  NumberField,
  TextArea,
  TextField
} from 'web-react-ui/src/reactFinalForm/fields';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';

export const integrationTypes = [
  { key: 'ios', label: 'iOS' },
  { key: 'android', label: 'Android' },
  { key: 'browser', label: 'Website' },
  { key: 'server', label: 'Server' },
  { key: 'pixel', label: 'Pixel' },
];

const pixelScopes = [
  { id: 'auth.identified.signed', label: 'auth.identified.signed' },
  { id: 'auth.identified.unsigned', label: 'auth.identified.unsigned' },
  { id: 'auth.anonymous.signed', label: 'auth.anonymous.signed' },
  { id: 'auth.anonymous.unsigned', label: 'auth.anonymous.unsigned' },
  { id: 'event.signed', label: 'event.signed' },
  { id: 'event.unsigned', label: 'event.unsigned' },
];

const required = v => (v == null ? 'Required' : undefined);

class AddIntegrationView extends Component {
  static propTypes = {
    backToIntegrations: PropTypes.func,
    integration: PropTypes.object
  };

  render() {
    const { integration, backToIntegrations } = this.props;
    return (
      <View>
        <View.Section narrow>
          <h1>Add Integration</h1>
          <View.Section>
            <Field name="sdk" validate={required}>
              {({ input }) => (
                <React.Fragment>
                  <Menu fluid widths={integrationTypes.length}>
                    {integrationTypes.map(({ key, label }) => {
                      const active = input.value ? input.value === key : key === integrationTypes[0].key;
                      return (
                        <Menu.Item
                          active={active}
                          key={key}
                          onClick={() => input.onChange(key)}
                          data-testid={`qa-${key}-integration`}
                        >
                          {label}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                  <FieldError name="sdk" />
                </React.Fragment>
              )}
            </Field>

            <TextField name="name" validate={required} placeholder="Name" fluid wrapLabel />

            <FormSpy subscription={{ values: true }}>
              {({ values: { sdk } }) => (['browser', 'pixel'].includes(sdk)) && (
                <React.Fragment>
                  <TextArea
                    name="corsDomains"
                    label="Allowed CORS Origins"
                    placeholder={`https://foo.com`}
                    wrapLabel={true}
                    validate={required}
                    info="One per line"
                  />
                  < p className="text--muted"></p>
                </React.Fragment>
              )}
            </FormSpy>

            <FormSpy subscription={{ values: true }}>
              {({ values: { sdk } }) => (['pixel'].includes(sdk)) && (
                <ListSelectorField
                  className="mt2"
                  name="scopes"
                  label="Scopes"
                  options={pixelScopes}
                />
              )}
            </FormSpy>

            <FormSpy subscription={{ values: true }}>
              {({ values: { sdk } }) => (['pixel'].includes(sdk)) && (
                <NumberField
                  name="lifespan"
                  label="Lifespan"
                  placeholder="16070400"
                  info="Pixel validity in seconds"
                />
              )}
            </FormSpy>

            {!integration && (
              <div className="mt2">
                <FormSubmit fluid label="Continue" />
              </div>
            )}

            <FormSpy subscription={{ submitError: true }}>
              {({ submitError }) => (
                <p>{submitError}</p>
              )}
            </FormSpy>
          </View.Section>
          {integration && (
            <View.Section>
              <div>
                <h6 className="mb0 mt2">Write Key</h6>
                <div className="c-display__keys mb3 p1 bg--gray">
                  {integration.writeKey}
                </div>
              </div>
              <Divider />
              <div>
                <h2>How To Finish</h2>
                <p>
                  Use the <strong>Write Key</strong> above to initialize the SDK in your integration,
                  as described in the
                  <a
                    href={process.env.REACT_APP_DOCS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Platform Integration Guide
                  </a>.
                </p>
              </div>
              <div className="pt2">
                <Button onClick={backToIntegrations}>Done</Button>
              </div>
            </View.Section>
          )}
        </View.Section>
      </View>
    );
  }
}

AddIntegrationView.propTypes = {
  onSubmit: PropTypes.func
};

export { AddIntegrationView as Presenter };

export default AddIntegrationView;
