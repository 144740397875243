import React, { useState } from 'react';
import useClient from 'shared/src/hooks/useClient';
import usePagedList from 'shared/src/hooks/usePagedList';
import { useImageWellContext } from '../../ImageWellContext';

import ImageGrid from '../ImageGrid';
import LibraryButton from '../LibraryButton';
import LibraryImage from '../LibraryImage';
import EmptyPagedList from './shared/EmptyPagedList';
import LoadMore from './shared/LoadMore';

interface ImageSearchItem {
  imageRefId: string,
  imageRef: {
    url: string
  }
}

const SharedImages = ({ query, onChange, fileType }
  : {
  query?: string,
  onChange: (imageRefId: string) => void,
  fileType: string,
}) => {
  const client = useClient();
  const imageWellContext = useImageWellContext();
  const imagePagedList = usePagedList(
    async () => {
      let endpoint = client.properties.for(imageWellContext.propertyId);
      return endpoint.images.search(query, fileType);
    },
    [query],
    { accumulate: true },
  );

  return (
    <ImageGrid loading={imagePagedList.loading}>
      {(!imagePagedList.loading || imagePagedList.paging) && imagePagedList.items.map((image: ImageSearchItem) => (
        <LibraryButton w="100px" h="100px" key={image.imageRefId} onClick={() => onChange(image.imageRefId)}>
          <LibraryImage
            imageRefId={image.imageRefId}
            url={image.imageRef.url}
          />
        </LibraryButton>
      ))}
      <EmptyPagedList pagedList={imagePagedList} />
      <LoadMore pagedList={imagePagedList} />
    </ImageGrid>
  );
};

export default SharedImages;
