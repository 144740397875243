import RequestCache from './RequestCache';

class RequestCacheProvider {
  constructor() {
    this.cacheCache = {};
  }

  getCache(key) {
    if (!key) return null;

    if (!this.cacheCache[key]) {
      this.cacheCache[key] = new RequestCache();
    }
    return this.cacheCache[key];
  }
}

const requestCacheProvider = new RequestCacheProvider();

export default requestCacheProvider;
