import React, { useMemo } from 'react';
import { TagField } from '../../reactFinalForm/fields';
import FilterCategoryField from './FilterCategoryField';
import FilterPlanField from './FilterPlanField';
import FilterSet from './FilterSet';


const FilterTagField = ({ name }) => {
  return (
    <TagField wrapLabel fluid name={name} />
  );
};

export const OPERATIONS = {
  inclusion: [
    { text: 'Contains Any Of', value: 'containsAnyOf' },
    { text: 'Contains All Of', value: 'containsAllOf' },
    { text: 'Does Not Contain Any Of', value: 'notContainsAnyOf' },
    { text: 'Does Not Contain All Of', value: 'notContainsAllOf' }
  ],
  singularInclusion: [
    { text: 'Is Any Of', value: 'isAnyOf' },
    { text: 'Is Not Any Of', value: 'notIsAnyOf' }
  ]
};

const rowConfig = {
  targets: [
    { text: 'Feature Plan', value: 'businessPolicyPlanId' },
    { text: 'Category', value: 'businessCategoryIds' },
    { text: 'Business Tag', value: 'businessTags' },
    { text: 'Offer Tag', value: 'businessOfferTags' }
  ],
  getOperations: (target) => {
    switch (target) {
      case 'businessPolicyPlanId':
        return OPERATIONS.singularInclusion;
      case 'businessCategoryIds':
      case 'businessTags':
      case 'businessOfferTags':
        return OPERATIONS.inclusion;
      default:
        return [];
    }
  },
  // eslint-disable-next-line react/display-name
  getComponent: (target, operation) => {
    switch (target) {
      case 'businessPolicyPlanId':
        return FilterPlanField;
      case 'businessCategoryIds':
        return FilterCategoryField;
      case 'businessTags':
      case 'businessOfferTags':
        return FilterTagField;
      default:
        return null;
    }
  }
};

const EmptyView = () => (
  <div className='mb1'>
    <em>All Businesses will be included.</em>
  </div>
);

const BusinessFilterSet = ({ name, propertyId }) => {
  const context = useMemo(
    () => ({ propertyId }),
    [propertyId]
  );
  return (
    <FilterSet name={name} rowConfig={rowConfig} empty={<EmptyView />} context={context} />
  );
};

export default BusinessFilterSet;
