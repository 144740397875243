import { types } from './types';

export const invalidate = () => ({
  type: types.PLAN_INVALIDATE,
});

export const getPlan = ({ businessId, propertyId }) => ({
  type: types.PLAN_REQUEST,
  payload: {
    businessId,
    propertyId
  }
});
