const shallowEqual = (a, b) => {
  if (a === b) return true;
  if (!a || !b) return false;

  if (Object.keys(a).some(key => a[key] !== b[key])) return false;
  if (Object.keys(b).some(key => !(key in a))) return false;

  return true;
};

export default shallowEqual;
