/* globals window */

import { Box } from '@chakra-ui/react';
import classnames from 'classnames';
import {
  FeatureGroup,
  Icon,
  map as createMap,
  Marker,
  Polygon,
  TileLayer
} from 'leaflet/dist/leaflet-src.esm';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './Map.css';
import disabledMarkerIcon from './marker-icons/marker-icon-grey.png';
import markerShadow from './marker-icons/marker-shadow.png';

import {
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  fixIconBug,
  getMapKey
} from './sharedMapSettings';

fixIconBug(Icon);

const MAP_TILE_KEY = getMapKey();

const getValidMarkerBounds = (markerLayer) => {
  const bounds = markerLayer.getBounds();
  return bounds.isValid() ? bounds : null;
};

class Map extends Component {
  static propTypes = {
    initialCenter: PropTypes.arrayOf(PropTypes.number),
    initialZoom: PropTypes.number,
    zoomControl: PropTypes.bool,
    className: PropTypes.string,
    onPopupClick: PropTypes.func,
    onMarkerUpdate: PropTypes.func,
    markers: PropTypes.arrayOf(PropTypes.shape({
      center: PropTypes.arrayOf(PropTypes.number),
      title: PropTypes.string
    })),
    regions: PropTypes.arrayOf(PropTypes.shape({
      area: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    fitToMarkers: PropTypes.bool,
    initialFitToMarkers: PropTypes.bool,
    fitToRegions: PropTypes.bool
  };

  static defaultProps = {
    fitToMarkers: true
  };

  state = {};

  componentDidMount() {
    const {
      fitToMarkers,
      initialFitToMarkers = fitToMarkers,
      initialCenter = DEFAULT_CENTER,
      initialZoom = DEFAULT_ZOOM,
      zoomControl = true
    } = this.props;

    // Marker Layer
    this.markerLayer = new FeatureGroup();
    this.updateMarkers();
    const initialBounds = initialFitToMarkers ? getValidMarkerBounds(this.markerLayer) : null;

    // Tile Layer
    const tileUrl = `https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=${MAP_TILE_KEY}`;
    this.tileLayer = new TileLayer(tileUrl, {
      attribution: 'Maps © Thunderforest, Data © OpenStreetMap contributors'
    });

    // Create Map
    const mapCfg = {
      zoomControl,
      center: initialBounds ? undefined : initialCenter,
      zoom: initialBounds ? undefined : initialZoom,
      zoomAnimationThreshold: 20,
      tap: false
    };
    this.map = createMap(this.mapEl, mapCfg);

    // Region Layer
    this.regionLayer = new FeatureGroup();
    this.updateRegions();

    this.map
      .addLayer(this.tileLayer)
      .addLayer(this.markerLayer)
      .addLayer(this.regionLayer);

    if (initialBounds) this.fitToMarkers({ animate: false });
  }

  fitToMarkers() {
    if (this.state.isDragging) return false;
    const markerBounds = this.markerLayer.getBounds();
    if (!markerBounds.isValid()) return false;
    this.map.fitBounds(markerBounds);
    return true;
  }

  componentDidUpdate(prevProps) {
    const { markers: prevMarkers, regions: prevRegions } = prevProps;
    const { markers, regions, fitToMarkers } = this.props;
    if (markers !== prevMarkers) this.updateMarkers();
    if (regions !== prevRegions) this.updateRegions();
    if (fitToMarkers) this.fitToMarkers();
  }

  markerMoveStart() {
    this.setState({ isDragging: true });
  }

  markerMoveEnd() {
    this.setState({ isDragging: false });
  }

  updateMarkers() {
    const { markers, onPopupClick, onMarkerUpdate } = this.props;
    this.markerLayer.clearLayers();
    _.forEach(markers, (m) => {
      const icon = m.isEnabled
        ? new Icon.Default()
        : new Icon({
          iconUrl: disabledMarkerIcon,
          shadowUrl: markerShadow,
          // The icon anchor values are the position of the tip of the marker in the disabledMarkerIcon image.
          iconAnchor: [12, 41]
        });
      const marker = new Marker(
        m.center,
        {
          title: m.title,
          icon,
          draggable: m.draggable,
          autoPan: m.draggable
        }
      );
      marker.data = m.data;

      if (onMarkerUpdate) marker.addEventListener('move', onMarkerUpdate);
      marker.addEventListener('movestart', () => this.markerMoveStart());
      marker.addEventListener('moveend', () => this.markerMoveEnd());


      if (m.popup && window.document) {
        const popupEl = window.document.createElement('div');
        popupEl.innerHTML = m.popup.content;
        const button = popupEl.querySelector('button.button');
        if (button) button.addEventListener('click', () => onPopupClick(m.popup));

        marker.bindPopup(popupEl);
      }
      marker.addTo(this.markerLayer);
    });
  }

  updateRegions() {
    const { regions, fitToRegions } = this.props;
    const regionLayer = this.regionLayer;
    regionLayer.clearLayers();
    _.forEach(regions, (region) => {
      const style = region.style || { color: 'red' };
      const polygon = new Polygon(region.area, style);
      polygon.addTo(regionLayer);
    });
    if (fitToRegions) this.map.fitBounds(this.regionLayer.getBounds());
  }

  componentWillUnmount() {
    this.map.remove();
    this.map = null;
  }

  render() {
    const {
      className,
      initialCenter,
      initialZoom,
      initialFitToMarkers, // eslint-disable-line no-unused-vars
      zoomControl,
      markers,
      onPopupClick,
      fitToMarkers, // eslint-disable-line no-unused-vars
      fitToRegions,
      ...rest
    } = this.props;
    return (
      <Box
        {...rest}
        className={classnames(['c-map', className])}
        ref={el => this.mapEl = el} // eslint-disable-line no-return-assign
      />
    );
  }
}

export default Map;
