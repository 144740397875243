import { useState, useEffect } from 'react';
import client from '../services/client';

const useCollectionFilters = (collectionId, propertyId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    businessFilter: {},
    businessOfferFilter: {},
    regionFilters: {}
  });

  const fetchFilterSets = (id) => {
    return client.properties.for(propertyId).collections.edit(id)
      .then(collectionEdit => collectionEdit && collectionEdit.getFields());
  };

  useEffect(() => {
    let isCanceled = false;

    const runEffect = async () => {
      try {
        setIsLoading(true);
        const result = await fetchFilterSets(collectionId);
        if (!isCanceled) {
          setData({
            businessFilter: result.businessFilter,
            businessOfferFilter: result.businessOfferFilter,
            regionFilters: result.regionFilters
          });
        }
      } catch (err) {
        if (!isCanceled) {
          setError(new Error('Sorry, There was an error loading the Collection Filters.'));
        }
      }
      setIsLoading(false);
    };

    runEffect();

    return () => {
      isCanceled = true;
    };
  }, [collectionId, propertyId]);

  return [data, isLoading, error];
};

export default useCollectionFilters;
