import { FileTypeInfo } from '../types';


const initialState = {
  value: null,
  ephemeralImageUrl: null,
  uploading: null,
  error: null,
};

const formatMinDimensions = (fileTypeInfo: FileTypeInfo) => {
  if (!fileTypeInfo) return '';
  const { restrictions: { dimensions: { minWidth, minHeight } } } = fileTypeInfo;
  return `${minWidth}x${minHeight}px`;
};

const stateReducer = (state: State, action: { type: string, payload?: any, error?: Error | null | undefined }) => {
  switch (action.type) {
    case 'uploadStart':
      return {
        ...state,
        uploading: true,
        ephemeralImageUrl: action.payload,
        error: null,
      };
    case 'uploadSuccess':
      return {
        ...state,
        uploading: false,
        ephemeralImageUrl: null,
        value: action.payload,
        error: null,
        dragging: false,
      };
    case 'uploadError':
      return {
        ...state,
        uploading: false,
        ephemeralImageUrl: null,
        error: action.payload,
        dragging: false,
      };
    case 'imageInvalid':
      return {
        ...state,
        uploading: false,
        ephemeralImageUrl: null,
        error: `Image too small (minimum ${formatMinDimensions(action.payload.fileTypeInfo)})`,
        dragging: false,
      };
    case 'fileInvalid':
      return {
        ...state,
        error: 'Unable to read image file',
        dragging: false,
      };
    case 'previewStart':
      return {
        ...state,
        previewUrl: action.payload,
      };
    case 'setMeta':
      return {
        ...state,
        meta: action.payload,
      };
    case 'confirmStart':
      return {
        ...state,
        confirming: true,
        dragging: false,
        ephemeralImageUrl: action.payload,
        previewUrl: null,
      };
    case 'confirmEnd':
      return {
        ...state,
        ephemeralImageUrl: null,
        confirming: false,
      };
    case 'cropPrepare':
      return {
        ...state,
        error: null,
        preparingCrop: true,
        ephemeralImageUrl: action.payload,
        dragging: false,
      };
    case 'cropStart':
      return {
        ...state,
        error: null,
        preparingCrop: false,
        cropping: true,
        ephemeralImageUrl: action.payload,
        dragging: false,
        previewUrl: null,
      };
    case 'cropSuccess':
      return {
        ...state,
        error: null,
        cropping: false,
        cropInput: null,
        ephemeralImageUrl: action.payload,
        dragging: false,
      };
    case 'cropCancelled':
      return {
        ...state,
        cropping: false,
        ephemeralImageUrl: null,
        dragging: false,
      };
    case 'dragIn':
      if (state.uploading) return state;
      return {
        ...state,
        dragging: true,
      };
    case 'dragOut':
      return {
        ...state,
        dragging: false,
      };
    default:
      return state;
  }
};

export {
  initialState,
  stateReducer,
};
