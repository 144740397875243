import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Message from '../../components/collections/Message';
import SupportLink from '../../support/SupportLink';

const RegionSupportMessage = ({ name }) => {
  const { strings } = useI18Next();

  return (
    <Field name={name} subscribe={{ error: true, submitError: true }}>
      {({ meta: { error, submitError } }) => {
        const currentError = error || submitError;
        if (currentError) {
          return (
            <Message info>
              <span>{strings('ui.component.regionSupportMessage.fragment.pt1')}</span>&nbsp;
              <div style={{ display: 'inline-block' }}>
                <SupportLink label={strings('ui.label.contactSupport')}/>
              </div>&nbsp;
              <span>{strings('ui.component.regionSupportMessage.fragment.pt2')}</span>
            </Message>
          );
        }
        return null;
      }}
    </Field>
  );
};

RegionSupportMessage.propTypes = {
  name: PropTypes.string.isRequired
};

export default RegionSupportMessage;
