import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import Menu from 'web-react-ui/src/components/collections/Menu';
import SubNav from 'web-react-ui/src/navigation/SubNav';

const NavMenuItem = (props: { name: string } & NavLinkProps) => (
  <Menu.Item as={NavLink} activeClassName='active' {...props} />
);

const Nav = ({ basePath }: { basePath: string }): JSX.Element => (
  <div className='brdr--b'>
    <SubNav>
      <NavMenuItem to={`${basePath}/offers`} name='offers' />
      <NavMenuItem to={`${basePath}/stats`} name='stats' />
    </SubNav>
  </div>
);

export default Nav;
