import PropTypes from 'prop-types';
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { FieldError } from '../../reactFinalForm/fields';
import { Field } from '../../reactFinalForm';
import ImageCarousel from '../imageWell2/imageCarousel/ImageCarousel';

const ImageField = (props) => {
  const {
    name,
    label,
    validate,
    fluid = true,
    skipUpload,
    disabled,
    ...rest
  } = props;
  const { strings } = useI18Next();

  return (
    <Field name={name} validate={validate}>
      {({ input }) => (
        <>
          <Text fontWeight={600} mb={3}>
            {label}
          </Text>
          <ImageCarousel {...input} fluid={fluid} type="text" skipUpload={skipUpload} {...rest} />
          <FieldError name={name} chakra />
        </>
      )}
    </Field>
  );
};

ImageField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  fluid: PropTypes.bool,
  skipUpload: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ImageField;
