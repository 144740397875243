import {
  Center,
  Icon,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PiFileImageThin } from 'react-icons/pi';
import useClient from 'shared/src/hooks/useClient';
import usePagedList from 'shared/src/hooks/usePagedList';
import { getImageFileUrlAsFile } from '../../../imageWell/imageUtils';
import ImageUploadWrapper from '../../ImageUploadWrapper/ImageUploadWrapper';
import uploadImage from '../../ImageUploadWrapper/uploadImage';
import { useImageWellContext } from '../../ImageWellContext';
import ImageUploadModal from '../../upload/ImageUploadModal';

import ImageGrid from '../ImageGrid';
import LibraryButton from '../LibraryButton';
import LibraryImage from '../LibraryImage';
import NewFileMeta from '../types/NewFileMeta.interface';
import EmptyPagedList from './shared/EmptyPagedList';
import Error from './shared/Error';
import LoadMore from './shared/LoadMore';

interface ImageSearchItem {
  imageRefId: string,
  imageRef: {
    url: string
  }
}

interface UploadedImage {
  image: string;
  promise: Promise<void>;
  imageRefId?: string;
  loading: boolean;
}

const MyImages = ({ query, onChange, fileType, aspectRatio, externalImageForUpload }
  : {
  query?: string,
  onChange: (imageRefId: string) => void,
  fileType: string,
  aspectRatio?: number,
  externalImageForUpload?: { src: string, meta: NewFileMeta }
}) => {
  const client = useClient();
  const toast = useToast();
  const imageWellContext = useImageWellContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addedImages, setAddedImages] = useState<Array<UploadedImage>>([]);

  const flushCompletedImages = useMemo(
    () => () => {
      // Remove all images that are finished uploading and will now come back from the search endpoint
      setAddedImages(list => list.filter(item => !item.imageRefId));
    },
    [],
  );

  const imagePagedList = usePagedList(
    async () => {
      let endpoint = client.properties.for(imageWellContext.propertyId);
      if (imageWellContext.businessId) endpoint = endpoint.businesses.for(imageWellContext.businessId);
      if (imageWellContext.locationId) endpoint = endpoint.locations.for(imageWellContext.locationId);

      flushCompletedImages();
      return endpoint.images.search(query, fileType);
    },
    [query],
    { accumulate: true },
  );

  const startUploadingImage = useMemo(
    () => (src: string, meta: NewFileMeta) => {
      const item: UploadedImage = {
        image: src,
        loading: true,
      } as UploadedImage;

      const file = getImageFileUrlAsFile(src);

      item.promise = uploadImage(
        client,
        file,
        fileType,
        imageWellContext.propertyId,
        imageWellContext.businessId,
        imageWellContext.locationId,
        meta?.keywords,
      ).then(
        (imageRefId) => {
          item.imageRefId = imageRefId;
          item.loading = false;
          // Hack: change list reference so the items get re-rendered
          setAddedImages(list => [...list]);
        },
        (error) => {
          toast({
            title: 'Error Uploading Image',
            description: 'There was a problem uploading an image. Please try again.',
            status: 'error',
            isClosable: true,
          });
        },
      );

      setAddedImages(list => ([item, ...list]));
      onClose();
    },
    [],
  );

  useEffect(
    () => {
      if (!externalImageForUpload) return;
      const { src, meta } = externalImageForUpload;
      startUploadingImage(src, meta);
    },
    [],
  );

  return (
    <ImageUploadWrapper
      onChange={startUploadingImage}
      fileType={fileType}
      aspectRatio={aspectRatio}
      skipUpload={true}
      stealth={true}
      isEmpty={true}
    >
      <ImageGrid loading={imagePagedList.loading}>
        <LibraryButton
          w="100px"
          h="100px"
          border="1px dashed silver"
          onClick={onOpen}
        >
          <Center w="100%" h="100%">
            <Icon as={PiFileImageThin} boxSize={16} color="gray.400" />
          </Center>
        </LibraryButton>
        {addedImages.map(
          ({ image, imageRefId, loading }) => (
            <LibraryButton w="100px" h="100px" key={image} onClick={imageRefId ? () => onChange(imageRefId) : null}>
              <LibraryImage ephemeralImageUrl={image} imageRefId={imageRefId} loading={loading} />
            </LibraryButton>
          ),
        )}
        {(!imagePagedList.loading || imagePagedList.paging) && imagePagedList.items.map((image: ImageSearchItem) => (
          <LibraryButton w="100px" h="100px" key={image.imageRefId} onClick={() => onChange(image.imageRefId)}>
            <LibraryImage
              imageRefId={image.imageRefId}
              url={image.imageRef.url}
            />
          </LibraryButton>
        ))}
        {!addedImages.length && (
          <EmptyPagedList pagedList={imagePagedList}>
            {query
              ? `No images found for "${query}"`
              : 'Click Add Photo to add your first image'
            }
          </EmptyPagedList>
        )}
        <Error pagedList={imagePagedList}/>
        <LoadMore pagedList={imagePagedList} />
      </ImageGrid>
      {isOpen && (
        <ImageUploadModal
          onClose={onClose}
          onChange={startUploadingImage}
          fileType={fileType}
          aspectRatio={aspectRatio}
          skipUpload={true} // Expect Base64Url and handle upload out here
          isEmpty={true}
        />
      )}
    </ImageUploadWrapper>
  );
};

export default MyImages;
