import {
  Box,
  Flex,
} from '@chakra-ui/react';
import React, { ComponentPropsWithoutRef } from 'react';

interface PipsProps extends ComponentPropsWithoutRef<typeof Flex> {
  current: number,
  max: number,
}

const Pips = ({ current, max, ...rest }: PipsProps) => {
  return (
    <Flex gap="1em" width="100%" justifyContent="center" pointerEvents="none" {...rest}>
      {Array(max).fill(undefined).map((_, i) => {
        const isCurrent = current === i;
        return (
          <Box
            key={i} borderRadius="full" boxSize="1em"
            bg={isCurrent ? 'white' : 'silver'}
            opacity={isCurrent ? '100%' : '50%'}
            border="1px solid #00000099"
          />
        );
      })}
    </Flex>
  );
};

export default Pips;
