/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import propertyModule from "../../modules/property/index";

class HomeView extends Component {
  static propTypes = {
    property: PropTypes.object
  };

  render() {
    const { property } = this.props;
    if (!property) return <Redirect to="/properties" />;
    return <Redirect to={`/${property.id}`} />;
  }
}

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state)
});

export default connect(mapState)(HomeView);
