import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import View from 'web-react-ui/src/components/layout/View';
import Item from 'web-react-ui/src/components/views/Item';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import auth from 'shared/src/modules/auth';
import client from '../services/client';

class Profile extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  state = {};

  componentDidMount() {
    this.setState({ isLoading: true });
    client
      .authProvider
      .getTokens()
      .then(tokens => this.setState({ tokens, isLoading: false }));
  }

  render() {
    const { user } = this.props;
    return (
      <View>
        <WaitFor waitFor={user}>
          {() => (
            <View.Section>
              <Item.Group>
                <Item>
                  <Item.Image size="small" src='https://www.gravatar.com/avatar/f35eaabdd541117221dcd591d856e460' />
                  <Item.Content>
                    <Item.Header>{user.name || "Your Name Here"}</Item.Header>
                    <Item.Extra>
                      <dl>
                        <dt>Email</dt>
                        <dd>{user.email}</dd>
                        <dt>Name</dt>
                        <dd>{user.name}</dd>
                        <dt>Id</dt>
                        <dd>{user.id}</dd>
                        <WaitFor waitFor={!this.state.isLoading && this.state.tokens}>
                          {() => (
                            <React.Fragment>
                              <dt>Token</dt>
                              <dd>{this.state.tokens.access}</dd>
                              <dt>Refresh</dt>
                              <dd>{this.state.tokens.refresh}</dd>
                              <dt>Version</dt>
                              <dd>{this.state.tokens.version}</dd>
                            </React.Fragment>
                          )}
                        </WaitFor>
                      </dl>
                    </Item.Extra>
                  </Item.Content>
                </Item>
              </Item.Group>
            </View.Section>
          )}
        </WaitFor>
      </View>
    );
  }
}

const mapState = state => ({ user: auth.selectors.getUser(state) });

export default connect(mapState)(Profile);
