import React from 'react';
import { Flex, Heading, HStack, IconButton, Button } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import User from '../../types/User.interface';

const UserProfileHeader = ({
  user,
  userData,
  isCurrentUser,
  isDeleting,
  isLoading,
  removeUser,
  onEdit,
  onCancel,
  isEdit,
}: {
  user: User,
  userData?: { name: string, newRoles: string[], context: string };
  isCurrentUser: boolean,
  isDeleting: boolean,
  isLoading?: boolean,
  removeUser: () => void,
  onEdit?: () => void,
  onCancel?: () => void,
  isEdit?: boolean,
}): JSX.Element => (
  <Flex
    justify='space-between'
    align='center'
    my={10}
    flexWrap='wrap'
  >
    <Heading>{user.name || user.email}</Heading>
    <HStack spacing={5} mt={[2, 0]}>
      {!isCurrentUser && (
        <IconButton
          icon={<DeleteIcon />}
          isLoading={isDeleting}
          aria-label='delete'
          onClick={removeUser}
        />
      )}
      {onCancel && (
        <Button variant='solid' onClick={onCancel}>
          Cancel
        </Button>
      )}
      {onEdit && (
        <Button variant='solid' colorScheme='blue' onClick={onEdit}>
          Edit User
        </Button>
      )}
      {isEdit && (
        <Button
          variant='solid'
          colorScheme='blue'
          isLoading={isLoading}
          type='submit'
        >
          Save
        </Button>
      )}
    </HStack>
  </Flex>
);

export default UserProfileHeader;
