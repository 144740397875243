import React, { Component } from 'react';

import Menu from '../components/collections/Menu';
import Dropdown from '../components/modules/Dropdown';
import Responsive from '../addons/Responsive';
// FIXME: Move the styles used here out of Nav.less into a shared less file
import './Nav.css';

const subNavStyle = {
  borderBottom: 'none'
};

class SubNavItem extends Component {
  render() {
    return (
      <Menu.Item className='--hide-inactive__child' activeClassName='active' {...this.props} />
    );
  }
}

class SubNav extends Component {
  state = {};

  handleResponsiveUpdate(event, responsive) {
    this.setState({ compactMode: (responsive.width <= responsive.maxWidth) });
  }

  render() {
    const { children, ...rest } = this.props;
    const childArray = React.Children.toArray(children);

    return (
      <React.Fragment>
        <Responsive minWidth={Responsive.onlyTablet.minWidth + 1}>
          <Menu pointing secondary {...rest} style={subNavStyle}>
            {children}
          </Menu>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyTablet.minWidth}>
          {/* HACK: We put all the items in as the label and CSS hides the inactive ones */}
          <Dropdown
            style={{ background: 'none', border: 'none' }}
            fluid
            selection
            text={<div className='--hide-inactive'>{childArray}</div>}
          >
            <Dropdown.Menu>
              {children}
            </Dropdown.Menu>
          </Dropdown>
        </Responsive>
      </React.Fragment>
    );
  }
}

SubNav.Item = SubNavItem;

export default SubNav;
