import React, { Component } from 'react';
import PropTypes from 'prop-types';

// trimStartPath & trimEndPath are used to correctly handle paths
// that contained leading & trailing slashes. These are converted to
// empty strings in the array, and if not removed correctly can cause
// double slashes to be added to urls, breaking navigation.
//
//  Example input that breaks without these:
//    resolvePath('/test/one/', '../../')
//
function trimStartPath(pathSegments) {
  const start = pathSegments.shift();
  // simply test if the first time is not empty, meaning it's not a slash
  if (start) pathSegments.unshift(start);
}

function trimEndPath(pathSegments) {
  const last = pathSegments.pop();
  if (last) pathSegments.push(last);
}

const resolvePath = (from = '', to = '') => {
  const fromSegments = from.split('/');
  trimEndPath(fromSegments);

  const toSegments = to.split('/');
  trimStartPath(toSegments);
  trimEndPath(toSegments);

  const merged = fromSegments.slice();
  toSegments.forEach((seg) => {
    switch (seg) {
      case '.':
        break;
      case '..':
        merged.pop();
        break;
      default:
        merged.push(seg);
        break;
    }
  });
  return merged.join('/');
};

const RelativeLinkFactory = (Link, Route) => {
  // NB: This is a class component (and not a functional component) because React Router assigns it a ref.
  class RelativeLink extends Component {
    static propTypes = {
      to: PropTypes.string
    };

    render() {
      const { to, ...rest } = this.props;

      return (
        <Route render={({ match }) => (
          <Link to={resolvePath(match.url, to)} {...rest} />
        )}/>
      );
    }
  }

  return RelativeLink;
};

export default RelativeLinkFactory;
