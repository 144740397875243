const CANCELED = 'cancelablePromise/canceled';

function CancelablePromise(promise, action) {
  const module = {
    isSettled: false,
    isCanceled: false,
    action
  };

  module.promise = new Promise((resolve, reject) => {
    Promise.resolve(promise).then(
      value => (module.isCanceled ? reject(new Error(CANCELED)) : resolve(value)),
      (error) => {
        if (error instanceof Error) return reject(error);
        return reject(new Error(error));
      }
    ).then(() => { module.isSettled = true; });
  });

  module.cancel = () => {
    module.isCanceled = true;
  };

  return module;
}

CancelablePromise.CANCELED = CANCELED;

export default CancelablePromise;
