/* eslint-disable no-bitwise */
export default function imgixParams(url, width, height, format, quality) {
  if (!url) return '';

  const params = [];
  if (width) params.push(`w=${width}`);
  if (height) params.push(`h=${height}`);
  if (format) params.push(`fm=${format}`);
  if (quality) params.push(`q=${quality}`);
  if (width && height) params.push('fit=crop');
  if (!params.length) return url;

  return url + (~url.indexOf('?') ? '&' : '?') + params.join('&');
}
