import PropTypes from 'prop-types';
import React from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import AudienceView from '../components/audience/AudienceView';
import AddBusinessModal from '../components/businesses/AddBusinessModal';
import BusinessList from '../components/businesses/BusinessList';
import AddCollectionModal from '../components/collections/AddCollectionModal';
import CollectionDetails from '../components/collections/CollectionDetails';
import CollectionsView from '../components/collections/CollectionsView';
import EditCollectionModal from '../components/collections/EditCollectionModal';
import IntegrationDetailsContainer from '../components/integration/IntegrationDetailsContainer';
import OffersView from '../components/offers/OffersView';

import withProperty from '../modules/property/hoc/withProperty';
import BusinessDetailsRoutes from './BusinessDetailsRoutes';
import PropertySettingsRoutes from './PropertySettingsRoutes';

// TODO: Implement Redirect to /properties if selectedProperty is not defined
const PropertyDetailsRoutes = (props) => {
  const { match, property } = props;
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/offers`}
        render={() => <OffersView property={property} basePath={match.url} />}
      />
      <Route path={`${match.url}/businesses/add`} render={() => <AddBusinessModal />} />
      <Route path={`${match.url}/businesses/:businessSlug`} component={BusinessDetailsRoutes} />
      <Route path={`${match.url}/businesses`} render={() => <BusinessList property={property} />} />
      <Route path={`${match.url}/audience`} component={AudienceView} />
      <Route path={`${match.url}/collections`} exact render={() => <CollectionsView property={property} />} />
      <Route path={`${match.url}/collections/add`} exact render={() => <AddCollectionModal property={property} />} />
      <Route
        path={`${match.url}/collections/:collectionSlug/edit`}
        exact
        render={routeProps => (
          <EditCollectionModal
            property={property}
            collectionId={routeProps.match.params.collectionSlug} {...routeProps}
          />
        )}
      />
      <Route
        path={`${match.url}/collections/:collectionSlug`}
        exact
        render={routeProps => (
          <CollectionDetails
            collectionId={routeProps.match.params.collectionSlug}
            propertyId={property.id}
            parentRoute={`${match.url}/collections`}
            {...routeProps}
          />
        )}
      />

      <Route
        path={`${match.url}/property-settings`}
        render={routeProps => <PropertySettingsRoutes basePath={routeProps.match.url} property={property} />}
      />

      <Route
        path={`${match.url}/integrations/:integrationSlug`}
        render={routeProps => (
          <IntegrationDetailsContainer
            integrationId={routeProps.match.params.integrationSlug}
            {...routeProps}

          />
        )}
        property={property}
      />
      <Redirect to={`${match.url}/businesses`} />
    </Switch>
  );
};

PropertyDetailsRoutes.propTypes = {
  property: PropTypes.object,
  match: PropTypes.any,
};

export default withProperty(PropertyDetailsRoutes);
