import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const FieldLabel = ({
  label, optional, children, wrapLabel, disabled
}) => {
  const { strings } = useI18Next();
  return (
    <div className={`${wrapLabel ? '' : 'flex'} aic fww${disabled ? ' text--muted' : ''}`}>
      <label className='field-label' style={{ width: '33%', minWidth: '130px' }}>
        {label}
        {optional && <small className='pull-right'>{strings('ui.label.optional')}</small>}
      </label>
      <div className='fg1' style={{ minWidth: '67%' }}>
        {children}
      </div>
    </div>
  );
};

FieldLabel.propTypes = {
  label: PropTypes.string,
  optional: PropTypes.bool,
  children: PropTypes.any,
  wrapLabel: PropTypes.bool,
  disabled: PropTypes.bool
};

export default FieldLabel;
