import {
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import React from 'react';

const ImageGrid = ({ loading, children }: { loading: boolean, children: React.ReactNode }) => {
  return (
    <Flex wrap="wrap" gap={4}>
      {children}
      {loading && (
        <>
          <Skeleton borderRadius="md" w="100px" height="100px" />
          <Skeleton borderRadius="md" w="100px" height="100px" opacity={0.5} />
          <Skeleton borderRadius="md" w="100px" height="100px" opacity={0.25} />
          <Skeleton borderRadius="md" w="100px" height="100px" opacity={0.125} />
        </>
      )}
    </Flex>
  );
};

export default ImageGrid;
