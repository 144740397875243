/* eslint-disable arrow-body-style */

import _ from 'lodash';
import PropTypes from 'prop-types';

import React, {
  useContext,
  useMemo
} from 'react';
import LocalizationWrapper from '../../reactFinalForm/fields/localizableField/LocalizationWrapper';
import OfferPreview from './OfferPreview';

const LocalizedOfferPreview = ({ offer, ...props }) => {
  const { locale } = useContext(LocalizationWrapper.context);
  const localizedOffer = useMemo(
    () => {
      return {
        ...offer,
        headline: _.get(offer, ['headlineLocalized', locale]),
        subHeadline: _.get(offer, ['subHeadlineLocalized', locale]),
        details: _.get(offer, ['detailsLocalized', locale]),
        legal: _.get(offer, ['legalLocalized', locale])
      };
    },
    [offer, locale]
  );
  return <OfferPreview {...props} offer={localizedOffer}/>;
};

LocalizedOfferPreview.propTypes = {
  offer: PropTypes.object
};

export default LocalizedOfferPreview;
