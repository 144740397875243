/* eslint no-nested-ternary:0 */
import {
  Badge,
  Box,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import ImageLibraryModal2 from './imageLibrary/ImageLibraryModal2';
import EmptyImage from './imagePreview/EmptyImage';
import FileTypeInfo2 from './imagePreview/FileTypeInfo2';
import { BORDER_RADIUS } from './constants';
import ImagePreview2 from './imagePreview/ImagePreview2';
import ImageUploadWrapper from './ImageUploadWrapper/ImageUploadWrapper';
import { useImageWellContext } from './ImageWellContext';

interface ImageWell2Props {
  value: string;
  onChange: (value: string) => void;
  width?: number;
  maxWidth?: number;
  height?: number;
  cover?: boolean;
  fileType?: string;
  propertyId?: string;
  businessId?: string;
  locationId?: string;
  aspectRatio?: number;
  skipUpload?: boolean;
  dashboard?: boolean;
}

const ImageWell2 = ({
  value,
  onChange,
  width,
  maxWidth = 650,
  height = 250,
  cover = false,
  fileType,
  propertyId,
  businessId,
  locationId,
  aspectRatio,
  skipUpload,
}: ImageWell2Props) => {
  const imageWellContext = useImageWellContext();
  const objectFit = cover ? 'cover' : 'contain';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onModalChange = (newValue: string) => {
    // This is really here just because the existing ImageWellModal doesn't close itself on change
    onClose();
    onChange(newValue);
  };
  useEffect(
    () => {
      imageWellContext.cover = cover;
      imageWellContext.fileType = fileType;
      imageWellContext.propertyId = propertyId;
      imageWellContext.businessId = businessId;
      imageWellContext.locationId = locationId;
      imageWellContext.aspectRatio = aspectRatio;
    },
    [],
  );
  return (
    <Box
      width={width}
      height={height}
      maxWidth={maxWidth}
      borderRadius={BORDER_RADIUS}
      position="relative"
    >
      <VStack w="100%" h="100%">
        <ImageUploadWrapper
          onChange={onChange}
          fileType={fileType}
          aspectRatio={aspectRatio}
          skipUpload={skipUpload}
          isEmpty={!value}
        >
          {/* NOTE: Click handler is in here so the ImageUploadWrapper can disable interaction when it's uploading */}
          <Box
            onClick={onOpen}
            w="100%"
            h="100%"
            position="relative"
            borderRadius={BORDER_RADIUS}
            overflow="hidden"
          >
            {
              value
                ? (
                  <>
                    <ImagePreview2 imageId={value} objectFit={objectFit} />
                    <Badge
                      borderRadius="full"
                      padding="0.5em"
                      position="absolute"
                      top="1em"
                      right="1em"
                      cursor="pointer"
                      bg="white"
                      color="gray.600"
                      sx={{
                        '.state-dragging &': {
                          display: 'none',
                        },
                      }}
                    >
                      Change
                    </Badge>
                  </>
                ) :
                (
                  <EmptyImage label="Drop photo here or click to open Image Library" />
                )
            }
          </Box>
        </ImageUploadWrapper>
        <ImageLibraryModal2
          open={isOpen}
          onClose={onClose}
          onChange={onModalChange}
          fileType={fileType}
          aspectRatio={aspectRatio}
        />
        <FileTypeInfo2 fileType={fileType} />
      </VStack>
    </Box>
  );
};

export default ImageWell2;
