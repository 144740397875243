import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LoginContainer from './LoginContainer';

const LoginView = (): JSX.Element => (
  <Flex align='stretch' maxW={{ base: '100%', sm: '70%', md: 'initial' }} mx={{ base: 'auto', md: 'initial' }}>
    <Flex flexDir='column' justify='center' w='40%' minW={{ base: '100%', md: '480px' }}>
      <LoginContainer />
    </Flex>
    <Box bg='gray.100' minH='100vh' w='60%' flexShrink={1} />
  </Flex>
);

export default LoginView;
