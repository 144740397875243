/* global window */
/* eslint class-methods-use-this: 0 object-shorthand: 0 */
/* eslint jsx-a11y/accessible-emoji: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { injectClient } from 'shared/src/components/contexts/ClientContext';
import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import BigModal from '../../BigModal/BigModal';
import Button from '../elements/Button';
import View from '../layout/View';
import authState from './authState';

class AuthExpiry extends Component {
  static propTypes = {
    client: PropTypes.object
  };

  state = { isOpen: false };

  componentDidMount() {
    authState.register(this.openModal.bind(this));
  }

  pollForTokenUpdate(oldToken) {
    return new Promise((res) => {
      const cb = () => {
        this.getToken()
          .then((newToken) => {
            if (newToken !== oldToken) return res();
            setTimeout(cb, 2000);
            return null;
          });
      };
      cb();
    });
  }

  async redirectToLogin() {
    const url = `${window.document.location.origin}/login`;
    const handle = window.open(url);

    const oldToken = await this.props.client.authProvider.getTokens()
      .then(tokens => tokens && tokens.access);

    this.pollForTokenUpdate(oldToken)
      .then(() => {
        handle.close();
        this.refreshPage();
      });
  }

  refreshPage() {
    this.getToken()
      .then((newAuthToken) => {
        if (newAuthToken !== null) {
          this.closeModal();
        }
      });
  }

  getToken() {
    return this.props.client.authProvider.refreshOutOfBandToken()
      .then(tokens => tokens && tokens.access);
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <I18NextContext.Consumer>
        {({ strings }) => (
          <BigModal open={this.state.isOpen}>
            <View>
              <View.Section narrow className='mt3'>
                <h6>{strings('ui.component.authExpiry.sessionHasExpired')}</h6>
                <h6 className='mt3'>
                  {strings('ui.component.authExpiry.startNewSession')}
                </h6>
                <div className='flex mt1'>
                  <Button primary onClick={this.redirectToLogin.bind(this)}>
                    {strings('ui.component.authExpiry.login')}
                  </Button>
                  <Button onClick={this.refreshPage.bind(this)}>
                    {strings('ui.component.authExpiry.refreshSession')}
                  </Button>
                </div>
              </View.Section>
            </View>
          </BigModal>
        )}
      </I18NextContext.Consumer>
    );
  }
}

export { AuthExpiry as Presenter };
export default injectClient(AuthExpiry);
