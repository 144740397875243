import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import ErrorMessage from '../../components/entities/ErrorMessage';

const FieldError = ({ name, alwaysShow, ...rest }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true, submitError: true }}
    render={({ meta: { touched, error, submitError } }) => {
      const currentError = error || submitError;
      return (alwaysShow || touched) && <ErrorMessage error={currentError} size='mini' {...rest} />;
    }}
  />
);
FieldError.propTypes = {
  name: PropTypes.string.isRequired,
  alwaysShow: PropTypes.bool
};

export default FieldError;
