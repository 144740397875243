/* eslint camelcase: 0, class-methods-use-this: 0 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import BusinessDetailsNav from '../components/businesses/BusinessDetailsNav';
import BusinessLocations from '../components/businesses/BusinessLocations';
import BusinessOffers from '../components/businesses/BusinessOffers';
import BusinessOverview from '../components/businesses/BusinessOverview';
import SuggestModal from '../components/businesses/promotions-ai/SuggestModal';
import Users from '../components/businesses/users/BusinessUsers';
import UserProfile from '../components/businesses/users/UserProfile';
import EditUserViewModel from '../components/businesses/users/EditUserViewModel';
import DuplicateOfferContainer from '../components/offers/DuplicateOfferContainer';
import NewCreateOfferContainer from '../components/offers/NewCreateOfferContainer';
import NewEditOfferContainer from '../components/offers/NewEditOfferContainer';
import OfferDetailsContainer from '../components/offers/OfferDetailsContainer';
import CollapsedNav from '../components/sitenav/CollapsedNav';
import businessModule from '../modules/businesses';
import planModule from '../modules/plan';
import propertyModule from '../modules/property';
import client from '../services/client';
import confirm from '../services/confirm';
import LocationDetailsRoutes from './LocationDetailsRoutes';

class BusinessDetailsRoutes extends Component {
  componentDidMount() {
    const { property, match, businessActions, planActions } = this.props;
    businessActions.details({
      propertyId: property?.id,
      businessId: match?.params?.businessSlug
    });
    planActions.getPlan({
      propertyId: property?.id,
      businessId: match?.params?.businessSlug
    });
  }

  state = {};

  invalidate() {
    const { property, match, businessActions } = this.props;
    businessActions.invalidate();
    businessActions.details({
      propertyId: property?.id,
      businessId: match?.params?.businessSlug
    });
  }

  disable() {
    const { property, business } = this.props;
    const result = confirm(`Are you sure you want to disable this Business?
Offers created under this Business will be disabled everywhere.`);
    if (result) {
      this.setState({ isBusy: true });
      client.properties.for(property.id).businesses.for(business.id).disable()
        .then(() => {
          this.setState({ isBusy: false });
          this.invalidate();
        })
        .catch(() => this.setState({ isBusy: false, submitError: true }));
    }
  }

  enable() {
    const { property, business } = this.props;
    const result = confirm('Are you sure you want to enable this Business?');
    if (result) {
      this.setState({ isBusy: true });
      client.properties.for(property.id).businesses.for(business.id).enable()
        .then(() => {
          this.setState({ isBusy: false });
          this.invalidate();
        })
        .catch(() => this.setState({ isBusy: false, submitError: true }));
    }
  }

  fetchTags() {
    const { property, business } = this.props;
    return client.properties.for(property.id).businesses.for(business.id).getTags()
      .then(tags => tags.items);
  }

  saveTags(newTags) {
    const { property, business } = this.props;
    return client.properties.for(property.id).businesses.for(business.id).updateTags(newTags);
  }

  cancel() {
    this.setState({ editTags: false });
  }

  handleSubmit(values) {
    const { property, business, businessActions } = this.props;
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .updateTags(values.tags)
      .then(() => {
        businessActions.invalidate();
        this.setState({ editTags: false });
      });
  }

  componentWillUnmount() {
    const { businessActions } = this.props;
    businessActions.invalidate();
  }

  render() {
    const { match, business, property, plan } = this.props;
    const { isBusy } = this.state;
    const allowsAdvancedSchedule = plan?.settings?.offer__feature__misc__allow_advanced_scheduling;

    return (
      <WaitFor waitFor={business && plan && !isBusy}>
        <Route render={(props) => {
          const currentPathTokens = props.location.pathname.split('/');
          const lastToken = currentPathTokens.pop();
          let currentPathDepth = currentPathTokens.length;
          if (lastToken === 'list' || lastToken === 'map') currentPathDepth -= 1;
          const parentPathDepth = props.match.url.split('/').length;
          const relativeDepth = currentPathDepth - parentPathDepth;

          return relativeDepth < 1
            ? (<BusinessDetailsNav
              business={business}
              property={property}
              basePath={match.url}
              fetchTags={this.fetchTags.bind(this)}
              saveTags={this.saveTags.bind(this)}
              onEnableBusiness={this.enable.bind(this)}
              onDisableBusiness={this.disable.bind(this)}
            />)
            : (<CollapsedNav label={business && business.name} />);
        }} />
        <Switch>
          <Route path={`${match.url}/overview`} property={property} business={business} component={BusinessOverview} />
          <Route
            path={`${match.url}/locations/map`}
            render={props => <BusinessLocations
              showView={'map'}
              history={props.history}
            />}
          />
          <Route
            path={`${match.url}/locations/list`}
            render={props => <BusinessLocations
              showView={'list'}
              history={props.history}
            />}
          />
          <Route
            path={`${match.url}/locations/:locationId`}
            render={props => (
              <LocationDetailsRoutes {...props} />
            )}
          />
          <Route path={`${match.url}/locations`}>
            <Redirect to={`${match.url}/locations/map`} />
          </Route>

          <Route path={`${match.url}/offers/suggest`}>
            <SuggestModal />
          </Route>
          <Route
            path={`${match.url}/offers/new/:offerId`}
            render={props => <DuplicateOfferContainer
              offerId={props.match.params.offerId}
              returnTo={`${match.url}/offers`}
              allowsAdvancedSchedule={allowsAdvancedSchedule}
            />}
          />
          <Route
            path={`${match.url}/offers/create`}
            render={() => <NewCreateOfferContainer
              returnTo={`${match.url}/offers`}
              plan={plan}
            />}
          />
          <Route
            path={`${match.url}/offers/:offerId/edit`}
            render={props => <NewEditOfferContainer
              offerId={props.match.params.offerId}
              redirectTo={`${match.url}/offers/${props.match.params.offerId}`}
            />}
          />
          <Route
            path={`${match.url}/offers/:offerId/:stats?`}
            render={props => <OfferDetailsContainer
              basePath={props.match.url}
              offerId={props.match.params.offerId}
              showStats={props.match.params.stats}
              history={props.history}
            />}
          />
          <Route path={`${match.url}/offers`} component={BusinessOffers} basePath={`${match.url}/offers`} />

          <Route path={`${match.url}/users/:userId/edit`}>
            <EditUserViewModel propertyId={property?.id} businessId={business?.id} baseUrl={`${match.url}/users`} />
          </Route>
          <Route path={`${match.url}/users/:userId`}>
            <UserProfile baseUrl={`/${property?.id}/businesses/${business?.id}/users`} />
          </Route>
          <Route path={`${match.url}/users`}>
            <Users />
          </Route>

          <Redirect to={`${match.url}/overview`} />
        </Switch>
      </WaitFor>
    );
  }
}

BusinessDetailsRoutes.propTypes = {
  business: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  match: PropTypes.any,
  property: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  details: PropTypes.func,
  businessActions: PropTypes.shape({
    invalidate: PropTypes.func,
    details: PropTypes.func,
  }),
  planActions: PropTypes.shape({
    invalidate: PropTypes.func,
    getPlan: PropTypes.func,
  }),
  plan: PropTypes.shape({
    settings: PropTypes.shape({
      offer__feature__misc__allow_advanced_scheduling: PropTypes.bool
    })
  })
};

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state),
  business: businessModule.selectors.business.getData(state),
  plan: planModule.selectors.plan.getData(state),
});

const mapDispatch = (dispatch) => {
  return {
    businessActions: bindActionCreators(businessModule.actions, dispatch),
    planActions: bindActionCreators(planModule.actions, dispatch)
  };
};

export default connect(mapState, mapDispatch)(BusinessDetailsRoutes);
