/* eslint class-methods-use-this: 0 */
/* eslint-disable-next-line no-redeclare */
/* globals window  */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Divider from 'web-react-ui/src/components/elements/Divider';
import Label from 'web-react-ui/src/components/elements/Label';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import View from 'web-react-ui/src/components/layout/View';
import CollectionPreview from 'web-react-ui/src/components/preview/CollectionPreview';
import TagEditor from 'web-react-ui/src/components/tags/TagEditor';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import propertyModule from '../../modules/property';
import client from '../../services/client';
import confirm from '../../services/confirm';
import RelativeLink from '../routing/RelativeLink';
import CollectionSchedule from './CollectionSchedule';
import MapRegions from './regions/MapRegions';
import ListRegions from './regions/ListRegions';
import CollectionListFilters from './CollectionListFilters';
import useCollectionFilters from '../../hooks/useCollectionFilters';

function Presenter({
  collection,
  propertyId,
  businessFeed,
  fetchTags,
  saveTags,
  handleEnable,
  handleDisable,
  handleDelete,
  fetchBusinessFeed
}) {
  const [{
    businessFilter,
    businessOfferFilter
  }, isLoading] = useCollectionFilters(collection && collection.id, propertyId);
  const enabled = collection && collection.enabled;
  const actions = [
    {
      label: 'Edit',
      extraProps: {
        as: RelativeLink,
        to: './edit'
      }
    },
    {
      label: 'Disable',
      action: handleDisable,
      hidden: !enabled
    },
    {
      label: 'Enable',
      action: handleEnable,
      hidden: enabled
    }
  ];
  const imageId = collection && collection.iconLarge;

  return (
    <View>
      <WaitFor waitFor={collection}>
        <View.Section fluid className="bg--gray brdr--b">
          <View.Section>
            <div className="pull-right">
              <ActionMenu actions={actions} />
            </div>
            <div className="pull-left mr1">
              <ProfileImage showBackground imageId={imageId} name={collection && collection.name} />
            </div>
            <div>
              <h1 className="mb0 mt0">
                {collection && collection.name}
              </h1>
              <TagEditor fetchTags={fetchTags} onSave={saveTags} />
              {(!enabled) && (
                <div className="mt1">
                  <Label color="red">Disabled</Label>
                </div>
              )}
            </div>
          </View.Section>
        </View.Section>
        {collection && (
          <View.Section className="mt2 flex fww">
            <div className="fg1" style={{ flexBasis: 0, minWidth: '300px' }}>
              <table style={{ 'table-layout': 'fixed', width: '100%' }}>
                <tbody>
                <tr>
                  <td><h6>Name</h6></td>
                  <td>{collection.name}</td>
                </tr>
                <tr>
                  <td><h6 className="mr2">Short Name</h6></td>
                  <td>{collection.shortName}</td>
                </tr>
                <tr>
                  <td><h6>Slug</h6></td>
                  <td>{collection.slug}</td>
                </tr>
                <tr>
                  <td><h6>Priority</h6></td>
                  <td>{collection.priority}</td>
                </tr>
                <tr>
                  <td><h6>Description</h6></td>
                  <td>{collection.desc}</td>
                </tr>
                </tbody>
              </table>

              <h2>Visibility</h2>
              <Divider />
              {collection.scheduleIds && (
                <div>
                  <h6 className="mb0 mt2">Schedule</h6>
                  <CollectionSchedule scheduleId={collection.scheduleIds[0]} />
                </div>
              )}

              <h6>Regions</h6>

              <ListRegions
                collection={collection}
                propertyId={propertyId}
                collectionId={collection.id} />

              <MapRegions collection={collection} />

            </div>
            <CollectionPreview image={collection.heroImage} collectionName={collection.name} />
          </View.Section>
        )}
        <View.Section>
          <h2>Content</h2>
          <Divider />

          <CollectionListFilters
            filterSetsTitle="Businesses"
            filterSets={businessFilter}
            isLoading={isLoading} />

          <CollectionListFilters
            filterSetsTitle="Offers"
            filterSets={businessOfferFilter}
            isLoading={isLoading} />

          <Divider />
          <div className="flex">
            <div className="mla">
              <ActionMenu actions={[
                ...actions,
                {
                  label: 'Delete Collection',
                  action: handleDelete,
                  negative: true
                }
              ]} />
            </div>
          </div>
          <div className="mt2">
            <button
              type="button"
              className="ui button primary basic fluid"
              onClick={fetchBusinessFeed}
            >
              Load Collection Data
            </button>
            <pre>{JSON.stringify(businessFeed, 0, 2)}</pre>
          </div>
        </View.Section>
      </WaitFor>
    </View>
  );
}

Presenter.propTypes = {
  collection: PropTypes.object,
  businessFeed: PropTypes.object,
  propertyId: PropTypes.string,
  fetchTags: PropTypes.func,
  saveTags: PropTypes.func,
  handleEnable: PropTypes.func,
  handleDisable: PropTypes.func,
  handleDelete: PropTypes.func,
  fetchBusinessFeed: PropTypes.func
};

class CollectionDetailsContainer extends Component {
  static propTypes = {
    property: PropTypes.object,
    parentRoute: PropTypes.string,
    propertyId: PropTypes.string,
    collectionId: PropTypes.string
  };

  state = {};

  componentDidMount() {
    const { propertyId, collectionId } = this.props;
    client.properties.for(propertyId).collections.details(collectionId).then((data) => {
      this.setState({ collection: data });
    }).catch((err) => {
      this.setState({ error: err });
    });
  }

  fetchTags() {
    const { property } = this.props;
    const { collection } = this.state;
    return client.tags.list(property.id, client.tags.TYPE_NAMES.collection, collection.id)
      .then(tags => tags.items);
  }

  saveTags(newTags) {
    const { property } = this.props;
    const { collection } = this.state;
    return client.tags.reconcile(property.id, client.tags.TYPE_NAMES.collection, collection.id, newTags);
  }

  handleEnable() {
    const { property } = this.props;
    const { collection } = this.state;
    if (!confirm(`Are you sure you want to enable ${collection.name}?`)) return false;
    this.setState({ isBusy: true });
    return client
      .properties.for(property.id)
      .collections.enable(collection.id)
      .then(() => {
        window.location.reload();
        this.setState({ isBusy: false });
      });
  }

  handleDisable() {
    const { property } = this.props;
    const { collection } = this.state;
    if (!confirm(`Are you sure you want to disable ${collection.name}?`)) return false;
    this.setState({ isBusy: true });
    return client
      .properties.for(property.id)
      .collections.disable(collection.id)
      .then(() => {
        window.location.reload();
        this.setState({ isBusy: false });
      });
  }

  handleDelete() {
    const { property } = this.props;
    const { collection } = this.state;
    if (!confirm(`Are you sure you want to delete ${collection.name}?`)) return false;
    this.setState({ isDeleting: true });
    return client.properties.for(property.id).collections.delete(collection.id)
      .then(() => this.setState({
        isDeleting: false,
        redirectTo: `/${property.id}/collections`
      }));
  }

  fetchBusinessFeed() {
    const { property } = this.props;
    const { collection } = this.state;
    return client
      .properties.for(property.id)
      .collections.for(collection.id)
      .businessFeed({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then(businessFeed => this.setState({ businessFeed }));
  }

  render() {
    const { property } = this.props;
    const { isDeleting, redirectTo, isBusy, businessFeed, collection, error } = this.state;
    return (
      <React.Fragment>
        {redirectTo && <Redirect to={redirectTo} />}
        {error && <ErrorMessage error={error} />}
        <WaitFor waitFor={!isDeleting && !isBusy} wrapContents>
          <Presenter
            collection={collection}
            businessFeed={businessFeed}
            propertyId={property.id}
            fetchTags={this.fetchTags.bind(this)}
            saveTags={this.saveTags.bind(this)}
            handleEnable={this.handleEnable.bind(this)}
            handleDisable={this.handleDisable.bind(this)}
            handleDelete={this.handleDelete.bind(this)}
            fetchBusinessFeed={this.fetchBusinessFeed.bind(this)}
          />
        </WaitFor>
      </React.Fragment>
    );
  }
}

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state),
});

export { Presenter };
export default connect(mapState)(CollectionDetailsContainer);
