import { Textarea } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import SuiForm from '../../components/collections/Form';
import Input from '../../components/elements/Input';

import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

const TextArea = (props) => {
  const {
    name, label, validate, optional, parse, format, allowNull, wrapLabel, info, chakra, alwaysShowValidation, ...rest
  } = props;

  const FieldCmp = chakra ? React.Fragment : SuiForm.Field;
  const InputCmp = chakra ? Textarea : SuiForm.TextArea;

  return (
    <Field
      name={name} validate={validate} parse={parse} format={format} allowNull={allowNull}
    >
      {({ input }) => (
        <FieldCmp>
          <FieldLabel label={label} optional={optional} wrapLabel={wrapLabel}>
            <InputCmp {...input} {...rest} autoHeight />
            {info && <p className='text--muted'>{info}</p>}
            <FieldError name={name} chakra={chakra} alwaysShow={alwaysShowValidation} />
          </FieldLabel>
        </FieldCmp>
      )}
    </Field>
  );
};
TextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  parse: PropTypes.func,
  format: PropTypes.func,
  optional: PropTypes.bool,
  allowNull: PropTypes.bool,
  wrapLabel: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  alwaysShowValidation: PropTypes.bool,
};

export default TextArea;
