export const NAME = 'property';

export const types = {
  PROPERTY_SELECT_REQUEST: 'property/SELECT_REQUEST',
  PROPERTY_SELECT_SUCCESS: 'property/SELECT_SUCCESS',
  PROPERTY_SELECT_FAILURE: 'property/SELECT_FAILURE',
  PROPERTY_LIST_REQUEST: 'property/LIST_REQUEST',
  PROPERTY_LIST_SUCCESS: 'property/LIST_SUCCESS',
  PROPERTY_LIST_FAILURE: 'property/LIST_FAILURE',
  PROPERTY_DETAILS_REQUEST: 'property/DETAILS_REQUEST',
  PROPERTY_DETAILS_SUCCESS: 'property/DETAILS_SUCCESS',
  PROPERTY_DETAILS_FAILURE: 'property/DETAILS_FAILURE',
  PROPERTY_INVALIDATE: 'property/INVALIDATE',
  PROPERTY_INVALIDATE_REFRESH: 'property/INVALIDATE_REFRESH',
};
