import {
  Text
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  formatFileDimensions,
  formatFileSize,
  formatFileTypesVerbose
} from '../../imageWell/imageUtils';

const ImageInfo = ({
  type, dimensions, maxSize
}) => {
  const fileTypes = formatFileTypesVerbose(type);
  const fileDimensions = formatFileDimensions(dimensions);
  const maxFileSize = maxSize && `${formatFileSize(maxSize)}`;
  return (
    <Text size="xs" color="gray.500">
      Images must be {fileTypes}, {fileDimensions} in dimension, with a max size less than {maxFileSize}
    </Text>
  );
};

const dimension = PropTypes.shape({ w: PropTypes.number, h: PropTypes.number });
ImageInfo.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  dimensions: PropTypes.oneOfType([dimension, PropTypes.arrayOf(dimension)]),
  maxSize: PropTypes.number,
};

export default ImageInfo;
