import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { Field, FormSpy } from 'web-react-ui/src/reactFinalForm';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import View from 'web-react-ui/src/components/layout/View';
import Wizard from 'web-react-ui/src/reactFinalForm/Wizard';
import { FieldError } from 'web-react-ui/src/reactFinalForm/fields';
import { required } from 'web-react-ui/src/reactFinalForm/validators';

import BusinessSearch from './BusinessSearch';
import BusinessLocationSelector from './BusinessLocationSelector';
import propertyModule from '../../modules/property';
import client from '../../services/client';
import SupportLink from '../support/SupportLink';

const Page1 = ({ propertyId }) => (
  <View>
    <View.Section>
      <h3>Find Business</h3>
      <Field name='business' validate={required}>
        {({ input }) => (
          <BusinessSearch {...input} propertyId={propertyId} />
        )}
      </Field>
      <FieldError name='business' />
      <p className='mt1 pull-right'>
        <SupportLink label={'Can\'t Find The Right Business?'} />
      </p>
    </View.Section>
  </View>
);

Page1.propTypes = {
  propertyId: PropTypes.string
};

const Page2 = () => (
  <FormSpy>
    {({ values: { business } }) => (
      <View>
        <View.Section>
          <h6>Adding {business.name}</h6>
          <h3 className='mt0'>Locations</h3>
        </View.Section>
        <View.Section>
          <Field name='locations'>
            {({ input }) => (
              <React.Fragment>
                <BusinessLocationSelector business={business} {...input} />
                <p className='mt1 mb3 pull-right'>
                  <SupportLink label={'Missing Location?'} />
                </p>
              </React.Fragment>
            )}
          </Field>
          <FieldError name='locations' />
        </View.Section>
      </View>
    )}
  </FormSpy>
);

const enableBusiness = (property, business, plan, locations) => {
  const context = client
    .properties.for(property.id)
    .businesses.for(business.id);

  const process = context.enable({ id: plan });

  if (!locations) return process;

  return process
    .then(() => Promise.all(locations.map(locationId => context.locations.for(locationId).enable())));
};

const DEFAULT_STATE = {
  step: 1,
  business: null,
  error: null,
  isSaving: false
};

class AddBusinessModal extends Component {
  static propTypes = {
    property: PropTypes.object,
    business: PropTypes.object
  };

  state = { ...DEFAULT_STATE };

  closeModal() {
    const propertyId = this.props.property.id;
    this.setState({ redirectTo: `/${propertyId}/businesses` });
  }

  submit(props) {
    const {
      property, business, plan, locations
    } = props;

    return enableBusiness(property, business, plan, locations)
      .then(() => this.closeModal(business));
  }

  render() {
    const { redirectTo } = this.state;
    const { property } = this.props;
    if (redirectTo) return <Redirect to={redirectTo} />;

    return (
      <BigModal open={true} {...this.props}>
        <BigModal.Contents>
          <BigModal.Header className='p1'>
            <BigModal.CloseButton floated='right' onClick={this.closeModal.bind(this)} />
          </BigModal.Header>
          <BigModal.Body>
            <View.Section narrow>
              <Wizard onSubmit={this.submit.bind(this)} initialValues={{ property }}>
                <Wizard.Page>
                  <Page1 propertyId={property.id} />
                </Wizard.Page>
                <Wizard.Page>
                  <Page2 />
                </Wizard.Page>
              </Wizard>
            </View.Section>
          </BigModal.Body>
        </BigModal.Contents>
      </BigModal>
    );
  }
}

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state)
});

export default connect(mapState)(AddBusinessModal);
