/* eslint class-methods-use-this: 0 */
import { ArrowForwardIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import usePagedList from 'shared/src/hooks/usePagedList';
import {
  Text,
  Badge
} from '@chakra-ui/react';

import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';

import Input from 'web-react-ui/src/components/elements/Input';
import {
  List,
  Item,
  Empty,
  Loading
} from 'web-react-ui/src/chakra/List';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import View from 'web-react-ui/src/components/layout/View';

import client from '../../services/client';

const CollectionItem = ({ collection, property }) => {
  return (
    <Item as={Link} to={`/${property.id}/collections/${collection.id}`} align={"center"}>
      <div className="mr1">
        <ProfileImage
          showBackground
          imageId={collection && collection.iconLarge}
          name={collection && collection.name}
        />
      </div>

      <Text
        fontWeight={400}
        fontSize="lg"
        color="black"
      >
        {collection.name}
      </Text>

      {collection.enabled === false
        ? (
          <Badge ml={10}>DISABLED</Badge>
        )
        : null
      }

      <ArrowForwardIcon w={5} color="gray.500" ml="auto" />
    </Item>
  );
};

CollectionItem.propTypes = {
  collection: PropTypes.object,
  property: PropTypes.object
};

const fetchCollections = ({ query, property }) => {
  return client.properties.for(property.id).collections.search({ query });
};

// TODO: Add "Enabled"/"Disabled" buttons back in when we can filter collections/search by enabled
const CollectionsList = ({ property }) => {
  const [query, debouncedQueryHandler] = useQueryDebounce(property);
  const collectionList = usePagedList(fetchCollections, { query, property });

  return (
    <View>
      <View.Section>
        <div className="flex">
          <Input
            type="search"
            fluid
            defaultValue={query}
            onChange={e => debouncedQueryHandler(e.target.value)}
            icon="search"
            placeholder="Find collection..."
            className="fg1"
          />
        </div>
      </View.Section>
      <View.Section>
        <List>
          <Empty isEmpty={collectionList.empty} />
          <Loading isLoading={collectionList.loading} />
          {!collectionList.loading && collectionList.items.map(collection => (
            <CollectionItem
              property={property}
              collection={collection}
              key={collection.id}
            />
          ))}
          <ErrorMessage chakra error={collectionList.error} />
        </List>
      </View.Section>
    </View>
  );
};

CollectionsList.propTypes = {
  property: PropTypes.object
};

export default CollectionsList;
