import React from 'react';
import PropTypes from 'prop-types';

import { injectClient } from 'shared/src/components/contexts/ClientContext';

import Request from '../../../data/Request';
import WaitFor from '../../../data/WaitFor';
import ErrorMessage from '../../entities/ErrorMessage';

import './ImageLibrary.css';
import {
  LibraryImage,
  LibraryImageButton
} from './LibraryImage';

const ImageLibrary = (props) => {
  const {
    client, property, business, location, query, onSelect, fileType, onUploadClick
  } = props;

  const loadImages = ({ query: q }) => {
    let endpoint = client.properties.for(property);

    if (business) endpoint = endpoint.businesses.for(business);
    if (location) endpoint = endpoint.locations.for(location);

    return endpoint.images.search(q, fileType)
      .then(pagedList => pagedList.items);
  };

  return (
    <div className='c-image-library'>
      <Request
        request={loadImages}
        params={{
          query, property, business, location
        }}
      >
        {({ data, loading, error }) => (
          <WaitFor waitFor={!loading}>
            {error && <ErrorMessage error={error}/>}
            <ul className="c-image-library__grid">
              {
                onUploadClick &&
                <div className="c-image-library__grid-button"><LibraryImageButton onClick={onUploadClick}/></div>
              }
              {
                !error && data && data.map(image =>
                  <li key={image.imageRefId} className="c-image-library__grid-item">
                    <LibraryImage
                      imageId={image.imageRefId}
                      imageUrl={image.imageRef && image.imageRef.url}
                      onSelect={onSelect}
                    />
                  </li>)
              }
            </ul>
          </WaitFor>
        )}
      </Request>
    </div>
  );
};

ImageLibrary.propTypes = {
  client: PropTypes.object,
  property: PropTypes.string,
  business: PropTypes.string,
  location: PropTypes.string,
  query: PropTypes.string,
  onSelect: PropTypes.func,
  fileType: PropTypes.string,
  onUploadClick: PropTypes.func
};

export default injectClient(ImageLibrary);
