/* eslint camelcase: 0, class-methods-use-this: 0 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'web-react-ui/src/components/elements/Icon';
import View from 'web-react-ui/src/components/layout/View';

import RelativeLink from '../routing/RelativeLink';

class CollapsedNav extends Component {
  static propTypes = {
    label: PropTypes.string,
    fluid: PropTypes.bool,
    narrow: PropTypes.bool
  };

  render() {
    const {
      label,
      fluid,
      narrow
    } = this.props;
    return (
      <View.Section fluid className='bg--gray brdr--b'>
        <View.Section fluid={fluid} narrow={narrow}>
          <RelativeLink to='.' className='flex'>
            <Icon className="long arrow alternate left"/>
            <h6 className='m0'>{label || 'Back'}</h6>
          </RelativeLink>
        </View.Section>
      </View.Section>
    );
  }
}

export default withRouter(CollapsedNav);
