import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';

import ClientContext from 'shared/src/components/contexts/ClientContext';

import WaitFor from 'web-react-ui/src/data/WaitFor';
import UserList from 'web-react-ui/src/chakra/UserList';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import User, { Role } from '../../types/User.interface';
import Location from '../../types/Location.interface';

interface PagedList {
  items: User[] | Role[];
  page: {
    next: any,
    prev: any,
  }
}

const UserListView = ({
  loadUsers,
  removeUser,
  isDeleting,
  query,
  refetchId,
  selectedRoles,
  currentUser,
  operationError,
  roles,
  baseUrl,
  context,
  locations,
}: {
  loadUsers: () => Promise<PagedList>,
  removeUser: (user: User) => void,
  isDeleting: boolean,
  query?: string,
  refetchId: number,
  selectedRoles: string[],
  currentUser: User,
  operationError: any,
  roles?: Role[],
  baseUrl: string,
  context?: 'platform' | 'property' | 'business',
  locations?: Location[],
}): JSX.Element => {
  // @ts-ignore
  const client = useContext(ClientContext);
  const [lastResult, setLastResult] = useState<any>();
  const [items, setItems] = useState<any>([]);
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const handleLoadUsers = async () => {
    try {
      setIsLoading(true);
      const res = await loadUsers();
      setLastResult(res);
      setItems(res.items);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadNext = async () => {
    try {
      setIsLoadingNext(true);
      // @ts-ignore
      const res = await client.pagination.next(lastResult);
      setLastResult(res);
      setItems([...items, ...res.items]);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoadingNext(false);
    }
  };

  useEffect(() => {
    handleLoadUsers();
  }, [query, refetchId, selectedRoles]);

  return (
    <WaitFor waitFor={items && !isLoading && !isDeleting} wrapContents>
      <ErrorMessage error={operationError} />
      <UserList
        key={refetchId}
        onDelete={removeUser}
        users={items}
        currentUser={currentUser}
        context={context}
        baseUrl={baseUrl}
        roles={roles}
        locations={locations}
        isLoading={isLoading || isLoadingNext}
      />
      {lastResult?.page?.next && (
        <Button my={5} isLoading={isLoadingNext} colorScheme='blue' onClick={handleLoadNext}>Load More</Button>
      )}
      <ErrorMessage error={error} />
    </WaitFor>
  );
};

export default UserListView;
