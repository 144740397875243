let subscribers = [];
let toasts = [];
let idCounter = 1;

const subscribe = (callback) => {
  subscribers = subscribers.concat(callback);
  callback(toasts);
  return true;
};

const unsubscribe = (callback) => {
  subscribers = subscribers.filter(f => f !== callback);
};

const notify = () => {
  subscribers.forEach(s => s(toasts));
};

const add = (toast) => {
  toasts = toasts.concat(Object.assign({ id: idCounter += 1 }, toast));
  notify();
};

const remove = (toast) => {
  toasts = toasts.filter(t => t.id !== toast.id);
  notify();
};

export default {
  subscribe, unsubscribe, add, remove
};
