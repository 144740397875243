import PropTypes from 'prop-types';
import React from 'react';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import { OPERATIONS as BUSINESS_OPERATIONS } from 'web-react-ui/src/components/filters/BusinessFilterSet';
import { OPERATIONS as OFFER_OPERATIONS } from 'web-react-ui/src/components/filters/OfferFilterSet';

const DAY_IN_MINUTES = 60 * 24;

const funTagGrid = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr)'
};

const FILTER_SET_TITLES = {
  businessPolicyPlanId: 'Feature Plans',
  businessCategoryIds: 'Category',
  businessTags: 'Business Tags',
  businessOfferTags: 'Offer Tag',
  businessOfferFlags: 'Offer Flag',
  businessLocationTags: 'Location Tag',
  businessOfferLocationTags: 'Location Tag',
  businessOfferLiveSince: 'Live Since',
  businessOfferExpiresIn: 'Expires In',
  businessId: 'Business Id',
  businessOfferIds: 'Business Offer Id'
};

const normalizeLabel = (arg) => {
  if (typeof arg !== 'object') return <Label key={arg}>{arg}</Label>;
  const key = arg.id || arg.name || JSON.stringify(arg);
  const label = arg.name || JSON.stringify(arg, null, 2);
  return (
    <Label key={key}>{label}</Label>
  );
};

const renderArgs = (args, target) => {
  switch (target) {
    case 'businessOfferLiveSince':
    case 'businessOfferExpiresIn':
      return <Label key={args}>{args / DAY_IN_MINUTES} days</Label>;
    default:
      break;
  }

  if (Array.isArray(args)) return args.map(normalizeLabel);

  if (typeof args === 'object') {
    return Object.entries(args).map(normalizeLabel);
  }
  return normalizeLabel(args);
};

const renderFilterHeadline = (data, title) => {
  if (!data || !title) return null;
  return (
    <div className='mt3 mb3'>
      <h6>{title}</h6>
    </div>
  );
};

const operations = [
  ...BUSINESS_OPERATIONS.inclusion, // OFFER_OPERATIONS.inclusion is the same
  ...BUSINESS_OPERATIONS.singularInclusion,
  ...OFFER_OPERATIONS.days
];

const CollectionListFilters = ({ filterSetsTitle, filterSets, isLoading }) => {
  const renderListFilters = (data) => {
    if (!data) return null;

    // If it's a filterSet
    if (data.filters) {
      return (
        <div className='ml1 pl4 brdr--l'> {/* Indent the child list */}
          {data.filters.map((f, index) => {
            return (
              <React.Fragment key={index}>
                <CollectionListFilters filterSets={f} /> {/* Render the next level */}
                {(index + 1 < data.filters.length) && (
                  <div className='ui grid'>
                    <div className='row'>
                      <Label
                        className='ui blue basic label large'
                        style={{ textTransform: 'uppercase' }}
                      >
                        {data.operator}
                      </Label>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    }

    if (data.target) {
      const operation = operations.find(x => x.value === data.operation);
      return (
        <div className='ui grid'>

          <div className='row'>
            <div className='three wide column'>
              <p>{FILTER_SET_TITLES[`${data.target}`]}</p>
            </div>
            <div className='three wide column'>
              <p>{operation && operation.text}</p>
            </div>
            <div className='ten wide column'>
              <Label.Group style={funTagGrid}>
                {renderArgs(data.args, data.target)}
              </Label.Group>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  if (isLoading) {
    return (
      <div className='mt2 mb1'>
        <div className='ui active centered inline loader'></div>
      </div>
    );
  }
  return (
    <React.Fragment>
      {renderFilterHeadline(filterSets, filterSetsTitle)}
      {renderListFilters(filterSets)}
    </React.Fragment>
  );
};

CollectionListFilters.propTypes = {
  filterSetsTitle: PropTypes.string.isRequired,
  filterSets: PropTypes.object,
  isLoading: PropTypes.bool
};

export default CollectionListFilters;
