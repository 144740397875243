/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import { Box, BoxProps, Stack, Text } from '@chakra-ui/react';

interface Props extends BoxProps {
  label?: React.ReactNode;
  children?: React.ReactChild[] | React.ReactChild;
}

export const NavGroup = ({ label, children, ...boxProps }: Props): JSX.Element => (
  <Box {...boxProps}>
    <Text as={Box}
      fontSize="md"
      fontWeight="semibold"
      textTransform="uppercase"
      letterSpacing="widest"
      color="gray.500"
      mb="3"
    >
      {label}
    </Text>
    <Stack spacing="1">{children}</Stack>
  </Box>
);
