import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Heading,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import RedemptionSelector from './RedemptionSections/RedemptionSelector';
import PunchCardRedemptionActionLabel from './RedemptionSections/PunchCardRedemptionActionLabel';
import RedemptionActionOptionalFields from './RedemptionSections/RedemptionActionOptionalFields';
import OfferValidationSelection from './OfferValidationSelection';
import RedemptionOtherDetails from './RedemptionSections/RedemptionOtherDetails';
import RedemptionPunches from './RedemptionSections/RedemptionPunches';
import RedemptionPunchLimits from './RedemptionSections/RedemptionPunchLimits';
import RedemptionPunchReward from './RedemptionSections/RedemptionPunchReward';

const PunchCardRedemption = ({ name, dashboard }) => {
  const { strings } = useI18Next();
  return (
    <Box w="100%">
      <Heading color="gray.700" mb={10} pb={3} borderBottomWidth="1px" borderColor="gray.300">Redemption</Heading>
      <VStack spacing={10} m={0} minW="100%" w="100%" divider={<StackDivider />}>
        <RedemptionPunches name={name} dashboard={dashboard} mb={5} w="100%" />
        <Box w="100%">
          <Heading size="md" mb={5}>
            {strings('dashboard.component.editOfferForm.field.label.earningPunches')}
          </Heading>
          <RedemptionSelector name={name} mb={5} />
          <PunchCardRedemptionActionLabel name={name} mb={5} />
          <RedemptionActionOptionalFields name={name} mb={10} />
          <OfferValidationSelection validationType="punches" name="flow.vars" chakra={true} w="100%" />
        </Box>
        <RedemptionPunchReward name={name} />
        <RedemptionPunchLimits name={name} />
        <RedemptionOtherDetails name={name} />
      </VStack>
    </Box>
  );
};

PunchCardRedemption.propTypes = {
  name: PropTypes.string,
  dashboard: PropTypes.bool,
};

export default PunchCardRedemption;
