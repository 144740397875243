import React, {
  useCallback,
  useContext
} from 'react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import LocalizationWrapper from './LocalizationWrapper';
import Form from '../../../components/collections/Form';

const LocaleSelector = ({ ...props }) => {
  const { locale, setLocale, locales } = useContext(LocalizationWrapper.context);
  const { strings, lookupExists } = useI18Next();

  const onChange = useCallback(
    (e) => {
      setLocale(e.target.value);
    },
    [setLocale]
  );

  if (!(locales && locales.length > 1)) return null;

  return (
    <Form.Field {...props}>
      <select onChange={onChange} value={locale}>
        {locales.map((loc) => {
          // If the lookup cannot be found use the locale instead of the lookup string
          const localeLabel = lookupExists(`shared.languages.${loc}`) ? strings(`shared.languages.${loc}`) : loc;
          return <option
            key={loc}
            value={loc}
          >
            {localeLabel}&nbsp;
            {locales[0] === loc && (
              ` - ${strings('ui.component.localeSelector.default')}`
            )}
          </option>;
        })}
      </select>
    </Form.Field>
  );
};

export default LocaleSelector;
