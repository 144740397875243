/* eslint camelcase: 0, class-methods-use-this: 0, function-paren-newline: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import businessModule from '../../modules/businesses';
import propertyModule from '../../modules/property';
import BusinessOfferListView from '../offers/BusinessOfferListView';
import RelativeLink from '../routing/RelativeLink';


class BusinessOffers extends Component {
  static propTypes = {
    property: PropTypes.object,
    business: PropTypes.object
  };

  state = {};

  render() {
    const { locationId } = this.state;
    return (
      <BusinessOfferListView
        propertyId={this.props.property.id}
        locationId={locationId}
        businessId={this.props.business.id}
        relativeLink={RelativeLink}
      />
    );
  }
}

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state),
  business: businessModule.selectors.business.getData(state)
});

export default connect(mapState)(BusinessOffers);
