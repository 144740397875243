/* eslint no-restricted-globals: 0 */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Message from 'web-react-ui/src/components/collections/Message';
import Divider from 'web-react-ui/src/components/elements/Divider';

import ImageField from 'web-react-ui/src/components/image/ImageField';

import CollectionScheduleFields from 'web-react-ui/src/components/offers/CollectionScheduleFields';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import {
  Field,
  FormSpy
} from 'web-react-ui/src/reactFinalForm';
import {
  NumberField,
  TextField
} from 'web-react-ui/src/reactFinalForm/fields';
import BooleanField from 'web-react-ui/src/reactFinalForm/fields/BooleanField';
import FieldLabel from 'web-react-ui/src/reactFinalForm/fields/FieldLabel';
import ListSelectorField from 'web-react-ui/src/reactFinalForm/fields/ListSelectorField';
import LocalizableTextArea from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextArea';
import LocalizableTextField from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextField';
import SelectField from 'web-react-ui/src/reactFinalForm/fields/SelectField';
import TextArea from 'web-react-ui/src/reactFinalForm/fields/TextArea';
import FormError from 'web-react-ui/src/reactFinalForm/FormError';
import BusinessFilterSet from 'web-react-ui/src/components/filters/BusinessFilterSet';
import OfferFilterSet from 'web-react-ui/src/components/filters/OfferFilterSet';

import {
  composeForLocale,
  forAllLocales,
  forAtLeastOneLocale,
  max,
  required,
  slug
} from 'web-react-ui/src/reactFinalForm/validators';
import client from '../../services/client';

import CollectionRegionFilters from './CollectionRegionFilters';
import {
  defaults,
  keys
} from './defaultCollectionScores';

const formatJson = (value) => {
  try {
    return JSON.stringify(value, 0, 2);
  } catch (err) {
    return undefined;
  }
};

const parseJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return undefined;
  }
};

const validateScore = value => ((value && value.every(v => v && v.length === 2))
  ? null
  : 'Requires valid JSON array of pairs ([[int, int], ...])');

const ScoreField = props => <TextArea
  format={formatJson}
  formatOnBlur
  parse={parseJson}
  validate={validateScore}
  placeholder="[[0, 1], [1, 0]]"
  {...props}
/>;

const ContentOrderOptions = [
  { text: 'Distance', value: keys.distance },
  { text: 'Age: New - Old', value: keys.age },
  { text: 'Random', value: keys.random },
  { text: 'Custom', value: keys.custom }
];

const PlanSelectorField = ({
  propertyId, name, toggleName, disabled
}) => {
  const getPlans = useCallback(
    () => client.properties.for(propertyId).plans.search({ enabled: true, discoverable: null }),
    [propertyId]
  );

  return (
    <div>
      <FieldLabel wrapLabel label="Plans included in this Collection:" />
      <div className="flex mt1">
        <div className="fg1">
          <BooleanField radio name={toggleName} label="all Plans" checked={v => !v} disabled={disabled} />
        </div>
        <div className="fg1">
          <BooleanField radio
                        name={toggleName}
                        label="these specific Plans..."
                        checked={v => !!v}
                        disabled={disabled} />
        </div>
      </div>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          if (!_.get(values, toggleName)) return null;
          return (
            <PagedList fetchList={getPlans}>
              {({ items, isEmpty, loaded }) => (
                <WaitFor waitFor={loaded}>
                  {isEmpty
                    ? null
                    : (
                      <ListSelectorField
                        labelKey="name"
                        idKey="id"
                        options={items}
                        name={name}
                        disabled={disabled}
                      />
                    )}
                </WaitFor>
              )}
            </PagedList>
          );
        }}
      </FormSpy>
    </div>
  );
};

PlanSelectorField.propTypes = {
  propertyId: PropTypes.string,
  name: PropTypes.string,
  toggleName: PropTypes.string,
  disabled: PropTypes.bool
};

function EditCollectionForm(props) {
  return (
    <React.Fragment>
      <FormError />
      <LocalizableTextField
        name="collection.nameLocalized"
        label="Name"
        validateLocale={composeForLocale(forAllLocales(max(32)), forAtLeastOneLocale(required))}
      />
      <LocalizableTextField
        name="collection.shortNameLocalized"
        label="ShortName"
        validateLocale={composeForLocale(forAllLocales(max(18)), forAtLeastOneLocale(required))}
      />

      <TextField name="collection.slug" label="Slug" validate={slug} />

      <LocalizableTextArea name="collection.descLocalized" label="Description" validateLocale={forAllLocales(max(254))} />

      <div className="mt4" style={{ maxWidth: '50%' }}>
        <NumberField name="collection.priority" label="Priority" validate={required} />
      </div>

      <h2>Visibility</h2>
      <Divider fitted />
      <h6>Schedules</h6>
      <p>When is this collection discoverable?</p>
      <CollectionScheduleFields name="schedules[0]" />

      <h6 className="mb1">Regions</h6>

      <CollectionRegionFilters name="regionFilters" />

      <h2>Images</h2>
      <ImageField
        name="collection.heroImage"
        label="Hero Image"
        fileType="collection-hero-standard"
      />
      <div className="flex fww">
        <div className="fg1" style={{ maxWidth: '50%' }}>
          <ImageField
            name="collection.iconLarge"
            backgroundColor="#F5F6F9"
            label="Icon (Large)"
            fileType="collection-icon-large"
            height={230}
          />
        </div>
        <div className="fg1">
          <ImageField
            name="collection.iconSmall"
            backgroundColor="#F5F6F9"
            label="Icon (Small)"
            fileType="collection-icon-small"
            height={230}
          />
        </div>
      </div>
      <h2>Content</h2>
      <Message info>
        <p>
          The content section filters the businesses and offers that are included in the collection.
          By default, no content is included in the collection.
          Order of operations include:
        </p>
        <ul>
          <li>
            <strong>
              The Business section filters available businesses using Business specific conditions
            </strong>
          </li>
          <li>
            <strong>
              Offers from the filtered Businesses are then further filtered with Offer and Location specific conditions
            </strong>
          </li>
        </ul>
      </Message>
      <div className="layout__stack--v2">
        <div>
          <h2 className="mb0 mt2">Businesses</h2>
          <Divider fitted className="pb2" />
          <BusinessFilterSet name="businessFilter" propertyId={props.propertyId} />
        </div>
        <div>
          <h2 className="mb0 mt2">Offers</h2>
          <Divider fitted className="pb2" />
          <OfferFilterSet name="businessOfferFilter" />
        </div>
      </div>

      <h2>Content Order</h2>

      <SelectField options={ContentOrderOptions} name="collectionScores" wrapLabel />

      <FormSpy subscription={{}}>
        {({ form: { change } }) => (
          <Field name="collectionScores">
            {({ input: actionInput }) => {
              if (actionInput.value === keys.distance) {
                change('collection.scores', defaults.distance);
              }
              if (actionInput.value === keys.age) {
                change('collection.scores', defaults.age);
              }
              if (actionInput.value === keys.random) {
                change('collection.scores', defaults.random);
              }
              if (actionInput.value === keys.custom) {
                return (
                  <div>
                    <ScoreField name="collection.scores.distance" label="Distance" />
                    <ScoreField name="collection.scores.age" label="Age" />
                    <ScoreField name="collection.scores.businessAge" label="Business Age" />
                    <ScoreField name="collection.scores.random" label="Random" />
                    <ScoreField name="collection.scores.featured" label="Featured" />
                    <ScoreField name="collection.scores.limited" label="Limited" />
                    <ScoreField name="collection.scores.exclusive" label="Exclusive" />
                  </div>
                );
              }
              return null;
            }}
          </Field>
        )}
      </FormSpy>
    </React.Fragment>
  );
}

EditCollectionForm.propTypes = {
  propertyId: PropTypes.string
};

export default EditCollectionForm;
