import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import SuiForm from '../components/collections/Form';
import WaitFor from '../data/WaitFor';
import FormDebugger from './FormDebugger';
import FormError from './FormError';
import wrapSubmit from './wrapSubmit';
import wrapValidate from './wrapValidate';

// Fix loading overlay in BigModal
const fixBigModal = { minHeight: '100%' };
const fixLoader = { minHeight: '100%', position: 'initial' };

function SimpleForm(props) {
  const {
    onSubmit,
    initialValues,
    debug,
    children,
    loading,
    showSubmitError,
    validate,
  } = props;

  const wrappedSubmit = React.useCallback(wrapSubmit(onSubmit), [onSubmit]);
  const wrappedValidate = React.useMemo(() => validate && wrapValidate(validate), [validate]);

  return (
    <Form onSubmit={wrappedSubmit} initialValues={initialValues} validate={wrappedValidate}>
      {({ handleSubmit, form }) => (
        <SuiForm onSubmit={handleSubmit} style={fixBigModal}>
          <WaitFor waitFor={!(loading || form.getState().submitting)} wrapContents style={fixLoader}>
            {children}
            {showSubmitError && <FormError />}
            {debug && <FormDebugger />}
          </WaitFor>
        </SuiForm>
      )}
    </Form>
  );
}

SimpleForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  debug: PropTypes.bool,
  children: PropTypes.node,
  loading: PropTypes.bool,
  showSubmitError: PropTypes.bool,
  validate: PropTypes.func,
  chakra: PropTypes.bool
};

export default SimpleForm;
