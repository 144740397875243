import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Message from '../collections/Message';
import ErrorMessage from '../entities/ErrorMessage';

const mapErrorMessage = (error, strings) => {
  switch (error && error.type) {
    case 'discoverable_per_location':
    case 'visible_per_location':
    case 'usable_per_location':
    case 'discoverable_per_business':
    case 'visible_per_business':
    case 'usable_per_business':
      return strings('ui.component.offerLimitErrorMessage.exceededMaxOffers');
    default:
      return strings('ui.component.offerLimitErrorMessage.exceededPlanLimit');
  }
};

const OfferLimitErrorMessage = ({ error, dashboard, isFreePlan }) => {
  const { strings } = useI18Next();

  if (!error) return null;
  if (error.planLimitViolations) {
    const violation = error.planLimitViolations && error.planLimitViolations[0];
    const planSettingsPath = dashboard && error.context && `/business/${error.context.businessId}/plans`;
    return (
      <Message negative>
        <span>
          {mapErrorMessage(violation, strings)}&nbsp;
          {strings('ui.component.offerLimitErrorMessage.rescheduleAnotherTime')}
        </span>
        {planSettingsPath && isFreePlan ? (
          <a
            href={planSettingsPath}
            target="__blank"
          >
            {strings('ui.component.offerLimitErrorMessage.seeUpgradeOptions')}
          </a>
        ) : (
          <span>{null}</span>
        )}
      </Message>
    );
  }

  return <ErrorMessage error={error} />;
};

OfferLimitErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  dashboard: PropTypes.bool,
  isFreePlan: PropTypes.bool
};

export default OfferLimitErrorMessage;
