import { AddIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';

const AddSectionButton = ({ name, display = 'block', icon, ...rest }) => {
  const { input } = useField(name);
  if (input.value) return null;
  const leftIcon = icon === undefined ? <AddIcon /> : icon;
  return <Button
    variant='ghost'
    display={display}
    leftIcon={leftIcon}
    onClick={() => input.onChange(true)}
    {...rest}
  />;
};

AddSectionButton.propTypes = {
  name: PropTypes.string,
  display: PropTypes.string,
  icon: PropTypes.node,
};

export default AddSectionButton;
