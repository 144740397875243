// eslint-disable consistent-return

/**
 * Use
 *
 *   `const thingRequest = useRequest(doThing, args, options);`
 * where
 *   `doThing` is an async function
 *   `args` is an array or object to be passed to `doThing`
 *   `options.cache` {string} to use a cache
 *      - all useRequests with the same string share the same cache
 *      - caches are keyed by JSON.stringify(args)
 *   `options.cachePredicate` {(args: any) => boolean} function to determine whether value should be cached
 *      - return `true` to cache
 *      - return `false` to bypass cache
 *
 * Values of each element in `args` (whether an array or object) are compared by reference
 * to determine when to re-request.
 *
 * When an arg value is updated, any pending requests are cancelled and a new one is made.
 *
 * Result is an object with keys
 *   `loading`: boolean indicating if request is in flight
 *   `error`: the error rejected from `doThing` (or undefined if loading or no error)
 *   `result`: value resolved from `doThing` (or undefined if loading or error)
 *   `settled`: boolean indicating if `doThing` has been run at least once and a request is NOT in flight
 *   `run`: a wrapped instance of `doThing` for explicitly triggering the function (mostly used with `undefined` `args`)
 */

import { isEqual } from 'lodash';
import {
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import requestCacheProvider from '../helpers/requestCacheProvider';
import CancelablePromise from '../modules/promise/CancelablePromise';
import { UseRequestResult } from '../types/UseRequestResult.interface';

const initialState = {
  loading: false,
  error: null,
  settled: false,
  result: null,
  counter: 0,
};

type State = {
  loading: boolean;
  result: any | undefined;
  error: Error | undefined | null;
  settled: boolean;
  counter: number;
}

type CancelablePromiseType = {
  isSettled: boolean;
  promise: Promise<any>;
  cancel: Function;

}

const stateReducer = (state: State, action: { type: string, payload?: any, error?: Error | null | undefined }) => {
  switch (action.type) {
    case 'request':
      return {
        ...state,
        loading: true,
        error: null,
        settled: false,
      };
    case 'success':
      return {
        loading: false,
        error: null,
        settled: true,
        result: action.payload,
        counter: state.counter + 1,
      };
    case 'error':
      return {
        loading: false,
        error: action.error,
        settled: true,
        result: null,
      };
    case 'cancel':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

interface UseRequestOptions {
  cache?: string;
  cachePredicate?: (args: any) => boolean;
  discardError?: boolean;
}

const getNewPromise = (fn, args, cacheKey, cachePredicate) => {
  if (cacheKey && cachePredicate?.(args)) {
    const cachedResult = requestCacheProvider.getCache(cacheKey).getItem(args);
    if (cachedResult) {
      return cachedResult.promise;
    }
  }
  const promise = fn(args);
  if (cacheKey) {
    requestCacheProvider.getCache(cacheKey).setItem(args, promise);
  }
  return promise;
};

const useRequest = <T>(fn: Function, args: any, {
  cache,
  cachePredicate,
  discardError,
}: UseRequestOptions = {}): UseRequestResult<T> => {
  const [state, dispatch] = useReducer(
    stateReducer,
    initialState,
  );

  const oldArgs = useRef();
  const pendingPromise = useRef<CancelablePromiseType>();

  const requestFactory = useMemo(
    () => {
      return (asyncFunction: Function) => {
        return async (innerArgs: any) => {
          dispatch({
            type: 'request',
            payload: undefined,
            error: undefined,
          });
          if (pendingPromise.current && !pendingPromise.current.isSettled) pendingPromise.current.cancel();
          try {
            pendingPromise.current = new (CancelablePromise as any)(
              getNewPromise(asyncFunction, innerArgs, cache, cachePredicate),
            );
            // @ts-ignore
            const result = await pendingPromise.current.promise;
            if (result == null) return dispatch({ type: 'cancel' });
            dispatch({ type: 'success', payload: result });
            return result;
          } catch (err) {
            // NOTE: We explicitly don't dispatch anything if the promise was cancelled because we don't care
            // @ts-ignore
            if (err.message === CancelablePromise.CANCELED) return null;
            dispatch({ type: 'error', error: err as Error });
            if (!discardError) return Promise.reject(err);
          }
        };
      };
    },
    [],
  );

  const doRequest = useMemo(
    () => {
      return requestFactory(fn);
    },
    [requestFactory, fn],
  );

  // Actual re-fetch functionality
  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      // If nothing is defined for `args`, do not automatically request
      if (!args) return undefined;

      if (!isEqual(oldArgs.current, args)) {
        doRequest(args)
          .catch(() => {
            // Avoid uncaught rejection bubbling up.
            // Error is tracked in the state.
          });
      }
      oldArgs.current = args;
    },
    [args],
  );

  return {
    result: state.result,
    loading: state.loading,
    error: state.error,
    settled: state.settled,
    counter: state.counter,
    run: doRequest,
  };
};

export default useRequest;
