/* globals window */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Portal from '../../addons/Portal';
import Button from '../elements/Button';
import './OfferPrintPreview.css';
import PinTable from './PinTable';

const OfferPrintPreview = ({ offer, property }) => {
  const { strings } = useI18Next();
  const print = useCallback(() => {
    window.print();
  });
  return (
    <React.Fragment>
      <Button className="text" basic onClick={print}>{strings('ui.component.offerPrintPreview.print')}</Button>
      <Portal open>
        <div className='c-print-preview p1'>
          <h1>{offer.headline}</h1>
          <h2>{offer.subhead}</h2>
          <p>{offer.details}</p>
          <h3>{strings('ui.label.termsAndConditions')}</h3>
          <p>{offer.legal}</p>
          {offer.pins && (
            <React.Fragment>
              <h3>{strings('ui.component.offerPrintPreview.pinVerify')}</h3>
              <PinTable pins={offer.pins}/>
              {property && (
                <p>
                  {strings('ui.component.offerPrintPreview.pinUpdate')}
                  https://{property.subdomain}.lmg-offers.com
                </p>
              )}
            </React.Fragment>
          )}
        </div>
      </Portal>
    </React.Fragment>
  );
};

OfferPrintPreview.propTypes = {
  offer: PropTypes.object,
  property: PropTypes.object
};

export default OfferPrintPreview;
