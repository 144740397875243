import React, { useContext, useState } from 'react';
import { Collapse, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import usePromise from 'shared/src/hooks/usePromise';
import usePermissions from 'shared/src/hooks/usePermissions';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import { getNestedRoles, getTotalRoles } from 'web-react-ui/src/utils/nestedRoles';
import RoleBadge from 'web-react-ui/src/chakra/UserList/RoleBadge';
import BusinessRoles from './BusinessRoles';
import { Role } from '../../types/User.interface';

interface Props {
  propertyId: string,
  propertyName: string,
  userId: string,
  roles?: Role[],
  isSingleProperty?: boolean;
}

const PropertyRoles = ({ propertyId, propertyName, userId, roles, isSingleProperty }: Props): JSX.Element => {
  const client = useContext(ClientContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  // @ts-ignore
  const hasPermissions = usePermissions({ propertyId }, ['property.role.search']);

  const getUserAccesses = (propertyId: string) => hasPermissions
    ? client.properties.for(propertyId).userAccesses(userId).then(client.pagination.loadRest)
    : null;
  const getPropertyRoles = (propertyId: string) => hasPermissions
    ? client.properties.for(propertyId).users.for(userId).getRoles({ context: ['P'] })
    : null;
  const [, , result]: any = usePromise(
    () => getUserAccesses(propertyId),
    [propertyId, userId, hasPermissions],
    null,
    true
  );
  const [, , propertyRolesResult]: any = usePromise(
    () => getPropertyRoles(propertyId),
    [propertyId, userId, hasPermissions],
    null,
    true
  );

  if (!hasPermissions || !result) return <></>;

  const accessRoles = getNestedRoles(result?.items, propertyId);
  const totalRoles = getTotalRoles(accessRoles);
  const hasMoreRoles = !isSingleProperty && totalRoles > accessRoles.roleIds.length;

  return (
    <>
      <Flex
        borderBottom='1px'
        borderColor='gray.200'
        py={5}
        key={propertyId}
        align='center'
        justify='space-between'
        onClick={() => setIsCollapsed(!isCollapsed)}
        cursor={hasMoreRoles ? 'pointer' : 'default'}
      >
        <Flex flexDir={['column', 'row']}>
          {!isSingleProperty && (
            <Flex
              justify={['flex-start', 'center']}
              align={['center', 'flex-start']}
              flexDir={['row', 'column']}
            >
              <Heading fontSize='lg' minW='xs'>{propertyName}</Heading>
              {hasMoreRoles && (
                <Text ml={[2, 0]}>{totalRoles} role{hasMoreRoles && 's'}</Text>
              )}
            </Flex>
          )}
          <Flex gap={2} mt={[2, 0]} wrap='wrap' align='center'>
            {propertyRolesResult?.items?.map((item: Record<string, string>) => (
              <RoleBadge key={item.id} label={item.name} />
            ))}
          </Flex>
        </Flex>
        {hasMoreRoles && (
          <Icon
            as={isCollapsed ? ChevronDownIcon : ChevronUpIcon}
            boxSize={8}
          />
        )}
      </Flex>
      {!isSingleProperty && (
        <Collapse in={!isCollapsed}>
          {Object.entries(accessRoles.businesses).map(([businessId, business]: [businessId: string, business: any]) => (
            <BusinessRoles
              key={businessId}
              businessId={businessId}
              business={business}
              propertyId={propertyId}
              roleIds={business.roleIds}
              roles={roles}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

export default PropertyRoles;
