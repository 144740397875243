import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

const TrackPageView = ({ gtag, gaKey }) => (
  <Route>
    {({ location }) => {
      if (gtag && gaKey) gtag('config', gaKey, { page_path: `${location.pathname}${location.search}` });
      return null;
    }}
  </Route>
);

TrackPageView.propTypes = {
  gtag: PropTypes.func,
  gaKey: PropTypes.string
};

export default TrackPageView;
