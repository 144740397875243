/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';

import authModule from 'shared/src/modules/auth';
import useRequest from 'shared/src/hooks/useRequest';

import UserProfileView from 'web-react-ui/src/chakra/users/UserProfileView';

import propertyModule from '../../../modules/property';
import client from '../../../services/client';

const UserProfile = ({ baseUrl, propertyId }: { baseUrl: string, propertyId: string }): JSX.Element => {
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const property = useSelector((state: RootStateOrAny) => propertyModule.selectors.property.getData(state));
  const history = useHistory();
  const { userId } = useParams<Record<string, string>>();
  const { pathname, state }: { pathname: string, state: { query: string } } = useLocation();

  const { result: roles } = useRequest(() => client.roles.list(), {});
  const { result: user } = useRequest(
    () => client.users.for(userId).details(),
    { userId },
  );

  const deleteRequest = useRequest(() => client
    .properties.for(propertyId)
    .users.for(user.id)
    .remove({ recursive: true }));

  const removeUser = async () => {
    if (!user) return null;
    const shouldDelete = confirm(
      /* eslint-disable-next-line max-len */
      `Are you sure you want to delete ${user.email}? Deleting this user will remove all property, business, and location roles.`,
    );
    if (!shouldDelete) return null;

    await deleteRequest.run();
    if (!deleteRequest.error) {
      history.push(`${baseUrl}${state?.query || ''}`);
    }

    return true;
  };

  const onEdit = () => history.push({
    pathname: `${pathname}/edit`,
    state,
  });

  return (
    <UserProfileView
      user={user}
      isCurrentUser={currentUser && user?.id === currentUser.id}
      onEdit={onEdit}
      backUrl={baseUrl}
      removeUser={removeUser}
      isDeleting={deleteRequest.loading}
      operationError={deleteRequest.error}
      roles={roles?.items}
      property={property}
    />
  );
};

export default UserProfile;
