import React, { Component } from "react";
import PropTypes from "prop-types";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

import I18NextContext from "shared/src/components/contexts/I18NextContext";

import Label from '../elements/Label';

import AgeStatsPlaceholder from "../../assets/stats/noAgeStats2.jpg";

const placeholderStyle = {
  backgroundImage: `url('${AgeStatsPlaceholder}')`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  backgroundBlendMode: 'lighten',
  textAlign: 'center',
  lineHeight: '1.2em'
};

class AgeChart extends Component { // TODO refactor to functional component
  static propTypes = {
    ageData: PropTypes.array
  };

  render() {
    const { ageData } = this.props;
    if (!ageData) {
      return (
        <I18NextContext.Consumer>
          {({ strings }) => (
            <div className='mt2' style={placeholderStyle}>
              <small>
                {strings('ui.component.chart.fragment.pt1')}
                <br />
                {strings('ui.component.chart.fragment.pt2')}
              </small>
            </div>
          )}
        </I18NextContext.Consumer>
      );
    }
    if (ageData) {
      const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
          return (
            <Label className="custom-tooltip bg--gray">
              <p className="label">{`${label}: ${Math.round(payload[0].value)}%`}</p>
            </Label>
          );
        }
        return null;
      };
      return (
        <I18NextContext.Consumer>
          {({ strings }) => (
            <div>
              <div className='mt2 ml1'>
                <div
                  style={{ fontSize: '14px', fontWeight: 'bold' }}
                >
                  {strings('ui.component.chart.ageRange').toUpperCase()}
                </div>
              </div>
              <div className='flex'>
                <ResponsiveContainer width='100%' height={80}>
                  <BarChart className='mla mra' data={ageData} barCategoryGap='2%'>
                    <XAxis
                      dataKey='category'
                      tickLine={false}
                      axisLine={false}
                      tick={{ fontSize: '12px' }}
                      minTickGap={-12}
                    />
                    <Tooltip content={CustomTooltip} />
                    <Bar dataKey='percentage' fill='#24AAF9' />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
        </I18NextContext.Consumer>
      );
    }
    return null;
  }
}

export default AgeChart;
