/* eslint camelcase: 0, class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Message from 'web-react-ui/src/components/collections/Message';
import Metrics from 'web-react-ui/src/components/metrics/Metrics';
import MetricsLoyalty from 'web-react-ui/src/components/metrics/MetricsLoyalty';
import MetricsLoyaltyTotal from 'web-react-ui/src/components/metrics/MetricsLoyaltyTotal';
import MetricsTimeSeries from 'web-react-ui/src/components/metrics/MetricsTimeSeries';
import TimeRangeSelector from 'web-react-ui/src/components/metrics/TimeRangeSelector';

import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../../services/client';

export default class LocationOverview extends Component {
  static propTypes = {
    businessId: PropTypes.string,
    propertyId: PropTypes.string,
    locationId: PropTypes.string
  };

  state = {
    days: 30
  };

  fetchStats(params) {
    if (!this.props.locationId) return null;
    const { propertyId, businessId, locationId } = this.props;
    const statsRepo = client
      .properties.for(propertyId)
      .businesses.for(businessId)
      .locations.for(locationId)
      .stats;

    return Promise.all([
      statsRepo.getLastNDays(params),
      statsRepo.getLoyaltyLastNDays(params)
    ])
      .catch(error => this.setState({ error }));
  }

  handleSetRange(params) {
    this.setState({ days: params.days });
  }

  render() {
    return (
      <Request
        request={this.fetchStats.bind(this)}
        params={{ days: this.state.days }}
      >
        {({ data: [stats, loyaltyStats] = [], loading, error }) => (
          <WaitFor waitFor={!loading}>
            {error && <Message error>{error.message}</Message>}
            <div className='flex fww'>
              <div style={{ width: '75%' }}/>
              <div className='fg1 mt2 m1' style={{ minWidth: '200px' }}>
                <TimeRangeSelector days={this.state.days} handleSetRange={this.handleSetRange.bind(this)}/>
              </div>
            </div>
            <h2>Total</h2>
            <Metrics data={stats}/>
            <h2>Loyalty</h2>
            <MetricsLoyaltyTotal
              propertyId={this.props.propertyId}
              businessId={this.props.businessId}
              locationId={this.props.locationId}
            />
            <MetricsLoyalty data={loyaltyStats}/>
            <MetricsTimeSeries data={stats}/>
          </WaitFor>
        )}
      </Request>
    );
  }
}
