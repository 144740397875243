/* eslint-disable no-alert */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import authModule from 'shared/src/modules/auth';
import usePermissions from 'shared/src/hooks/usePermissions';
import useRequest from 'shared/src/hooks/useRequest';
import {
  saveUserName,
  saveRoleChanges,
} from 'shared/src/helpers/userUtils';
import { Role } from 'web-react-ui/src/types/User.interface';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import EditUserView from 'web-react-ui/src/chakra/users/EditUserView';
import client from '../../../services/client';

const EditUserViewModel = ({ propertyId, baseUrl }: { propertyId: string, baseUrl: string }): JSX.Element => {
  const toast = useToast();
  const { userId } = useParams<Record<string, string>>();
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const history = useHistory();
  const { pathname, state }: { pathname: string, state: { query: string } } = useLocation();
  const [refetchId, setRefetchId] = useState(0);
  const canEditUser = usePermissions({ userId }, ['user.update']);

  const canUpdateRoles = usePermissions(
    { propertyId, userId },
    ['property.promote', 'property.demote'],
  );

  const requestBase = client.properties.for(propertyId);
  const { result: user, loading: isLoadingUser } = useRequest(
    () => client.users.for(userId).details(),
    { userId },
  );

  const { result: propertyRolesResult, loading: isLoadingPropertyRoles } = useRequest(
    () => requestBase.users.for(userId).getRoles({ context: ['P'] }),
    { userId, propertyId, refetchId },
  );

  const propertyRoles = propertyRolesResult?.items.map((item: Role) => item.id) || [];
  const { result: roles, loading: isLoadingRoles } = useRequest(() => client.roles.list({ type: 'property' }), {});

  const save = (values: Record<string, any>) => {
    const { name, roles: newRoles = [] } = values;
    const context = { propertyId };
    return Promise.all([
      (canUpdateRoles && Promise.resolve(saveRoleChanges(user, context, propertyRoles, newRoles, client))),
      (canEditUser && Promise.resolve(saveUserName(user, name, client))),
    ]);
  };

  const saveRequest = useRequest(save);
  useEffect(() => {
    const toastProps = { duration: 5000, isClosable: true, position: 'top-right' };
    if (saveRequest.settled && !saveRequest.error) {
      // @ts-ignore
      toast({ ...toastProps, title: 'User Profile Saved!', status: 'success' });
      setRefetchId(refetchId + 1);
    } else if (saveRequest.error) {
      // @ts-ignore
      toast({ ...toastProps, title: saveRequest.error, status: 'error' });
    }
  }, [saveRequest.settled, saveRequest.error, saveRequest.result]);

  const handleSave = async (values: Record<string, any>) => {
    if (!user) return false;
    await saveRequest.run(values);
    return true;
  };

  const handleCancel = () => history.push({
    pathname: pathname.replace('/edit', ''),
    state,
  });

  const deleteRequest = useRequest(() => requestBase.users.for(user.id).remove({ recursive: true }));

  const handleDelete = async () => {
    if (!user) return null;
    /* eslint-disable-next-line no-restricted-globals */
    const shouldDelete = confirm(
      /* eslint-disable-next-line max-len */
      `Are you sure you want to delete ${user.email}? Deleting this user will remove all property, business, and location roles.`,
    );
    if (!shouldDelete) return null;

    await deleteRequest.run();
    if (deleteRequest.settled && !deleteRequest.error) {
      history.push(baseUrl);
    }

    return true;
  };

  const key = [...propertyRoles, user?.name].join(':');
  const isLoading = isLoadingUser || isLoadingPropertyRoles || isLoadingRoles;

  return (
    <WaitFor waitFor={currentUser && user}>
      {currentUser && user && roles && (
        <EditUserView
          key={key}
          isSaving={saveRequest.loading}
          isLoading={isLoading}
          isDeleting={deleteRequest.loading}
          user={user}
          canEditUser={canEditUser}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
          roles={roles.items}
          roleTypes={['Property Roles']}
          canUpdateRoles={canUpdateRoles}
          initialRoles={propertyRoles}
          backUrl={baseUrl}
          context="property"
          operationError={deleteRequest.error}
        />
      )}
    </WaitFor>
  );
};

export default EditUserViewModel;
