import CancelablePromise from 'shared/src/modules/promise/CancelablePromise';
import { types } from './types';

const fetchPlan = async (client, propertyId, businessId) => {
  const plan = await client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .getPlan();

  return plan || {};
};

let pending;

const planRequestMiddleware = (client, store, propertyId, businessId, action) => {
  // already loading for this action, ignore request
  if (pending?.action.payload.businessId === businessId) {
    return null;
  }

  // pending promise, not yet settled, cancel it
  if (pending && !pending.isSettled) {
    pending.cancel();
  }

  pending = new CancelablePromise(fetchPlan(client, propertyId, businessId), action);

  return pending.promise.then((entity) => {
    store.dispatch({
      type: types.PLAN_SUCCESS,
      payload: {
        data: entity,
      }
    });
    return entity;
  }).catch((error) => {
    if (error.message === CancelablePromise.CANCELED) return null;

    if (pending.isCanceled === action) {
      return null;
    }

    store.dispatch({
      type: types.PLAN_FAILURE,
      error,
    });
    return error;
  });
};

const planMiddleware = client => store => next => async (action) => {
  const { type, payload } = action;
  const { businessId, propertyId } = payload || {};
  switch (type) {
    case types.PLAN_INVALIDATE:
      pending.cancel();
      pending = null;
      return next(action); // Let reducer handle it too
    case types.PLAN_REQUEST:
      return planRequestMiddleware(client, store, propertyId, businessId, action);
    default:
      return next(action);
  }
};

export default planMiddleware;
