import React, { useState } from 'react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { FormSpy } from '../../reactFinalForm';
import {
  BooleanField,
  TagField,
  TextField
} from '../../reactFinalForm/fields';
import ListSelectorField from '../../reactFinalForm/fields/ListSelectorField';
import RegionField from '../../reactFinalForm/fields/RegionField';
import RegionSupportMessage from '../../reactFinalForm/fields/RegionSupportMessage';
import { slug } from '../../reactFinalForm/validators';
import Button from '../elements/Button';
import Divider from '../elements/Divider';
import Icon from '../elements/Icon';

const Advanced = () => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { strings } = useI18Next();

  const featuredFlagOption = [{
    label: strings('ui.component.advanced.featuredOffer'),
    id: 'featured'
  }];

  const exclusiveFlagOption = [{
    label: strings('ui.component.advanced.exclusive'),
    id: 'exclusive'
  }];

  const toggleAdvanced = () => {
    setShowAdvanced(prevShowAdvanced => !prevShowAdvanced);
  };

  return (
    <React.Fragment>
      <Divider/>
      <Button type="button" onClick={toggleAdvanced} className='link'>
        <h4>
          {strings('ui.component.advanced.advanced')}
          {showAdvanced && <Icon name='angle up'/>}
          {!showAdvanced && <Icon name='angle down'/>}
        </h4>
      </Button>

      {showAdvanced && (
        <div>
          <ListSelectorField name='flags' options={featuredFlagOption}/>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const { id, flags } = values;
              const isEditing = !!id;
              const isLimited = flags.includes('limited');
              const isPunchcard = flags.includes('punchcard');
              const canChangeExclusive = isEditing && !isLimited && !isPunchcard;
              if (!canChangeExclusive) return null;
              return <ListSelectorField name='flags' options={exclusiveFlagOption}/>;
            }}
          </FormSpy>

          <div className='flex fdr aic jcb mt2' style={{ padding: '6px' }}>
            <div style={{ width: '100%' }}>
              <h6>Hidden</h6>
            </div>
            <BooleanField toggle name="schedules[0].isHidden" />
          </div>

          <Divider />

          <RegionField
            name='discoverableRegions'
            label={strings('ui.component.advanced.regionField.discoverableRegions')}
          />
          <RegionSupportMessage name='discoverableRegions'/>
          <TagField
            name='keywords'
            label={strings('ui.component.advanced.field.label.keywords')}
            wrapLabel
            optional
            info={strings('ui.component.advanced.field.info.keywords')}
          />
          <TextField
            name='slug'
            label={strings('ui.component.advanced.field.label.slug')}
            validate={slug}
            info={strings('ui.component.advanced.field.info.slug')}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Advanced;
