import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import ImageField from 'web-react-ui/src/components/image/ImageField';
import View from 'web-react-ui/src/components/layout/View';
import ColorField from 'web-react-ui/src/reactFinalForm/fields/ColorField';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import { TextField, TextArea } from 'web-react-ui/src/reactFinalForm/fields';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';

import propertyModule from '../../modules/property';
import client from '../../services/client';
import usePropertySetting from '../../hooks/usePropertySettting';

const PropertyTheme = ({
  propertyId,
  redirectTo
}: {
  propertyId: string,
  redirectTo: string,
}): JSX.Element => {
  const dispatch = useDispatch();
  const property = useSelector((state: any) => propertyModule.selectors.property.getData(state));
  const [initialFormValues, setInitialFormValues] = useState({});
  const [redirect, setRedirect] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const theme = usePropertySetting('hosted_redemption.theme');

  useEffect(() => {
    let formValues = {};
    if (property) {
      const { profileImage, logoImage, splashImage } = property;
      formValues = {
        property: {
          ...(profileImage) && { profileImage: profileImage.id },
          ...(logoImage) && { logoImage },
          ...(splashImage) && { splashImage }
        }
      };

      if (theme) {
        formValues = {
          ...formValues,
          hosted_redemption: { theme }
        };
      }
      setInitialFormValues(formValues);
    }
  }, [property, theme]);

  const handleSubmit = async (updatedFields: { [key: string]: any }) => {
    try {
      setIsLoading(true);
      setError(null);
      const originalProperty = initialFormValues;
      const { id, slug, ...updates } = updatedFields.property;
      await client.properties.for(propertyId).update(originalProperty, updates);
      await client.properties.for(propertyId).setSetting(
        'hosted_redemption.theme',
        updatedFields.hosted_redemption.theme
      );
      dispatch(propertyModule.actions.invalidateAndRefresh(propertyId));
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setRedirect(redirectTo);
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <BigModal open>
      <BigModal.Contents>
        <BigModal.Header className='p1'>
          <BigModal.CloseButton floated='right' onClick={handleCancel} />
        </BigModal.Header>
        <BigModal.Body>
          <View>
            <View.Section narrow>
              <h1>Property Theme</h1>
              <SimpleForm
                onSubmit={handleSubmit}
                loading={isLoading}
                initialValues={initialFormValues}
              >
                <ErrorMessage error={error} />
                <ImageField name='property.profileImage' fileType='property-profile' label='Profile Image' />
                <ImageField name='property.logoImage' fileType='property-logo-standard' label='Logo' />
                <ImageField name='property.splashImage' fileType='property-splash-standard' label='Splash Image' />
                <h3 className="mt3 text--muted">Brand Colour</h3>
                <ColorField
                  name='hosted_redemption.theme.brandPrimaryColor'
                  type='text'
                  label='Brand Primary Colour'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.brandSecondaryColor'
                  type='text'
                  label='Brand Secondary Colour'
                  placeholder='eg: #FF0099'
                />
                <h3 className="mt3 text--muted">Navigation Bar</h3>
                <ColorField
                  name='hosted_redemption.theme.navSecondaryColor'
                  type='text'
                  label='Background Colour'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.navPrimaryColor'
                  type='text'
                  label='Foreground Colour'
                  placeholder='eg: #FF0099'
                />
                <h3 className="mt3 text--muted">Main</h3>
                <ColorField
                  name='hosted_redemption.theme.mainBackgroundColor'
                  type='text'
                  label='Background Main Colour'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.mainDividerColor'
                  type='text'
                  label='Divider Colour'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.mainHeadingTextColor'
                  type='text'
                  label='Heading Text Colour'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.mainBodyTextColor'
                  type='text'
                  label='Body Text Colour'
                  placeholder='eg: #FF0099'
                />
                <h3 className="mt3 text--muted">Footer</h3>
                <ColorField
                  name='hosted_redemption.theme.footerBackgroundColor'
                  type='text'
                  label='Background Colour'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.footerForegroundColor'
                  type='text'
                  label='Foreground Colour'
                  placeholder='eg: #555555'
                />
                <h3 className="mt3 text--muted">Primary & Secondary Actions</h3>
                <ColorField
                  name='hosted_redemption.theme.actionPrimaryColor'
                  type='text'
                  label='Active Colour'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.actionSecondaryColor'
                  type='text'
                  label='Disabled Colour'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.actionOnActive'
                  type='text'
                  label='On Active'
                  placeholder='eg: #FF0099'
                />
                <ColorField
                  name='hosted_redemption.theme.actionOnDisabled'
                  type='text'
                  label='On Disabled'
                  placeholder='eg: #FF0099'
                />
                <h3 className="mt3 text--muted">Button</h3>
                <TextField
                  wrapLabel
                  name='hosted_redemption.theme.actionBorderRadius'
                  type='text'
                  label='Border Radius'
                  placeholder='4px'
                />
                <h3 className="mt3 text--muted">Offer Image</h3>
                <TextField
                  wrapLabel
                  name='hosted_redemption.theme.imageBorderRadius'
                  type='text'
                  label='Border Radius'
                  placeholder='2em'
                />
                <h3 className="mt3 text--muted">Custom CSS</h3>
                <TextArea
                  wrapLabel
                  label="CSS Custom Styles Block"
                  name='hosted_redemption.theme.customCss'
                  placeholder='Insert custom css here'

                />
                <div className="mt2 mb4">
                  <FormSubmit fluid label='Submit' />
                </div>
              </SimpleForm>
            </View.Section>
          </View>
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

export default PropertyTheme;
