import React from 'react';
import SearchBaseField from '../../reactFinalForm/fields/SearchBaseField';

const OFFER_FLAGS = [
  { name: 'Exclusive', id: 'exclusive' },
  { name: 'Limited', id: 'limited' },
  { name: 'Punchcard', id: 'punchcard' },
  { name: 'Featured', id: 'featured' }
];

const getOfferFlags = async (query) => {
  return OFFER_FLAGS.filter(f => f.name.toLowerCase().includes(query.toLowerCase()));
};

const parse = (values) => {
  if (!values) return values;
  return values.map(value => value.id);
};

const format = (values) => {
  if (!values) return values;
  return values.map(value => OFFER_FLAGS.find(flag => flag.id === value));
};

const FilterFlagField = ({ name }) => {
  return (
    <SearchBaseField
      search={getOfferFlags}
      parse={parse}
      format={format}
      multiSelect
      wrapLabel
      fluid
      name={name}
    />
  );
};

export default FilterFlagField;
