/* eslint object-curly-newline: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import './View.css';

import Divider from '../elements/Divider';

const View = (props) => {
  const { className } = props;
  const classes = `c-view ${className}`;
  return <div className={classes}>{props.children}</div>;
};
View.propTypes = {
  className: PropTypes.string
};

const ViewSection = (props) => {
  const { fluid, narrow, title, children, divided, className: classProp, ...rest } = props;
  const classes = [
    'c-view-section',
    'p1--default'
  ];
  if (classProp) classes.push(classProp);
  if (fluid) classes.push('c-view-section--fluid');
  if (narrow) classes.push('c-view-section--narrow');
  const className = classes.join(' ');
  return (
    <div className={className} {...rest}>
      { title }
      { divided && <Divider /> }
      { children }
    </div>
  );
};
ViewSection.propTypes = {
  fluid: PropTypes.bool,
  narrow: PropTypes.bool,
  title: PropTypes.any,
  divided: PropTypes.bool,
  className: PropTypes.string
};

View.Section = ViewSection;

export default View;
