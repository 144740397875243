import _ from "lodash";
import PropTypes from 'prop-types';
import React, { Component } from "react";
import './ToggleGroup.css';

class ToggleItem extends Component {
  render() {
    const { o, handleClick, value } = this.props;
    return (
      <label className='week-day-selector__day p1 fg1 fb0'>
        <div className='flex'>
          <input
            type='checkbox'
            key={o.value}
            onChange={handleClick}
            checked={value.includes(o.value)}
            className='mla mra'
          />
        </div>
        <div className='tac'>
          {o.label}
        </div>
      </label>
    );
  }
}

ToggleItem.propTypes = {
  o: PropTypes.object,
  handleClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

class ToggleGroup extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    options: PropTypes.array,
    onChange: PropTypes.func
  };

  handleToggle(value) {
    const { onChange } = this.props;
    // FIXME: Does allowNull not work on <Field>?
    const fieldValue = this.props.value || [];
    onChange(fieldValue.includes(value)
      ? _.without(fieldValue, value)
      : fieldValue.concat(value));
  }

  render() {
    const { options, value } = this.props;
    return (
      <div className='flex mt1'>
        {options.map(o => (
          <ToggleItem o={o} value={value} key={o.value} handleClick={this.handleToggle.bind(this, o.value)}/>
        ))}
      </div>
    );
  }
}

export default ToggleGroup;
