/* eslint no-nested-ternary: 0 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import usePromise from 'shared/src/hooks/usePromise';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Label from '../elements/Label';
import Icon from '../elements/Icon';

import numbersLargeBlurred from '../../assets/stats/Numbers_Large_Blurred.png';

import './Metrics.css';

const MetricsLoyaltyTotalView = ({ data, isPermitted }) => {
  const { strings } = useI18Next();

  const renderContent = (isRestricted, metrics) => {
    if (!isPermitted) { // User has NO permissions
      return (
        <div className='flex aic jcc mb3'>
          <div className='tac metrics-funnel__label tooltip'>
            <Label className='tooltiptext'>
              {strings('ui.component.metricsLoyaltyTotalView.toolTipText1')}
            </Label>
            <span className="text--muted">
              <Icon className="eye slash outline grey left" />
              <em>{strings('ui.component.metricsLoyaltyTotalView.requiresBusinessRole')}</em>
            </span>
          </div>
        </div>
      );
    }
    if (isRestricted) { // User has permissions and isRestricted is true
      return (
        <img
          className='metrics-funnel__placeholder-number mla mra'
          src={numbersLargeBlurred}
        />
      );
    }

    if (metrics) { // User has permissions, isRestricted is false, and there's a number
      return <h2 className='tac mt0 mb1 metrics-funnel__value'>{Math.ceil(metrics)}</h2>;
    }
    return <h2 className='tac mt0 mb1 metrics-funnel__value'>–</h2>;
  };

  const { count, restrictions } = data || {};

  const restricted = restrictions && restrictions['stats.feature.unique.loyalty.include'];

  return (
    <div className='fg1 fb0 mt1 mb1'>
      <div className='metrics-funnel metrics-funnel__step tac'>
        <div className='tac mv2 metrics-funnel__label tooltip'>
          <Label className='tooltiptext'>
            {strings('ui.component.metricsLoyaltyTotalView.toolTipText2')}
          </Label>
          {strings('ui.component.metricsLoyaltyTotalView.totalLoyaltyMember')}
        </div>
        {renderContent(restricted, count)}
      </div>
    </div>
  );
};

MetricsLoyaltyTotalView.propTypes = {
  data: PropTypes.object,
  isPermitted: PropTypes.bool
};

const MetricsLoyaltyTotal = ({
  propertyId,
  businessId,
  locationId,
  offerId,
  userNoPermission
}) => {
  const client = useContext(ClientContext);
  const [, , stats] = usePromise(
    () => {
      if (userNoPermission) return null;
      if (!client) return null;
      let statsRepo = client.properties.for(propertyId);
      if (businessId) statsRepo = statsRepo.businesses.for(businessId);
      if (locationId) statsRepo = statsRepo.locations.for(locationId);
      if (offerId) statsRepo = statsRepo.offers.for(offerId);
      statsRepo = statsRepo.stats;

      return statsRepo
        ? statsRepo.getLoyaltyUniques()
        : null;
    },
    [client, propertyId, businessId, locationId, offerId, userNoPermission],
    null,
    true
  );

  return <MetricsLoyaltyTotalView data={stats} isPermitted={!userNoPermission} />;
};

MetricsLoyaltyTotal.propTypes = {
  propertyId: PropTypes.String,
  businessId: PropTypes.String,
  locationId: PropTypes.String,
  offerId: PropTypes.String,
  userNoPermission: PropTypes.bool
};

export { MetricsLoyaltyTotalView };
export default MetricsLoyaltyTotal;
