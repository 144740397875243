/**
 * Checks offers against syndication criteria, deciding which to approve / deny
 */

import isEmpty from 'web-react-ui/src/utils/isEmpty';
import defaults from './keywords';

class LocalizationError extends Error {
  constructor(message, locale) {
    super();
    this.message = message;
    this.locale = locale;
  }
}

class ContentFilter {
  constructor({ requiredPhrases, excludedPhrases } = {}) {
    this.requiredPhrases = defaults.isDealPhrases.concat(requiredPhrases || []);
    this.excludedPhrases = excludedPhrases || [];
  }

  /**
   * Checks an offers against the ContentFilter criteria.
   *
   * @returns Boolean Error if the offer fails any criteria
   */
  check(offer /* , localizationContext */) {
    // TODO: use localizationContent.locales to check through all localizations
    // And provide errors with information about which localization doesn't meet the requirements
    const locale = 'en';
    const headline = offer.headlineLocalized[locale];
    const subHeadline = offer.subHeadlineLocalized[locale];

    // Do not check completely empty languages
    if (isEmpty(headline) && isEmpty(subHeadline)) return null;

    const text = `${headline}\n${subHeadline}`;

    // Exclude bad words
    if (this.excludedPhrases.find(phrase => text.includes(phrase.toLowerCase()))) {
      return new LocalizationError(ContentFilter.ERROR_CONTAINS_EXCLUDED_WORD, locale);
    }

    // Exclude by phrase in headline, subhead, details
    const contains = this.requiredPhrases
      .find((phrase) => {
        if (phrase instanceof RegExp) {
          phrase.lastIndex = 0;
          return phrase.test(text);
        }
        return text.toLowerCase().includes(phrase.toLowerCase());
      });
    if (!contains) {
      return new LocalizationError(ContentFilter.ERROR_MISSING_REQUIRED_PHRASE, locale);
    }

    return null;
  }
}

ContentFilter.ERROR_INVALID_SCHEDULE = 'invalid-discoverable-schedule';
ContentFilter.ERROR_INVALID_STATUS = 'invalid-status';
ContentFilter.ERROR_OFFER_DISABLED = 'offer-disabled';
ContentFilter.ERROR_EXCLUDED_BUSINESS_ID = 'excluded-business-id';
ContentFilter.ERROR_EXCLUDED_BUSINESS_CATEGORY = 'excluded-business-category';
ContentFilter.ERROR_CONTAINS_EXCLUDED_WORD = 'contains-excluded-words';
ContentFilter.ERROR_MISSING_REQUIRED_PHRASE = 'missing-required-phrases';

export default ContentFilter;
