import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SearchBase from 'web-react-ui/src/search/SearchBase';
import client from '../../services/client';


const mapResult = business => ({
  title: business.name,
  value: business,
  id: business.id,
  key: business.id
});

class BusinessSearch extends Component {
  static propTypes = {
    propertyId: PropTypes.string,
    onSearchChange: PropTypes.func,
    value: PropTypes.any // ignore
  };

  searchBusinesses(query) {
    const { propertyId } = this.props;
    return client.properties.for(propertyId).businesses.listNotAttached({ query });
  }

  render() {
    const {
      onSearchChange, value, propertyId, ...rest
    } = this.props;
    return (
      <SearchBase onSearchChange={this.searchBusinesses.bind(this)} mapResult={mapResult} {...rest} />
    );
  }
}

export default BusinessSearch;
