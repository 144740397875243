import PropTypes from 'prop-types';
import React, {
  Component,
  Fragment
} from 'react';

import ImageLibraryModal from './library/ImageLibraryModal';
import ImageUploadModal2 from './upload2/ImageUploadModal2';

class ImageWellModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    property: PropTypes.string,
    business: PropTypes.string,
    location: PropTypes.string,
    onSetOpen: PropTypes.func,
    initialImage: PropTypes.string
  };

  state = {};

  static getDerivedStateFromProps(props, state) {
    if (state.view) return null;
    // If property id is not provided, we can't list library images
    if (props.property && !props.initialImage) {
      return {
        view: 'library',
        canShowLibrary: true,
        showLibrary: true
      };
    }
    return {
      view: 'upload',
      canShowLibrary: false,
      showLibrary: false
    };
  }

  setView(view) {
    this.setState({ view });
  }

  handleUploadCancel() {
    const { onSetOpen } = this.props;
    const { canShowLibrary } = this.state;
    if (canShowLibrary) return this.setView('library');
    return onSetOpen(false);
  }

  render() {
    const {
      open, property, business, location, initialImage, ...rest
    } = this.props;
    const { view, showLibrary } = this.state;
    const context = { property, business, location };

    if (!open) return null;

    return (
      <Fragment>
        {
          view === 'upload' && <ImageUploadModal2
            open={true}
            onCancel={this.handleUploadCancel.bind(this)}
            initialImage={initialImage}
            {...context}
            {...rest} />
        }
        {
          showLibrary && view === 'library' && <ImageLibraryModal
            open={true}
            onSetView={this.setView.bind(this)}
            {...context}
            {...rest} />
        }
      </Fragment>
    );
  }
}

export default ImageWellModal;
