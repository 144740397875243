import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import MetricItem from './MetricItem';
import './Metrics.css';
import MetricsFunnelStep from './MetricsFunnelStep';
import MetricsUpgradeBanner from './MetricsUpgradeBanner';

const MetricsLoyalty = ({ data, plansHref }) => {
  const { strings } = useI18Next();

  const reach = {
    total: data && data.reach && data.reach.total,
    age: data && data.reach && data.reach.ageSeries,
    gender: data && data.reach && data.reach.genderBreakdown
  };
  const response = {
    total: data && data.response && data.response.total,
    age: data && data.response && data.response.ageSeries,
    gender: data && data.response && data.response.genderBreakdown
  };
  const action = {
    total: data && data.action && data.action.total,
    age: data && data.action && data.action.ageSeries,
    gender: data && data.action && data.action.genderBreakdown
  };
  const earned = {
    total: data && data.earned && data.earned.total,
    age: data && data.earned && data.earned.ageSeries,
    gender: data && data.earned && data.earned.genderBreakdown
  };
  const restrictions = (data && data.restrictions) || {};
  const label = {
    reach: strings('ui.component.metrics.reach'),
    reachExplanation: strings('ui.component.metrics.reachExplanation'),
    response: strings('ui.component.metrics.response'),
    responseExplanation: strings('ui.component.metrics.responseExplanation'),
    action: strings('ui.component.metrics.action'),
    actionExplanation: strings('ui.component.metrics.actionExplanation'),
    earned: strings('ui.component.metricsLoyalty.earned'),
    earnedExplanation: strings('ui.component.metricsLoyalty.earnedExplanation')
  };

  const reachRestricted = restrictions['stats.feature.general.loyalty.include_reach'];
  const responseRestricted = restrictions['stats.feature.general.loyalty.include_response'];
  const actionRestricted = restrictions['stats.feature.general.loyalty.include_action'];
  const earnedRestricted = restrictions['stats.feature.general.loyalty.include_earned'];

  const reachDemoRestricted = restrictions['stats.feature.general.loyalty.include_reach_demographic'];
  const responseDemoRestricted = restrictions['stats.feature.general.loyalty.include_response_demographic'];
  const actionDemoRestricted = restrictions['stats.feature.general.loyalty.include_action_demographic'];
  const earnedDemoRestricted = restrictions['stats.feature.general.loyalty.include_earned_demographic'];

  return (
    <div>
      {restrictions.isRestricted
        ? (
          <MetricsUpgradeBanner plansHref={plansHref}>
            {strings('ui.component.metricsLoyalty.metricsUpgradeBanner')}
          </MetricsUpgradeBanner>
        )
        : null
      }
      <div className='wrap--contents unwrap--contents mt1'>
        <MetricsFunnelStep
          label={label.reach}
          explanationLabel={label.reachExplanation}
          data={reach}
          restricted={reachRestricted}
        />
        <MetricsFunnelStep
          label={label.response}
          explanationLabel={label.responseExplanation}
          data={response}
          restricted={responseRestricted}
        />
        <MetricsFunnelStep
          label={label.action}
          explanationLabel={label.actionExplanation}
          data={action}
          restricted={actionRestricted}
        />
        <MetricsFunnelStep
          label={label.earned}
          explanationLabel={label.earnedExplanation}
          data={earned}
          isLast
          restricted={earnedRestricted}
        />
      </div>
      <div className='wrap--contents unwrap--contents'>
        <MetricItem
          ageData={reach.age}
          genderData={reach.gender}
          name={label.reach}
          restricted={reachRestricted || reachDemoRestricted}
        />
        <MetricItem
          ageData={response.age}
          genderData={response.gender}
          name={label.response}
          restricted={responseRestricted || responseDemoRestricted}
        />
        <MetricItem
          ageData={action.age}
          genderData={action.gender}
          name={label.action}
          restricted={actionRestricted || actionDemoRestricted}
        />
        <MetricItem
          ageData={earned.age}
          genderData={earned.gender}
          name={label.earned}
          restricted={earnedRestricted || earnedDemoRestricted}
        />
      </div>
    </div>
  );
};

MetricsLoyalty.propTypes = {
  data: PropTypes.object,
  plansHref: PropTypes.string
};

export default MetricsLoyalty;
