import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';

class FormReducer extends Component {
  render() {
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => {
          this.props.children(values, form);
          return null;
        }}
      </FormSpy>
    );
  }
}

export default FormReducer;
