import React from 'react';
import PropTypes from 'prop-types';
import Label from 'web-react-ui/src/components/elements/Label';
import useFilteredRegions from '../../../hooks/useFilteredRegions';
import useCollectionFilters from '../../../hooks/useCollectionFilters';

const getDiscoverType = (regions, key) => {
  let outputText = '';
  switch (key) {
    case 'included':
      if (regions.included.length > 0) outputText = 'Is Discoverable In';
      break;
    case 'excluded':
      if (regions.excluded.length > 0) outputText = 'Is Not Discoverable In';
      break;
    default:
      break;
  }
  return outputText;
};

const renderListRegions = (regions, key) => {
  return (
    <div className='mb2'>
      <div className='ui two column doubling grid'>
        <div className='five wide column'>
          <p className='ml1'>{getDiscoverType(regions, key)}</p>
        </div>
        <div className='eleven wide column'>
          <Label.Group>
            {((regions && regions[key]) || []).map(({ id, label }) => <Label key={id}>{label}</Label>)}
          </Label.Group>
        </div>
      </div>
    </div>
  );
};

const renderOperator = (regions, operator) => {
  if ((regions.included.length && regions.excluded.length) === 0) return null;
  if (!operator) return null;
  return (
    <div className='mb2'>
      <Label className='ui blue basic label large' style={{ textTransform: 'uppercase' }}>
        <strong>{operator}</strong>
      </Label>
    </div>
  );
};

const ListRegions = ({ collection, collectionId, propertyId }) => {
  const [regions, isLoading] = useFilteredRegions(collection);
  const [{ regionFilters: { operator } }, regionIsLoading] = useCollectionFilters(collectionId, propertyId);

  const hasRegions = regions && regions.items && regions.items.length;

  if (isLoading || regionIsLoading) return <div className='ui active centered inline loader' />;

  return (
    <div>
      {hasRegions ? (
        <React.Fragment>
          {renderListRegions(regions, 'included')}
          {renderOperator(regions, operator)}
          {renderListRegions(regions, 'excluded')}
        </React.Fragment>
      ) : (
        <em>none</em>
      )}
    </div>
  );
};

ListRegions.propTypes = {
  collection: PropTypes.object.isRequired,
  collectionId: PropTypes.string.isRequired,
  propertyId: PropTypes.string.isRequired
};

export default ListRegions;
