/* eslint class-methods-use-this:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SupportLink extends Component {
  static propTypes = {
    label: PropTypes.string
  };

  render() {
    const { label } = this.props;

    return (
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
        >
          { label }
        </a>
      </div>
    );
  }
}

export default SupportLink;
