import React, { Component } from 'react';

import { FormSpy } from './index';

class FormDebugger extends Component {
  state = { visible: false };

  toggleVisibility() {
    this.setState(({ visible }) => ({ visible: !visible }));
  }

  render() {
    const { visible } = this.state;
    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => (
          <div>
            <button
              type="button"
              className="ui button link"
              onClick={this.toggleVisibility.bind(this)}
              style={{ color: '#77777777' }}
            >
              Debug <i className={`icon fitted caret ${visible ? 'down' : 'right'}`} />
            </button>
            {visible && <pre>{JSON.stringify(values, 0, 2)}</pre>}
          </div>
        )}
      </FormSpy>
    );
  }
}

export default FormDebugger;
