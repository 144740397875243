import React from 'react';
import { Flex, Button } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

const BackButton = ({ baseUrl }: { baseUrl: string }): JSX.Element => (
  <Flex my={10}>
    <Button
      variant='link'
      as={Link}
      to={baseUrl}
      leftIcon={<ChevronLeftIcon />}
    >
      Back to Users
    </Button>
  </Flex>
);

export default BackButton;
