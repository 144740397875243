import React from 'react';
import Button from '../elements/Button';

const RemoveButton = props => (
  <Button
    {...props}
    type='button'
    circular
    className='text'
    size='large'
    icon='trash alternate outline'
  />
);

export default RemoveButton;
