import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  MenuDivider,
  MenuGroup,
  MenuItem
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import auth from 'shared/src/modules/auth';

const AccountMenu = ({ user }) => {
  const dispatch = useDispatch();
  if (!user) return null;

  return (
    <>
      <MenuDivider />
      <MenuGroup>
        <MenuItem
          as="a"
          href={process.env.REACT_APP_DOCS}
          target="_blank"
          rel="noopener noreferrer"
        >
          Documentation
          <ArrowForwardIcon ml="auto" />
        </MenuItem>
        <MenuItem
          as="a"
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
        >
          Help
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => dispatch(auth.actions.logout())}>Logout</MenuItem>
      </MenuGroup>
    </>
  );
};

export default AccountMenu;
