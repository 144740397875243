import {
  format,
  parse,
} from 'date-fns';
import SimpleSchedule from '../../../types/SimpleSchedule.interface';

const getDateRangeStr = (
  { startDate, endDate }: SimpleSchedule,
  dateLocale: { locale: Locale } | null,
  t: (key: string) => string,
) => {
  if (!endDate) {
    return `
      ${t('ui.component.offerListItem.live')}
      ${format(parse(startDate, 'yyyy-MM-dd', new Date()), 'MMM d', dateLocale)}
    `;
  }
  const startDateStr = format(parse(startDate, 'yyyy-MM-dd', new Date()), 'MMM d', dateLocale);
  const endDateStr = format(parse(endDate, 'yyyy-MM-dd', new Date()), 'MMM d', dateLocale);
  return `${startDateStr} - ${endDateStr}`;
};

export default getDateRangeStr;
