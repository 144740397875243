import _ from 'lodash';
import { useEffect } from 'react';
import { useField } from 'react-final-form';

const useAutoSelectFirst = (fieldName, options, onlyIfEmpty) => {
  const { input } = useField(fieldName);
  useEffect(
    () => {
      // Gross delay to avoid updating too soon and skipping form update
      (async () => {
        await new Promise(res => setTimeout(res, 1));
        if (onlyIfEmpty && !_.isEmpty(input.value)) return;
        if (!options.length || options.find(({ value }) => value === input.value)) return;
        input.onChange(options[0].value);
      })();
    },
    [fieldName, options]
  );
};

export default useAutoSelectFirst;
