const imgixParams = (
  url,
  params = {}
) => {
  if (!url) return url;

  const newUrl = new URL(url);

  Object.entries(params).forEach(([key, value]) => {
    newUrl.searchParams.set(key, value);
  });

  return newUrl.href;
};

export default imgixParams;
