import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Button from '../elements/Button';
import Icon from '../elements/Icon';

const MetricsUpgradeBanner = ({ children, plansHref }) => {
  const { strings } = useI18Next();

  if (!plansHref) return null;
  return (
    <div className='stats-upgrade-banner'>
      <Icon size='large' name='arrow alternate circle up outline'/>
      {children || strings('ui.component.metricsUpgradeBanner.defaultCopy')}
      <Button
        as='a'
        href={plansHref}
        className='mla'
        positive
      >
        {strings('ui.component.metricsUpgradeBanner.seeUpgradeOptions')}
      </Button>
    </div>
  );
};

MetricsUpgradeBanner.propTypes = {
  plansHref: PropTypes.string
};

export default MetricsUpgradeBanner;
