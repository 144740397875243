import {
  Box,
  Button,
  Center,
  Flex,
  Stack,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { Logo } from '../../chakra/shell/Logo';

const SitePermissionDenied = () => {
  return (
    <Flex direction='column' minH='100vh' minW='100vw' p={8}>
      <Box w='100%'>
        <Logo h='6' />
      </Box>
      <Center flexGrow={1}>
        <Stack spacing={8} alignItems='center'>
          <RiQuestionnaireLine size={50} opacity='50%' />
          <Stack spacing={4} alignItems='center'>
            <Text fontSize='xl' bold>You do not have access to this site</Text>
          </Stack>
          <Stack direction='row'>
            <Button
              as='a'
              colorScheme='blue'
              target='_blank'
              rel='noopener noreferrer'
              href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
            >
              Contact Support
            </Button>
            <Button
              as='a'
              href='/login'
              colorScheme='blue'
            >
              Back To Login
            </Button>
          </Stack>
        </Stack>
      </Center>
    </Flex>
  );
};

export default SitePermissionDenied;
