import PropTypes from 'prop-types';
import React from 'react';
import Message from '../collections/Message';
import './toast.css';


const Toast = ({
  title, message, onDismiss, className, type, icon
}) => {
  const typeProp = type
    ? {
      [type]: true
    }
    : null;
  return (
    <div className={`${className} toast`}>
      <Message
        header={title}
        content={message}
        onDismiss={onDismiss}
        className='toast__message'
        icon={icon}
        size='small'
        {...typeProp}
      />
    </div>
  );
};

Toast.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string
};

export default Toast;
