import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import { Box, Input, Text } from '@chakra-ui/react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import { max } from 'web-react-ui/src/reactFinalForm/validators';
import { TextField } from '../../../reactFinalForm/fields';
import RadioButtonGroupField from '../../../chakra/forms/RadioButtonGroup/RadioButtonGroupField';

const RedemptionActionOptionalFields = ({ name, ...boxProps }) => {
  const { strings } = useI18Next();
  const form = useForm();
  const offerType = form.getState().values.offerType;
  const formActionName = offerType === 'punchcard'
    ? `${name}.punchAction` // this doesn't actually do anything for punch cards!
    : `${name}.action`;
  return (
    <Box {...boxProps}>
      <Field name={formActionName}>
        {({ input: actionInput }) => {
          if (actionInput.value === 'call') {
            return (
              <Field name={`${name}.setPhone`}>
                {({ input: phoneInput }) => {
                  return (
                    <>
                      <Box mb={5}>
                        <Text fontWeight={600} mb={3}>
                          {strings('ui.component.offerRedeemLimits.field.label.whatNumber')}
                        </Text>
                        <RadioButtonGroupField
                          value={phoneInput.value || 0}
                          name={phoneInput.name}
                          options={[
                            {
                              label: strings('ui.component.offerRedeemLimits.field.label.location'),
                              value: 0,
                            },
                            {
                              label: strings('ui.component.offerRedeemLimits.field.label.altNumber'),
                              value: 1
                            }
                          ]}
                        />
                      </Box>
                      {phoneInput.value > 0 && (
                        <Box mb={5}>
                          <Text fontWeight={600} mb={3}>
                            {strings('ui.component.offerRedeemLimits.field.label.phone')}
                          </Text>
                          <Field name={`${name}.phone`}>
                            {({ input }) => {
                              return (
                                <Input
                                  type='text'
                                  value={input.value}
                                  onChange={input.onChange}
                                  placeholder={`Phone Number`}
                                />
                              );
                            }}
                          </Field>
                        </Box>
                      )}
                    </>
                  );
                }}
              </Field>
            );
          } else if (actionInput.value === 'website') {
            return (
              <>
                <Text fontWeight={600} mb={3}>
                  {strings('ui.component.offerRedeemLimits.field.label.url')}
                </Text>
                <TextField
                  name={`${name}.url`}
                  type='url'
                  validate={max(512)}
                  placeholder={`URL`}
                  wrapLabel={true}
                  required
                  chakra
                />
              </>
            );
          }

          return <></>;
        }}
      </Field>
    </Box>
  );
};

RedemptionActionOptionalFields.propTypes = {
  name: PropTypes.string,
};

export default RedemptionActionOptionalFields;
