/* eslint class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import EditOfferMenu from 'web-react-ui/src/components/offers/EditOfferMenu';
import OfferTypeSelection from 'web-react-ui/src/components/offers/OfferTypeSelection';
import { FormSpy } from 'web-react-ui/src/reactFinalForm';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import { atLeastOneCompleteLocalization } from 'web-react-ui/src/reactFinalForm/validators';
import businessesModule from '../../modules/businesses';
import propertyModule from '../../modules/property';
import plansModule from '../../modules/plan';
import client from '../../services/client';
import pipelines from '../../services/pipelines';
import ConnectedTranslationWrapper from '../i18n/ConnectedTranslationWrapper';

// @todo make this a functional component
class DuplicateOfferContainer extends Component {
  static propTypes = {
    returnTo: PropTypes.string,
    property: PropTypes.object,
    business: PropTypes.object,
    offerId: PropTypes.string,
    allowsAdvancedSchedule: PropTypes.bool,
  };

  state = {
    redirect: false
  };

  componentDidMount() {
    this.initialOffer()
      .then((offerEdit) => {
        this.setState({ instance: offerEdit });
      });
  }

  handleCancel() {
    this.setState({ redirect: this.props.returnTo });
  }

  async handleSubmit(offer) {
    const { property, business } = this.props;
    const { instance } = this.state;

    // TODO: Pull out EditOfferPipeline instance values individually?
    // Form EditOfferForm needs to be updated to look for instance elements separately instead of under `offer`
    await instance.deprecatedUpdateWithOldOfferModel(offer);

    const { id } = instance.getInstance('offer');
    const offerDetailsUrl = `/${property.id}/businesses/${business.id}/offers/${id}`;
    this.setState({ redirect: offerDetailsUrl });
  }

  initialOffer() {
    const { property, business, offerId, allowsAdvancedSchedule } = this.props;
    if (!offerId) return null;
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offerId)
      .duplicate(allowsAdvancedSchedule);
  }

  getOfferType(flags) {
    if (flags.includes('punchcard')) return 'punchcard';
    if (flags.includes('limited')) return 'limited';
    if (flags.includes('exclusive')) return 'exclusive';
    return 'reach';
  }

  render() {
    const { property, business, plan } = this.props;
    const { instance } = this.state;
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;

    let initialValues = null;

    // TODO properly handle this in the SDK by passing an object with instances AND updates
    if (instance) {
      initialValues = instance.deprecatedGetOldOfferModel();
      instance.setInstance('discoverableRegions', []);
      instance.setInstance('locations', []);
    }

    return (
      <BigModal open>
        <ConnectedTranslationWrapper>
          <LocalizableSimpleForm
            initialValues={initialValues}
            onSubmit={this.handleSubmit.bind(this)}
            loading={!initialValues}
            validate={pipelines.OfferUpdatePipeline.deprecatedValidateWithOldOfferModel}
            validateLocale={atLeastOneCompleteLocalization([
              'headlineLocalized', 'subHeadlineLocalized', 'detailsLocalized'
            ])}
          >
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                if (!Object.keys(values).length) return null;
                return (
                  <BigModal.Contents>
                    <BigModal.Header className='p1'>
                      <Box maxW='8xl' mx='auto' p={3}>
                        <EditOfferMenu
                          handleCancel={this.handleCancel.bind(this)}
                          isEdit={true}
                          existingOfferType={this.getOfferType(values.flags)}
                        />
                      </Box>
                    </BigModal.Header>
                    <BigModal.Body>
                      <Box my={5} maxW='8xl' mx='auto'>
                        <OfferTypeSelection
                          existingOfferType={this.getOfferType(values.flags)}
                          propertyId={property.id}
                          businessId={business.id}
                          plan={plan}
                        />
                      </Box>
                    </BigModal.Body>
                  </BigModal.Contents>
                );
              }}
            </FormSpy>
          </LocalizableSimpleForm>
        </ConnectedTranslationWrapper>
      </BigModal>
    );
  }
}

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state),
  business: businessesModule.selectors.business.getData(state),
  plan: plansModule.selectors.plan.getData(state),
});

export default connect(mapState)(DuplicateOfferContainer);
