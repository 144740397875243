import { Badge } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

export const InfoBadge = ({ type, ...rest }: { type: string | null }) => {
  const { strings } = useI18Next();
  let badgeColor;
  let name;
  let color;
  switch (type) {
    case 'exclusive':
      badgeColor = 'purple.500';
      name = strings('ui.component.offerListItem.field.label.exclusive');
      break;
    case 'limited':
      badgeColor = 'orange.500';
      name = strings('ui.component.offerListItem.field.label.limitedQty');
      break;
    case 'punchcard':
      badgeColor = 'blue.500';
      name = strings('ui.component.offerListItem.field.label.punchCard');
      break;
    case 'live':
      badgeColor = 'green.500';
      name = strings('ui.component.offerListItem.field.label.live');
      break;
    case 'scheduled':
      badgeColor = 'blue.500';
      name = strings('ui.component.offerListItem.field.label.scheduled');
      break;
    case 'expired':
      badgeColor = 'pink.500';
      name = strings('ui.component.offerListItem.field.label.expired');
      break;
    case 'pending':
      badgeColor = 'blue.500';
      name = strings('ui.component.offerListItem.field.label.scheduled');
      break;
    case 'draft':
      badgeColor = 'yellow.500';
      name = strings('ui.component.offerListItem.field.label.draft');
      break;
    case 'hidden':
      badgeColor = 'gray.100';
      name = strings('shared.field.label.hidden.value').toUpperCase();
      color = 'gray.500';
      break;
    case 'syndicated':
      badgeColor = 'gray.500';
      name = strings('ui.component.offerListItem.field.label.syndicated');
      break;
    default:
      badgeColor = null;
      name = null;
  }

  return type ? (
    <Badge
      textAlign="center"
      color={color || 'white'}
      bg={badgeColor}
      borderRadius={6}
      fontSize="12px"
      px="8px"
      py={{ base: 0.5, lg: 1.5 }}
      mb={2}
      {...rest}
    >
      {name}
    </Badge>
  ) : null;
};

InfoBadge.propTypes = {
  type: PropTypes.oneOf([
    'exclusive',
    'limited',
    'punchcard',
    'live',
    'expired',
    'pending',
    'draft',
  ]),
};

export default InfoBadge;
