import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@chakra-ui/react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const InlineSupportLink = ({ label }) => {
  const { strings } = useI18Next();
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

  // TODO make a default prop for the Link component
  // so we don't have to go around adding it on every single usage.
  return (
    <Link
      fontSize='md'
      fontWeight='medium'
      textColor='blue.500'
      target="_blank"
      rel="noopener noreferrer"
      href={`mailto:${supportEmail}`}
    >
      { label || `${strings('ui.label.contact')} ${supportEmail}`}
    </Link>
  );
};

InlineSupportLink.propTypes = {
  label: PropTypes.string
};

export default InlineSupportLink;
