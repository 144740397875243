import { types } from './types';

const defaultState = {
  isPending: true,
  data: null,
  error: null,
};

export default (state = defaultState, action) => {
  const { type, payload, error } = action;
  const { data } = payload || {};

  switch (type) {
    case types.PLAN_REQUEST:
      return { ...state, data: null, isPending: true };
    case types.PLAN_SUCCESS:
      return { ...state, data, isPending: false };
    case types.PLAN_FAILURE:
      return { ...state, isPending: false, error };
    case types.PLAN_INVALIDATE:
      return { ...state, data: null };
    default:
      return state;
  }
};
