import _ from 'lodash';

const keys = {
  distance: 'distance',
  age: 'age',
  random: 'random',
  custom: 'custom'
};

const distanceScores = {
  distance: [
    [0, 1],
    [1000000, 0]
  ],
  age: [],
  businessAge: [],
  featured: [[0, 0], [1, 50]],
  limited: [],
  exclusive: [],
  random: []
};

const DAYS = 3600 * 24;

const ageScores = {
  distance: [
    [0, 1],
    [1000000, 0]
  ],
  age: [
    [0, 0],
    [0, 10], // don't boost future offers because they might not be visible/discoverable
    [DAYS, 10],
    [DAYS, 7],
    [3 * DAYS, 7],
    [3 * DAYS, 5],
    [7 * DAYS, 5],
    [7 * DAYS, 0]
  ],
  businessAge: [],
  featured: [[0, 0], [1, 50]],
  limited: [],
  exclusive: [],
  random: []
};

const randomScores = {
  distance: [],
  age: [],
  businessAge: [],
  featured: [],
  limited: [],
  exclusive: [],
  random: [[0, 0], [1, 1]]
};

const defaults = {
  [keys.distance]: distanceScores,
  [keys.age]: ageScores,
  [keys.random]: randomScores
};

const findScorePreset = (collectionScores) => {
  const scores = Object.assign({}, collectionScores);
  if (_.isEqual(scores, distanceScores)) return keys.distance;
  if (_.isEqual(scores, ageScores)) return keys.age;
  if (_.isEqual(scores, randomScores)) return keys.random;

  return keys.custom;
};

export { keys, defaults, findScorePreset };
