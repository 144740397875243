import 'cropperjs/dist/cropper.min.css';
import {
  ButtonGroup,
  Flex,
  Button,
  Spacer,
  Heading,
  Box
} from '@chakra-ui/react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import BigModal from '../../../BigModal/BigModal';
import PageBackButton from '../../../chakra/page/PageBackButton';
import ReactCropper from '../../../cropperjs/ReactCropper';
import Message from '../../collections/Message';
import Icon from '../../elements/Icon';
import {
  BACKGROUND_COLOR,
  BORDER,
  BORDER_COLOR,
  BORDER_RADIUS
} from '../../imageWell2/constants';
import {
  ERROR_CODES,
  getFileTypesForEncode,
  getSafeFileDimensions,
  getScaledDataUrlUnderSize
} from '../imageUtils';

import '../upload/ImageCropper.css';

class ImageCropModal extends Component {
  static propTypes = {
    src: PropTypes.string,
    onDone: PropTypes.func,
    fileTypeInfo: PropTypes.object,
    aspectRatio: PropTypes.number
  };

  state = {};

  clearError() {
    this.setState({ error: null });
  }

  async handleCrop() {
    const { fileTypeInfo } = this.props;
    const safeFileDimensions = getSafeFileDimensions(
      {
        box: this.cropper.getData(),
        image: this.cropper.getImageData()
      },
      fileTypeInfo.restrictions.dimensions
    );

    const mimeTypes = getFileTypesForEncode(fileTypeInfo.restrictions.types);

    this.setState({ cropping: true });

    let file;
    try {
      file = await getScaledDataUrlUnderSize(
        this.cropper,
        safeFileDimensions,
        fileTypeInfo.restrictions.maxSize,
        mimeTypes
      );
    } catch (error) {
      this.setState({ cropping: false, error });
    }

    this.setState({ cropping: false });

    this.props.onDone(file);
  }

  handleCancel() {
    this.props.onDone();
  }

  handleRotate(direction = 1) {
    // HACK: The sizing keeps centering around the cropbox
    // so we clear it before rotation, refit the image, and add it back
    this.cropper.clear();
    this.cropper.rotate(90 * direction);
    this.fitImage();
    this.cropper.crop();
    this.cropper.setCropBoxData(this.cropper.getCanvasData());
  }

  handleCropUpdate({ detail: { height, width } }) {
    const { fileTypeInfo } = this.props;
    const {
      restrictions: {
        dimensions: {
          maxWidth, minWidth, maxHeight, minHeight
        }
      }
    } = fileTypeInfo;

    // Allow a 1-pixel overlap to cover floating-point rounding
    const maxRatio = maxWidth / (minHeight - 1);
    const minRatio = minWidth / (maxHeight + 1);

    const ratio = width / height;

    this.setState({ isRatioLow: ratio < minRatio, isRatioHigh: ratio > maxRatio });
  }

  fitImage() {
    const cropper = this.cropper;
    const { width: containerWidth, height: containerHeight } = cropper.getContainerData();
    const containerAspect = containerWidth / containerHeight;
    const { naturalWidth: canvasWidth, naturalHeight: canvasHeight } = cropper.getCanvasData();
    const canvasAspect = canvasWidth / canvasHeight;

    const newZoom = (containerAspect > canvasAspect)
      ? containerHeight / canvasHeight
      : containerWidth / canvasWidth;

    this.cropper.zoomTo(newZoom);

    // cropper.setCropBoxData says you can set this but you can't
    this.cropper.cropper.options.minCropBoxWidth = this.props.fileTypeInfo.restrictions.dimensions.minWidth * newZoom;
    this.cropper.cropper.options.minCropBoxHeight = this.props.fileTypeInfo.restrictions.dimensions.minHeight * newZoom;

    const { width: newCanvasWidth, height: newCanvasHeight } = cropper.getCanvasData();

    this.cropper.moveTo(
      (containerWidth / 2) - (newCanvasWidth / 2),
      (containerHeight / 2) - (newCanvasHeight / 2)
    );
  }

  render() {
    const { src, fileTypeInfo, aspectRatio } = this.props;
    const {
      isRatioHigh, isRatioLow, cropping, error
    } = this.state;
    const isCropInvalid = isRatioHigh || isRatioLow;
    const fileSizeThreshold = `${Math.round((fileTypeInfo.restrictions.maxSize / 1024) * 100) / 100}kB`;
    return (
      <BigModal open>
        <BigModal.Contents>
          <BigModal.Header>
            <PageBackButton onClick={this.handleCancel.bind(this)}>Cancel</PageBackButton>
            <I18NextContext.Consumer>
              {({ strings }) => (
                <Flex w="100%" gap="1em" p="1em">
                  <Heading>Add Photo</Heading>
                  <Spacer />

                  <ButtonGroup>
                    <Button onClick={() => this.handleRotate(-1)}>
                      <Icon name="undo" />
                    </Button>
                    <Button onClick={() => this.handleRotate(1)}>
                      <Icon name="undo" flipped="horizontally" />
                    </Button>
                  </ButtonGroup>

                  <Button
                    colorScheme="blue"
                    onClick={this.handleCrop.bind(this)}
                    isDisabled={isRatioHigh || isRatioLow}
                    isLoading={cropping}
                  >
                    {strings('ui.component.imageCropModal.done')}
                  </Button>
                </Flex>
              )}
            </I18NextContext.Consumer>
          </BigModal.Header>
          <BigModal.Body>
            <Box
              className={`c-image-cropper__wrapper ${isCropInvalid ? 'invalid-crop' : ''}`}
              style={{ height: '100%' }}
              borderRadius={BORDER_RADIUS}
              border={BORDER}
              borderColor={BORDER_COLOR}
              bg={BACKGROUND_COLOR}
            >
              <ReactCropper
                className="c-image-cropper__body"
                src={src}
                autoCropArea={1}
                viewMode={1}
                zoomable={true}
                zoomOnTouch={false}
                zoomOnWheel={false}
                movable={true}
                responsive={true}
                background={false}
                ref={(el) => { this.cropper = el; }}
                minCanvasHeight={1}
                minCanvasWidth={1}
                crop={this.handleCropUpdate.bind(this)}
                aspectRatio={aspectRatio}
                ready={this.fitImage.bind(this)}
              />
            </Box>
          </BigModal.Body>

          <I18NextContext.Consumer>
            {({ strings }) => (
              <BigModal.Footer className="p1">
                <div className="mb1">
                  {isRatioHigh && (
                    <Message error>{strings('ui.component.imageCropModal.cropBoxTooWide')}</Message>
                  )}
                  {isRatioLow && (
                    <Message error>{strings('ui.component.imageCropModal.cropBoxTooTall')}</Message>
                  )}
                  {error && error.code === ERROR_CODES.FILE_SIZE_LIMIT_EXCEEDED &&
                    <Message error onDismiss={this.clearError.bind(this)}>
                      {`${strings('ui.component.imageCropModal.unableToReduceSize')} (${fileSizeThreshold})`}
                    </Message>
                  }
                </div>
              </BigModal.Footer>
            )}
          </I18NextContext.Consumer>
        </BigModal.Contents>
      </BigModal>
    );
  }
}

export default ImageCropModal;
