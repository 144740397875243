// React
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

/**
 * Field label and locale language label
 * @param {{label: String, optional: Boolean, children: Any, wrapLabel: Boolean, localeLabel: String}} props
 * @return field label and locale language label and children
 */
const LocalizableTextFieldLabel = ({
  label, optional, children, wrapLabel, locale, locales
}) => {
  const { strings, lookupExists } = useI18Next();

  // If the lookup cannot be found use the locale instead of the lookup string
  const localeLabel = lookupExists(`shared.languages.${locale}`) ? strings(`shared.languages.${locale}`) : locale;

  let extLabel = null;
  if (locales[0] !== locale) {
    extLabel = <mark>{localeLabel}</mark>; // highlight non-default locale
  }

  return (
    <Box as={wrapLabel ? '' : Flex} justify='center' align='flex-start' flexWrap='wrap'>
      <Text as='label' w='33%' minW='130px' fontWeight={600}>
        {label} {locale &&
          <Text as='span' color='gray.500'>{extLabel && '-'} {extLabel}</Text>
        }
        {optional && <small className='pull-right'>Optional</small>}
      </Text>
      <Box flexGrow={1} minW='67%' mt={2}>
        {children}
      </Box>
    </Box>
  );
};

LocalizableTextFieldLabel.propTypes = {
  label: PropTypes.string,
  optional: PropTypes.bool,
  children: PropTypes.any,
  wrapLabel: PropTypes.bool,
  locale: PropTypes.string,
  locales: PropTypes.array.isRequired
};

export default LocalizableTextFieldLabel;
