import { types } from './types';

const defaultState = {
  isLoggedIn: false,
  user: null,
  isPending: true,
  error: null,
};

export default (state = defaultState, action) => {
  const { type, user, error } = action;

  switch (type) {
    case types.AUTH_REFRESH_REQUEST:
      return { ...state, isLoggedIn: false, isPending: true };
    case types.AUTH_REFRESH_SUCCESS:
      return { ...state, isLoggedIn: true, isPending: false, user };
    case types.AUTH_REFRESH_FAILURE:
      return { ...state, isLoggedIn: false, isPending: false, error };
    case types.AUTH_LOG_OUT_REQUEST:
      return { ...state, isPending: true };
    case types.AUTH_LOG_OUT_SUCCESS:
      return { ...state, isLoggedIn: false, isPending: false, user: {} };
    case types.AUTH_LOG_OUT_FAILURE:
      return { ...state, isPending: false, error };
    default:
      return state;
  }
};
