import { combineReducers } from 'redux';
import auth from 'shared/src/modules/auth';

import property from './modules/property';
import businesses from './modules/businesses';
import plan from './modules/plan';

export default combineReducers({
  [auth.constants.NAME]: auth.reducer,
  [property.constants.NAME]: property.reducer,
  [businesses.constants.NAME]: businesses.reducer,
  [plan.constants.NAME]: plan.reducer,
});
