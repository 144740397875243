import React, { Component } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import TimeSeriesPlaceholder from "../../assets/stats/stats-empty-placeholder.jpg";


class TimeSeries extends Component {
  static propTypes = {
    data: PropTypes.array,
    height: PropTypes.number
  };

  state = {
    showReach: true,
    showResponse: true,
    showAction: true
  }

  handleClick(...args) {
    const dataKey = _.trim(args[0].dataKey);
    const { showReach, showResponse, showAction } = this.state;

    if (dataKey === 'reach') return this.setState({ showReach: !showReach });
    if (dataKey === 'response') return this.setState({ showResponse: !showResponse });
    if (dataKey === 'action') return this.setState({ showAction: !showAction });
    return null;
  }

  render() {
    const { data, height } = this.props;
    const { showReach, showResponse, showAction } = this.state;

    function formatDate(tickItem) {
      const date = new Date(tickItem);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    if (!data) {
      return (
        <div className='mt4'>
          <img style={{ height: 'auto', width: '100%' }} src={TimeSeriesPlaceholder} alt="TimeSeriesPlaceholder"/>
        </div>
      );
    }

    if (data) {
      return (
        <ResponsiveContainer width='100%' height={height}>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false}/>
            <XAxis
              type='number'
              domain={['dataMin', 'dataMax']}
              dataKey='timeStamp'
              tickFormatter={formatDate}
              interval='preserveStartEnd'
              tickLine={false}
              tickMargin={5}
            />
            <YAxis
              allowDecimals={false}
            />
            <Tooltip
              labelFormatter={formatDate}
              formatter={value => Math.round(value)}
            />
            <Legend onClick={this.handleClick.bind(this)}/>
            <Area
              type='linear'
              dataKey={showReach ? 'reach' : 'reach '}
              stackId="1"
              stroke='#24AAF9'
              fill='#24AAF9'
              fillOpacity={0.8}
              legendType={showReach ? 'circle' : 'line'}
            />
            <Area
              type='linear'
              dataKey={ showResponse ? 'response' : 'response ' }
              stackId="1"
              stroke='#FEB743'
              fill='#FEB743'
              fillOpacity={0.8}
              legendType={showResponse ? 'circle' : 'line'}
            />
            <Area
              type='linear'
              dataKey={ showAction ? 'action' : 'action ' }
              stackId="1"
              stroke='#F88869'
              fill='#F88869'
              fillOpacity={0.8}
              legendType={showAction ? 'circle' : 'line'}
            />
          </AreaChart>
        </ResponsiveContainer>
      );
    }
    return null;
  }
}

export default TimeSeries;
