export const NAME = 'auth';
export const types = {
  AUTH_LOG_IN_REQUEST: 'AUTH/LOG_IN_REQUEST',
  AUTH_LOG_IN_SUCCESS: 'AUTH/LOG_IN_SUCCESS',
  AUTH_LOG_IN_FAILURE: 'AUTH/LOG_IN_FAILURE',
  AUTH_LOG_OUT_REQUEST: 'AUTH/LOG_OUT_REQUEST',
  AUTH_LOG_OUT_SUCCESS: 'AUTH/LOG_OUT_SUCCESS',
  AUTH_LOG_OUT_FAILURE: 'AUTH/LOG_OUT_FAILURE',
  AUTH_REFRESH_REQUEST: 'AUTH/REFRESH_REQUEST',
  AUTH_REFRESH_SUCCESS: 'AUTH/REFRESH_SUCCESS',
  AUTH_REFRESH_FAILURE: 'AUTH/REFRESH_FAILURE',
};
