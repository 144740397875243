import React from 'react';
import { Box, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router';

import WaitFor from 'web-react-ui/src/data/WaitFor';
import RoleBadge from 'web-react-ui/src/chakra/UserList/RoleBadge';
import User, { Role } from 'web-react-ui/src/types/User.interface';
import Property from 'web-react-ui/src/types/Property.interface';
import Location from 'web-react-ui/src/types/Location.interface';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import UserProfileInfo from 'web-react-ui/src/chakra/users/UserProfileInfo';

import PropertyRoles from './PropertyRoles';
import ReferralContext from './ReferralContext';
import UserProfileHeader from './UserProfileHeader';
import BackButton from './BackButton';
import Business from '../../types/Business.interface';

interface Props {
  isCurrentUser: boolean;
  user?: User;
  roles?: Role[];
  platformRoles?: Role[];
  properties?: Property[];
  property?: Property;
  business?: Business;
  businessRoles?: Role[];
  locations?: Location[];
  locationRoles?: any[];
  operationError?: string;
  isDeleting: boolean;
  removeUser: () => void;
  onEdit: () => void;
  backUrl: string;
}

const UserProfileView = ({
  isCurrentUser,
  user,
  roles,
  platformRoles,
  properties,
  property,
  business,
  businessRoles,
  locations,
  locationRoles,
  operationError,
  isDeleting,
  removeUser,
  onEdit,
  backUrl,
}: Props): JSX.Element => {
  const { state }: { state: { query: string } } = useLocation();
  return (
    <Box maxW='7xl' mx='auto' px={[2, 0]}>
      <ErrorMessage error={operationError} />
      <WaitFor waitFor={user}>
        {user && (
          <>
            <BackButton baseUrl={`${backUrl}${state?.query || ''}`} />
            <UserProfileHeader
              user={user}
              isCurrentUser={isCurrentUser}
              isDeleting={isDeleting}
              removeUser={removeUser}
              onEdit={onEdit}
            />

            <UserProfileInfo user={user} />
            {property && !business && (
              <>
                <Heading fontSize='2xl' borderBottom='1px' borderColor='gray.200' pb={3}>Property Roles</Heading>
                <PropertyRoles
                  key={property.id}
                  propertyName={property.name}
                  propertyId={property.id}
                  userId={user.id}
                  roles={roles}
                  isSingleProperty={true}
                />
              </>
            )}
            {property && business && (
              <>
                <Heading fontSize='2xl' borderBottom='1px' borderColor='gray.200' pb={3}>Business &amp; Location Roles</Heading>
                <HStack mt={3} mb={8}>
                  {businessRoles?.map((item) => {
                    const thisRole = roles?.find(role => item.id === role.id);
                    return thisRole ? (
                      <RoleBadge key={thisRole.id} id={thisRole.id} label={thisRole.name} />
                    ) : null;
                  })}
                </HStack>
                {locationRoles?.map((entry: { id: string, items: Role[] }) => {
                  const thisLocation = locations?.find(location => location.id === entry.id);
                  if (!thisLocation || !entry.items.length) {
                    return <React.Fragment key={entry.id}></React.Fragment>;
                  }
                  return (
                    <Flex my={3} align='center' key={entry.id}>
                      <Text fontSize='xl' fontWeight={500} minW={200}>{thisLocation.name}</Text>
                      <HStack spacing={3}>
                        {entry.items?.map(role => (
                          <RoleBadge key={role.id} id={role.id} label={role.name} />
                        ))}
                      </HStack>
                    </Flex>
                  );
                })}
              </>
            )}
            {properties && (
              <>
                <Heading fontSize='2xl' borderBottom='1px' borderColor='gray.200' pb={3}>Roles</Heading>
                {!property && platformRoles && (
                  <Flex
                    flexWrap='wrap'
                    align='center'
                    borderBottom='1px'
                    borderColor='gray.200'
                    py={5}
                  >
                    <Heading fontSize='lg' minW='xs'>Platform Roles</Heading>
                    <HStack spacing={5} mt={[2, 0]}>
                      {platformRoles.map(item => <RoleBadge key={item.id} label={item.name} />)}
                    </HStack>
                  </Flex>
                )}
                {properties?.map((property: Property) => (
                  <PropertyRoles
                    key={property.id}
                    propertyName={property.name}
                    propertyId={property.id}
                    userId={user.id}
                    roles={roles}
                  />
                ))}
                <ReferralContext userId={user?.id} />
              </>
            )}
          </>
        )}
      </WaitFor>
    </Box>
  );
};

export default UserProfileView;
