import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { Box, FormLabel, HStack, Input } from '@chakra-ui/react';

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const randomDigit = () => Math.floor(Math.random() * 10);

const randomPin = () => `${randomDigit()}${randomDigit()}${randomDigit()}${randomDigit()}`;

const findPin = (initialPins, day, perDayOfWeek) => {
  if (!perDayOfWeek && day === DAYS[0]) return initialPins.find(p => !p.weekday && !p.skips);
  return initialPins.find(p => p.weekday === day && !p.skips);
};

const findPinOrDefault = (pins, day, perDayOfWeek, generatePins) => {
  // If there are no pins at all, generate some random ones
  // TODO: Ensure they're unique
  if (!pins.length) return randomPin();
  const match = findPin(pins, day, perDayOfWeek);
  // eslint-disable-next-line no-nested-ternary
  return match
    ? match.pin
    : generatePins
      ? randomPin()
      : '';
};

const initializeDays = (pins, perDayOfWeek, generatePins) => DAYS.reduce(
  (acc, day) => {
    acc[day] = findPinOrDefault(pins, day, perDayOfWeek, generatePins);
    return acc;
  },
  {}
);

const PinControl = ({
  value,
  generatePins,
  onChange,
  perDayOfWeek = false,
  skips = 0
}) => {
  // eslint-disable-next-line no-param-reassign
  value = value || [];
  const [pinState, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'UPDATE': {
          return Object.assign({}, state, { [action.day]: action.pin });
        }
        default:
      }
      return pinState;
    },
    undefined,
    () => initializeDays(value, perDayOfWeek, generatePins)
  );

  useEffect(
    () => {
      let newPins = [];
      if (perDayOfWeek) {
        DAYS.forEach(day => newPins.push({ pin: pinState[day], skips: 0, weekday: day }));
      } else {
        newPins.push({ pin: pinState.Sun, skips: 0 });
      }

      newPins = newPins.filter(p => p.pin);

      if (skips) {
        const multiPins = [];
        newPins.forEach((singlePin) => {
          for (let n = 0; n < skips; n += 1) {
            multiPins.push({
              pin: `${singlePin.pin}${n + 2}`,
              skips: n + 1,
              weekday: singlePin.weekday
            });
          }
        });
        newPins = newPins.concat(multiPins);
      }
      if (!_.isEqual(value, newPins)) onChange(newPins);
    },
    [pinState, perDayOfWeek, skips, onChange]
  );

  if (perDayOfWeek) {
    return (
      <HStack>
        {DAYS.map(day => (
          <Box key={day}>
            <FormLabel>{day}</FormLabel>
            <Input
              value={pinState[day]}
              onChange={e => dispatch({ type: 'UPDATE', day, pin: e.target.value })}
            />
          </Box>
        ))}
      </HStack>
    );
  }

  return (
    <Input
      value={pinState.Sun}
      onChange={e => dispatch({ type: 'UPDATE', day: 'Sun', pin: e.target.value })}
    />
  );
};

PinControl.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  perDayOfWeek: PropTypes.bool,
  skips: PropTypes.number,
  generatePins: PropTypes.bool
};

export default PinControl;
