import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import RadioButtonGroup from './RadioButtonGroup';

const RadioButtonGroupField = ({ name, validate, ...rest }) => {
  const { input } = useField(name, { validate });
  return (
    <RadioButtonGroup value={input.value} setValue={input.onChange} {...rest} />
  );
};

RadioButtonGroupField.propTypes = {
  name: PropTypes.string,
};

export default RadioButtonGroupField;
