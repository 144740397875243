import PropTypes from 'prop-types';
import React from 'react';
import ImageInfo from './ImageInfo2';
import withFileType from '../../imageWell/withFileType';

const FileTypeInfo = ({ fileTypeInfo }) => {
  if (!fileTypeInfo) return null;

  const { restrictions: { types, dimensions, maxSize } } = fileTypeInfo;
  return (
    <ImageInfo
      type={types}
      dimensions={[{ w: dimensions.minWidth, h: dimensions.minHeight }, {
        w: dimensions.maxWidth,
        h: dimensions.maxHeight
      }]}
      maxSize={maxSize}
    />
  );
};

FileTypeInfo.propTypes = {
  fileTypeInfo: PropTypes.object
};

export default withFileType(FileTypeInfo);
