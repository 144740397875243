import OffersListTable from 'web-react-ui/src/chakra/offers/OffersListTable';
import PropTypes from 'prop-types';
import React from 'react';

import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import {
  offerListViewParamsFactory,
  VIEW_TYPES
} from 'shared/src/helpers/offerListViewParams';

import Input from 'web-react-ui/src/components/elements/Input';
import View from 'web-react-ui/src/components/layout/View';
import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';

import client from '../../services/client';

const fetchOffers = ({ query, view, propertyId }) => {
  return client
    .properties.for(propertyId)
    .offers.list({
      query, ...offerListViewParamsFactory(view)
    });
};

const options = [
  { key: 1, text: 'New', value: VIEW_TYPES.new },
  { key: 2, text: 'Live', value: VIEW_TYPES.live },
  { key: 3, text: 'Expiring Soon', value: VIEW_TYPES.expiring },
  { key: 4, text: 'Expired', value: VIEW_TYPES.expired },
  { key: 5, text: 'Draft', value: VIEW_TYPES.disabled }
];

const buildHref = (offer) => {
  return `./businesses/${offer.businessId}/offers/${offer.id}`;
};
const OfferListView = ({ propertyId, relativeLink, basePath }) => {
  const [view, setView] = useQuerystringParam('view', VIEW_TYPES.live, { squash: true });
  const [query, debouncedQueryHandler] = useQueryDebounce(view, propertyId);

  const handleViewChange = (e) => {
    setView(e);
  };

  return (
    <View>
      <View.Section className="mt3 flex aic">
        <Input
          className="fg1 mr1"
          placeholder="Find offer..."
          fluid
          icon="search"
          defaultValue={query}
          onChange={e => debouncedQueryHandler(e.target.value)}
        />
        <Dropdown
          onChange={(e, { value }) => handleViewChange(value)}
          options={options}
          selection
          value={view}
        />
      </View.Section>
      <View.Section>
        <PagedList fetchList={fetchOffers} params={{ query, view, propertyId }}>
          {(offersList) => (
            <OffersListTable offersList={offersList} buildHref={buildHref} />
          )}
        </PagedList>
      </View.Section>
    </View>
  );
};

OfferListView.propTypes = {
  propertyId: PropTypes.string,
  basePath: PropTypes.string,
  relativeLink: PropTypes.func
};

export default OfferListView;
