import PropTypes from 'prop-types';
import React from 'react';

import usePromise from 'shared/src/hooks/usePromise';

import OfferScheduleInfo from 'web-react-ui/src/components/offers/OfferScheduleInfo';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../services/client';

const fetchSchedule = async (scheduleId) => {
  if (!scheduleId) return null;
  return client.schedules.for(scheduleId).details();
};

const CollectionSchedule = ({ scheduleId }) => {
  const [, loading, schedule] = usePromise(
    () => fetchSchedule(scheduleId),
    [scheduleId],
    null,
    true
  );

  return (
    <WaitFor waitFor={!loading}>
      <OfferScheduleInfo schedule={schedule} />
    </WaitFor>
  );
};

CollectionSchedule.propTypes = {
  scheduleId: PropTypes.string // UUID
};

export default CollectionSchedule;
