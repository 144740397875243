/* eslint max-len: 0 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Divider from 'web-react-ui/src/components/elements/Divider';
import Icon from 'web-react-ui/src/components/elements/Icon';
import View from 'web-react-ui/src/components/layout/View';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Button from 'web-react-ui/src/components/elements/Button';

import propertyModule from '../../../modules/property';
import client from '../../../services/client';
import PropertyShare from '../PropertyShare';
import PropertyOfferRedemption from '../PropertyOfferRedemption';
import { LANGUAGE_OPTIONS } from '../../../i18n';

import './PropertySettingsGeneral.css';

const listHmacs = ({ propertyId }) => client.properties.for(propertyId).hmacs.list();

class PropertySettingsGeneral extends Component {
  state = {
    propertyLocales: [],
    langPropertyObj: {}
  };

  componentDidMount() {
    const { property } = this.props;
    const languageOptions = [...LANGUAGE_OPTIONS];

    client
      .properties.for(property.id)
      .getSetting('locales')
      .then(({ value: propertyLocales }) => { // ['en', 'fr']
        const langPropertyObj = languageOptions.reduce((acc, lang) => ({
          ...acc,
          [lang.value]: lang
        }), {}); // ie. { en: 'en', fr: 'fr' }

        this.setState({
          propertyLocales,
          langPropertyObj
        });
      });
  }

  render() {
    const { property } = this.props;
    const { propertyLocales, langPropertyObj } = this.state;

    return (
      <View>
        <View.Section>
          <div className='mt3 mb4'>
            <h3>Audience Verification</h3>
            <label>Verification Key</label>
            <Request request={listHmacs} params={{ propertyId: property.id }}>
              {({ data, loaded }) => (
                <WaitFor waitFor={loaded}>
                  <div className='flex mt1'>
                    <div className='c-display__keys p1 bg--gray'>
                      {_.get(data, 'items.0.secret')}
                    </div>
                    <div className='pl1 ml1 c-general__configuration'>
                      <h6 className='mb0'>How To Configure</h6>
                      <a
                        href={process.env.REACT_APP_DOCS}
                        target='_blank'
                        rel="noopener noreferrer"
                      >
                        <div className='flex'>
                          <p className='mb0 small mr1'>View Documentation</p>
                          <Icon className='long arrow alternate right'/>
                        </div>
                      </a>
                    </div>
                  </div>
                </WaitFor>
              )}
            </Request>
          </div>
          <Divider/>
        </View.Section>
        <View.Section>
          <div className='mb4'>
            <h3>Offer Syndication</h3>
            <p>The offers in this list are submitted by businesses on other applications wanting to include their offers into your application.</p>
            <h6>Review and approve available offers</h6>
            <Button
              primary
              as={Link}
              to={`/${property.id}/property-settings/syndication`}
            >REVIEW NOW
            </Button>
          </div>
          <Divider/>
        </View.Section>
        <View.Section>
          <div className='mb4'>
            <h3>Languages</h3>
            <WaitFor waitFor={propertyLocales && propertyLocales.length}>
              <h6>
                {propertyLocales.map((locale, index) => {
                  if (langPropertyObj[locale]) {
                    return (
                      <span key={locale.text}>
                        {langPropertyObj[locale].text}
                        {propertyLocales.length !== index + 1 && `,\u00A0`}
                      </span>
                    );
                  }
                  return null;
                })}
              </h6>
            </WaitFor>
          </div>
          <Divider/>
        </View.Section>
        <PropertyOfferRedemption propertyId={property.id} />
        <PropertyShare propertyId={property.id}/>
      </View>
    );
  }
}

PropertySettingsGeneral.propTypes = {
  property: PropTypes.object
};

export { PropertySettingsGeneral as Presenter };

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state)
});

export default connect(mapState)(PropertySettingsGeneral);
