import { get as _get } from 'lodash';
import { useForm } from 'web-react-ui/src/reactFinalForm';

const useFormReducer = (reducer) => {
  const form = useForm();
  const values = form.getState().values;
  const getValue = key => _get(values, key);
  form.batch(() => reducer(values, form, getValue));
};

export default useFormReducer;
