import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  SkeletonCircle,
  SkeletonText,
  Table,
  Text,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import Offer from '../../types/Offer.interface';
import OffersListItem from './OffersListItem';
import MobileOffersList from './MobileOffersList';

const LoadingRow = ({ hideBusiness }: { hideBusiness: boolean }) => {
  return (
    <Tr h="70px">
      <Td>
        <Flex align="center" w="100%">
          <SkeletonCircle h="36px" w="48px" />
          <SkeletonText w="100%" noOfLines={1} ml={4} />
        </Flex>
      </Td>
      {!hideBusiness && (
        <Td><SkeletonText w="100%" noOfLines={2} /></Td>
      )}
      <Td><SkeletonCircle h="16px" w="16px" /></Td>
      <Td><SkeletonText w="100%" noOfLines={2} /></Td>
      <Td><SkeletonText w="100%" noOfLines={2} /></Td>
    </Tr>
  );
};

type OffersListType = {
  loading: boolean;
  settled: boolean;
  error: Error;
  empty: boolean;
  items: Array<Offer>
}

type OffersListTableProps = {
  offersList: OffersListType;
  noOffersString: string;
  buildHref: (offer: Offer) => string;
  hideBusiness: boolean;
  maxBodyHeight: string | undefined;
}

const OffersListTable = ({
  offersList,
  noOffersString,
  buildHref,
  hideBusiness,
  maxBodyHeight,
}: OffersListTableProps) => {
  const { strings } = useI18Next();
  const isSmallScreen = useBreakpointValue({ base: true, lg: false });

  if (offersList?.error) {
    return (
      <Box p={4}>
        <ErrorMessage error={offersList.error} />
      </Box>
    );
  }

  if ((offersList?.settled) && offersList?.empty) {
    return (
      <Flex align="center" justify="center" minH="100px">
        <Text color="gray.400">
          {noOffersString}
        </Text>
      </Flex>
    );
  }

  if (isSmallScreen) {
    return (
      <Box>
        <MobileOffersList offers={offersList?.items} isLoading={offersList?.loading} buildHref={buildHref} />
      </Box>
    );
  }

  // TODO: Move Thead sticky styles to theme
  return (
    <Box maxHeight={maxBodyHeight} overflowY="auto">
      <Table variant="simple" size="lg">
        <Thead
          sx={{
            '&': {
              position: 'sticky',
              top: 0,
              zIndex: 2,
            },
          }}
        >
          <Tr>
            <Th fontSize="md">{strings('owners.widget.offers.offerListing')}</Th>
            {!hideBusiness && (
              <Th fontSize="md">{strings('owners.widget.offers.business')}</Th>
            )}
            <Th fontSize="md">{strings('owners.widget.offers.priority')}</Th>
            <Th fontSize="md">{strings('owners.widget.offers.typePerformance')}</Th>
            <Th fontSize="md" textAlign="right">{strings('owners.widget.offers.status')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {offersList?.loading ? (
            Array(3).fill(null).map((item, index) => (
              <LoadingRow key={`loading-${index}`} hideBusiness={hideBusiness} />
            ))
          ) : offersList.items?.map((item, index) => (
            <OffersListItem
              offer={item}
              key={item.id}
              buildHref={buildHref}
              hideBusiness={hideBusiness}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default OffersListTable;
