import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Field,
  useForm
} from 'react-final-form';
import { Select } from '@chakra-ui/react';
import SuiForm from '../../components/collections/Form';
import FieldError from './FieldError';
import FieldLabel from './FieldLabel';


const SelectField = ({
  name, label, placeholder, values, options, validate, format, parse,
  wrapLabel, loading, search, autoSelectFirst, filter, info, disabled, dataTestId,
  chakra
}) => {
  const fieldName = name;

  // eslint-disable-next-line no-param-reassign
  if (!options) options = values.map(v => ({ value: v, text: v }));

  // eslint-disable-next-line no-param-reassign
  if (filter) options = options.filter(filter);

  const form = useForm();
  if (autoSelectFirst && options && options.length) {
    const { values: formValues } = form.getState();
    if (!_get(formValues, name)) {
      form.change(name, options[0].value);
    }
  }

  const FieldCmp = chakra ? React.Fragment : SuiForm.Field;
  const SelectCmp = chakra ? Select : SuiForm.Select;

  return (
    <Field name={fieldName} validate={validate} format={format} parse={parse} info={info}>
      {({ input }) => {
        const onChange = (event, { value }) => {
          input.onChange(value);
        };

        if (chakra) {
          return (
            <FieldLabel label={label} wrapLabel={wrapLabel}>
              <Select {...input} disabled={disabled}>
                {options.map(o => (
                  <option onChange={onChange} key={o.text} value={o.value}>
                    {o.text}
                  </option>
                ))}
              </Select>
              <FieldError name={fieldName} />
              {info && <p className='text--muted'>{info}</p>}
            </FieldLabel>
          )
        }

        return (
          <FieldCmp>
            <FieldLabel label={label} wrapLabel={wrapLabel}>
              <SelectCmp
                data-testid={dataTestId}
                search={search}
                options={options}
                loading={loading}
                placeholder={placeholder}
                {...input}
                onChange={onChange}
                disabled={disabled}
              />
              <FieldError name={fieldName} />
              {info && <p className='text--muted'>{info}</p>}
            </FieldLabel>
          </FieldCmp>
        );
      }}
    </Field>
  );
};
SelectField.propTypes = {
  name: PropTypes.string,
  values: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  wrapLabel: PropTypes.bool,
  loading: PropTypes.bool,
  search: PropTypes.bool,
  autoSelectFirst: PropTypes.bool,
  filter: PropTypes.func,
  info: PropTypes.string,
  disabled: PropTypes.bool,
  datatestId: PropTypes.string,
  chakra: PropTypes.string,
};

export default SelectField;
