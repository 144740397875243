import {
  Box,
  Flex,
  FormLabel,
  Icon,
  Tooltip
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useField } from 'react-final-form';
import { FaInfoCircle } from 'react-icons/fa';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import FormReducer from '../../../reactFinalForm/FormReducer';
import SimpleSelectField from '../../../reactFinalForm/fields/SimpleSelectField';
import OFFER_OPTIONS from '../offerOptions';
import LEGACY_LABELS from '../legacyRedeemLabels';

const PunchCardRedemptionActionLabel = ({ name, ...boxProps }) => {
  const { strings } = useI18Next();
  const actionLabelFormName = `${name}.punchTransitionButtonText`;
  const actionButtonLabel = strings('dashboard.component.editOfferForm.field.label.earnPunchActionButtonLabel');

  const actionLabelGroup = [
    { text: strings(OFFER_OPTIONS['{{earn_punch}}'].text), value: '{{earn_punch}}' },
    { text: strings(OFFER_OPTIONS['{{check_in}}'].text), value: '{{check_in}}' },
    { text: strings(OFFER_OPTIONS['{{buy_now}}'].text), value: '{{buy_now}}' }
  ];

  const { input } = useField(actionLabelFormName);

  useEffect(() => {
    if (input.value) return;
    input.onChange(actionLabelGroup[0].value);
  }, []);

  return (
    <Box {...boxProps}>
      <FormReducer>
        {(values, form) => {
          const transitionLabel = values.flow.vars.punchTransitionButtonText;
          if (LEGACY_LABELS[transitionLabel]) {
            form.change(`${name}.punchTransitionButtonText`, LEGACY_LABELS[transitionLabel]);
          } else if (!transitionLabel) {
            form.change(`${name}.punchTransitionButtonText`, '{{earn_punch}}');
          }

          const label = values.flow.vars.label;
          if (LEGACY_LABELS[label]) {
            form.change(`${name}.label`, LEGACY_LABELS[label]);
          } else if (!label || label === '{{use_now}}') {
            // At first we had 'Use Now' as an option for Punchcards
            // But somebody decided Punchcards shouldn't say that - should say 'Use Reward'
            form.change(`${name}.label`, '{{use_reward}}');
          }
        }}
      </FormReducer>
      <FormLabel>
        <Flex align='center'>
          {actionButtonLabel}
          <Tooltip label={actionButtonLabel} placement='top' hasArrow>
            <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
          </Tooltip>
        </Flex>
      </FormLabel>
      <SimpleSelectField
        name={actionLabelFormName}
        options={actionLabelGroup}
        wrapLabel
        chakra
      />
    </Box>
  );
};

PunchCardRedemptionActionLabel.propTypes = {
  name: PropTypes.string
};

export default PunchCardRedemptionActionLabel;
