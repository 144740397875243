import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import Property from 'web-react-ui/src/types/Property.interface';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';

import {
  List,
  Item,
  Empty,
  Loading,
} from 'web-react-ui/src/chakra/List';
import { UseRequestResult } from 'shared/src/types/UseRequestResult.interface';

interface ListItemProps {
  property: Property;
  baseUrl: string;
}

const PropertyListItem = ({ property, baseUrl }: ListItemProps): JSX.Element => {
  const { name, status, profileImage, profileImageUrl } = property || {};

  return (
    <Item
      as={Link}
      to={`${baseUrl}/${property.id}`}
      justify="space-between"
      align="center"
    >
      <Flex align="center">
        <Box mr={4}>
          <ProfileImage
            imageId={profileImage}
            imageUrl={profileImageUrl}
            name={name}
            status={status}
          />
        </Box>
        <Text
          fontWeight={400}
          fontSize="lg"
          color="black"
        >
          {name}
        </Text>
      </Flex>
      <ArrowForwardIcon w={5} color="gray.500" ml="auto" />
    </Item>
  );
};

interface Props {
  properties: UseRequestResult<PagedList<Property>>;
  baseUrl: string;
}

const PropertySelector = ({ properties, baseUrl }: Props): JSX.Element => {
  if (properties.error) return <ErrorMessage chakra error={properties.error} />;

  if (properties.settled && !properties?.result?.items?.length) {
    return <Empty isEmpty={true}>No Properties Found</Empty>;
  }

  return (
    <div>
      <List mt={4}>
        <Loading isLoading={properties.loading} />
        {properties?.result?.items?.map?.((p: Property) => (
          <PropertyListItem baseUrl={baseUrl} property={p} key={p.id} />
        ))}
      </List>
    </div>
  );
};

export default PropertySelector;
