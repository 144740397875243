import {
  getBase64StringFromBlob,
  getImageFileUrlDimensions,
} from '../../imageWell/imageUtils';

const uploadImage = async (client, file, fileType, propertyId, businessId, locationId, keywords) => {
  const imageFileUrl = await getBase64StringFromBlob(file);
  const dimensions = await getImageFileUrlDimensions(imageFileUrl);

  // await new Promise(res => setTimeout(res, 2000));
  const imageDetails = await client.images.create(
    file,
    fileType,
    file.type,
    dimensions.width,
    dimensions.height,
    file.size,
  );

  let context = client.properties.for(propertyId);
  if (businessId) context = context.businesses.for(businessId);
  if (locationId) context = context.locations.for(locationId);
  try {
    await context.images.link(imageDetails.id, { keywords });
  } catch (err) {
    // If we couldn't add it to the library, we still want to be able to use the image
  }

  return imageDetails.id;
};

export default uploadImage;
