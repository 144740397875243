import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Stack, VStack } from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { FormSpy } from '../../reactFinalForm';
import ErrorMessage from '../entities/ErrorMessage';
import LocalizedOfferPreview from '../preview/LocalizedOfferPreview';
import Advanced from './Advanced';
import OfferInfo from './OfferInfo';
import OfferLocationsSection from './OfferLocationsSection';
import OfferScheduleFields from './OfferScheduleFields';
import PrioritySelectField from '../../reactFinalForm/fields/PrioritySelectField';
import ExclusiveRedemption from './ExclusiveRedemption';

function ExclusiveOffer({
  error, dashboard, isPriorityDisabled, plansHref, plan, hasPerformanceError
}) {
  const { strings } = useI18Next();

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing={5} w='100%' p={5} align='flex-start'>
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => {
          const flags = values && values.flags;
          if (flags.includes('limited')) {
            form.change('flags', flags.filter(flag => flag !== 'limited'));
          }
          if (flags.includes('punchcard')) {
            form.change('flags', flags.filter(flag => flag !== 'punchcard'));
          }
          if (!flags.includes('exclusive')) {
            form.change('flags', flags.concat('exclusive'));
          }
          form.change('flow.vars.isLimited', false);
          form.change('flow.type', 'default');
          return null;
        }}
      </FormSpy>
      <ErrorMessage error={error} />
      <VStack spacing={10} flexGrow={1} w='100%'>
        <Box w='100%'>
          <OfferInfo dashboard={dashboard} hasPerformanceError={hasPerformanceError} />
        </Box>

        <Box w='100%'>
          <ExclusiveRedemption name='flow.vars' />
        </Box>

        <Box w='100%'>
          <Heading color='gray.700' mb={10} pb={3} borderBottomWidth='1px' borderColor='gray.300'>
            {strings('ui.component.exclusiveOffer.schedule')}
          </Heading>
          <OfferScheduleFields
            name='schedules[0]'
            label='Schedules'
            dashboard={dashboard}
            plan={plan}
            plansHref={plansHref}
          />
        </Box>

        <Box w='100%'>
          <FormSpy subscription={{ values: true }}>
            {({ values: { propertyId, businessId } }) => (
              <OfferLocationsSection propertyId={propertyId} businessId={businessId} dashboard={dashboard} />
            )}
          </FormSpy>

          {!dashboard && (
            <Advanced />
          )}
        </Box>

        <Box w='100%'>
          <Heading color='gray.700' mb={10} pb={3} borderBottomWidth='1px' borderColor='gray.300'>
            {strings('dashboard.component.editOfferForm.field.label.display')}
          </Heading>
          <PrioritySelectField disabled={isPriorityDisabled} plansHref={plansHref} />
        </Box>
      </VStack>
      <ErrorMessage error={error} />
      <Box minW='377px'>
        <div style={{ pointerEvents: 'none' }}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <LocalizedOfferPreview
                offer={values}
                image={values.heroImages?.[0]}
              />
            )}
          </FormSpy>
        </div>
      </Box>
    </Stack>
  );
}

ExclusiveOffer.propTypes = {
  error: PropTypes.object,
  dashboard: PropTypes.bool,
  isPriorityDisabled: PropTypes.bool,
  plan: PropTypes.object,
  plansHref: PropTypes.string,
  hasPerformanceError: PropTypes.bool
};

export default ExclusiveOffer;
