import Downshift from 'downshift';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TagChip from './TagChip';

const stateReducer = (state, changes) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: true
      };

    case Downshift.stateChangeTypes.blurButton:
    case Downshift.stateChangeTypes.mouseUp:
      if (state.inputValue && state.inputValue !== changes.inputValue) {
        return {
          ...changes,
          inputValue: state.inputValue
        };
      }
      /* eslint-disable-line no-fallthrough */

    default:
      return changes;
  }
};

const normalizeTag = (str) => {
  if (!str && str.toLowerCase) return str;

  return str
    .toLowerCase()
    .split(' ')
    .map(token => token.replace(/[^a-zA-Z0-9-]/g, ''))
    .filter(token => !!token)
    .join('-');
};


class TagInput extends Component {
  state = {
    inputValue: ''
  };

  addElement(element) {
    const { value, onChange } = this.props;
    if (!element) return false;
    const tagName = normalizeTag(element);
    if (value && value.includes(tagName)) return false;
    this.setState({ inputValue: '' });
    return onChange((value || []).concat(tagName));
  }

  removeElement(element) {
    const { value, onChange } = this.props;
    onChange(value.filter(v => v !== element));
  }

  removeLastElement() {
    const { value, onChange } = this.props;
    const lastElement = value[value.length - 1];
    this.setState({ inputValue: lastElement });
    onChange(value.slice(0, -1));
  }

  handleBlur(event, inputValue) {
    const { onBlur } = this.props;
    if (inputValue) this.addElement(inputValue);
    if (onBlur) onBlur(event);
  }

  handleInputValueChange(value, state) {
    if (!value && state.type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem) return;
    this.setState({ inputValue: value });
  }

  render() {
    const {
      value, placeholder, onFocus, className
    } = this.props;
    const { inputValue } = this.state;
    const keyCodes = ['Enter', 'Tab', ','];
    return (
      <Downshift
        selectedItem={value}
        stateReducer={stateReducer}
        itemToString={() => ''}
        inputValue={inputValue || ''} // '' to ensure it's always "controlled"
        onInputValueChange={this.handleInputValueChange.bind(this)}
      >
        {({
          selectedItem,
          getInputProps
        }) => (
          <div className={`ui fluid search selection dropdown icon ${className || ''}`} style={{ padding: '0.25em' }}>
            <div className='flex'>
              <div className='flex fww aic fg1 ui input'>
                {selectedItem && selectedItem.map(item => (
                  <TagChip key={item} tag={item} onDelete={() => this.removeElement(item)}/>
                ))}
                <input
                  style={{
                    minWith: '1em',
                    alignSelf: 'stretch',
                    border: 'none',
                    padding: '0.25em',
                    width: 'auto'
                  }}
                  placeholder={placeholder}
                  {...getInputProps({
                    autoComplete: 'nope', // 'nope' because browsers ignore autocomplete="off"
                    onKeyDown: (event) => {
                      if (keyCodes.includes(event.key)) {
                        if (inputValue) {
                          this.addElement(inputValue);
                          event.preventDefault(); // Prevent form submit when adding a tag
                        }
                      } else if (event.key === 'Backspace' && inputValue === '') {
                        this.removeLastElement();
                        event.preventDefault(); // Prevent deleting last character
                      }
                    },
                    onFocus,
                    onBlur: event => this.handleBlur(event, inputValue)
                  })}
                />
              </div>
            </div>
          </div>
        )}
      </Downshift>
    );
  }
}


TagInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

export default TagInput;
