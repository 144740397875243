import React, { useContext, useEffect, useState } from 'react';
import { Flex, Heading, HStack } from '@chakra-ui/react';
import usePermissions from 'shared/src/hooks/usePermissions';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import RoleBadge from 'web-react-ui/src/chakra/UserList/RoleBadge';
import Business from 'web-react-ui/src/types/Business.interface';
import Location from 'web-react-ui/src/types/Location.interface';
import LocationRoles from './LocationRoles';
import { Role } from '../../types/User.interface';

interface Props {
  propertyId: string,
  businessId: string,
  business: any,
  roleIds: string[],
  roles?: Role[],
}

const BusinessRoles = ({
  propertyId,
  businessId,
  business,
  roleIds,
  roles,
}: Props): JSX.Element => {
  const client = useContext(ClientContext);
  const getBusiness = () => client.properties.for(propertyId).businesses.for(businessId).detailsForAdmin();
  const getBusinessLocations = () => client.businesses.for(businessId).locations.listAdmin();

  const hasPermissions = usePermissions(
    // @ts-ignore
    { propertyId, businessId },
    ['property.business.search']
  );

  const [businessDetails, setBusinessDetails] = useState<Business>();
  const [businessLocations, setBusinessLocations] = useState<Location[]>();

  useEffect(() => {
    if (!businessDetails && hasPermissions) {
      getBusiness().then((res: Business) => setBusinessDetails(res));
    }
  }, [propertyId, businessId, hasPermissions]);

  useEffect(() => {
    if (!businessLocations) {
      getBusinessLocations().then((res: { items: Location[] }) => setBusinessLocations(res.items));
    }
  }, [propertyId, businessId]);

  if (!businessDetails) {
    return <></>;
  }

  return (
    <>
      <Flex key={businessId} flexDir='column' borderBottom='1px' borderColor='gray.200' py={5}>
        <Flex flexWrap='wrap' align='center' ml={10}>
          <Heading fontSize='lg' minW='xs' mr={5}>{businessDetails?.name}</Heading>
          <HStack mt={[2, 0]}>
            {roleIds.map((item) => {
              const thisRole = roles?.find(role => item === role.id);
              return thisRole ? (
                <RoleBadge key={thisRole.id} id={thisRole.id} label={thisRole.name} />
              ) : null;
            })}
          </HStack>
        </Flex>
        {Object.entries(business.locations).map(([locationId, location]: [locationId: string, location: any]) => (
          <LocationRoles
            key={locationId}
            businessLocation={businessLocations?.find(i => i.id === locationId)}
            locationId={locationId}
            roleIds={location.roleIds}
            roles={roles}
          />
        ))}
      </Flex>
    </>

  );
};

export default BusinessRoles;
