export default {
  addressNotComplete: 'addressNotComplete',
  dateFormat: 'dateFormat',
  dateMustBeValidDate: 'dateMustBeValidDate',
  min: 'min',
  max: 'max',
  mustbeAnInteger: 'mustbeAnInteger',
  mustMatchPattern: 'mustMatchPattern',
  required: 'required',
  tagRequirement: 'tagRequirement',
  tooLong: 'tooLong',
  tooShort: 'tooShort',
  tooHigh: 'tooHigh',
  tooLow: 'tooLow',
  phoneMustHaveAreaCode: 'phoneMustHaveAreaCode',
  postalCodeRequirement: 'postalCodeRequirement',
  regionNotComplete: 'regionNotComplete',
  missingFieldsInLanguage: 'missingFieldsInLanguage',
  invalidURL: 'invalidURL',
  noWhiteSpace: 'noWhiteSpace',
  hexColor: 'hexColor'
};
