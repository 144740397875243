import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  AvatarBadge,
  Flex,
  LinkBox,
  LinkOverlay,
  SkeletonCircle,
  SkeletonText
} from '@chakra-ui/react';
import List from 'web-react-ui/src/chakra/List';
import OfferPriority from 'web-react-ui/src/components/offers/OfferPriority';
import fr from 'date-fns/locale/fr';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import iconizeImageUrl from '../../utils/iconizeImageUrl';
import InfoBadge from './cells/InfoBadge';
import getDateRangeStr from './utils/getDateRangeStr';
import getOfferType from './utils/getOfferType';

const renderScheduleDate = ({ discoverableSchedules = [], usableSchedules = [] }, i18nLang, strings) => {
  const discoverableSchedule = discoverableSchedules[0];
  const usableSchedule = usableSchedules[0];
  // TODO: This will need to updated to a lookup as more supported locales are added
  const dateLocale = i18nLang === 'fr' ? { locale: fr } : null;
  const scheduleToUse = discoverableSchedule || usableSchedule || null;
  return scheduleToUse ? (
    getDateRangeStr(scheduleToUse, dateLocale, strings)
  ) : (
    strings('ui.component.offerListItem.noSchedule')
  );
};

const MobileOffersList = ({ offers, isLoading, buildHref }) => {
  const history = useHistory();
  const { strings, default: { language: i18nLang } } = useI18Next();
  if (isLoading) {
    return (
      <Flex py="12px" px="24px" w="100%">
        <Flex mr="16px">
          <SkeletonCircle h="32px" w="32px" />
        </Flex>
        <Flex w="100%">
          <SkeletonText noOfLines={4} spacing={3} h="50px" w="100%" />
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      {offers?.map((item) => {
        const locationString = item.locations.length > 1 ?
          `${item.locations.length} locations` :
          item.locations[0].name;

        return (
          <LinkBox
            as={List.Item}
            key={item.id}
          >
            <Avatar src={iconizeImageUrl(item.heroImageUrl)} alt="image" mr="16px">
              <AvatarBadge boxSize="1.25em" bg={item.status === 'live' ? 'green.500' : 'red.500'} />
            </Avatar>
            <Flex flexGrow={1}>
              <Flex flexDir="column" w="100%">
                <Flex justify="space-between" mb="4px">
                  <InfoBadge type={getOfferType(item)} />

                  <OfferPriority priority={item.priority} />
                </Flex>
                <LinkOverlay href={buildHref(item)}>
                  <List.Header>{item.headline}</List.Header>
                </LinkOverlay>
                <List.Body>{item.subHeadline}</List.Body>
                <List.Footnote>
                  {item.business.name} • {locationString} • {renderScheduleDate(item, i18nLang, strings)}
                </List.Footnote>
              </Flex>
            </Flex>
          </LinkBox>
        );
      })}
    </>
  );
};

MobileOffersList.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })),
    heroImageUrl: PropTypes.string,
    status: PropTypes.string,
    priority: PropTypes.number,
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    business: PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  })),
  isLoading: PropTypes.bool
};

export default MobileOffersList;
