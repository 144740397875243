import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import useValidationOptions from 'shared/src/hooks/useValidationOptions';

const OfferRedeemValidationInfo = ({ offerFlow }) => {
  const { strings } = useI18Next();
  const options = useValidationOptions();
  const offerType = offerFlow.type;
  const validationKey = offerType === 'punchcard' ? 'punchValidation' : 'validation';
  const validation = options.find(option => option.key === offerFlow.vars[validationKey]);

  return (
    <div>
      <div className='mt2 mb2'>
        <h6>{strings('ui.component.offerRedeemLimitsInfo.validation')}</h6>
      </div>
      <p>{validation && validation.text}</p>
    </div>
  );
};

OfferRedeemValidationInfo.propTypes = {
  offerFlow: PropTypes.object
};

export default OfferRedeemValidationInfo;
