import {
  Button,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';

const LoadMore = ({ pagedList }: { pagedList: any }) => {
  if (!pagedList) return null;
  if (!pagedList.next) return null;
  if (pagedList.loading) return null;
  return (
    <>
      <Spacer flexBasis="100%" />
      <Button
        color="blue.600"
        variant="ghost"
        onClick={pagedList.next}
        m="auto"
      >
        Load More
      </Button>
    </>
  );
};

export default LoadMore;
