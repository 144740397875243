import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Heading,
  Stack
} from '@chakra-ui/react';
import { useField } from 'react-final-form';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { FormSpy } from '../../reactFinalForm';
import LocalizableTextArea from '../../reactFinalForm/fields/localizableField/LocalizableTextArea';
import LocalizableTextField from '../../reactFinalForm/fields/localizableField/LocalizableTextField';
import {
  composeForLocale,
  forAllLocales,
  forAtLeastOneLocale,
  max,
  required
} from '../../reactFinalForm/validators';

import ImageCarouselField from '../image/ImageCarouselField';
import { PerformanceCheckAlert } from './PerformanceCheckAlert';

function OfferInfo({ dashboard, hasPerformanceError }) {
  const { strings } = useI18Next();
  return (
    <Stack direction="column" spacing={5}>
      <Heading color="gray.700" pb={3} borderBottomWidth="1px" borderColor="gray.300">Content</Heading>
      <PerformanceCheckAlert hasPerformanceError={hasPerformanceError} />
      <Box>
        <LocalizableTextField
          name="headlineLocalized"
          label={strings('ui.component.offerInfo.field.label.headline')}
          wrapLabel
          inputProps={{
            isInvalid: hasPerformanceError,
            errorBorderColor: 'orange.400'
          }}
          hasPerformanceError={hasPerformanceError}
          validateLocale={composeForLocale(forAllLocales(max(32)), forAtLeastOneLocale(required))}
          placeholder={strings('ui.component.offerInfo.field.info.headline')}
          chakra
        />
      </Box>
      <Box>
        <LocalizableTextField
          name="subHeadlineLocalized"
          label={strings('ui.component.offerInfo.field.label.subHeadline')}
          wrapLabel
          inputProps={{
            isInvalid: hasPerformanceError,
            errorBorderColor: 'orange.400'
          }}
          validateLocale={composeForLocale(forAllLocales(max(64)), forAtLeastOneLocale(required))}
          placeholder={strings('ui.component.offerInfo.field.info.subHeadline')}
          chakra
        />
      </Box>
      <Box>
        <LocalizableTextArea
          name="detailsLocalized"
          label={strings('ui.component.offerInfo.field.label.details')}
          wrapLabel
          validateLocale={composeForLocale(forAllLocales(max(400)), forAtLeastOneLocale(required))}
          placeholder="Add all offer details"
          chakra
        />
      </Box>
      <Box>
        <FormSpy subscription={{ values: true }}>
          {({ values: { propertyId, businessId } }) => (
            <>
              <ImageCarouselField
                name="heroImages"
                fileType="offer-hero-standard"
                label={strings('ui.component.offerDetails.field.label.offerImage')}
                validate={required}
                height={250}
                propertyId={propertyId}
                businessId={businessId}
                dashboard={dashboard}
                backgroundColor="gray.200"
                showImageGuidelines
              />
            </>
          )}
        </FormSpy>
      </Box>
    </Stack>
  );
}

OfferInfo.propTypes = {
  dashboard: PropTypes.bool,
  hasPerformanceError: PropTypes.bool,
};

export default OfferInfo;
