/* eslint object-curly-newline: 0, class-methods-use-this: 0, arrow-body-style: 0 react/prop-types: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import auth from 'shared/src/modules/auth';

class PrivateRoute extends Component {
  redirectToLogin() {
    const redirectTo = this.props.location.pathname;
    const redirectPath = `/login?redirect_to=${redirectTo}`;
    this.props.history.push(redirectPath);
  }

  render() {
    const { component: RouteComponent, render, isAuthenticated, isPending, location, history, ...rest } = this.props;
    return (
      <Route
        {...rest}
        location={location}
        history={history}
        render={
          (props) => {
            if (isPending) return <span>Loading...</span>;
            if (isAuthenticated) return render ? render(props) : <RouteComponent {...props} />;

            this.redirectToLogin();
            return null;
          }
        }
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  isPending: PropTypes.bool,
  location: PropTypes.any,
  render: PropTypes.func
};

const mapStateToProps = state => ({
  isAuthenticated: auth.selectors.getIsLoggedIn(state),
  isPending: auth.selectors.isPending(state)
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
