import { useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useQuerystringParam } from "./useQuerystringParam";

const useQueryDebounce = (...dependencies) => {
  const [query, setQuery] = useQuerystringParam('query', '', { squash: true });

  const handleQueryChange = value => setQuery(value);

  const debouncedQueryHandler = useMemo(() =>
    debounce(handleQueryChange, 750)
  , [query, ...dependencies]);

  useEffect(() => {
    // Cleanup Function - Stop the invocation of the debounced function
    return () => {
      // lodash.debounce provides debouncedCallback.cancel() to cancel any scheduled calls.
      debouncedQueryHandler.cancel();
    };
  }, []);

  return [query, debouncedQueryHandler];
};

export default useQueryDebounce;
