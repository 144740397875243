import {
  Flex,
  Spacer,
  Text
} from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const Pagination = ({
  isLoading,
  handlePrevBtn,
  handleNextBtn,
  page
}) => {
  const { strings } = useI18Next();
  const isDisabledNext = isLoading || !handleNextBtn || page?.current === page?.max;
  const isDisabledPrev = isLoading || !handlePrevBtn || page?.current === 1;

  return (
    <Flex alignItems='center'>
      {page?.max > 1 && (
        <Text fontSize={12} layerStyle='widgetContentText'>
          {strings('dashboard.component.pagination.pageCounter', { current: page.current, max: page.max })}
        </Text>
      )}
      <Spacer />
      <Flex>
        <Button
          mr={2}
          variant='outline'
          cursor='pointer'
          fontWeight={600}
          isDisabled={isDisabledPrev}
          onClick={handlePrevBtn}
        >
          {strings('owners.widget.actions.previous')}
        </Button>
        <Button
          variant='outline'
          cursor='pointer'
          fontWeight={600}
          isDisabled={isDisabledNext}
          onClick={handleNextBtn}
        >
          {strings('owners.widget.actions.next')}
        </Button>
      </Flex>
    </Flex>
  );
};

Pagination.propTypes = {
  title: PropTypes.string,
  totalItems: PropTypes.number,
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number,
  page: PropTypes.shape({
    current: PropTypes.number,
    max: PropTypes.number
  }),
  handlePrevBtn: PropTypes.func,
  handleNextBtn: PropTypes.func,
};

const AutoPagination = ({ pagedList }) => {
  const { loading, next, prev, page } = pagedList || {};
  return <Pagination
    isLoading={loading}
    handlePrevBtn={prev}
    handleNextBtn={next}
    page={page}
  />;
};

export { AutoPagination };

export default Pagination;
