import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  SkeletonCircle,
  SkeletonText,
  Text
} from '@chakra-ui/react';

export const Header = ({ children, ...rest }) => (
  <Text fontSize="14px" fontWeight="600" color="gray.800" mb="4px" {...rest}>{children}</Text>
);

export const Body = ({ children, ...rest }) => (
  <Text fontSize="12px" fontWeight="400" color="gray.600" mb="4px" {...rest}>{children}</Text>
);

export const Footnote = ({ children, ...rest }) => (
  <Text fontSize="12px" fontWeight="400" color="gray.500" {...rest}>{children}</Text>
);

export const Item = ({ children, onClickHandler, ...rest }) => {
  return (
    <Flex
      sx={{
        '&:first-child': {
          borderTopWidth: '1px'
        }
      }}
      borderBottomWidth='1px'
      py="12px"
      px="24px"
      borderColor="gray.200"
      onClick={onClickHandler}
      cursor="pointer"
      _hover={{ bg: 'gray.50' }}
      {...rest}
    >
      {children}
    </Flex>
  );
};

Item.propTypes = {
  children: PropTypes.node,
  onClickHandler: PropTypes.func
};

export const List = ({ children, ...rest }) => {
  return (
    <Flex direction="column" {...rest} >
      {children}
    </Flex>
  );
};

export const Empty = ({ children, isEmpty }) => {
  if (!isEmpty) return null;
  return (
    <Flex align="center" justify="center" minH="100px">
      <Text color="gray.400">
        {children}
      </Text>
    </Flex>
  );
};

export const ItemSkeleton = ({ ...props }) => {
  return (
    <Flex
      py="12px"
      px="24px"
      borderBottom="1px"
      borderColor="gray.200"
      align="center"
      {...props}
    >
      <Flex mr="8px">
        <SkeletonCircle h="32px" w="32px" />
      </Flex>
      <SkeletonText noOfLines={1} w="100%" />
    </Flex>
  );
};

export const Loading = ({ children, isLoading, repeat = 3 }) => {
  if (!isLoading) return null;
  if (children) return children;
  return Array(repeat)
    .fill()
    .map((_, index) => <ItemSkeleton key={index} />);
};

export const LoadingWrapper = ({ isLoading, children, skeleton, repeat = 3 }) => {
  if (isLoading) {
    const RowSkeleton = skeleton || ItemSkeleton;

    return Array(repeat)
      .fill()
      .map((_, index) => <RowSkeleton key={index} />);
  }
  return children;
};

export default {
  Item,
  Header,
  Body,
  Footnote,
  List
};
