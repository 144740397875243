import React from 'react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { FormSpy } from '../../reactFinalForm';
import BooleanField from '../../reactFinalForm/fields/BooleanField';
import PinTable from './PinTable';

// eslint-disable-next-line arrow-body-style
const MultiPinField = () => {
  const { strings } = useI18Next();

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        if (values && values.flow && values.flow.type !== 'punchcard') return null;
        return (
          <React.Fragment>
            <div className='mt2 mb2'>
              <label>{strings('ui.component.pinField.field.label.enableMultiPin')}</label>
              <p className='text--muted'>{strings('ui.component.pinField.allowMultiPunches')}</p>
              <div className='flex mv1'>
                <div className='fg1'>
                  <BooleanField
                    radio
                    name={`pinsMultiPunch`}
                    label={strings('ui.component.pinField.field.label.no')}
                    checked={v => !v}
                  />
                </div>
                <div className='fg1'>
                  <BooleanField
                    radio
                    name={`pinsMultiPunch`}
                    label={strings('ui.component.pinField.field.label.yes')}
                    checked={v => !!v}
                  />
                </div>
              </div>
            </div>

            {values.pinsMultiPunch ? (
              <React.Fragment>
                <p className='text--muted'>{strings('ui.component.pinField.generatePin')}</p>
                <PinTable pins={values.pins} />
              </React.Fragment>
              ) : null
            }
          </React.Fragment>
        );
      }}
    </FormSpy>
  );
};

export default MultiPinField;
