/* global window */
// Depends on Okta widget javascript being loaded in index.html

import { parse } from 'mini-querystring';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import WaitFor from '../../data/WaitFor';
import Message from '../collections/Message';

import './OktaSignIn.css';

// Only here because IE doesn't support the URL constructor
const ORIGIN_REGEX = /^(https?:\/\/[^/?]+)/;

const getRedirectPath = () => parse(window.document.location.search).redirect_to || '/';
const getRecoveryToken = () => parse(window.document.location.search).recovery_token || '';

const InitializationError = () => {
  return (
    <div>
      Unable to initialize. Please try again, or <a
      target="_blank"
      rel="noopener noreferrer"
      href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
    >
      Contact Support
    </a>.
    </div>
  );
};

class OktaWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.signIn = null;
  }

  componentDidMount() {
    this.props.client.auth.meta.get()
      .then((meta) => {
        this.setState({ meta });
      }).catch(() => {
      this.setState({ error: <InitializationError /> });
    });
  }

  componentDidUpdate() {
    if (!this.signIn && this.state.meta) {
      this.initializeSignInWidget(this.state.meta);
    }
  }

  componentWillUnmount() {
    if (this.signIn) this.signIn.remove();
  }

  initializeSignInWidget(meta) {
    const { i18next, redirectUri } = this.props;
    const i18n = {
      // Overrides default text when using English. Override other languages by adding additional sections.
      en: {
        'primaryauth.title': ' ', // Changes the sign in text
        'primaryauth.submit': 'LOG IN',
        'primaryauth.username.placeholder': 'Email'
      },
      fr: {
        'primaryauth.title': ' ', // Changes the sign in text
        'primaryauth.submit': 'S\'IDENTIFIER',
        'primaryauth.username.placeholder': 'Adresse de courriel'
      }
    };

    const features = {
      rememberMe: false,
      registration: false
    };

    const state = this.props.state || { redirectTo: getRedirectPath() };

    const authParams = {
      issuer: meta.oauth.issuer,
      display: 'page',
      responseType: ['code'],
      state: JSON.stringify(state)
    };

    const idps = meta.oauth.googleClientId ? [{ type: 'GOOGLE', id: meta.oauth.googleClientId }] : undefined;

    const config = {
      ...(i18next && i18next.language) && { language: i18next.language },
      i18n,
      features,
      recoveryToken: getRecoveryToken(),
      baseUrl: ORIGIN_REGEX.exec(meta.oauth.issuer)[1],
      clientId: meta.oauth.clientId,
      redirectUri: redirectUri || `${window.location.protocol}//${window.location.host}`,
      authParams,
      idps
    };

    this.signIn = new window.OktaSignIn(config);
    this.signIn.renderEl(
      {
        el: this.oktaEl
      },
      (res) => {
        if (res.status !== 'SUCCESS' && res.status !== 'FORGOT_PASSWORD_EMAIL_SENT') {
          this.setState({ error: 'Okta sign in error' });
        }
      },
      (err) => {
        throw err;
      }
    );
    return null;
  }

  render() {
    const { error, meta } = this.state;
    return (
      <WaitFor waitFor={meta || error}>
        <div
          ref={el => this.oktaEl = el} // eslint-disable-line no-return-assign
        />
        {error && <Message negative>{error}</Message>}
      </WaitFor>
    );
  }
}

OktaWidget.propTypes = {
  client: PropTypes.object,
  i18next: PropTypes.object
};


export default OktaWidget;
