/**
 * Usage
 *
 * const {
 *   items: Array<any>                           // array of results -- always present
 *   loading: Boolean                            // is currently pending
 *   paging: Boolean                             // is requesting another page of results
 *   wasPaging: Boolean                          // was paging for last request -- used with `error`
 *   error: Error                                // last error -- reset on load start
 *   settled: Boolean                            // is there no pending request
 *   empty: Boolean                              // result received and list is empty
 *   next: () => void                            // callback to load next page -- null if no next page
 *   prev: () => void                            // callback to load prev page -- null if no prev page
 *   page: {                                     // raw page object from PagedList
 *     next,                                     // token for next page
 *     prev,                                     // token for previous page
 *     current,                                  // current page
 *     max                                       // highest page
 *   }
 * } = usePagedList(
 *   (args) => Promise<PagedList>,               // args is the object passed as the second parameter
 *   args: Object<any>,                          // dictionary of arguments to pass to async function
 *                                               // -- request occurs when shallowly different
 *   {
 *     shouldUpdate: (args, oldArgs) => Boolean  // called whenever args is shallowly different
 *                                               // if false is returned, args update is ignored
 *   }
 * );
 */

import { isEqual } from 'lodash';
import {
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react';
import CancelablePromise from '../modules/promise/CancelablePromise';

const initialState = {
  loading: false,
  error: null,
  settled: false,
  empty: false,
  items: [],
  page: {},
  pagedList: null,
  paging: false,
  wasPaging: false
};

const stateReducer = (state, action) => {
  switch (action.type) {
    case 'request':
      return {
        ...state,
        loading: true,
        error: null,
        settled: false,
        empty: false,
        wasPaging: false
      };
    case 'success':
      return {
        loading: false,
        paging: false,
        wasPaging: state.paging,
        error: null,
        settled: true,
        empty: action.payload.items?.length === 0,
        items: action.options?.accumulate ? [...state.items, ...action.payload.items] : action.payload.items,
        page: action.payload.page,
        pagedList: action.payload
      };
    case 'error':
      return {
        ...state,
        loading: false,
        paging: false,
        wasPaging: state.paging,
        error: action.error,
        settled: true
      };
    case 'pagingStart':
      return {
        ...state,
        paging: true,
        wasPaging: false
      };
    case 'cancel':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

const defaultShouldUpdate = () => true;

const usePagedList = (fn, args, { shouldUpdate = defaultShouldUpdate, accumulate = false } = {}) => {
  // TODO: consider useReducer for state
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const oldArgs = useRef();
  const pendingPromise = useRef();

  const requestFactory = useMemo(
    () => {
      return (asyncFunction, options) => {
        return async (innerArgs) => {
          dispatch({ type: 'request' });
          if (pendingPromise.current && !pendingPromise.current.isSettled) pendingPromise.current.cancel();
          try {
            pendingPromise.current = new CancelablePromise(asyncFunction(innerArgs));
            const result = await pendingPromise.current.promise;
            if (result == null) return dispatch({ type: 'cancel' });
            return dispatch({ type: 'success', payload: result, options });
          } catch (err) {
            // NOTE: We explicitly don't dispatch anything if the promise was cancelled because we don't care
            if (err.message === CancelablePromise.CANCELED) return null;
            return dispatch({ type: 'error', error: err });
          }
        };
      };
    },
    []
  );

  const next = useMemo(
    () => {
      if (!state?.page?.next || !state?.page?.helpers) return null;
      return () => {
        dispatch({ type: 'pagingStart' });
        return requestFactory(
          state.page.helpers.next.bind(state.page.helpers),
          { accumulate }
        )(state.pagedList);
      };
    },
    [requestFactory, state?.page?.next]
  );

  const prev = useMemo(
    () => {
      if (!state?.page?.prev || !state?.page?.helpers) return null;
      return () => {
        dispatch({ type: 'pagingStart' });
        return requestFactory(state.page.helpers.prev.bind(state.page.helpers))(state.pagedList);
      };
    },
    [requestFactory, state?.page?.prev]
  );

  const doRequest = useMemo(
    () => {
      return requestFactory(fn);
    },
    [requestFactory, fn]
  );

  // Actual re-fetch functionality
  useEffect(
    () => {
      if (!isEqual(args, oldArgs.current) && shouldUpdate(args, oldArgs.current)) {
        doRequest(args);
      }
      oldArgs.current = args;
    },
    [args]
  );

  return {
    items: state.items,
    loading: state.loading,
    paging: state.paging,
    wasPaging: state.wasPaging,
    error: state.error,
    settled: state.settled,
    empty: state.empty,
    next,
    prev,
    page: state.page
  };
};

export default usePagedList;
