import {
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  Tooltip
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState
} from 'react';
import {
  Field,
  useField,
  useForm
} from 'react-final-form';
import { FaInfoCircle } from 'react-icons/fa';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import OFFER_OPTIONS from '../offerOptions';
import { actionOptions } from './RedemptionSelector';

const RedemptionActionLabel = ({ name, ...boxProps }) => {
  const { strings } = useI18Next();
  const [actionLabelGroup, setActionLabelGroup] = useState([]);
  const actionLabelFormName = `${name}.label`;

  const inPersonOptions = [
    { text: strings(OFFER_OPTIONS['{{use_now}}'].text), value: '{{use_now}}' },
    { text: strings(OFFER_OPTIONS['{{redeem}}'].text), value: '{{redeem}}' }
  ];

  const byPhoneOptions = [
    { text: strings(OFFER_OPTIONS['{{call_to_book}}'].text), value: '{{call_to_book}}' },
    { text: strings(OFFER_OPTIONS['{{call_to_order}}'].text), value: '{{call_to_order}}' },
    { text: strings(OFFER_OPTIONS['{{call_for_info}}'].text), value: '{{call_for_info}}' }
  ];

  const onlineOptions = [
    { text: strings(OFFER_OPTIONS['{{buy_online}}'].text), value: '{{buy_online}}' },
    { text: strings(OFFER_OPTIONS['{{book_online}}'].text), value: '{{book_online}}' },
    { text: strings(OFFER_OPTIONS['{{order_online}}'].text), value: '{{order_online}}' },
    { text: strings(OFFER_OPTIONS['{{learn_more}}'].text), value: '{{learn_more}}' },
    { text: strings(OFFER_OPTIONS['{{enter_now}}'].text), value: '{{enter_now}}' }
  ];

  const form = useForm();

  const actionButtonLabel = strings('dashboard.component.editOfferForm.field.label.actionButtonLabel');

  const action = form.getState().values?.flow?.vars?.action;
  const useActionOptions = actionOptions();

  useEffect(() => {
    switch (action) {
      case useActionOptions[1].value:
        setActionLabelGroup(byPhoneOptions);
        break;
      case useActionOptions[2].value:
        setActionLabelGroup(onlineOptions);
        break;
      default:
        setActionLabelGroup(inPersonOptions);
    }
  }, [action]);

  const { input } = useField(actionLabelFormName);

  useEffect(() => {
    if (!actionLabelGroup[0]) return;
    if (actionLabelGroup.some(item => item.value === input.value)) return;
    form.change(actionLabelFormName, actionLabelGroup[0].value);
  }, [actionLabelGroup, input]);

  return (
    <Box {...boxProps}>
      <FormLabel>
        <Flex align='center'>
          {actionButtonLabel}
          <Tooltip label={actionButtonLabel} placement='top' hasArrow>
            <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
          </Tooltip>
        </Flex>
      </FormLabel>
      <Field name={actionLabelFormName}>
        {/* eslint-disable-next-line no-shadow */}
        {({ input }) => (
          <Select {...input}>
            {actionLabelGroup?.map(item => (
              <option key={item.value} value={item.value}>{item.text}</option>
            ))}
          </Select>
        )}
      </Field>
    </Box>
  );
};

RedemptionActionLabel.propTypes = {
  name: PropTypes.string
};

export default RedemptionActionLabel;
