import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Link,
  Redirect
} from 'react-router-dom';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Icon from 'web-react-ui/src/components/elements/Icon';

import Label from 'web-react-ui/src/components/elements/Label';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import Markdown from 'web-react-ui/src/components/Markdown';
import PlanSettingsFields from 'web-react-ui/src/components/plans/PlanSettingsFields';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';

import client from '../../../services/client';


class PlanDetails extends Component {
  state = {
    refetchKey: 0
  };

  handleEnable() {
    const { property, planId } = this.props;
    this.setState({ isBusy: true });
    client
      .properties.for(property.id)
      .plans.for(planId).enable()
      .then(
        () => {
          this.reloadPlan();
          this.setState({ isBusy: false });
        },
        error => this.setState({ error, isBusy: false })
      );
  }

  handleDisable() {
    const { property, planId } = this.props;
    this.setState({ isBusy: true });
    client
      .properties.for(property.id)
      .plans.for(planId).disable()
      .then(() => {
        this.reloadPlan();
        this.setState({ isBusy: false });
      });
  }

  reloadPlan() {
    this.setState(({ refetchKey }) => ({ refetchKey: refetchKey + 1 }));
  }

  async fetchPlan() {
    const { property, planId } = this.props;
    const propertyId = property.id;
    const plan = await client
      .properties.for(propertyId)
      .plans.for(planId)
      .details();
    return plan;
  }

  render() {
    const { property } = this.props;
    const {
      isBusy, redirect, refetchKey
    } = this.state;


    if (redirect) return <Redirect to={redirect}/>;

    return (
      <Request request={this.fetchPlan.bind(this)} params={{ refetchKey }}>
        {({ data: plan, error, loading }) => {
          const actions = [
            {
              label: 'Edit',
              extraProps: {
                as: Link,
                to: `/${property.id}/property-settings/business-management/plans/${plan && plan.id}/edit`
              }
            },
            {
              label: 'Enable', action: this.handleEnable.bind(this), hidden: plan && plan.enabled
            },
            {
              label: 'Disable', action: this.handleDisable.bind(this), hidden: plan && !plan.enabled
            }
          ];

          return (
            <View>
              {error && <ErrorMessage error={error}/>}
              <WaitFor waitFor={!loading && !isBusy}>
                <View.Section fluid className='bg--gray brdr--b'>
                  <View.Section className='flex'>
                    <Link to={`/${property.id}/property-settings/business-management/plans/`} className='flex'>
                      <Icon className="long arrow alternate left"/>
                      <h6 className='m0'>{plan && plan.name}</h6>
                    </Link>
                    <div className='mla'>
                      <ActionMenu actions={actions}/>
                    </div>
                    <div className='pull-right mt1 mr1'>
                      {plan && !plan.enabled && <Label color='red'>Disabled</Label>}
                    </div>
                  </View.Section>
                </View.Section>
                <View.Section>
                  <h3 className='mt0'>Plan Details</h3>
                  <h6>Name</h6>
                  <p>{plan && plan.name}</p>
                  <h6>Description</h6>
                  <div className='ml2'>
                    <Markdown source={plan && plan.description}/>
                  </div>
                  <h6>Priority</h6>
                  <p>{plan && plan.priority}</p>
                  <h6>Discoverable</h6>
                  {(plan && plan.discoverable) && (
                    <p>Yes</p>
                  )}
                  {(plan && !plan.discoverable) && (
                    <p>No</p>
                  )}
                  <SimpleForm initialValues={plan}>
                    <PlanSettingsFields settingGroups={plan && plan.settingGroups} baseName='settings.' disabled/>
                  </SimpleForm>
                </View.Section>
              </WaitFor>
            </View>
          );
        }}
      </Request>
    );
  }
}

PlanDetails.propTypes = {
  property: PropTypes.object,
  planId: PropTypes.string
};

export default PlanDetails;
