import {
  chakra,
} from '@chakra-ui/react';
import React, { ComponentPropsWithoutRef } from 'react';
import { Form } from 'react-final-form';
import Spinner from '../chakra/Spinner';
import FormDebugger from './FormDebugger';
import FormError from './FormError';
import wrapSubmit from './wrapSubmit';
import wrapValidate from './wrapValidate';

interface SimpleForm2Props {
  onSubmit: (formValues: any) => Promise<any>,
  initialValues: Record<string, any>,
  debug?: boolean,
  children: React.ReactNode,
  loading?: boolean,
  showSubmitError?: boolean,
  validate?: (formValues: any) => any,
  formProps?: ComponentPropsWithoutRef<typeof chakra.form>,
  spinnerProps?: ComponentPropsWithoutRef<typeof Spinner>
}

function SimpleForm2({
  onSubmit,
  initialValues,
  debug,
  children,
  loading,
  showSubmitError,
  validate,
  formProps = {},
  spinnerProps = {},
}: SimpleForm2Props) {

  const wrappedSubmit = React.useCallback(wrapSubmit(onSubmit), [onSubmit]);
  const wrappedValidate = React.useMemo(() => validate && wrapValidate(validate), [validate]);

  return (
    <Form onSubmit={wrappedSubmit} initialValues={initialValues} validate={wrappedValidate}>
      {({ handleSubmit, form }) => (
        <chakra.form onSubmit={handleSubmit} {...formProps}>
          <Spinner loading={loading || form.getState().submitting} {...spinnerProps}>
            {children}
            {showSubmitError && <FormError />}
            {debug && <FormDebugger />}
          </Spinner>
        </chakra.form>
      )}
    </Form>
  );
}

export default SimpleForm2;
