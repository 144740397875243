import React from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  HStack,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import RoleBadge from './RoleBadge';
import User, { Role } from '../../types/User.interface';
import Location from '../../types/Location.interface';
import BusinessFilterSet from '../../components/filters/BusinessFilterSet';

interface Props {
  users: User[];
  context?: 'platform' | 'property' | 'business';
  currentUser: User;
  onDelete?: (user: User) => void;
  viewPath?: string;
  baseUrl: string;
  roles?: Role[];
  locations?: Location[];
  isLoading: boolean;
}

const UserList = ({
  users,
  currentUser,
  context,
  baseUrl,
  onDelete,
  roles,
  locations,
  isLoading,
}: Props): JSX.Element => {
  const isBusinessContext = context === 'business';
  const { search } = useLocation();
  const isMobileSize = useBreakpointValue([true, null, null, false]);
  const handleDelete = (e: any, user: User) => {
    e.preventDefault();
    e.stopPropagation();
    return onDelete?.(user);
  };

  const columns: Record<string, any>[] = [{
    label: 'User',
    key: 'user',
    width: '40%',
    minWidth: ['100%', null, null, '25rem']
  }, {
    label: 'Role',
    key: 'role',
    width: !isBusinessContext ? '50%' : '30%',
    minWidth: ['100%', null, null, '10rem']
  }, {
    label: 'Locations',
    key: 'locations',
    hide: !isBusinessContext,
    width: '30%',
    minWidth: ['100%', null, null, '10rem']
  }];

  const cellProps = { px: [3, null, null, 5], py: [2, null, null, 5], align: 'center' };

  const UserHeader = (): JSX.Element => (
    <Flex bg='gray.200' fontWeight={600} fontSize='xl'>
      {columns.filter(column => !column.hide).map(column => (
        <Flex w={column.width} minWidth={column.minWidth} px={5} py={2} {...column.props}>{column.label}</Flex>
      ))}
      <Flex
        flexGrow={1}
        justifySelf='flex-end'
        width={isBusinessContext ? undefined : '25%'}
        minWidth={['auto', null, null, '10rem']}
      />
    </Flex >
  );

  const renderUserCell = (user: User): JSX.Element => {
    const textProps = { maxW: '90%', noOfLines: 2, wordBreak: 'break-all' };
    const isCurrentUser = user.id === currentUser.id;
    return (
      <Flex {...cellProps}>
        <Stack direction='row' spacing={5} w='100%'>
          <Avatar name={user.name} showBorder color='white' size='md' shadow='lg' />
          <Box flexGrow={1}>
            {isCurrentUser && (
              <Badge mb={2} colorScheme='orange'>Current User</Badge>
            )}
            {/* @ts-ignore */}
            <Text {...textProps} fontWeight={500} fontSize='lg'>{user.email}</Text>
            {/* @ts-ignore */}
            <Text {...textProps}>{user.name}</Text>
            {context === 'platform' && (
              <Text fontSize='sm' mt={2} fontWeight={500}><i>{user.id}</i></Text>
            )}
          </Box>
        </Stack>
      </Flex >
    );
  };

  const renderRoleCell = (user: User): JSX.Element => {
    // @ts-ignore
    const uniqueRoles = [...new Set(user.roleIds)];
    return (
      <Flex {...cellProps} w={['100%', null, null, 'auto']} wrap='wrap' gap={2}>
        {roles && uniqueRoles.map((role: string) => (
          <RoleBadge
            key={role}
            my={1}
            label={roles.find((item: Role) => item.id === role)?.name || ''}
            textAlign='center'
          />
        ))}
      </Flex>
    );
  };

  const renderLocationsCell = (user: User): JSX.Element => {
    const roleLocations = user.locationIds?.map(locationId => locations?.find(loc => loc.id === locationId)?.name || '');
    const locationsString = () => {
      let label = 'All Locations';
      if (roles && roleLocations) {
        label = roleLocations.length === 1 ? roleLocations[0] : `${roleLocations.length} Locations`;
      }

      return label;
    };
    return (
      <Flex {...cellProps}>
        {locationsString()}
      </Flex>
    );
  };

  const ActionsCell = ({ user }: { user: User }): JSX.Element => {
    const isCurrentUser = user.id === currentUser.id;
    return (
      <Stack
        {...cellProps}
        ml='auto'
        spacing={5}
        justify='center'
        bg={['gray.100', null, null, 'none']}
        minWidth={['auto', null, null, '10rem']}
      >
        <Flex flexDir='column' justify='flex-start' align='center'>
          <IconButton
            variant='ghost'
            icon={<EditIcon />}
            aria-label='edit'
            as={Link}
            to={{
              pathname: `${baseUrl}/${user.id}/edit`,
              state: { query: search }
            }}
            onClick={(e: any) => e.stopPropagation()}
          />
          {!isCurrentUser && (
            <IconButton
              variant='ghost'
              aria-label='delete'
              icon={<DeleteIcon />}
              color='gray.600'
              onClick={(e: any) => handleDelete(e, user)}
            />
          )}
        </Flex>
      </Stack>
    );
  };

  const renderCells: Record<string, Function> = {
    user: renderUserCell,
    role: renderRoleCell,
    locations: renderLocationsCell,
  };

  return (
    <Box border='1px' borderColor='gray.200' borderRadius={8} h='100%' overflow='hidden' minH='40px'>
      {!isMobileSize && (
        <UserHeader />
      )}
      {users.map((user, index) => (
        <Flex
          borderTop={index === 0 ? 0 : '1px'}
          borderColor='gray.200'
          as={Link}
          to={{
            pathname: `${baseUrl}/${user.id}`,
            state: { query: search }
          }}
          onClick={(e: any) => e.stopPropagation()}
          cursor="pointer"
          _hover={{
            bg: 'gray.50'
          }}
        >
          <Flex flexDir={['column', null, null, 'row']} width='90%'>
            {columns.filter(column => !column.hide).map(column => (
              <Flex
                w={['auto', null, null, column.width]}
                minWidth={['auto', null, null, column.minWidth]}
                flexDir='column'
                justify='center'
              >
                {renderCells[column.key](user)}
              </Flex>
            ))}
          </Flex>
          <ActionsCell user={user} />
        </Flex>
      ))}
      {!isLoading && users.length === 0 && (
        <Flex {...cellProps}>
          <Text fontSize='lg' fontWeight={600}>No users found.</Text>
        </Flex>
      )}
    </Box>
  );
};

export default UserList;
