import { SearchIcon } from '@chakra-ui/icons';
import {
  CloseButton,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import React, {
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';
import BigModal from '../../../BigModal/BigModal';
import PageBackButton from '../../../chakra/page/PageBackButton';
import SearchInput from '../../../chakra/SearchInput';
import ImageLibrary2 from './ImageLibrary2';

const ImageLibraryModal2 = ({ open, onClose, onChange, fileType, aspectRatio }: {
  open: boolean,
  onClose: () => void,
  onChange: (imageRefId: string) => void,
  fileType: string,
  aspectRatio: number
}) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 700);

  return (
    <BigModal open={open} autoFocus={false}>
      <BigModal.Contents>
        <BigModal.Header>
          <PageBackButton onClick={onClose}>Back</PageBackButton>
          <Flex alignItems="top" gap="1em" p="1em" pb="0" direction={['column', 'column', 'row']}>
            <Heading w="100%" pb="2">Image Library</Heading>
            <SearchInput value={query} onChange={setQuery} size="lg" bg="gray.50" />
          </Flex>
        </BigModal.Header>
        <BigModal.Body>
          <ImageLibrary2
            onChange={onChange}
            fileType={fileType}
            aspectRatio={aspectRatio}
            query={debouncedQuery}
          />
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

export default ImageLibraryModal2;
