import { Button } from '@chakra-ui/react';
import React from 'react';

const LibraryButton = ({ onClick, children, ...rest }: {
  onClick: React.ComponentProps<typeof Button>['onClick'], children: React.ReactNode
}) => {
  return (
    <Button
      borderRadius="md"
      onClick={onClick}
      variant="unstyled"
      sx={{ outline: '1px solid transparent' }}
      _hover={{ bg: 'gray.100', outlineColor: 'blue.300' }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default LibraryButton;
