import { extendTheme } from '@chakra-ui/react';

// FIXME: We specify Inter and Montserrat here so that we can link either one in the index.html for each app
// So the old sites can continue to use Montserrat for now
// REMOVE Montserrat ONCE WE'VE STOPPED USING THE OLD `dashboard` PACKAGE
const fonts = {
  heading: 'Inter, Montserrat, \'Helvetica Neue\', Arial, Helvetica, sans-serif',
  body: 'Inter, Montserrat, \'Helvetica Neue\', Arial, Helvetica, sans-serif'
};

const widgetStyles = {
  widgetBorderColor: {
    borderColor: 'gray.200'
  },
  widgetRounded: {
    rounded: '12px'
  },
  widgetPadding: {
    padding: '24px'
  },
  widgetHeaderText: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 700
  },
  widgetContentCallout: {
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: 700
  },
  widgetContentText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    isTruncated: true
  },
  widgetContentSubText: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400
  }
};

/**
 * TODO after the release of the new LO dashboard(s)
 * Go back and set the base font size for the site
 * and only use chakra's 'sm' 'md' 'lg' etc sizing.
 * */

const fontStyles = {
  defaultFont: {
    size: {
      fontSize: '14px'
    }
  }
};

const theme = extendTheme(
  {
    components: {
      Table: {
        variants: {
          simple: {
            thead: {
              bg: 'gray.50'
            },
            th: {
              fontSize: 'sm',
              textTransform: 'none'
            }
          }
        }
      },
      Button: {
        variants: {
          solid: props => ({
            _hover: props.colorScheme === 'blue' ? {
              color: 'white',
              bg: 'blue.400'
            } : null
          }),
          simple: {
            bg: 'none',
            _hover: { bg: 'none' },
            _active: { bg: 'none' },
            _focus: { bg: 'none' }
          }
        }
      }
    },
    styles: {
      global: {
        html: {
          fontSize: '12px'
        },
        '@media (max-resolution: 191dpi)': {
          body: {
            '-webkit-font-smoothing': 'antialiased'
          }
        }
      }
    },
    fonts,
    layerStyles: {
      ...widgetStyles,
      ...fontStyles
    }
  }
);

/* eslint-disable-next-line no-console */

export default theme;

