import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Badge, Box, Container, Flex, Heading, theme } from '@chakra-ui/react';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import SubNav from 'web-react-ui/src/navigation/SubNav';

import propertyModule from '../../../modules/property';

const NavMenuItem = props => <SubNav.Item as={NavLink} {...props} />;

const PropertySettingsNav = (props) => {
  const { property, basePath } = props;
  const { name: propertyName, status } = property || {};

  const actions = [
    {
      label: 'Edit',
      extraProps: {
        as: NavLink,
        to: `${basePath}/edit`
      }
    },
    {
      label: 'Theme',
      extraProps: {
        as: NavLink,
        to: `${basePath}/theme`
      }
    }
  ];

  const statusColor = status === "live" ? "green" : "orange";

  return (
    <Box borderBottom={`solid 2px ${theme.colors.gray[200]}`} bg={theme.colors.gray[50]} pt={2}>
      <Container maxW={992}>
        <Flex justify="space-between" align="center" mt={8}>
          <Flex align="center">
            <Heading as="h1" m={0} fontWeight="regular">{propertyName} Settings</Heading>
            {status && (
              <Badge colorScheme={statusColor} p={2} ml={4}>{status}</Badge>
            )}
          </Flex>
          <ActionMenu actions={actions} />
        </Flex>
      </Container>
      <Container maxW={992}>
        <Box mt={2}>
          <SubNav>
            <NavMenuItem to={`${basePath}/general`} name='general' />
            <NavMenuItem to={`${basePath}/integrations`} name='integrations' />
            <NavMenuItem to={`${basePath}/destinations`}>
              Destinations
              <Badge p={2} ml={2} my={-1} colorScheme="gray">BETA</Badge>
            </NavMenuItem>
            <NavMenuItem to={`${basePath}/users`} name='users' />
            <NavMenuItem to={`${basePath}/business-management/plans`} name='Business Management' />
          </SubNav>
        </Box>
      </Container>
    </Box>
  );
};

PropertySettingsNav.propTypes = {
  basePath: PropTypes.string,
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['live', 'test']),
  }),
};

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state)
});

export default connect(mapState, undefined, undefined, { pure: false })(PropertySettingsNav);
export { PropertySettingsNav as Presenter };
