import {
  Alert,
  Text
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Message from '../collections/Message';

const getMessage = (error, i18n) => {
  if (error.code) {
    let values = null;
    if (error.context) {
      values = error.context.values;
      // HACK: Localize locale values to language names
      // We need a more general way to localize context values
      if (error.context.locale) {
        values = Object.assign(
          {},
          error.context.values,
          { locale: i18n.strings(`shared.languages.${error.context.locale}`) }
        );
      }
    }

    if (i18n.lookupExists(`ui.component.reactFinalForm.validation.${error.code}`)) {
      return i18n.strings(`ui.component.reactFinalForm.validation.${error.code}`, values);
    }
  }
  if (error.message) return `Error: ${error.message}`;
  return error;
};

const ErrorMessage = ({ error, size, chakra }) => {
  const i18n = useI18Next();
  if (!error) return null;

  const message = getMessage(error, i18n);

  if (chakra) {
    return (
      <Alert status="error">
        {message}
      </Alert>
    );
  }

  return <Message size={size} negative>{message}</Message>;
};

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  size: PropTypes.string,
  chakra: PropTypes.bool
};

export default ErrorMessage;
