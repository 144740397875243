const IGNORED_WORDS = [
  'and',
  'or',
  'the',
  'about',
  'beside',
  'near',
  'to',
  'above',
  'between',
  'of',
  'towards',
  'across',
  'beyond',
  'off',
  'under',
  'after',
  'by',
  'on',
  'underneath',
  'against',
  'despite',
  'onto',
  'unlike',
  'along',
  'down',
  'opposite',
  'until',
  'among',
  'during',
  'out',
  'up',
  'around',
  'except',
  'outside',
  'upon',
  'as',
  'for',
  'over',
  'via',
  'at',
  'from',
  'past',
  'with',
  'before',
  'in',
  'within',
  'behind',
  'inside',
  'since',
  'without',
  'below',
  'into',
  'than',
  'beneath',
  'like',
  'through',
  'who',
  'what',
  'when',
  'where',
  'why',
];

const extractKeywords = (input?: string) => {
  if (!input) return [];
  return input
    .split(/[^\w\s-]+/).join('')
    .split('_').join('')
    .toLowerCase()
    .split(/\s+/)
    .map(t => t.replace(/(^-+)|(-+$)/g, ''))
    .filter(t => t.length >= 2)
    .filter(t => !/^\d+$/.test(t))
    .filter(t => !IGNORED_WORDS.includes(t));
};

export default extractKeywords;
