// const [wrappedFn, loading, result, error] = useAsync(args => client.fetchSomething(), []);
// const callback = useCallback(async () => {
//   const output = await wrappedFn(some, args);
//
// ), [wrappedFn]);
//
// return <em>{loading ? 'Loading...' : 'Not Loading.'}

import {
  useCallback,
  useEffect,
  useState
} from 'react';

const usePromise = (fn, deps, initialValue = null, autoTrigger) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(initialValue);
  const wrappedFn = useCallback((...args) => {
    setLoading(true);
    setError(null);
    return Promise.resolve(fn(...args))
      .then(
        (r) => {
          setLoading(false);
          setResult(r);
          return r;
        },
        (err) => {
          setLoading(false);
          setError(err);
          return Promise.reject(err);
        }
      );
  }, deps);

  useEffect(() => {
    if (autoTrigger) wrappedFn();
  }, [wrappedFn]);

  return [wrappedFn, loading, result, error];
};

export default usePromise;
