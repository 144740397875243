import React from "react";
import PropTypes from "prop-types";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import GenderStatsPlaceholder from "../../assets/stats/noGenderStats2.jpg";

const placeholderStyle = {
  backgroundImage: `url('${GenderStatsPlaceholder}')`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  backgroundBlendMode: 'lighten',
  textAlign: 'center',
  lineHeight: '1.2em'
};

const GenderChart = ({ genderData }) => {
  const { strings } = useI18Next();

  if (!genderData) {
    return (
      <div className='mt2' style={placeholderStyle}>
        <small>
          {strings('ui.component.chart.fragment.pt1')}
          <br />
          {strings('ui.component.chart.fragment.pt2')}
        </small>
      </div>
    );
  }

  return (
    <div>
      <div
        className='ml1'
        style={{ fontSize: '14px', fontWeight: 'bold' }}
      >
        {strings('ui.component.chart.gender').toUpperCase()}
      </div>
      <div className='flex'>
        <ResponsiveContainer width='100%' height={20} debounce={100}>
          <BarChart layout='vertical' className='mla mra' data={[genderData]} barCategoryGap='0%'>
            <Tooltip
              labelFormatter={() => strings('ui.component.chart.gender')}
              formatter={value => `${Math.round(value)}%`}
            />
            <Bar dataKey='female' stackId="a" fill='#FDD93A' />
            <Bar dataKey='male' stackId="a" fill='#DA9F00' />
            <XAxis type="number" hide={true} />
            <YAxis type="category" hide={true} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="flex">
        <label className='ml1' style={{ fontSize: '12px' }}>{strings('ui.component.chart.women')}</label>
        <label className='mla mr1' style={{ fontSize: '12px' }}>{strings('ui.component.chart.men')}</label>
      </div>
    </div>
  );
};

GenderChart.propTypes = {
  genderData: PropTypes.shape({
    male: PropTypes.number,
    female: PropTypes.number
  })
};

export default GenderChart;
