import React, { Component } from 'react';
import Transition from '../modules/Transition';
import Toast from './Toast';
import toastStore from './toastStore';
import './toast.css';

class ToastContainer extends Component {
  state = { toasts: [] };

  updateToasts(toasts) {
    this.setState({ toasts });
  }

  componentDidMount() {
    toastStore.subscribe(this.updateToasts.bind(this));
  }

  componentWillUnmount() {
    toastStore.unsubscribe(this.updateToasts.bind(this));
  }

  render() {
    const { toasts } = this.state;
    return (
      <div className='toast-container'>
        <Transition.Group animation='fade' duration={200}>
          {toasts.map(t => (
            <Toast
              key={t.id}
              title={t.title}
              message={t.message}
              type={t.type}
              icon={t.icon}
              className='transition fade in'
              onDismiss={() => toastStore.remove(t)}
            />
          ))}
        </Transition.Group>
      </div>
    );
  }
}

export default ToastContainer;
