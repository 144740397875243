import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, FormLabel, Heading, Select, VStack } from '@chakra-ui/react';
import { Field, useField } from 'react-final-form';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import AddSectionButton from '../../../chakra/forms/RevealField/AddSectionButton';
import RemoveSectionButton from '../../../chakra/forms/RevealField/RemoveSectionButton';
import RevealField from '../../../chakra/forms/RevealField/RevealField';
import RadioButtonGroupField from '../../../chakra/forms/RadioButtonGroup/RadioButtonGroupField';

const RedemptionPunchLimits = ({ name }) => {
  const { strings } = useI18Next();
  const { input: oncePerUser } = useField('oncePerUser');
  const { input: useOncePerUser } = useField('useOncePerUser');

  useEffect(() => {
    if (oncePerUser?.value && !useOncePerUser?.value) {
      useOncePerUser.onChange(true);
    }
  }, []);

  return (
    <Box w='100%'>
      <Heading size="md" mb={5}>
        {strings('ui.component.redeemLimits.field.label.limits')}
      </Heading>
      <VStack w='100%' spacing={5}>
        <Box w='100%'>
          <AddSectionButton name='useOncePerUser'>
            {strings('dashboard.component.editOfferForm.field.label.addMemberLimits')}
          </AddSectionButton>
          <RevealField name='useOncePerUser'>
            <FormLabel>
              <Flex justify='space-between' align='center'>
                {strings('dashboard.component.editOfferForm.field.label.customersCanUsePunchcard')}
                <RemoveSectionButton name='useOncePerUser' />
              </Flex>
            </FormLabel>
            <RadioButtonGroupField
              name={`${name}.oncePerUser`}
              options={[
                { label: strings('ui.component.punches.field.label.once'), value: true },
                { label: strings('ui.component.punches.field.label.manyTimes'), value: false },
              ]}
            />
          </RevealField>
        </Box>
      </VStack>
    </Box>
  );
};

RedemptionPunchLimits.propTypes = {
  name: PropTypes.string
};

export default RedemptionPunchLimits;
