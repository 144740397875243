import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import MetricItem from './MetricItem';
import './Metrics.css';
import MetricsFunnelStep from './MetricsFunnelStep';
import MetricsUpgradeBanner from './MetricsUpgradeBanner';

const Metrics = ({ data, plansHref }) => {
  const { strings } = useI18Next();

  const reach = {
    total: data && data.reach && data.reach.total,
    age: data && data.reach && data.reach.ageSeries,
    gender: data && data.reach && data.reach.genderBreakdown
  };
  const response = {
    total: data && data.response && data.response.total,
    age: data && data.response && data.response.ageSeries,
    gender: data && data.response && data.response.genderBreakdown
  };
  const action = {
    total: data && data.action && data.action.total,
    age: data && data.action && data.action.ageSeries,
    gender: data && data.action && data.action.genderBreakdown
  };
  const restrictions = (data && data.restrictions) || {};
  const label = {
    reach: strings('ui.component.metrics.reach'),
    reachExplanation: strings('ui.component.metrics.reachExplanation'),
    response: strings('ui.component.metrics.response'),
    responseExplanation: strings('ui.component.metrics.responseExplanation'),
    action: strings('ui.component.metrics.action'),
    actionExplanation: strings('ui.component.metrics.actionExplanation')
  };

  const reachRestricted = restrictions['stats.feature.general.core.include_reach'];
  const responseRestricted = restrictions['stats.feature.general.core.include_response'];
  const actionRestricted = restrictions['stats.feature.general.core.include_action'];

  return (
    <div>
      {restrictions.isRestricted
        ? <MetricsUpgradeBanner plansHref={plansHref}/>
        : null
      }
      <div className='wrap--contents unwrap--contents mt1'>
        <MetricsFunnelStep
          label={label.reach}
          explanationLabel={label.reachExplanation}
          data={reach}
          restricted={reachRestricted}
        />
        <MetricsFunnelStep
          label={label.response}
          explanationLabel={label.responseExplanation}
          data={response}
          restricted={responseRestricted}
        />
        <MetricsFunnelStep
          label={label.action}
          explanationLabel={label.actionExplanation}
          data={action}
          isLast
          restricted={actionRestricted}
        />
      </div>
      <div className='wrap--contents unwrap--contents'>
        <MetricItem
          ageData={reach.age}
          genderData={reach.gender}
          name={label.reach}
          restricted={reachRestricted || restrictions['stats.feature.general.core.include_reach_demographic']}
        />
        <MetricItem
          ageData={response.age}
          genderData={response.gender}
          name={label.response}
          restricted={responseRestricted || restrictions['stats.feature.general.core.include_response_demographic']}
        />
        <MetricItem
          ageData={action.age}
          genderData={action.gender}
          name={label.action}
          restricted={actionRestricted || restrictions['stats.feature.general.core.include_action_demographic']}
        />
      </div>
    </div>
  );
};

Metrics.propTypes = {
  data: PropTypes.object,
  plansHref: PropTypes.string
};

export default Metrics;
