import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

export const DEFAULT_CENTER = [49.891857, -119.492318];

export const DEFAULT_ZOOM = 20;

export const fixIconBug = (Icon) => {
  /* Fix long-standing Leaflet css bug */
  delete Icon.Default.prototype._getIconUrl; // eslint-disable-line no-underscore-dangle, no-param-reassign
  Icon.Default.mergeOptions({ iconRetinaUrl, iconUrl, shadowUrl });
};

export const getMapKey = () => {
  /**
   * Get Map Tile API Key from .env
   * Note that due to CRA's shenanigans, `process` does not actually
   * appear to be defined (eg: `process` returns `undefined`.
   * But accessing the keys directly works. 🤷‍
   */
  try {
    return process.env.REACT_APP_THUNDER_FOREST_KEY;
  } catch (error) {
    console.error('Missing API key for Map Tiles'); // eslint-disable-line no-console
    return null;
  }
};
