import React, { Component } from 'react';
import PropTypes from 'prop-types';

import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import Request from '../../data/Request';
import WaitFor from '../../data/WaitFor';
import Message from '../collections/Message';
import Button from '../elements/Button';
import TagDisplay from './TagDisplay';
import TagEditModal from './TagEditModal';

class TagEditor extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    fetchTags: PropTypes.func,
  };

  state = {
    refetchKey: 0
  };

  showModal() {
    this.setState({ showModal: true });
  }

  handleCancel() {
    this.setState({ showModal: false });
  }

  handleSave({ tags }) {
    const { onSave } = this.props;
    this.setState({ isSaving: true, error: null });
    return onSave(tags)
      .then(() => {
        this.setState({ isSaving: false, showModal: false });
        this.refetch();
      })
      .catch((error) => {
        this.setState({ isSaving: false, error });
      });
  }

  refetch() {
    this.setState(({ refetchKey }) => ({ refetchKey: refetchKey + 1 }));
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState;
  }

  render() {
    const { fetchTags } = this.props;
    const { showModal, error: saveError, refetchKey } = this.state;
    return (
      <React.Fragment>
        <Request request={fetchTags} key={refetchKey}>
          {({ data, loading, error }) => (
            <React.Fragment>
              <TagEditModal
                tags={data}
                open={showModal}
                onSave={this.handleSave.bind(this)}
                onCancel={this.handleCancel.bind(this)}
                error={saveError}
              />
              <WaitFor waitFor={!loading}>
                {
                  error
                    ? (
                      <I18NextContext.Consumer>
                        {({ strings }) => (
                          <Message error>
                            {strings('ui.component.tagEditor.failedToLoadTags')}
                            <Button
                              compact
                              className='link'
                              onClick={this.refetch.bind(this)}
                            >
                              {strings('ui.component.tagEditor.tryAgain')}
                            </Button>
                          </Message>
                        )}
                      </I18NextContext.Consumer>
                    )
                    : <TagDisplay tags={data} onEdit={this.showModal.bind(this)} />
                }
              </WaitFor>
            </React.Fragment>
          )}
        </Request>
      </React.Fragment>
    );
  }
}

export default TagEditor;
