import {
  Box,
  Flex,
} from '@chakra-ui/react';
import React, { Children } from 'react';

const Shell = ({ children }) => {
  let header;
  let footer;
  const body = [];
  Children.forEach(
    children,
    (c) => {
      if (c.type === Shell.Header) {
        header = c;
      } else if (c.type === Shell.Footer) {
        footer = c;
      } else {
        body.push(c);
      }
    },
  );

  return (
    <Flex minHeight="100vh" flexDirection="column">
      {
        header
          ? (
            <Flex bg="#475771" borderBottom="2px solid rgba(34, 36, 38, 0.15)">
              {header}
            </Flex>
          )
          : null
      }
      <Box flexGrow={1}>
        {body}
      </Box>
      {
        footer
          ? (
            <Flex bg="white" mt="2em">
              {footer}
            </Flex>
          )
          : null
      }
    </Flex>
  );
};

Shell.Header = ({ children }) => {
  return children;
};

Shell.Footer = ({ children }) => {
  return children;
};

export default Shell;
