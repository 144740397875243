/* eslint-disable import/prefer-default-export */
const OFFER_OPTIONS = {
  "{{buy_online}}": {
    text: "ui.component.offerRedeemLimits.onlineOptions.buyOnline"
  },
  "{{book_online}}": {
    text: "ui.component.offerRedeemLimits.onlineOptions.bookOnline"
  },
  "{{buy_now}}": {
    text: "ui.component.punches.progressLabels.buyNow"
  },
  "{{call_to_book}}": {
    text: "ui.component.offerRedeemLimits.byPhoneOptions.callBook"
  },
  "{{call_to_order}}": {
    text: "ui.component.offerRedeemLimits.byPhoneOptions.callOrder"
  },
  "{{call_for_info}}": {
    text: "ui.component.offerRedeemLimits.byPhoneOptions.callInfo"
  },
  "{{check_in}}": {
    text: "ui.component.punches.progressLabels.checkIn"
  },
  "{{claim_reward}}": {
    text: "ui.component.punches.redeemLabels.claimReward"
  },
  "{{earn_punch}}": {
    text: "ui.component.punches.progressLabels.earnPunch"
  },
  "{{enter_now}}": {
    text: "ui.component.offerRedeemLimits.onlineOptions.enterNow"
  },
  "{{learn_more}}": {
    text: "ui.component.offerRedeemLimits.onlineOptions.learnMore"
  },
  "{{order_online}}": {
    text: "ui.component.offerRedeemLimits.onlineOptions.orderOnline"
  },
  "{{redeem}}": {
    text: "ui.component.offerRedeemLimits.inPersonOptions.redeem"
  },
  "{{redeem_now}}": {
    text: "ui.component.punches.redeemLabels.redeemNow"
  },
  "{{use_now}}": {
    text: "ui.component.offerRedeemLimits.inPersonOptions.useNow"
  },
  "{{use_reward}}": {
    text: "ui.component.punches.redeemLabels.useReward"
  }
};

export default OFFER_OPTIONS;
