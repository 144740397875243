import _ from 'lodash';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePromise from 'shared/src/hooks/usePromise';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';

import propertyModule from '../../modules/property';
import client from '../../services/client';
import PropertyEditForm from './PropertyEditForm';

const escapeKey = key => key.replace(/\./g, '__');
const unescapeKey = key => key.replace(/__/g, '.');

const getPropertyFields = async (propertyId) => {
  const propertyRepo = client.properties.for(propertyId);
  const property = await propertyRepo.details();
  const settings = await propertyRepo.getSettings();

  return {
    property,
    settings: settings.items
      .reduce((acc, { key, value }) => {
        acc[escapeKey(key)] = value;
        return acc;
      }, {})
  };
};

const updateSetting = async (name, value, propertyRepo) => {
  return propertyRepo.setSetting(unescapeKey(name), value);
};

const updateProperty = async ({ property, settings }, { property: propertyUpdates, settings: settingsUpdates }) => {
  const propertyRepo = client.properties.for(property.id);

  // Update Property Model
  await propertyRepo.update(property, _.omit(propertyUpdates, ['id', 'slug']));

  const settingPromises = Object.entries(settingsUpdates)
    .map(([name, value]) => {
      if (settings[name] === value) return false;
      return updateSetting(name, value, propertyRepo, property);
    })
    .filter(Boolean);

  return Promise.all(settingPromises);
};

const PropertyEdit = ({ propertyId, handleClose, admin }) => {
  const [, loading, propertyFields, error] = usePromise(
    () => getPropertyFields(propertyId),
    [propertyId],
    null,
    true
  );

  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (updates) => {
      await updateProperty(propertyFields, updates);
      dispatch(propertyModule.actions.invalidate());
      handleClose();
    },
    [propertyFields]
  );

  return (
    <div>
      <ErrorMessage error={error} />
      <h1>Edit Property</h1>

      <SimpleForm onSubmit={handleSubmit} initialValues={propertyFields} loading={loading}>
        <PropertyEditForm admin={admin} status={(propertyFields && propertyFields.property.status) || 'Live'} />
        <FormSubmit label="Save" />
      </SimpleForm>
    </div>
  );
};

PropertyEdit.propTypes = {
  propertyId: PropTypes.string,
  handleClose: PropTypes.func,
  admin: PropTypes.bool,
};

const PropertyEditModal = ({ propertyId, redirectTo, admin }) => {
  const history = useHistory();
  const handleClose = useCallback(
    () => history.push(redirectTo)
  );

  return (
    <BigModal open>
      <BigModal.Contents>
        <BigModal.Header className='p1'>
          <BigModal.CloseButton floated='right' onClick={handleClose} />
        </BigModal.Header>
        <BigModal.Body>
          <View>
            <View.Section narrow>
              <PropertyEdit propertyId={propertyId} handleClose={handleClose} admin={admin} />
            </View.Section>
          </View>
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

PropertyEditModal.propTypes = {
  propertyId: PropTypes.string,
  redirectTo: PropTypes.string,
  admin: PropTypes.bool
};

export default PropertyEditModal;
