import PropTypes from "prop-types";
import React from "react";

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Button from "../elements/Button";
import Label from "../elements/Label";
import Icon from "../elements/Icon";

const EditButton = ({ tags, onClick, ...rest }) => {
  if (!onClick) return null;
  return (
    <Button
      type='button'
      onClick={onClick} {...rest}
      className='link label'
    >
      {(tags && tags.length ? <EditTags/> : <AddTags/>)}
    </Button>
  );
};

EditButton.propTypes = {
  tags: PropTypes.array,
  onClick: PropTypes.func
};

const AddTags = ({ onEdit }) => {
  const { strings } = useI18Next();
  return (
    <Label color='blue' onClick={onEdit}>
      <Icon name='add'/>
      {strings('ui.component.tagDisplay.tag')}
    </Label>
  );
};

AddTags.propTypes = {
  onEdit: PropTypes.func
};

const EditTags = ({ onEdit }) => (
  <div>
    <Icon className='base-blue' onClick={onEdit} name='add circle' size='big'/>
  </div>
);

EditTags.propTypes = {
  onEdit: PropTypes.func
};

const TagDisplay = ({ tags, onEdit }) => (
  <Label.Group>
    {tags && tags.map(tag => (
      <Label color='blue' key={tag}>{tag}</Label>
    ))}
    <EditButton tags={tags} onClick={onEdit}/>
  </Label.Group>
);

TagDisplay.propTypes = {
  tags: PropTypes.array,
  onEdit: PropTypes.func
};


export default TagDisplay;
