import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Map from 'web-react-ui/src/map/Map';
import useFilteredRegions from '../../../hooks/useFilteredRegions';

// TODO: Don't do this weird thing where it tries to figure out the data structure
// `useFilteredRegions` does it too

const getRegionPolygons = (collection) => {
  if (collection.polygons) return collection.polygons;
  if (collection.collection) return collection.collection.polygons;
  return undefined;
};

const MapRegions = ({ collection }) => {
  const [regions, isLoading] = useFilteredRegions(collection);
  const polygons = getRegionPolygons(collection);

  const displayRegions = useMemo(
    () => {
      if (!polygons) return regions;
      const mergedRegions = polygons.map(poly => ({
        area: poly.map(list => list.map(([a, b]) => [b, a])),
        style: { color: 'yellow', dashArray: '5,5' }
      }));
      return ((regions && regions.items) || []).concat(mergedRegions);
    },
    [regions, polygons]
  );

  const renderMap = () => {
    if (displayRegions.length > 0) {
      return (
        <Map
          regions={displayRegions}
          initialZoom={12}
          fitToRegions
        />
      );
    }
    return <p>No Filtered Regions Selected</p>;
  };

  return (
    <div className='mt2 mb3'>
      <WaitFor wrapContents waitFor={!isLoading}>
        {renderMap()}
      </WaitFor>
    </div>
  );
};

MapRegions.propTypes = {
  collection: PropTypes.object.isRequired
};

export default MapRegions;
