import React from 'react';
import PropTypes from 'prop-types';

const Context = React.createContext();

const injectClient = (Component) => {
  const Wrapped = ({ forwardedRef, ...rest }) => (
    <Context.Consumer>
      {client => <Component ref={forwardedRef} {...rest} client={client}/>}
    </Context.Consumer>
  );

  Wrapped.propTypes = {
    forwardedRef: PropTypes.func
  };

  Wrapped.displayName = `client(${Component.displayName || Component.name})`;
  const Wrapper = (props, ref) => (<Wrapped {...props} forwardedRef={ref}/>);
  return React.forwardRef(Wrapper);
};

export { injectClient };
export default Context;
