import {
  Center,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const Empty = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Spacer flexBasis="100%" />
      <Center w="100%" p="2em">
        <Text fontSize="xl" color="gray.600">{children}</Text>
      </Center>
    </>
  );
};

const EmptyPagedList = ({ pagedList, children }: {
  pagedList: any,
  children: React.ReactNode
}) => {
  if (!pagedList) return null;
  if (pagedList.loading) return null;
  if (!pagedList.empty) return null;
  return <Empty>{children}</Empty>;
};

export { Empty };
export default EmptyPagedList;
