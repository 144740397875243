/* eslint camelcase: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import DestinationCreateModal from '../components/destination/DestinationCreateModal';
import DestinationDetails from '../components/destination/DestinationDetails';
import AddIntegrationContainer from '../components/integration/AddIntegrationContainer';

import PropertySettingsNav from '../components/property/propertyDetailsView/PropertyNav';
import PropertySettingsDestinations from '../components/property/propertyDetailsView/PropertySettingsDestinations';
import PropertySettingsGeneral from '../components/property/propertyDetailsView/PropertySettingsGeneral';
import PropertySettingsIntegrations from '../components/property/propertyDetailsView/PropertySettingsIntegrations';
import PropertySettingsUsers from '../components/property/propertyDetailsView/PropertySettingsUsers';
import UserProfile from '../components/property/propertyDetailsView/UserProfile';
import EditUserViewModel from '../components/property/propertyDetailsView/EditUserViewModel';
import PropertySettingsManagement from '../components/property/propertyDetailsView/PropertySettingsManagement';
import OfferSyndicationReview from '../components/property/propertyDetailsView/OfferSyndicationReview';
import PropertyEdit from '../components/property/PropertyEdit';
import PropertyTheme from '../components/property/PropertyTheme';
import TokenCreateModal from '../components/token/TokenCreateModal';
import PlanCreateModal from '../components/property/propertyDetailsView/PlanCreateModal';
import PlanEditModal from '../components/property/propertyDetailsView/PlanEditModal';
import PlanDetails from '../components/property/propertyDetailsView/PlanDetails';

export default class PropertySettingsRoutes extends Component {
  static propTypes = {
    basePath: PropTypes.string,
    property: PropTypes.object,
    propertyId: PropTypes.string
  };

  render() {
    const { basePath, property } = this.props;

    return (
      <div>
        <PropertySettingsNav basePath={basePath} state={this.state} />
        <Switch>
          <Route
            path={`${basePath}/edit`}
            exact
            render={() => <PropertyEdit
              propertyId={property.id}
              redirectTo={`${property.id}/property-settings/general`}
            />
            }
          />
          <Route
            path={`${basePath}/theme`}
            exact
            render={() => <PropertyTheme
              propertyId={property.id}
              redirectTo={`${property.id}/property-settings/general`} />
            }
          />
          <Route path={`${basePath}/general`} component={PropertySettingsGeneral} />
          <Route
            path={`${basePath}/syndication`}
            exact
            render={() => <OfferSyndicationReview
              propertyId={property.id}
              redirectTo={`${property.id}/property-settings/general`} />
            }
          />
          <Route
            path={`${basePath}/integrations/tokens/add`}
            render={props => <TokenCreateModal history={props.history} propertyId={property.id} />}
          />

          <Route
            path={`${basePath}/integrations/add`}
            render={props => <AddIntegrationContainer propertyId={property.id} history={props.history} />}
          />
          <Route path={`${basePath}/integrations`} component={PropertySettingsIntegrations} />

          <Route
            path={`${basePath}/destinations/add`}
            render={props => <DestinationCreateModal propertyId={property.id} history={props.history} />}
          />
          <Route
            path={`${basePath}/destinations/:destinationId`}
            render={props => <DestinationDetails
              propertyId={property.id}
              destinationId={props.match.params.destinationId}
              history={props.history}
            />}
          />
          <Route
            path={`${basePath}/destinations`}
            render={() => <PropertySettingsDestinations propertyId={property.id} />}
          />

          <Route path={`${basePath}/users/:userId/edit`}>
            <EditUserViewModel
              propertyId={property.id}
              baseUrl={`${basePath}/users`}
            />
          </Route>
          <Route path={`${basePath}/users/:userId`}>
            <UserProfile
              propertyId={property.id}
              baseUrl={`${basePath}/users`}
            />
          </Route>
          <Route path={`${basePath}/users`} component={PropertySettingsUsers} />

          <Route
            path={`${basePath}/business-management/plans/add`}
            render={props => <PlanCreateModal
              property={property}
              history={props.history}
              redirectTo={`${basePath}/business-management/plans`}
            />}
          />

          <Route
            path={`${basePath}/business-management/plans/:planId/edit`}
            render={props => <PlanEditModal
              property={property}
              planId={props.match.params.planId}
              history={props.history}
              redirectTo={`${basePath}/business-management/plans/${props.match.params.planId}`}
            />}
          />

          <Route
            path={`${basePath}/business-management/plans/:planId`}
            render={props => <PlanDetails
              property={property}
              planId={props.match.params.planId}
              redirectTo={`${basePath}/business-management/plans`}
            />}
          />

          <Route path={`${basePath}/business-management/plans`} component={PropertySettingsManagement} />

          <Redirect to={`${basePath}/general`} />
        </Switch>
      </div>
    );
  }
}
