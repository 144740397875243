import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import usePermissions from 'shared/src/hooks/usePermissions';
import ClientContext from 'shared/src/components/contexts/ClientContext';

export const defaultProperties: Record<string, string> = {
  dev: '0e934bc2-5fec-4fc8-a849-43b9903a113b', // Global Offer Pool (dev)
  prod: 'a7b96a51-5813-431e-a91b-d31ee510d35c', // GetintheLoop (prod)
  stage: '034867ae-d186-4f7e-849b-f0d5384b5d7e', // GetintheLoop -- DEV (stage)
};

const ReferralContext = ({ userId }: { userId?: string }): JSX.Element => {
  const client = useContext(ClientContext);
  const defaultPropertyId: string = defaultProperties[process.env.REACT_APP_ENV as string];
  const referralContextPermission = usePermissions(
    // typescript doesn't like these not being declared
    { propertyId: defaultPropertyId, businessId: undefined, locationId: undefined },
    ['property.referralContext.get']
  );
  const getReferralContext = () =>
    client.properties.for(defaultPropertyId).users.for(userId).getReferralContext({ dynamic: true });
  const [referralContext, setReferralContext] = useState<string>();

  useEffect(() => {
    if (referralContextPermission && !referralContext) {
      getReferralContext().then((res: { context: JSON }) => setReferralContext(JSON.stringify(res.context)));
    }
  }, [userId, referralContextPermission]);

  if (!referralContext || !userId) {
    return <></>;
  }

  return (
    <Box mt={10}>
      <Heading fontSize='2xl' borderBottom='1px' borderColor='gray.200' pb={3} mb={5}>Referral Context</Heading>
      <code>{referralContext}</code>
    </Box>
  );
};

export default ReferralContext;
