import {
  ChevronDownIcon
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  Text
} from '@chakra-ui/react';
import React from 'react';
import {
  useSelector
} from 'react-redux';
import auth from 'shared/src/modules/auth';
import propertyModule from '../../modules/property';
import AccountMenu from './AccountMenu';
import PropertyMenu from './PropertyMenu';

const MenuHeader = ({ user }) => {
  if (!user) return null;
  return (
    <MenuGroup title={
      <Box my="1em">
        <Text fontWeight="bold">{user.name}</Text>
        <Text fontColor="silver">{user.email}</Text>
      </Box>
    } />
  );
};

const SiteMenu = () => {
  const user = useSelector(auth.selectors.getUser);
  const property = useSelector(propertyModule.selectors.property.getData);

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="simple"
        color="white"
        rightIcon={<ChevronDownIcon />}
        p="2em"
      >
        Settings
      </MenuButton>
      <MenuList>
        <MenuHeader user={user} />
        <PropertyMenu property={property} />
        <AccountMenu user={user} />
      </MenuList>
    </Menu>
  );
};

export default SiteMenu;
