import PropTypes from 'prop-types';
import React from 'react';
import { calendarDate, compose, dateFormat } from '../validators';
import TextField from './TextField';

// FIXME: Replace this with an actual Date control

const DateField = ({ validate, ...props }) => {
  const composedValidate = compose(dateFormat, calendarDate, validate);
  return <TextField type='date' validate={composedValidate} placeholder="yyyy-mm-dd" {...props}/>;
};

DateField.propTypes = {
  validate: PropTypes.func
};

export default DateField;
