/* eslint arrow-body-style:0 */
import _ from 'lodash';
import errorCodes from '../errors/errorCodes';
import ValidationError from '../errors/ValidationError';

export const replaceMessage = (validator, messageBuilder = msg => msg) => (value) => {
  const result = validator(value);
  if (!result) return result;
  return messageBuilder(result);
};

// Test each validator and return the result of the first one that fails
export const compose = (...validators) => (value) => {
  let result;
  _.forEach(
    validators,
    (fn) => {
      if (!fn) return null;
      result = fn(value);
      return !result;
    }
  );
  return result;
};

export const required = value => ((value == null || _.trim(value) === '')
  ? new ValidationError('Required', errorCodes.required)
  : undefined);

export const requiredArray = value => ((_.isArray(value) && value.length)
  ? undefined
  : new ValidationError('Required', errorCodes.required));

export const max = limit => value => ((value && value.length > limit)
  ? new ValidationError('Too long', errorCodes.tooLong, { values: { limit } })
  : undefined);

export const min = limit => value => (value && value.length < limit
  ? new ValidationError('Too short', errorCodes.tooShort, { values: { limit } })
  : undefined);

export const maxValue = limit => value => (value && value > limit
  ? new ValidationError('Too high', errorCodes.tooHigh, { values: { limit } })
  : undefined);

export const int = (value) => {
  if (value == null || _.trim(value) === '') return undefined;
  return Number.isInteger(parseFloat(value, 10))
    ? undefined
    : new ValidationError('Must be an integer', errorCodes.mustbeAnInteger);
};

export const regex = (pattern, message) => (value) => {
  if (value && !pattern.test(value)) {
    return message || new ValidationError('Must match pattern', errorCodes.mustMatchPattern, { values: { pattern } });
  }
  return undefined;
};

export const minValue = limit => (value) => {
  const num = parseFloat(value);
  if (Number.isNaN(num)) return undefined;
  return (num < limit) ? new ValidationError('Too Low', errorCodes.tooLow, { values: { limit } }) : undefined;
};

export const every = validator => (value) => {
  if (value == null) return undefined;
  const results = _.compact(value.map(validator));
  return _.head(results);
};

export const some = validator => (value) => {
  if (value == null) return undefined;
  const results = value.map(validator);
  const onlyFailures = _.compact(results);
  return (results.length === onlyFailures.length) ? _.head(onlyFailures) : undefined;
};

export const forPath = (path, validator) => (value) => {
  if (value == null) return undefined;
  const selectedValue = _.get(value, path);
  return validator(selectedValue);
};


export const composeForLocale = (...validators) => (localeInfo) => {
  return compose(...validators.map(validator => validator(localeInfo)));
};

/* eslint-disable-next-line arrow-body-style */
export const forDefaultLocale = validator => ({ locales }) => {
  const defaultLocale = locales[0];
  return forPath(
    defaultLocale,
    replaceMessage(
      validator,
      error => new ValidationError(
        error.message,
        error.code,
        { ...error.context, locale: defaultLocale }
      )
    )
  );
};

export const forCurrentLocale = validator => ({ locale }) => {
  return forPath(locale, validator);
};

export const forAllLocales = validator => ({ locales }) => {
  return compose(...locales.map(locale => replaceMessage(
    forPath(locale, validator),
    error => new ValidationError(error.message, error.code, { ...error.context, locale })
  )));
};

export const forAtLeastOneLocale = (validator) => {
  return ({ locales }) => (value) => {
    const results = locales
      .map(locale => forPath(locale, validator)(value))
      .filter(Boolean);

    if (results.length === locales.length) return results[0];
    return null;
  };
};


const TAG_REGEX = /^[a-z0-9][a-z0-9-]*[a-z0-9]$/;

export const tag = val => (((!val || TAG_REGEX.test(val)))
  ? undefined
  : new ValidationError(
    'RequiredTags must consist only of lowercase letters (a-z), numbers (0-9) and dashes (-)',
    errorCodes.tagRequirement
  ));

export const validateTags = every(tag);

const SLUG_REGEX = /^[a-z0-9][a-z0-9-]*$/;

export const slug = val => (((!val || SLUG_REGEX.test(val)))
  ? undefined
  : new ValidationError(
    'RequiredTags must consist only of lowercase letters (a-z), numbers (0-9) and dashes (-)',
    errorCodes.tagRequirement
  ));

export const validateRegions = every(r => (r.isComplete
  ? null
  : new ValidationError('Region is not complete.', errorCodes.regionNotComplete)));

export const validateAddresses = every(a => (a.isComplete
  ? null
  : new ValidationError('Address is not complete.', errorCodes.addressNotComplete)));


const ISO_DATE_REGEX = /^(\d{4})-(\d\d)-(\d\d)$/;

export const dateFormat = str => ((!str || ISO_DATE_REGEX.test(str))
  ? null
  : new ValidationError('Date must be yyyy-mm-dd', errorCodes.dateFormat));

// eslint-disable-next-line arrow-body-style
export const calendarDate = (str) => {
  if (!str) return null;

  const errorMessage = new ValidationError('Date must be a valid calendar date', errorCodes.dateMustBeValidDate);
  const date = new Date(str);
  const match = ISO_DATE_REGEX.exec(str);

  if (!match) return errorMessage;

  const [, , month] = match;
  const m = date.getUTCMonth() + 1;

  if (isNaN(date.valueOf()) || (parseInt(month, 10) !== m)) { // eslint-disable-line no-restricted-globals
    return errorMessage;
  }

  return null;
};

export const cleanPhoneNumber = str => str.replace(/\D/g, '');

// Valid phone # -- SUPER simple by design
// Technically the SDKs all support alpha numeric dialing, extentions, etc.
// However, this is hard to validate, so I'm not unitl it's absoloutly necessary.
export const phoneNumber = (str) => {
  if (!str) return undefined;
  const clean = cleanPhoneNumber(str);
  if (clean.length !== 10 && clean.length !== 11) {
    return new ValidationError('Phone number must include area code', errorCodes.phoneMustHaveAreaCode);
  }
  return undefined;
};

// See https://en.wikipedia.org/wiki/Postal_code#Reserved_characters for discussion
// of reserved characters in Canadian postal codes.
// eslint-disable-next-line max-len
const CAN_POSTAL_CODE_REGEX = /^[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]$/;
const US_ZIP_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/i;

export const postalCode = str => ((!str || CAN_POSTAL_CODE_REGEX.test(str.toUpperCase())
  ? undefined
  : new ValidationError('Postal code must be in format A1A 1B1', errorCodes.postalCodeRequirement)));

export const zipCode = str => ((!str || US_ZIP_CODE_REGEX.test(str.toUpperCase())
  ? undefined
  : new ValidationError('Zip code must be in format 12345 and 12345-6789', errorCodes.zipCodeRequirement)));

export const atLeastOneCompleteLocalization = requiredLocalizableFields => localeContext => (values) => {
  const locales = _.get(localeContext, 'locales');
  if (!locales) return null;

  const resultLocales = locales
    .map((locale) => {
      const validationErrors = requiredLocalizableFields.map((fieldName) => {
        // Done in two steps to handle multi-part fieldNames (eg: `collection.nameLocalized`)
        const dictionary = _.get(values, fieldName);
        return required(_.get(dictionary, locale));
      });

      return {
        locale,
        validationErrors,
        isEmpty: validationErrors.every(r => !!r),
        isComplete: validationErrors.every(r => !r)
      };
    });

  // if every locale is valid and at least one is complete, return `null`
  const areAllValid = resultLocales.every(it => it.isEmpty || it.isComplete);
  const areSomeComplete = resultLocales.some(it => it.isComplete);
  if (areAllValid && areSomeComplete) return null;

  const currentResultLocale = resultLocales.find(it => it.locale === localeContext.locale);
  const firstInvalidLocale = resultLocales.find(it => !(it.isEmpty || it.isComplete));

  // if current locale is complete return "Missing required fields in {first invalid locale}. Please update."
  if (currentResultLocale.isComplete) {
    return new ValidationError(
      `Missing required fields. Please update.`,
      errorCodes.missingFieldsInLanguage,
      { locale: firstInvalidLocale.locale }
    );
  }

  // return mapped errors for current locale
  return _.zipObject(requiredLocalizableFields, currentResultLocale.validationErrors);
};

export const validateUrl = (url) => {
  // No need to validate a null/undefined URL
  if (!url) return undefined;

  try {
    // Try to create a new URL object from the user typed url string - let it do the heavy lifting
    const urlObj = new URL(url);

    // Check for inncorrect protocol
    // eg. 'htp://apple.com'
    if (urlObj.protocol !== 'https:' && urlObj.protocol !== 'http:') {
      throw new Error();
    }

    // Check for inncorrect part of scheme
    if (!url.includes('://')) {
      throw new Error();
    }

    // Check for inncorrect domain
    // eg. 'http:// apple.com' or 'http: //apple.com'
    if (urlObj.hostname.startsWith('%20') || urlObj.hostname.endsWith('%20')) {
      throw new Error();
    }

    return undefined;
  } catch (error) {
    return new ValidationError('Invalid URL', errorCodes.invalidURL);
  }
};

export const formatUrl = (url) => {
  // No need to format a null/undefined URL
  if (!url) return '';

  // Remove any leading or trailing whitespace
  let newUrl = url.trim();

  // Check for missing scheme
  if (!newUrl.includes(':/') && (!newUrl.includes('https://') && !newUrl.includes('http://'))) {
    newUrl = `https://${newUrl}`;
  }

  try {
    // Try to create a new URL object from the user typed url string - let it do the heavy lifting
    const urlObj = new URL(newUrl);

    // Check for inncorrect domain
    // eg. 'http:// apple.com' or 'http: //apple.com'
    if (urlObj.hostname.startsWith('%20') || urlObj.hostname.endsWith('%20')) {
      throw new Error();
    }

    // Since new URL() will convert whitespace to the ASCII character convert it back
    const replaceWhiteSpaceChar = urlObj.includes('%20') ? urlObj.replace('%20', ' ') : urlObj.hostname;

    return `${urlObj.protocol}//${replaceWhiteSpaceChar}${urlObj.pathname === '/' ? '' : urlObj.pathname}`;
  } catch (error) {
    // If there is an error creating an instace of URL then return the string
    // that has had some manipulation done
    return newUrl;
  }
};

export const noWhiteSpace = value => ((/\s/.test(value))
  // \s means whitespace character like: spaces, tabs, line breaks, and etc
  ? new ValidationError('No white space', errorCodes.noWhiteSpace)
  : undefined);

export const hexColor = (value) => {
  if (!value) return undefined;
  return ((/^#[A-Fa-f0-9]{6}$/.test(value))
    ? undefined
    : new ValidationError('Hexidecimal Color Code Required', errorCodes.hexColor));
};
