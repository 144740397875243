import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RelativeLink from "../routing/RelativeLink";
import OfferListView from "./OfferListView";

class OffersView extends Component {
  render() {
    const { property } = this.props;
    if (!property) return null;
    return (
      <OfferListView
        propertyId={property.id}
        basePath={`../businesses`}
        relativeLink={RelativeLink}
      />
    );
  }
}

OffersView.propTypes = {
  property: PropTypes.object
};

export default OffersView;
