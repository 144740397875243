import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import BigModal from '../../BigModal/BigModal';
import TagField from '../../reactFinalForm/fields/TagField';
import SimpleForm from '../../reactFinalForm/SimpleForm';
import { compose, every, min, tag } from '../../reactFinalForm/validators';
import Menu from '../collections/Menu';
import Message from '../collections/Message';
import Button from '../elements/Button';
import CancelButton from '../elements/CancelButton';
import View from '../layout/View';

const tagValidator = every(compose(min(2), tag));

const TagEditModal = ({
  tags, onSave, onCancel, error, ...rest
}) => {
  const { strings } = useI18Next();

  return (
    <BigModal {...rest}>
      <SimpleForm initialValues={{ tags }} onSubmit={onSave}>
        <BigModal.Contents>
          <BigModal.Header className='p1'>
            <Menu secondary>
              <Menu.Item fitted>
                <CancelButton onClick={onCancel}/>
              </Menu.Item>
              <Menu.Menu position='right'>
                <Menu.Item fitted>
                  <Button type='submit' primary>{strings('ui.label.save')}</Button>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </BigModal.Header>
          <BigModal.Body>
            <View>
              <View.Section>
                <h1>{strings('ui.component.tagEditModal.editTags')}</h1>
                <TagField name='tags' wrapLabel validate={tagValidator}/>
                {error && <Message error>{error.message}</Message>}
              </View.Section>
            </View>
          </BigModal.Body>
        </BigModal.Contents>
      </SimpleForm>
    </BigModal>
  );
};

TagEditModal.propTypes = {
  tags: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.instanceOf(Error)
};

export default TagEditModal;
