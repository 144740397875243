import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import PropertyProfileImage from 'web-react-ui/src/components/image/PropertyProfileImage';

import propertyModule from '../../modules/property';

const NavLogo = ({ property }) => {
  if (!property) return null;
  const propertyLogoUrl = _.get(property, 'profileImage.url');
  return (
    <PropertyProfileImage imageUrl={propertyLogoUrl} propertyName={property.name}/>
  );
};

NavLogo.propTypes = {
  property: PropTypes.object
};

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state)
});

export default connect(mapState)(NavLogo);
