/* eslint-disable no-alert */
import React, {
  useEffect,
  useState,
} from 'react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import authModule from 'shared/src/modules/auth';
import usePermissions from 'shared/src/hooks/usePermissions';
import useRequest from 'shared/src/hooks/useRequest';
import {
  saveUserName,
  saveRoleChanges,
  saveLocationRoleChanges,
} from 'shared/src/helpers/userUtils';
import Location from 'web-react-ui/src/types/Location.interface';
import { Role } from 'web-react-ui/src/types/User.interface';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import EditUserView from 'web-react-ui/src/chakra/users/EditUserView';
import client from '../../../services/client';

const EditUserViewModel = ({
  propertyId,
  businessId,
  baseUrl,
}: {
  propertyId: string,
  businessId: string,
  baseUrl: string
}): JSX.Element => {
  const toast = useToast();
  const { userId } = useParams<Record<string, string>>();
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const history = useHistory();
  const [refetchId, setRefetchId] = useState(0);
  const { pathname, state }: { pathname: string, state: { query: string } } = useLocation();

  const canEditUser = usePermissions({ userId }, ['user.update']);

  const canUpdateRoles = usePermissions(
    { propertyId, businessId, userId },
    ['property.business.promote', 'property.business.demote'],
  );

  const requestBase = client.properties.for(propertyId).businesses.for(businessId);
  const { result: user, loading: isLoadingUser } = useRequest(
    () => client.users.for(userId).details(),
    { userId },
  );

  const { result: businessRoles, loading: isLoadingBusinessRoles } = useRequest(
    () => requestBase.users.for(userId).getRoles({ context: ['PB'] }),
    { userId, propertyId, businessId, refetchId },
  );

  const filteredBusinessRoles = businessRoles?.items?.filter((item: Role) => item.type === 'property-business')
    .map((item: Role) => item.id) || [];

  const { result: roles, loading: isLoadingRoles } = useRequest(() => client.roles.list(), {});

  const getLocations = () => requestBase.locations.listAdmin();
  const { result: locations, loading: isLoadingLocations } = useRequest(getLocations, { propertyId, businessId });

  const getUserLocationRoles = async () => {
    const temp: any = {};
    if (locations?.items && user) {
      const res = locations.items.map(async (location: Location) => {
        const hasPermissions = await client.access.checkPermissions(
          { propertyId, businessId, locationId: location.id },
          ['property.business.location.role.search'],
        );
        if (!hasPermissions) return false;
        const roleResult = await requestBase.locations.for(location.id).users.for(user.id).getRoles();
        return { id: location.id, items: roleResult.items };
      });

      const final = await Promise.all(res);
      final.filter((item: any) => item.items.length > 0).forEach((item: any) => {
        temp[item.id] = item.items;
      });
    }

    return temp;
  };

  const { result: userLocationRoles, loading: isLoadingUserLocationRoles } = useRequest(
    getUserLocationRoles,
    { user, locations, propertyId, businessId, refetchId },
  );

  const save = (values: Record<string, any>) => {
    const { name, roles: newRoles = [], locationRoles: newLocationRoles = {} } = values;
    const context = { propertyId, businessId };
    return Promise.all([
      (canUpdateRoles && Promise.resolve(saveRoleChanges(user, context, filteredBusinessRoles, newRoles, client))),
      (canEditUser && Promise.resolve(saveUserName(user, name, client))),
      (canUpdateRoles && Promise.resolve(saveLocationRoleChanges(
        user,
        context,
        locations?.items,
        userLocationRoles,
        newLocationRoles,
        client,
      ))),
    ]);
  };

  const saveRequest = useRequest(save);
  useEffect(() => {
    const toastProps = { duration: 5000, isClosable: true, position: 'top-right' };
    if (saveRequest.settled && !saveRequest.error) {
      // @ts-ignore
      toast({ ...toastProps, title: 'User Profile Saved!', status: 'success' });
      setRefetchId(refetchId + 1);
    } else if (saveRequest.error) {
      // @ts-ignore
      toast({ ...toastProps, title: saveRequest.error, status: 'error' });
    }
  }, [saveRequest.settled, saveRequest.error, saveRequest.result]);

  const handleSave = async (values: Record<string, any>) => {
    if (!user) return false;
    await saveRequest.run(values);
    return true;
  };

  const handleCancel = () => history.push({
    pathname: pathname.replace('/edit', ''),
    state,
  });

  const deleteRequest = useRequest(
    async () => {
      await requestBase.users.for(user.id).remove({ recursive: true });
    },
  );

  const handleDelete = async () => {
    if (!user) return null;
    /* eslint-disable-next-line no-restricted-globals */
    const shouldDelete = confirm(
      /* eslint-disable-next-line max-len */
      `Are you sure you want to delete ${user.email}? Deleting this user will remove all property, business, and location roles.`,
    );
    if (!shouldDelete) return null;

    await deleteRequest.run();
    if (!deleteRequest.error) {
      history.push(baseUrl);
    }

    return true;
  };

  const key = [...filteredBusinessRoles, user?.name].join(':');
  const isLoading = isLoadingUserLocationRoles
    || isLoadingBusinessRoles
    || isLoadingLocations
    || isLoadingUser
    || isLoadingRoles;

  return (
    <WaitFor waitFor={currentUser && user}>
      {currentUser && user && roles && (
        <EditUserView
          key={key}
          isSaving={saveRequest.loading}
          isLoading={isLoading}
          isDeleting={deleteRequest.loading}
          user={user}
          canEditUser={canEditUser}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
          roles={roles?.items.filter((role: Role) => role.type === 'property-business')}
          secondaryRoles={roles?.items.filter((role: Role) => role.type === 'property-business-location')}
          locations={locations?.items}
          roleTypes={['Business Roles', 'Location Roles']}
          canUpdateRoles={canUpdateRoles}
          initialRoles={filteredBusinessRoles}
          initialLocationRoles={userLocationRoles}
          backUrl={baseUrl}
          context="business"
          operationError={deleteRequest.error}
        />
      )}
    </WaitFor>
  );
};

export default EditUserViewModel;
