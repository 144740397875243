import React from 'react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import Label from 'web-react-ui/src/components/elements/Label';

const STATUS = {
  live: {
    color: 'green',
    labelKey: 'ui.component.offerListItem.field.label.live'
  },
  expired: {
    color: 'grey',
    labelKey: 'ui.component.offerListItem.field.label.expired'
  },
  draft: {
    color: 'yellow',
    labelKey: 'ui.component.offerListItem.field.label.draft'
  },
  disabled: {
    color: 'grey',
    labelKey: 'ui.component.offerListItem.field.label.disabled'
  },
  scheduled: {
    color: 'blue',
    labelKey: 'ui.component.offerListItem.field.label.scheduled'
  }
};

const OfferStatus = ({ offer }) => {
  const { strings } = useI18Next();
  if (!offer) return null;
  const status = STATUS[offer.status];
  if (!status) return null;

  return (
    <Label size='mini' color={status.color}>{strings(status.labelKey)}</Label>
  );
};

export default OfferStatus;
