import { Box, Heading, Stack, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useFormReducer from 'shared/src/hooks/useFormReducer';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { FormSpy } from '../../reactFinalForm';
import PrioritySelectField from '../../reactFinalForm/fields/PrioritySelectField';
import FormReducer from '../../reactFinalForm/FormReducer';
import ErrorMessage from '../entities/ErrorMessage';
import LocalizedOfferPreview from '../preview/LocalizedOfferPreview';
import Advanced from './Advanced';
import OfferInfo from './OfferInfo';
import OfferLocationsSection from './OfferLocationsSection';
import OfferScheduleFields from './OfferScheduleFields';
import PunchCardRedemption from './PunchCardRedemption';

const PunchCardOffer = ({
  error, dashboard, isPriorityDisabled, plansHref, plan, hasPerformanceError
}) => {
  const { strings } = useI18Next();
  const [endDateWarning, setEndDateWarning] = useState(null);

  useFormReducer((values, form, getValue) => {
    const flowVars = getValue('flow.vars');

    form.change('offerType', 'punchcard');
    form.change('flow.type', 'punchcard');
    let flags = getValue('flags').slice();
    if (flags.includes('limited')) {
      flags = flags.filter(flag => flag !== 'limited');
      form.change('flags', flags);
    }
    if (!flags.includes('punchcard')) {
      flags.push('punchcard');
      form.change('flags', flags);
    }
    if (!flags.includes('exclusive')) {
      flags.push('exclusive');
      form.change('flags', flags);
    }

    if (!flowVars.punches) form.change('flow.vars.punches', 6);
    if (!flowVars.validation) form.change('flow.vars.validation', 'location+fallback');

    let oncePerUser = flowVars.oncePerUser;

    if (oncePerUser == null) {
      // If offer is limited, assume it's because we've set it to one per user
      oncePerUser = flowVars.isLimited;
      form.change('flow.vars.oncePerUser', oncePerUser);
    }

    if (oncePerUser) {
      form.change('flow.vars.isLimited', true);
      form.change('flow.vars.userLimit', 1);
    } else {
      form.change('flow.vars.isLimited', false);
    }
  });

  const warningEndDate = (startDate, endDate) => {
    // Create new date objects from the string date values
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Set the start date to a year forward to compare against the end date
    startDateObj.setFullYear(startDateObj.getFullYear() + 1);

    // Get the localized warning message
    const warning = ((startDateObj && endDateObj) && endDateObj < startDateObj)
      ? strings('ui.component.offerScheduleFields.warningEndDate') : null;

    // Set the warning message
    setEndDateWarning(warning);
  };

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing={5} w='100%' p={5} align='flex-start'>
      <ErrorMessage error={error} />
      <VStack spacing={10} flexGrow={1} w='100%'>
        <Box w='100%'>
          <OfferInfo dashboard={dashboard} hasPerformanceError={hasPerformanceError} />
        </Box>

        <Box w='100%'>
          <PunchCardRedemption dashboard={dashboard} name='flow.vars' />
        </Box>

        <Box w='100%'>
          <Heading color='gray.700' mb={10} pb={3} borderBottomWidth='1px' borderColor='gray.300'>
            {strings('ui.component.exclusiveOffer.schedule')}
          </Heading>
          <OfferScheduleFields
            name='schedules[0]'
            label={strings('ui.component.punchCardOffer.schedules')}
            dashboard={dashboard}
            endDateWarning={endDateWarning}
            plan={plan}
            plansHref={plansHref}
          />
        </Box>

        <Box w='100%'>
          <FormSpy subscription={{ values: true }}>
            {({ values: { propertyId, businessId } }) => (
              <OfferLocationsSection propertyId={propertyId} businessId={businessId} dashboard={dashboard} />
            )}
          </FormSpy>

          {!dashboard && (
            <Advanced />
          )}
        </Box>

        <Box w='100%'>
          <Heading color='gray.700' mb={10} pb={3} borderBottomWidth='1px' borderColor='gray.300'>
            {strings('dashboard.component.editOfferForm.field.label.display')}
          </Heading>
          <PrioritySelectField disabled={isPriorityDisabled} plansHref={plansHref} />
        </Box>
      </VStack>
      <ErrorMessage error={error} />
      <Box w='100%' maxW='377px' style={{ pointerEvents: 'none' }}>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <LocalizedOfferPreview
              offer={values}
              image={values.heroImages?.[0]}
            />
          )}
        </FormSpy>
      </Box>
      <FormReducer>
        {(values) => {
          const schedule = values && values.schedules ? values.schedules[0] : null;

          // Only check to warn the user if there is a start/end date for an offer
          if (schedule && schedule.startDate && schedule.endDate) {
            warningEndDate(schedule.startDate, schedule.endDate);
          }
        }}
      </FormReducer>
    </Stack>
  );
};

PunchCardOffer.propTypes = {
  error: PropTypes.object,
  dashboard: PropTypes.bool,
  isPriorityDisabled: PropTypes.bool,
  plan: PropTypes.object,
  plansHref: PropTypes.string,
  hasPerformanceError: PropTypes.bool
};

export default PunchCardOffer;
