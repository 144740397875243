/* eslint camelcase: 0, class-methods-use-this: 0 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Input from 'semantic-ui-react/dist/es/elements/Input';
import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';

import Button from 'web-react-ui/src/components/elements/Button';
import Icon from 'web-react-ui/src/components/elements/Icon';
import List from 'web-react-ui/src/components/elements/List';
import EmptyList from 'web-react-ui/src/components/entities/EmptyList';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import propertyModule from '../../../modules/property';
import client from '../../../services/client';
import confirm from '../../../services/confirm';

const IntegrationItem = (props) => {
  const { integration } = props;

  return (
    <List.Item as={Link} to={`/${props.property.id}/integrations/${integration.id}`}>
      <List.Content>
        <Icon className='long arrow alternate right pt1 pull-right' />
        <List.Header className='pt1 pb1'>{props.integration.name}</List.Header>
      </List.Content>
    </List.Item>
  );
};

IntegrationItem.propTypes = {
  integration: PropTypes.object,
  property: PropTypes.object
};

const formatGroupTitle = (name) => {
  switch (name) {
    case 'ios':
      return 'iOS';
    default:
      return _.capitalize(name);
  }
};

const IntegrationList = (props) => {
  const { integrations, title, property } = props;
  if (!integrations) return null;
  return (
    <div className='mv3'>
      <h3>{title}</h3>
      <List celled selection>
        {_.map(
          integrations,
          i => <IntegrationItem integration={i} property={property} key={i.id} />
        )}
      </List>
    </div>
  );
};

IntegrationList.propTypes = {
  integrations: PropTypes.array,
  title: PropTypes.string,
  property: PropTypes.object
};

const fetchIntegrations = ({ query, property }) => {
  return client.properties.for(property.id).integrations.search({ query });
};

const IntegrationsView = () => {
  const [deleting, setDeleting] = useState();
  const [tokenError, setTokenError] = useState();
  const property = useSelector(state => propertyModule.selectors.property.getData(state));
  const [query, debouncedQueryHandler] = useQueryDebounce(property);

  const deleteToken = async (token) => {
    if (!confirm(`Are you sure you want to delete the API Token ${token.name}?`)) return false;
    setDeleting(true);
    setTokenError(tokenError);

    try {
      await client.properties.for(property.id).tokens.for(token.id).delete();
    } catch (err) {
      setTokenError(err);
    } finally {
      setDeleting(false);
    }
    return true;
  };

  const propertyId = property && property.id;

  return (
    <View>
      <View.Section>
        <div className='flex'>
          <Input
            type='search'
            fluid
            defaultValue={query}
            onChange={e => debouncedQueryHandler(e.target.value)}
            icon='search'
            placeholder='Find Integration...'
            className='fg1'
          />
        </div>
      </View.Section>
      <View.Section className='mb2'>
        <PagedList fetchList={fetchIntegrations} params={{ query, property }}>
          {({ items, isEmpty, loading }) => {
            const integrationEntries = Object.entries(_.groupBy(items, 'target'));
            return (
              <WaitFor waitFor={!loading} wrapContents>
                {isEmpty && <EmptyList message='No Active Integrations' />}
                {integrationEntries.map(([name, integrations]) => (
                  <IntegrationList
                    key={name}
                    integrations={integrations}
                    title={formatGroupTitle(name)}
                    property={property}
                  />
                ))}
                <Button
                  as={Link}
                  basic
                  className='text'
                  floated='right'
                  to={`/${propertyId}/property-settings/integrations/add`}
                >
                  + Add Integration
                </Button>
              </WaitFor>
            );
          }}
        </PagedList>
      </View.Section>
      <View.Section>
        <h3>API Tokens</h3>
        <PagedList fetchList={() => client.properties.for(property.id).tokens.list()}>
          {({ items, isEmpty }) => (
            <WaitFor waitFor={!deleting} wrapContents>
              <List celled>
                {isEmpty && <EmptyList message='No API Tokens' />}
                {items.map(token => (
                  <List.Item key={token.id}>
                    <div className='flex aic'>
                      <List.Content>
                        <List.Header className='pt1 pb1'>{token.name}</List.Header>
                      </List.Content>
                      <List.Content className='mla'>
                        <Button onClick={() => deleteToken(token)}>DELETE</Button>
                      </List.Content>
                    </div>
                  </List.Item>
                ))}
              </List>
            </WaitFor>
          )}
        </PagedList>
        <ErrorMessage error={tokenError} />
        <Button
          as={Link}
          basic
          className='text'
          floated='right'
          to={`/${propertyId}/property-settings/integrations/tokens/add`}
        >
          + Add API Token
        </Button>
      </View.Section>
    </View>
  );
};

const PropertySettingsIntegrations = IntegrationsView;

PropertySettingsIntegrations.propTypes = {
  property: PropTypes.object
};

export default PropertySettingsIntegrations;
