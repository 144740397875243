import useQuerystring from './useQuerystring';

const identity = x => x;
const identityTransport = {
  parse: identity,
  serialize: identity
};

/** Hook to Get/Set a specific querystring param
 *
 * @param param:String - query parameter name
 * @param defaultValue:any - value to return when param not set in querystring
 * @param options.squash:Boolean - (optional) remove param from querystring when it matches the default value
 * @param options.transport.serialize:(T)=>String - (optional) serialize to querystring param
 * @param options.transport.parse:(String)=>T - (optional) parse querystring param to value
 *        (return undefined to use defaultValue)
 * @returns [
 *   T,
 *   (T)=>void
 * ]
 */
function useQuerystringParam(
  param,
  defaultValue,
  {
    transport: {
      parse = identity,
      serialize = identity
    } = identityTransport,
    squash
  } = {}
) {
  const [qs, updateQs] = useQuerystring();

  const actualValue = qs[param];
  const parsedValue = parse(actualValue);
  const value = (actualValue != null && parsedValue != null)
    ? parsedValue
    : defaultValue;

  const update = (newValue) => {
    if (newValue === value) return;
    const serialized = squash && (newValue === defaultValue)
      ? undefined
      : serialize(newValue);
    updateQs({ [param]: serialized });
  };

  return [value, update];
}

const transport = {
  identity: identityTransport,
  boolean: {
    parse: it => it === 'true',
    serialize: String
  },
  naturalNumber: {
    parse: (it) => {
      const int = parseInt(it, 10);
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(int) || int < 0) return undefined;
      return int;
    },
    serialize: it => String(Math.max(it || 0, 0))
  },
  positiveNumber: {
    parse: (it) => {
      const int = parseInt(it, 10);
      if (!int || int < 0) return undefined;
      return int;
    }
  },
  float: {
    parse: (it) => {
      const float = parseFloat(it, 10);
      if (isNaN(float)) return undefined;
      return float;
    }
  },
  array: {
    parse: (it) => {
      if (!it) return undefined;
      return it.split(',');
    },
    serialize: (it) => {
      if (!Array.isArray(it)) return it;
      return it.join(',');
    }
  },
  dateString: {
    parse: (it) => {
      if (!it) return undefined;
      return isNaN(new Date(it)) ? undefined : it;
    },
    serialize: (it) => {
      return isNaN(new Date(it)) ? undefined : it;
    }
  }
};

export {
  useQuerystringParam,
  transport
};
