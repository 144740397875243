import PropTypes from 'prop-types';
import React from 'react';
import List from '../elements/List';
import {
  formatFileDimensions,
  formatFileSize,
  formatFileTypes
} from './imageUtils';

const ImageInfo = ({
  type, dimensions, size, maxSize, over
}) => {
  const fileTypes = formatFileTypes(type);
  const fileDimensions = formatFileDimensions(dimensions);
  const fileSize = size && `${formatFileSize(size)}`;
  const maxFileSize = maxSize && `≤${formatFileSize(maxSize)}`;
  return (
    <div className={`image-upload__info-banner ${over ? 'image-upload__info-banner--over' : ''}`}>
      <List bulleted horizontal size='tiny'>
        {fileTypes && <List.Item>{fileTypes}</List.Item>}
        {fileDimensions && <List.Item>{fileDimensions}</List.Item>}
        {fileSize && <List.Item>{fileSize}</List.Item>}
        {maxFileSize && <List.Item>{maxFileSize}</List.Item>}
      </List>
    </div>
  );
};

const dimension = PropTypes.shape({ w: PropTypes.number, h: PropTypes.number });
ImageInfo.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  dimensions: PropTypes.oneOfType([dimension, PropTypes.arrayOf(dimension)]),
  size: PropTypes.number,
  maxSize: PropTypes.number,
  over: PropTypes.bool
};

export default ImageInfo;
