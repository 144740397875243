import React from 'react';
import { Flex, Heading, Image, Link, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const OfferTypeCard = ({
  offerType,
  onClick,
  enabled,
  plansHref
}: {
  offerType: {
    color: string,
    label: string,
    desc: string,
    title: string,
    imgSrc: string,
    disabledDesc: string,
  },
  onClick: () => void,
  enabled: boolean,
  plansHref: string,
}) => {
  const { strings } = useI18Next();

  const disabledStyle = !enabled ? { 'p, h2, img': { opacity: 0.3 } } : undefined;

  return (
    <Flex
      shadow='md'
      p={8}
      borderRadius={8}
      borderColor='gray.100'
      borderWidth='1px'
      w='100%'
      _hover={enabled ? {
        borderColor: enabled ? 'blue.500' : '',
        transform: 'scale(1.01)',
      } : undefined}
      sx={disabledStyle}
      transition='all ease 0.2s'
      cursor={enabled ? 'pointer' : 'default'}
      onClick={enabled ? onClick : undefined}
      data-testid={offerType.title}
    >
      <Image src={offerType.imgSrc} mr={10} />
      <Flex flexDir='column' gap={2}>
        <Text color={offerType.color}>{offerType.label}</Text>
        <Heading fontSize='2xl'>{offerType.title}</Heading>
        {enabled ? (
          <Text fontSize='lg'>{offerType.desc}</Text>
        ) : (
          <>
            <Text fontSize='lg'>{offerType.disabledDesc}</Text>
            {!!plansHref && (
              <Link
                fontSize='lg'
                fontWeight={700}
                href={plansHref}
                color='blue.600'
                target='_blank'
                rel='noopener noreferrer'
              >
                {strings('ui.component.offerTypeCard')}
                &nbsp;<ArrowForwardIcon />
              </Link>
            )}
          </>
        )}

      </Flex>
    </Flex>
  );
};

export default OfferTypeCard;
