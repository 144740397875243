/* eslint no-underscore-dangle: 0 */
/* eslint-disable-next-line no-redeclare */
/* global window */
import { createStore, applyMiddleware, compose } from 'redux';
import authMiddleware from 'shared/src/modules/auth/middleware';
import propertyMiddleware from '../modules/property/middleware';
import businessMiddleware from '../modules/businesses/middleware';
import planMiddleware from '../modules/plan/middleware';
import rootReducer from '../rootReducer';
import client from '../services/client';

// Redux Devtools -- skipped in production
const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middlewares = [
  authMiddleware(client),
  propertyMiddleware(client),
  businessMiddleware(client),
  planMiddleware(client)
];

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(
    ...middlewares
  ))
);
