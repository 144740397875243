import React from 'react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import Label from '../elements/Label';

const HiddenLabel = () => {
  const { strings } = useI18Next();

  return (
    <div className='mla'>
      <Label
        size='mini'
        color='gray'
        title={strings('shared.field.label.hidden.info')}
      >
        {strings('shared.field.label.hidden.value').toUpperCase()}
      </Label>
    </div>
  );
};

export default HiddenLabel;
