import {
  getBase64StringFromBlob,
  getImageFileUrlDimensions,
} from '../../imageWell/imageUtils';
import { FileTypeInfo } from '../types';

const validateFileRestrictions = async (file: Blob, fileTypeInfo: FileTypeInfo) => {
  const result = {
    canUpload: true,
    cropRequired: false,
    fileTypeInfo
  };

  if (!fileTypeInfo) {
    result.canUpload = false;
    return result;
  }

  const {
    restrictions: {
      maxSize, types, dimensions: {
        maxWidth, minWidth, maxHeight, minHeight,
      },
    },
  } = fileTypeInfo;

  // File too big
  if (file.size > maxSize) {
    result.cropRequired = true;
  }

  const base64Url = await getBase64StringFromBlob(file);
  const fileDimensions = await getImageFileUrlDimensions(base64Url);

  const { width, height } = fileDimensions || {};

  // Image dimensions too small
  if (width < minWidth) {
    result.canUpload = false;
  }
  if (height < minHeight) {
    result.canUpload = false;
  }

  // Image dimensions too large
  // We can eventually auto-crop but we'd need a way to automatically adjust the compression level
  if (width > maxWidth) {
    result.cropRequired = true;
  }
  if (height > maxHeight) {
    result.cropRequired = true;
  }

  const maxAspect = maxHeight / minWidth;
  const minAspect = minHeight / maxWidth;
  const fileAspect = height / width;

  // Aspect Ratio Too Extreme
  if (fileAspect > maxAspect || fileAspect < minAspect) {
    result.cropRequired = true;
  }

  return result;
};

export default validateFileRestrictions;
