/* eslint class-methods-use-this:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Map from 'web-react-ui/src/map/Map';

class LocationsMap extends Component {
  static propTypes = {
    locations: PropTypes.array,
    property: PropTypes.object,
    business: PropTypes.object,
    onPopupClick: PropTypes.func,
    maxHeight: PropTypes.string,
    minWidth: PropTypes.string
  };

  state = {
    redirectToLocation: false
  };

  render() {
    const {
      locations, onPopupClick, maxHeight, minWidth
    } = this.props;

    const markers = _.map(locations, (location) => {
      const isEnabled = location.isEnabled !== false;
      const popupContent = isEnabled
        ? [
          `<p class="lead">${location.name}</p>`,
          location.address ? `<p>${location.address.label}</p>` : '',
          `<button class="ui button primary small">See Details</button>`
        ].join('')
        : [
          `<p class="lead">`,
          `${location.name} <span class="ui label red tiny">Disabled</span>`,
          location.address ? `<p>${location.address.label}</p>` : '',
          `</p>`,
          `<button class="ui button primary small">Enable ${location.name}</button>`
        ].join('');
      return ({
        center: [location.address.centre.lat, location.address.centre.long],
        title: location.name,
        isEnabled,
        popup: {
          content: popupContent,
          data: {
            id: location.id,
            isEnabled
          }
        }
      });
    });

    return (
      <div>
        <Map
          style={{ maxHeight: `${maxHeight}`, minWidth: `${minWidth}` }}
          initialZoom={15}
          markers={markers}
          onPopupClick={onPopupClick}
        />
      </div>
    );
  }
}

export default LocationsMap;
