import {
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import propertyModule from '../modules/property/index';

const usePropertySetting = (propertySettingKey = '') => {
  const [propertySetting, setPropertySetting] = useState(null);
  const property = useSelector(state => propertyModule.selectors.property.getData(state));

  useEffect(() => {
    if (property) {
      const { settings } = property;
      const settingValue = settings[propertySettingKey];
      setPropertySetting(settingValue);
    }
  }, [property]);

  return propertySetting;
};

usePropertySetting.propTypes = {
  propertySettingKey: PropTypes.string
};

export default usePropertySetting;
