import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import Divider from 'web-react-ui/src/components/elements/Divider';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import { BooleanField } from 'web-react-ui/src/reactFinalForm/fields';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';

import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';

import propertyModule from '../../modules/property';
import client from '../../services/client';


const HOSTED_CLIENT_KEY_NAME = 'Hosted Redemption (Managed)';
const HOSTED_SERVER_KEY_NAME = 'Hosted Redemption Server (Managed)';

async function getHostedIntegrationKeyClient(propertyRepo) {
  const keys = await propertyRepo.integrations.list();
  const key = keys.items.find(i => i.name === HOSTED_CLIENT_KEY_NAME);
  if (key) return key;

  const property = await propertyRepo.details();

  if (!process.env.REACT_APP_ROOT_DOMAIN) throw new Error('Missing required config REACT_APP_ROOT_DOMAIN.');

  return propertyRepo.integrations.create({
    name: HOSTED_CLIENT_KEY_NAME,
    sdk: 'browser',
    corsDomains: [
      `http://${property.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`,
      `https://${property.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`
    ]
  });
}

async function getHostedIntegrationKeyServer(propertyRepo) {
  const keys = await propertyRepo.integrations.list();
  const key = keys.items.find(i => i.name === HOSTED_SERVER_KEY_NAME);
  if (key) return key;

  return propertyRepo.integrations.create({
    name: HOSTED_SERVER_KEY_NAME,
    sdk: 'server',
    corsDomains: []
  });
}

const updateHostedRedemption = async ({ propertyRepo, hostedRedemptionEnabled }) => {
  const updates = [];
  if (hostedRedemptionEnabled) {
    const integrationKeyClient = await getHostedIntegrationKeyClient(propertyRepo);
    updates.push(propertyRepo.setSetting('hosted_redemption.integration_key_client', integrationKeyClient.writeKey));
    // TODO: Remove when deployed and all properties are migrated
    updates.push(propertyRepo.setSetting('hosted_redemption.integration_key', integrationKeyClient.writeKey));

    const integrationKeyServer = await getHostedIntegrationKeyServer(propertyRepo);
    updates.push(propertyRepo.setSetting('hosted_redemption.integration_key_server', integrationKeyServer.writeKey));

    const hostedRedeemUrl = `https://{{subdomain}}.{{domain}}/offer/{{offer_slug}}/redeem`;
    updates.push(propertyRepo.setSetting('template.redeem_url.offer', hostedRedeemUrl));
  } else {
    updates.push(propertyRepo.setSetting('hosted_redemption.integration_key', null));
    updates.push(propertyRepo.setSetting('template.redeem_url.offer', null));
  }
  await propertyRepo.setSetting('hosted_redemption.enabled', hostedRedemptionEnabled);
  await Promise.all(updates);
};


const PropertyOfferRedemption = ({ propertyId }) => {
  const [initialFormValues, setInitialFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (propertyId) {
      const runEffect = async () => {
        try {
          const propertyRepo = client.properties.for(propertyId);
          const { value } = await propertyRepo.getSetting('hosted_redemption.enabled');
          setInitialFormValues({
            hostedRedemptionEnabled: value
          });
        } catch (err) {
          setError(err);
        }
        setIsLoading(false);
      };
      runEffect();
    }
  }, [propertyId]);

  const handleSubmit = async ({ hostedRedemptionEnabled }) => {
    try {
      setError(null);
      const propertyRepo = client.properties.for(propertyId); // This is always synchronous
      await updateHostedRedemption({ propertyRepo, hostedRedemptionEnabled });
    } catch (err) {
      setError(err);
    }
    dispatch(propertyModule.actions.invalidate());
  };

  return (
    <View.Section>
      <div className='mb4'>
        <h3>Hosted Offer Redemption</h3>
      </div>
      <ErrorMessage error={error} />
      <SimpleForm
        onSubmit={handleSubmit}
        loading={isLoading}
        initialValues={initialFormValues}
      >
        <BooleanField
          className='pt2'
          toggle
          name='hostedRedemptionEnabled'
          label='Enable Offer Redemption'
        />
        <div className='flex jcfe'>
          <FormSubmit label='Save' />
        </div>
      </SimpleForm>
      <Divider section />
    </View.Section>
  );
};

PropertyOfferRedemption.propTypes = {
  propertyId: PropTypes.string.isRequired
};

export default PropertyOfferRedemption;
