import React from 'react';

const Context = React.createContext();

const injectClient = (Component) => {
  const wrapped = props => (
    <Context.Consumer>
      {client => <Component {...props} client={client}/>}
    </Context.Consumer>
  );

  wrapped.displayName = `client(${Component.displayName || Component.name})`;

  return wrapped;
};

export { injectClient };

export default Context;
