import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from 'web-react-ui/src/components/collections/Form';
import Request from 'web-react-ui/src/data/Request';
import businessesModule from '../../modules/businesses';
import propertyModule from '../../modules/property';
import client from '../../services/client';

const ALL_LOCATIONS = 'ALL_LOCATIONS';

const fetchLocations = async ({ propertyId, businessId }) => {
  const locations = await client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .locations.search({
      hasRoles: true,
      accessContext: 'implicit'
    })
    .then(client.pagination.loadRest);

  const locationList = locations.items.map(location => ({
    key: location.id,
    text: location.name,
    value: location.id
  }));

  locationList.unshift({
    key: 0,
    text: 'All Locations',
    value: ALL_LOCATIONS // can't set to null or label doesn't display (thanks, SUI)
  });


  return locationList;
};

class MyLocationSelector extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    business: PropTypes.object,
    property: PropTypes.object,
    offerLocations: PropTypes.array,
    locationId: PropTypes.string
  };

  onLocationChange(event, select) {
    const value = select.value;
    this.props.onChange(value === ALL_LOCATIONS ? null : value);
  }

  fetchLocations({ businessId, propertyId }) {
    if (!businessId || !propertyId) return false;
    const { offerLocations, locationId } = this.props;

    return fetchLocations({ businessId, propertyId })
      .then((locations) => {
        if (!offerLocations) return locations;
        return (
          locations.filter(location => offerLocations.includes(location.value) || location.value === ALL_LOCATIONS)
        );
      })
      .then((locations) => {
        if (locationId != null && !locations.find(l => l.value === locationId)) {
          this.onLocationChange(null, locations[0]);
        }
        return locations;
      });
  }

  render() {
    const { property, business, locationId } = this.props;
    return (
      <Request request={this.fetchLocations.bind(this)} params={{ businessId: business.id, propertyId: property.id }}>
        {({ data, loading }) => (
          <Form.Field>
            <Form.Select
              fluid
              options={data}
              value={locationId || ALL_LOCATIONS}
              loading={loading}
              onChange={this.onLocationChange.bind(this)}
            />
          </Form.Field>
        )}
      </Request>
    );
  }
}

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state),
  business: businessesModule.selectors.business.getData(state)
});

export default connect(mapState)(MyLocationSelector);
