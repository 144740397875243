/* eslint no-nested-ternary: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import numbersLargeBlurred from '../../assets/stats/Numbers_Large_Blurred.png';
import Label from '../elements/Label';

const MetricsFunnelStep = ({
  label, explanationLabel, data, isLast, restricted
}) => (
  <div className='mt1 mb1 metrics-funnel metrics-funnel__step-wrapper'>
    <div className='metrics-funnel metrics-funnel__step'>
      <div className='tac mb1 metrics-funnel__label tooltip'>
        <Label className='tooltiptext'>
          {explanationLabel}
        </Label>
        {label}
      </div>
      {restricted
        ? (
          <img className='metrics-funnel__placeholder-number' src={numbersLargeBlurred}/>
        )
        : data.total
          ? (
            <h2 className='mt0 mb1 metrics-funnel__value'>{Math.ceil(data.total)}</h2>
          ) : (
            <h2 className='mt0 mb1 metrics-funnel__value'>–</h2>
          )
      }
      {isLast ? null : <div className="metrics-funnel__arrow mla hide--tablet"/>}
    </div>
  </div>
);

MetricsFunnelStep.propTypes = {
  label: PropTypes.string,
  explanationLabel: PropTypes.string,
  data: PropTypes.obj,
  isLast: PropTypes.bool,
  restricted: PropTypes.bool
};

export default MetricsFunnelStep;
