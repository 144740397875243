import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box, Flex, FormLabel, Heading, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import { FormSpy } from 'react-final-form';
import { FaInfoCircle } from 'react-icons/fa';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import useValidationOptions from 'shared/src/hooks/useValidationOptions';

import SimpleSelectField from '../../reactFinalForm/fields/SimpleSelectField';
import PinField from './PinField';

const VALIDATION_DEFAULTS = ['none', 'location+fallback', 'location', 'location+pin', 'pin'];
// Exclusive Offers VALIDATION_DEFAULTS
// - By Phone: No Valdation = []
// - In Person: All Valdation = VALIDATION_DEFAULTS
// - Online: ['none', 'pin']

// Reach Offers VALIDATION_DEFAULTS - Same as Exclusive
// - By Phone: No Valdation = []
// - In Person: All Valdation = VALIDATION_DEFAULTS
// - Online: ['none', 'pin']

// Limited Offers VALIDATION_DEFAULTS
// - By Phone: No Valdation = [] - Pre offer-validation-options Epic
// - In Person: All Valdation = ['location+fallback', 'location', 'location+pin', 'pin']

// PunchCard Offers VALIDATION_DEFAULTS - Same as Exclusive
// - By Phone: No Valdation = [] - Pre offer-validation-options Epic
// - In Person: All Valdation = ['location+fallback', 'location', 'location+pin', 'pin']
// - Online: ['none', 'pin']

const filterAvailableOptions = (options, includeInOptions) => { // includeInOptions ie. ['pin', 'none']
  if (!includeInOptions.length) return null;
  return options.filter(opt => includeInOptions.includes(opt.value));
};

const getDefaultOption = (type) => {
  switch (type) {
    case 'punchcard':
      return 'location';
    case 'limited':
      return 'location+fallback';
    default:
      return 'none';
  }
};

const noneInOptions = VALIDATION_DEFAULTS.filter(opt => opt !== 'none');

const getOptionsFromAction = (action, offerType) => {
  switch (action) {
    case 'call':
      return [];
    case 'website':
      return ['none', 'pin'];
    default: // 'present'
      return offerType === 'limited' ? noneInOptions : VALIDATION_DEFAULTS;
  }
};

const OfferValidationSelection = ({ name, validationType = 'redeem', chakra, ...boxProps }) => {
  const validationOptions = useValidationOptions();
  const { strings } = useI18Next();
  return (
    <Box {...boxProps}>
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => {
          let includes = [];
          let fieldName = `${name}.validation`;

          const action = values?.flow?.vars?.action; // present, call, website
          const offerType = values?.offerType;

          switch (validationType) { // PunchCard Offer's validationType => 'punches'
            case 'redeem':
              includes = getOptionsFromAction(action, offerType);
              break;
            default:
              includes = noneInOptions; // Options will not change based on (reward) action: 'present, website, call'
              fieldName = `${name}.punchValidation`;
              break;
          }

          const options = filterAvailableOptions(validationOptions, includes);
          if (!options) {
            form.change(fieldName, 'none');
            return null;
          }

          const labelText = offerType === 'punchcard'
            ? strings('dashboard.component.editOfferForm.field.label.earnPunchValidation')
            : strings('dashboard.component.editOfferForm.field.label.customerRedeemValidation');

          const headingText = offerType === 'punchcard'
            ? strings('ui.component.offerRedeemLimitsInfo.validationOptions')
            : strings('ui.component.offerRedeemLimitsInfo.validation');

          return (
            <>
              <Heading size={offerType === 'punchcard' ? 'sm' : 'md'} mb={5}>
                {headingText}
              </Heading>
              <FormLabel>
                <Flex align='center'>
                  {labelText}
                  <Tooltip
                    label={strings('dashboard.component.editOfferForm.field.label.validationCriteriaTooltip')}
                    placement='top'
                    hasArrow
                  >
                    <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
                  </Tooltip>
                </Flex>
              </FormLabel>
              <Stack direction={{ base: 'column', lg: 'row' }} spacing={5}>
                <Box minW='50%'>
                  <SimpleSelectField
                    name={fieldName}
                    options={options}
                    defaultValue={getDefaultOption(offerType)}
                    wrapLabel
                    chakra
                  />
                </Box>
                <Box>
                  <Text color='gray.500' size='sm'>
                    {validationOptions.find(item => item.value === values.flow?.vars?.validation)?.info}
                  </Text>
                </Box>
              </Stack>

            </>
          );
        }}
      </FormSpy>
      <FormSpy subscription={{ values: true }}>
        {({ values, form }) => {
          const fieldName = validationType === 'redeem' ? `${name}.validation` : `${name}.punchValidation`;
          const validation = _.get(values, fieldName); // location, location+pin, pin, and etc

          if (validation !== 'pin' && validation !== 'location+pin') {
            const pins = values && values.pins;
            if (pins && pins.length) form.change('pins', []);
            return null;
          }

          const punches = _.get(values, `${name}.punches`, Infinity);

          return (
            <Box mt={5} p={5} borderWidth='1px' borderColor='gray.200' borderRadius={6}>
              <FormLabel>{strings('ui.component.pinField.field.label.pinFrequency')}</FormLabel>
              <PinField maxSkips={punches - 1} />
            </Box>
          );
        }}
      </FormSpy>
    </Box>
  );
};

OfferValidationSelection.propTypes = {
  name: PropTypes.string,
  validationType: PropTypes.string,
  chakra: PropTypes.bool,
};

export default OfferValidationSelection;
