import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import SuiForm from '../../components/collections/Form';
import ToggleGroup from '../../components/elements/ToggleGroup';

import FieldError from './FieldError';
import FieldLabel from "./FieldLabel";


class ToggleGroupField extends Component {
  render() {
    const {
      name,
      label,
      validate,
      options,
      optional,
      disabled
    } = this.props;

    return (
      <Field name={name} validate={validate} allowNull>
        {({ input }) => (
          <SuiForm.Field disabled={disabled}>
            <FieldLabel label={label} optional={optional} />
            <ToggleGroup {...input} options={options}/>
            <FieldError name={name}/>
          </SuiForm.Field>
        )}
      </Field>
    );
  }
}

ToggleGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.func,
  optional: PropTypes.bool,
  disabled: PropTypes.bool,
  /* eslint-disable */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ).isRequired
  /* eslint-enable */
};

export default ToggleGroupField;
