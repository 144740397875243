import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { resetIdCounter } from 'downshift';
import { Field } from 'react-final-form';

import { injectClient } from 'shared/src/components/contexts/ClientContext';

import SearchBase from '../../components/newSearchBase/SearchBase';
import FieldError from './FieldError';


const searchRegions = client => query => (
  client
    .regions.search({ query })
    .then(pagedList => pagedList.items)
);

// FIXME: Move searchRegions(client) into componentDidMount
class RegionField extends Component {
  render() {
    const {
      name, label, client, validate, idField = 'id', disabled
    } = this.props;

    resetIdCounter();

    return (
      <Field name={name} validate={validate}>
        {({ input }) => (
          <label className='field-label'>
            {label}
            <SearchBase
              getLabel={i => (i ? i.label : '')}
              multiSelect
              search={searchRegions(client)}
              idField={idField}
              defaultSelectedItem={input.value}
              disabled={disabled}
              labelColor='blue'
              {...input}
            />
            <FieldError name={name} alwaysShow={true} />
          </label>
        )}
      </Field>
    );
  }
}

RegionField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  client: PropTypes.object,
  validate: PropTypes.func,
  idField: PropTypes.string,
  disabled: PropTypes.bool
};

export default injectClient(RegionField);
