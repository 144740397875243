import React, {
  useCallback,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePromise from 'shared/src/hooks/usePromise';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import propertyModule from '../../modules/property';
import client from '../../services/client';
import confirm from '../../services/confirm';
import IntegrationDetails from './IntegrationDetails';

const IntegrationDetailsContainer = ({ integrationId }) => {
  const property = useSelector(propertyModule.selectors.property.getData);

  const fetchTags = async () => {
    const tags = await client.tags.list(property.id, client.tags.TYPE_NAMES.integration, integrationId);
    return tags?.items;
  };

  const saveTags = (newTags) => {
    return client.tags.reconcile(property.id, client.tags.TYPE_NAMES.integration, integrationId, newTags);
  };

  const [, isLoadingIntegration, result] = usePromise(
    async () => {
      if (!property) return [];
      return client
        .properties.for(property.id)
        .integrations.for(integrationId)
        .details();
    },
    [property, integrationId],
    undefined,
    true
  );

  const integration = result;

  const history = useHistory();

  const [deleting, setDeleting] = useState(false);

  const deleteIntegration = useCallback(
    // eslint-disable-next-line consistent-return
    async () => {
      if (!confirm(`Are you sure you want to delete ${integration.name}?`)) return false;
      setDeleting(true);
      await client
        .properties.for(property.id)
        .integrations.for(integration.id)
        .delete();
      setDeleting(false);
      history.push(`/${property.id}/property-settings/integrations`);
    },
    [property, integration]
  );

  if (!property) return null;

  return (
    <WaitFor waitFor={!deleting}>
      <IntegrationDetails
        property={property}
        fetchTags={fetchTags}
        saveTags={saveTags}
        integration={integration}
        isLoading={isLoadingIntegration}
        deleteIntegration={deleteIntegration}
      />
    </WaitFor>
  );
};

IntegrationDetailsContainer.propTypes = {
  integrationId: PropTypes.string.isRequired
};

export default IntegrationDetailsContainer;
