import React from 'react';
import { Flex, Heading, HStack } from '@chakra-ui/react';
import RoleBadge from 'web-react-ui/src/chakra/UserList/RoleBadge';
import { Role } from '../../types/User.interface';

interface Props {
  locationId: string;
  roleIds: string[];
  businessLocation: any;
  roles?: Role[];
}

const LocationRoles = ({
  businessLocation,
  locationId,
  roleIds,
  roles,
}: Props) => {
  return (
    <Flex pt={3} ml={10} align='center' flexWrap='wrap'>
      {!businessLocation && (
        <Heading fontSize='lg' fontWeight={400} minW='xs' mr={5}><i>Unknown Location:</i> {locationId}</Heading>
      )}
      {businessLocation && (
        <Heading fontSize='lg' fontWeight={400} minW='xs' mr={5}>
          {businessLocation.name}
        </Heading>
      )}

      <HStack spacing={5} mt={[2, 0]}>
        {roleIds.map((item) => {
          const thisRole = roles?.find(role => item === role.id);
          return thisRole ? (
            <RoleBadge key={thisRole.id} id={thisRole.id} label={thisRole.name} />
          ) : null;
        })}
      </HStack>
    </Flex>
  );
};

export default LocationRoles;
