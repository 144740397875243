import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../components/elements/Icon';
import Label from '../../components/elements/Label';

const TagChip = ({ tag, onDelete }) => (
  <Label color='blue' style={{ paddingRight: 0, margin: '2px' }}>
    <span style={{ wordBreak: 'break-word' }}>{tag}</span>
    <span onClick={onDelete} className='p1'>
      <Icon link fitted name='remove'/>
    </span>
  </Label>
);

TagChip.propTypes = {
  tag: PropTypes.string,
  onDelete: PropTypes.func
};

export default TagChip;
