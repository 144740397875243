import {
  useCallback,
  useRef,
  useState
} from 'react';

const useAwaitDisclosure = ({ defaultIsOpen = false } = {}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const ref = useRef();
  const onOpen = useCallback(() => {
    setIsOpen(true);
    return new Promise((resolve) => {
      ref.current = resolve;
    });
  }, []);
  const onClose = useCallback((result) => {
    setIsOpen(false);
    ref.current(result);
  }, []);
  return { isOpen, onClose, onOpen };
};

export default useAwaitDisclosure;

