/* eslint consistent-return:0 */
import React, { useCallback } from 'react';
import {
  Field,
  useField
} from 'react-final-form';
import SimpleSelectField from '../../reactFinalForm/fields/SimpleSelectField';
import Button from '../elements/Button';
import FilterRow from './FilterRow';
import './FilterSet.css';
import useAutoSelectFirst from './useAutoSelectFirst';

const MAX_CONDITIONS = 20;
const MAX_DEPTH = 1;

const DEFAULT_OPERATOR_OPTIONS = [
  { text: 'AND', value: 'and' },
  { text: 'OR', value: 'or' }
];

const DefaultEmpty = (
  <div>
    <em>No conditions.</em>
  </div>
);

const addButtonStyle = {
  margin: 'auto',
  display: 'block',
  transform: 'translateY(-50%)',
  position: 'absolute',
  left: '50%'
};

const AddButton = ({ onClick, depth }) => {
  if (!(depth > 0)) {
    return (
      <div className='mt1'>
        <Button type='button' color='blue' onClick={onClick}>+ Add Conditions</Button>
      </div>
    );
  }
  return (
    <Button type='button' circular icon='plus' onClick={onClick} style={addButtonStyle} />
  );
};

const GroupOperator = ({ operatorOptions, ...rest }) => {
  useAutoSelectFirst(rest.name, operatorOptions);
  return (
    <SimpleSelectField
      wrapLabel
      options={operatorOptions}
      {...rest}
      disabled={operatorOptions.length === 1}
      fieldColor='blue'
    />
  );
};

const SingleRow = ({ name, depth, maxDepth, rowConfig, ...rest }) => {
  const { input } = useField(name);
  const addRow = useCallback(
    () => {
      input.onChange({
        filters: [
          input.value,
          {}
        ]
      });
    },
    [input]
  );

  return (
    <div>
      <FilterRow name={name} config={rowConfig} {...rest} />
      {depth <= maxDepth && <AddButton depth={depth} onClick={addRow} />}
    </div>
  );
};

const Level = ({
  name,
  ...rest
}) => {
  const { input } = useField(name);
  if (input.value.filters) return <FilterSet name={name} {...rest} />;
  return <SingleRow name={name} {...rest} />;
};

const FilterSet = ({
  name,
  rowConfig,
  empty = DefaultEmpty,
  context,
  depth = 0,
  removeCallback
}) => {
  const maxDepth = rowConfig.maxDepth === undefined ? MAX_DEPTH : rowConfig.maxDepth;
  const { input } = useField(`${name}.filters`);
  const filters = input.value || [];

  const maxRowsReached = filters.length >= MAX_CONDITIONS;
  const maxDepthReached = depth > maxDepth;
  const operatorOptions = rowConfig.operatorOptions || DEFAULT_OPERATOR_OPTIONS;

  const addRow = useCallback(
    () => {
      input.onChange(filters.concat({}));
    },
    [input.onChange, filters]
  );

  const onRemoveRow = useCallback(
    (index) => {
      if (removeCallback && filters.length <= 1) return removeCallback();
      const newFilters = filters.slice();
      newFilters.splice(index, 1);
      input.onChange(newFilters);
    },
    [input.onChange, removeCallback, filters]
  );

  if (!filters.length) {
    return (
      <div className='c-filter-set'>
        {empty}
        <AddButton onClick={addRow} depth={depth} />
      </div>
    );
  }

  if (filters.length === 1) {
    return (
      <div className='c-filter-set__row-container'>
        <Level
          rowConfig={rowConfig}
          name={`${name}.filters[0]`}
          onRemoveRow={() => onRemoveRow(0)}
          removeCallback={() => onRemoveRow(0)}
          context={context}
          depth={depth + 1}
          maxDepth={maxDepth}
        />
        {
          maxRowsReached
            ? <Button disabled>Max Conditions Reached</Button>
            : !maxDepthReached && <AddButton onClick={addRow} depth={depth} />
        }
      </div>
    );
  }

  return (
    <div className='c-filter-set'>
      <div className='c-filter-set__row-container'>
        {filters.map((filter, index) => (
          <div className='c-filter-set__row' key={`${filter.target}${index}`}>
            <div className='c-filter-set__row-body'>
              <Field name={`${name}.filters[${index}`}>
                {({ input: rowInput }) => {
                  if (rowInput.value.filters) {
                    return (
                      <div className='c-filter-set__filter-set'>
                        <FilterSet
                          rowConfig={rowConfig}
                          name={`${name}.filters[${index}`}
                          context={context}
                          depth={1 + depth}
                          removeCallback={() => onRemoveRow(index)}
                        />
                      </div>
                    );
                  }
                  return (
                    <React.Fragment>
                      <SingleRow
                        config={rowConfig}
                        name={`${name}.filters[${index}]`}
                        onRemoveRow={() => onRemoveRow(index)}
                        context={context}
                        depth={depth + 1}
                        maxDepth={maxDepth}
                      />
                    </React.Fragment>
                  );
                }}
              </Field>
            </div>
            <div className='c-filter-set__operator'>
              <GroupOperator name={`${name}.operator`} fluid='false' operatorOptions={operatorOptions} />
            </div>
          </div>
        ))}
      </div>
      {
        maxRowsReached
          ? <Button disabled>Max Conditions Reached</Button>
          : !maxDepthReached && <AddButton onClick={addRow} depth={depth} />
      }

    </div>
  );
};

export default FilterSet;
