import {
  Box,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Field,
} from 'react-final-form';
import FieldLabel from 'web-react-ui/src/reactFinalForm/fields/FieldLabel';
import FieldError from './FieldError';

const normalizeColor = (val) => {
  if (!val) return val;
  if (val.charAt(0) === '#') return val;
  return `#${val}`;
};

const ColorField = ({
  name,
  label,
  validate,
  format,
  parse = normalizeColor,
  info
}) => {
  return (
    <Field name={name} validate={validate} format={format} parse={parse}>
      {({ input, meta }) => {
        return (
          <FieldLabel label={label}>
            <InputGroup>
              <Input {...input} />
              <InputRightElement>
                <Box p={1} w="3em" h="100%">
                  <label>
                    <Box
                      w="100%"
                      h="100%"
                      background={meta.valid ? input.value : undefined}
                      border="1px dashed silver"
                    />
                    <Input
                      w="5em"
                      hidden
                      type="color"
                      value={input.value}
                      onChange={input.onChange}
                    />
                  </label>
                </Box>
              </InputRightElement>
            </InputGroup>
            <p className="text--muted">{info}</p>
            <FieldError name={name} chakra alwaysShow={false} />
          </FieldLabel>
        );
      }}
    </Field>
  );
};

ColorField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  fluid: PropTypes.bool
};

export default ColorField;
