/* eslint no-unused-vars:0 */
/* eslint class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import ContentFilter from 'shared/src/helpers/valuableContentFilter/valuableContentFilter';
import useAwaitDisclosure from 'shared/src/hooks/useAwaitDisclosure';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import EditOfferMenu from 'web-react-ui/src/components/offers/EditOfferMenu';
import OfferTypeSelection from 'web-react-ui/src/components/offers/OfferTypeSelection';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import FormReducer from 'web-react-ui/src/reactFinalForm/FormReducer';
import { atLeastOneCompleteLocalization } from 'web-react-ui/src/reactFinalForm/validators';
import { PerformanceCheckModal } from 'web-react-ui/src/chakra/PerformanceCheckModal';

import businessesModule from '../../modules/businesses';
import propertyModule from '../../modules/property';
import client from '../../services/client';
import pipelines from '../../services/pipelines';
import ConnectedTranslationWrapper from '../i18n/ConnectedTranslationWrapper';

const filter = new ContentFilter();

const NewCreateOfferContainer = ({ returnTo, plan }) => {
  const [redirect, setRedirect] = useState(false);
  const [instance, setInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPriorityDisabled, setIsPriorityDisabled] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [formValues, setFormValues] = useState({});
  const { onOpen, onClose, isOpen } = useAwaitDisclosure();

  const property = useSelector(state => propertyModule.selectors.property.getData(state));
  const business = useSelector(state => businessesModule.selectors.business.getData(state));

  useEffect(() => {
    const runEffect = async () => {
      try {
        setIsLoading(true);
        if (property && business) {
          const initOffer = await client
            .properties.for(property.id)
            .businesses.for(business.id)
            .offers
            .initialize();
          setInstance(initOffer);
        }
      } catch (err) {
        throw new Error(err);
      }
      setIsLoading(false);
    };
    runEffect();
  }, [property, business]);

  useEffect(() => {
    const runEffect = async () => {
      try {
        setIsLoading(true);
        // TODO properly handle this in the SDK by passing an object with instances AND updates
        if (instance) {
          const offer = await instance.deprecatedGetOldOfferModel();
          setInitialValues(offer);
          // instance.setInstance(...) is a hack
          // For example with locations:
          // 1) We get the initialValues from the instance which contains all
          // of the Location ids allowed (so they're all selected by default)
          // 2) We reset the instance's internal value for locations
          // 3) When we save, the OfferUpdatePipeline diffs the form value with its internal state.
          // So if we didn't reset the value of locations
          // it would say, "oh, all these are already selected so I don't need to do anything"
          instance.setInstance('discoverableRegions', []);
          instance.setInstance('locations', []);
        }
      } catch (err) {
        throw new Error(err);
      }
      setIsLoading(false);
    };
    runEffect();
  }, [instance]);

  useEffect(() => {
    if (instance && (formValues && formValues.offerType)) {
      const resetInitValues = instance.resetOnNewOfferTypeChange(formValues, isPriorityDisabled);
      setInitialValues(resetInitValues);
    }
  }, [instance, (formValues && formValues.offerType)]);

  const handleCancel = () => setRedirect(returnTo);

  const handleFormChange = values => setFormValues(values);

  const handlePriorityDisabled = value => setIsPriorityDisabled(value);

  const handlePerformanceWarningCheck = async () => {
    /* prompt User offer's performance warning */
    if (formValues.enabled && filter.check(formValues)) return onOpen();
    return true;
  };

  const handleSubmit = async () => {
    const shouldSubmit = await handlePerformanceWarningCheck();
    if (!shouldSubmit) return;
    try {
      setIsLoading(true);
      // TODO: Pull out EditOfferPipeline instance values individually?
      // Form EditOfferForm needs to be updated to look for instance elements separately instead of under `offer`
      await instance.deprecatedUpdateWithOldOfferModel(formValues);
      const { id } = instance.getInstance('offer');
      const offerDetailsUrl = `/${property.id}/businesses/${business.id}/offers/${id}`;
      setRedirect(offerDetailsUrl);
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <BigModal open>
      <ConnectedTranslationWrapper>
        <LocalizableSimpleForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={isLoading}
          validate={pipelines.OfferUpdatePipeline.deprecatedValidateWithOldOfferModel}
          validateLocale={atLeastOneCompleteLocalization([
            'headlineLocalized', 'subHeadlineLocalized', 'detailsLocalized'
          ])}
          chakra
        >
          <BigModal.Contents>
            <BigModal.Header className='p1'>
              <Box maxW='8xl' mx='auto' p={3}>
                <EditOfferMenu handleCancel={handleCancel} />
              </Box>
            </BigModal.Header>
            <BigModal.Body>
              <Box my={5} maxW='8xl' mx='auto'>
                <PerformanceCheckModal isOpen={isOpen} onClose={onClose} />
                <OfferTypeSelection
                  propertyId={property.id}
                  businessId={business.id}
                  handlePriorityDisabled={handlePriorityDisabled}
                  plan={plan}
                  offerTypes={['exclusive', 'limited', 'punchcard', 'reach']}
                />
              </Box>
            </BigModal.Body>
          </BigModal.Contents>
          <FormReducer>
            {(values) => { return values?.offerType ? handleFormChange(values) : null; }}
          </FormReducer>
        </LocalizableSimpleForm>
      </ConnectedTranslationWrapper>
    </BigModal>
  );
};

NewCreateOfferContainer.propTypes = {
  returnTo: PropTypes.string,
  plan: PropTypes.shape({
    settings: PropTypes.shape({
      offer__feature__misc__allow_advanced_scheduling: PropTypes.bool
    })
  })
};

export default NewCreateOfferContainer;
