import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import SuiForm from '../../components/collections/Form';
import FieldLabel from "./FieldLabel";
import TagInput from './TagInput';

import FieldError from './FieldError';

const TagField = (props) => {
  const {
    name,
    label,
    validate,
    format,
    parse,
    optional,
    wrapLabel,
    info,
    disabled
  } = props;

  return (
    <Field name={name} validate={validate} format={format} parse={parse} info={info}>
      {({ input }) => (
        <SuiForm.Field disabled={disabled}>
          <FieldLabel label={label} wrapLabel={wrapLabel} optional={optional}>
            <TagInput {...input} />
            { info && <p className='text--muted'>{info}</p> }
            <FieldError name={name} />
          </FieldLabel>
        </SuiForm.Field>
      )}
    </Field>
  );
};
TagField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  fluid: PropTypes.bool,
  inputProps: PropTypes.object,
  optional: PropTypes.bool,
  wrapLabel: PropTypes.bool,
  info: PropTypes.string,
  disabled: PropTypes.bool
};

export default TagField;
