import {
  Image,
  Skeleton,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import useRequest from 'shared/src/hooks/useRequest';

const ImagePreview2 = ({ imageId, objectFit }) => {
  const client = useContext(ClientContext);
  const imageUrlRequest = useRequest(
    async ([imageId]) => {
      if (!imageId) return null;
      if (imageId.startsWith('data:')) return imageId; // base64 date url
      const imageDetails = await client.images.for(imageId).details();
      return imageDetails.url;
    },
    [imageId],
  );

  if (imageUrlRequest.loading) {
    return (
      <Skeleton h="100%" />
    );
  }

  return (
    <Image src={imageUrlRequest.result} maxHeight="100%" width="100%" objectFit={objectFit} />
  );
};

export default ImagePreview2;
