import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { formatDate, formatDateRange, formatTimeRanges, formatWeekDays } from '../../utils/dates';

const USABLE_SCHEDULE_TYPE = 'usable';

const SchedulePropType = PropTypes.shape({
  weekDays: PropTypes.arrayOf(PropTypes.number),
  timeRanges: PropTypes.arrayOf(PropTypes.array),
  startDate: PropTypes.string,
  endDate: PropTypes.string
});

const renderScheduleDate = (startDate, endDate, i18nLang, t) => {
  if (!endDate) {
    return `${t('ui.component.offerListItem.liveAsOf')} ${formatDate(startDate)}`;
  }
  return formatDateRange(startDate, endDate, i18nLang);
};

const OfferScheduleInfo = ({ schedules, schedule }) => {
  const displaySchedule = schedule || (schedules && schedules.find(s => s.type === USABLE_SCHEDULE_TYPE));
  const { strings, default: { language: i18nLang } } = useI18Next();

  if (!displaySchedule) {
    return (
      <em>
        <div className='pv1'>
          {strings('ui.component.offerScheduleInfo.noSchedules')}
        </div>
      </em>
    );
  }

  return (
    <React.Fragment>
      <div>{formatWeekDays(displaySchedule.weekDays, strings)}</div>
      <div>{formatTimeRanges(displaySchedule.timeRanges)}</div>
      <div>
        {displaySchedule.allDates ? (
          strings('ui.component.offerScheduleInfo.allTheTime')
        ) : (
          renderScheduleDate(displaySchedule.startDate, displaySchedule.endDate, i18nLang, strings)
        )}
      </div>
    </React.Fragment>
  );
};
OfferScheduleInfo.propTypes = {
  schedule: SchedulePropType,
  schedules: PropTypes.arrayOf(SchedulePropType)
};

export default OfferScheduleInfo;
