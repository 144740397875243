import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import ErrorAlert from '../../../../../chakra/ErrorAlert';
import InlineSupportLink from '../../../../../support/InlineSupportLink';

const Content = () => {
  return (<>
    Please try again. If this problem persists, <InlineSupportLink />
  </>);
};

const TITLE = 'There was an error loading images';

const Error = ({ pagedList }: { pagedList: any }) => {
  const toast = useToast();

  useEffect(
    () => {
      if (pagedList.error && pagedList.wasPaging) {
        toast({
          status: 'error',
          title: TITLE,
          description: <Content />,
          variant: 'subtle',
          isClosable: true
        });
      }
    },
    [pagedList.error, pagedList.wasPaging],
  );

  if (pagedList.wasPaging) return null;

  return (
    <ErrorAlert error={pagedList.error} title={TITLE}>
      <Content />
    </ErrorAlert>
  );
};

export default Error;
