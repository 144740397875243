import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import SuiForm from '../components/collections/Form';
import Button from '../components/elements/Button';
import ErrorMessage from '../components/entities/ErrorMessage';
import wrapSubmit from '../reactFinalForm/wrapSubmit';

class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {}
    };
  }

  static Page = ({ children }) => children;

  next = values => this.setState(state => ({
    page: Math.min(state.page + 1, this.props.children.length - 1),
    values
  }));

  previous = () => this.setState(state => ({
    page: Math.max(state.page - 1, 0)
  }));

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) return wrapSubmit(onSubmit)(values);
    return this.next(values);
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit.bind(this)}>
        {({ handleSubmit, form }) => {
          const { submitting, submitError } = form.getState();
          return (
            <SuiForm error onSubmit={handleSubmit} loading={submitting}>
              <div className='p1' style={{ clear: 'both' }}>
                {activePage}
              </div>
              <div className='p1' style={{ clear: 'both' }}>
                <div>
                  <ErrorMessage error={submitError}/>
                </div>
                <div className='flex'>
                  <div className='mla'>
                    <I18NextContext.Consumer>
                      {({ strings }) => (
                        <Button.Group>
                          {page > 0 && (
                            <Button type="button" onClick={this.previous}>
                              « {strings('ui.component.wizard.previous')}
                            </Button>
                          )}
                          {!isLastPage && (
                            <Button primary type="submit">
                              {strings('ui.component.wizard.next')} »
                            </Button>
                          )}
                          {isLastPage && (
                            <Button primary type="submit" disabled={submitting}>
                              {strings('ui.component.wizard.submit')}
                            </Button>
                          )}
                        </Button.Group>
                      )}
                    </I18NextContext.Consumer>
                  </div>
                </div>
              </div>
            </SuiForm>
          );
        }}
      </Form>
    );
  }
}

export default Wizard;
