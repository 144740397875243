import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import View from 'web-react-ui/src/components/layout/View';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import Message from 'web-react-ui/src/components/collections/Message';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';

import propertyModule from '../../modules/property';
import client from '../../services/client';
import usePropertySetting from '../../hooks/usePropertySettting';

const validateUrl = (val) => {
  if (!val) return '';
  return (/^https?:\/\/.+/.test(val) ? '' : 'Share URL must start with http:// or https://');
};

const PropertyShare = ({ propertyId, redirectTo }) => {
  const [property, setProperty] = useState({});
  const [initialFormValues, setInitialFormValues] = useState({});
  const [redirect, setRedirect] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const shareOffer = usePropertySetting('template.share_url.offer');
  const shareBusiness = usePropertySetting('template.share_url.business');
  const shareLocation = usePropertySetting('template.share_url.location');
  const dispatch = useDispatch();

  useEffect(() => {
    const runEffect = async () => {
      try {
        const propertyRepo = client.properties.for(propertyId);
        const data = await propertyRepo.details();
        setProperty(data);
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    };
    runEffect();
  }, []);

  useEffect(() => {
    let formValues = {};
    if (property) {
      formValues = {
        template: {
          share_url: {
            ...(shareOffer) && { offer: shareOffer },
            ...(shareBusiness) && { business: shareBusiness },
            ...(shareLocation) && { location: shareLocation }
          }
        }
      };
      setInitialFormValues(formValues);
    }
  }, [property, shareOffer, shareBusiness, shareLocation]);

  const handleSubmit = async (updatedFields) => {
    try {
      setError(null);
      const originalFormFields = initialFormValues;
      const updates = _.omit(updatedFields, ['id', 'slug', 'template']);

      const propertyRepo = client.properties.for(propertyId);
      await propertyRepo.update(originalFormFields, updates);
      await propertyRepo.setSetting('template.share_url.offer', updatedFields.template.share_url.offer);
      await propertyRepo.setSetting('template.share_url.business', updatedFields.template.share_url.business);
      await propertyRepo.setSetting('template.share_url.location', updatedFields.template.share_url.location);
    } catch (err) {
      setError(err);
    }
    setRedirect(redirectTo);
    dispatch(propertyModule.actions.invalidate());
  };

  if (redirect) return <Redirect to={redirect} />;

  const ROOT_URL = `${property && property.subdomain}.${process.env.REACT_APP_ROOT_DOMAIN}`;

  return (
    <View.Section>
      <h3>Sharing</h3>
      <SimpleForm
        onSubmit={handleSubmit}
        loading={isLoading}
        initialValues={initialFormValues}
      >
        <div>
          <ErrorMessage error={error} />
          <div className='pt2'>
            <h6>Offer</h6>
            <TextField
              parse={null}
              name='template.share_url.offer'
              placeholder={`${ROOT_URL}/offer/{{offer_slug}}`}
              wrapLabel
            />
          </div>
          <Message info>
            <ul>
              <li>{`{{business_slug}}: Unique string identifying an business.`}</li>
              <li>{`{{offer_slug}}: Unique string identifying an offer.`}</li>
            </ul>
          </Message>
          <div className='pt1'>
            <h6 className='mt1 mr1'>Business</h6>
            <TextField
              parse={null}
              name='template.share_url.business'
              placeholder={`${ROOT_URL}/business/{{business_slug}}`}
              validate={validateUrl}
              wrapLabel
            />
          </div>
          <Message info>
            <ul>
              <li>{`{{business_slug}}: Unique string identifying a business.`}</li>
            </ul>
          </Message>
          <div className='pt1'>
            <h6 className='mt1 mr1'>Business Location</h6>
            <TextField
              parse={null}
              name='template.share_url.location'
              placeholder={`${ROOT_URL}/business/{{business_slug}}/location/{{location_slug}}`}
              wrapLabel
            />
          </div>
          <Message info>
            <ul>
              <li>{`{{business_slug}}: Unique string identifying a business.`}</li>
              <li>{`{{location_slug}}: Unique string identifying a location.`}</li>
            </ul>
          </Message>
        </div>
        <div className='mt4'>
          <FormSubmit className='pull-right' label='Save'/>
        </div>
      </SimpleForm>
    </View.Section>
  );
};

PropertyShare.propTypes = {
  propertyId: PropTypes.string,
  redirectTo: PropTypes.string
};

export default PropertyShare;
