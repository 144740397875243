/* eslint react/prop-types: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import Menu from 'web-react-ui/src/components/collections/Menu';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import View from 'web-react-ui/src/components/layout/View';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import client from '../../services/client';
import DestinationEditForm from './DestinationEditForm';

class DestinationCreateModal extends Component {
  static propTypes = {
    history: PropTypes.object
  };


  async createDestination(values) {
    const { propertyId } = this.props;
    const destination = { type: 'webhook', ...values };
    await client.properties.for(propertyId).destinations.create(destination);
    this.goBack();
  }

  goBack() {
    const { history } = this.props;
    history.push('./');
  }

  render() {
    return (
      <BigModal open={true}>
        <SimpleForm onSubmit={this.createDestination.bind(this)}>
          <BigModal.Contents>
            <BigModal.Header className='p1'>
              <div className='flex'>
                <h1>Add Destination</h1>
                <div className='mla'>
                  <CancelButton onClick={this.goBack.bind(this)}/>
                </div>
              </div>
            </BigModal.Header>
            <BigModal.Body>
              <View>
                <View.Section narrow>
                  <DestinationEditForm/>
                </View.Section>
                <View.Section narrow>
                  <Menu secondary>
                    <Menu.Item fitted primary position='right'>
                      <FormSubmit label='Create'/>
                    </Menu.Item>
                  </Menu>
                </View.Section>
              </View>
            </BigModal.Body>
          </BigModal.Contents>
        </SimpleForm>
      </BigModal>
    );
  }
}

export default DestinationCreateModal;
