import React from 'react';
import { Box, Text, RadioGroup, Stack, Radio } from '@chakra-ui/react';
import { Field, useField, useForm, useFormState } from 'react-final-form';

import Location from '../../types/Location.interface';
import { Role } from '../../types/User.interface';

const LocationRolesField = ({
  location,
  roles,
}: {
  location: Location,
  roles: Role[],
}): JSX.Element => {
  const { input: { value: locationsValue } } = useField(`locations.${location.id}`, {});
  const form = useForm();

  if (!locationsValue) {
    form.change(`locationRoles[${location.id}]`, undefined);
  }

  return (
    <Box key={location.id}>
      <Box alignSelf='flex-start'>
        <Text fontSize='lg' fontWeight={500}>{location.name}</Text>
        <Text>{location.address?.label}</Text>
      </Box>
      {!!locationsValue && (
        <Box mt={5}>
          <Text fontWeight={500} fontSize='lg' mb={3}>Location Roles</Text>
          <Field name={`locationRoles[${location.id}][0].id`} defaultValue={roles[0].id}>
            {({ input }) => (
              <RadioGroup flexDir='column' {...input}>
                <Stack direction='column'>
                  {roles.map(role => (
                    <Radio
                      value={role.id}
                      size='lg'
                      key={role.id}
                      onChange={() => input.onChange(role.id)}
                    >
                      <Box alignSelf='flex-start'>
                        <Text fontSize='lg' fontWeight={500}>{role.name}</Text>
                        <Text>{role.description}</Text>
                      </Box>
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}
          </Field>
        </Box>
      )}
    </Box>
  );
};

export default LocationRolesField;
