import { Collapse } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  useField,
  useFormState
} from 'react-final-form';

const defaultPredicate = v => !!v;

/**
 * @param name: string // form field name
 * @param predicate: (value: any) => boolean // true to show, false to hide. `value` is form[name]
 * @param initialize: (values: object) => boolean
 *    // if `value` is undefined, call initialize with form values to determine initial `value`
 */

const Reveal = ({ name, predicate = defaultPredicate, initialize, children }) => {
  const { input } = useField(name);
  const { values } = useFormState();
  useEffect(
    () => {
      if (!initialize) return;
      if (input.value !== undefined && input.value !== '') return;
      input.onChange(initialize(values));
    },
    [initialize, input.value]
  );
  return (
    <Collapse in={predicate(input.value, values)}>
      {children}
    </Collapse>
  );
};

Reveal.propTypes = {
  name: PropTypes.string,
  predicate: PropTypes.func
};

export default Reveal;
