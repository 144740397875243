import PropTypes from 'prop-types';
import React from 'react';
import {
  Field,
  useField
} from 'react-final-form';
import { Box, Flex, Text } from '@chakra-ui/react';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { FormSpy } from '../../reactFinalForm';
import FieldError from '../../reactFinalForm/fields/FieldError';
import FormReducer from '../../reactFinalForm/FormReducer';
import {
  compose,
  every,
  max,
  min,
  regex,
  requiredArray
} from '../../reactFinalForm/validators';
import MultiPinField from './MultiPinField';
import PinControl from './PinControl';
import RadioButtonGroupField from '../../chakra/forms/RadioButtonGroup/RadioButtonGroupField';

const validatePin = (value) => {
  if (!value) return undefined;
  if (value.skips) return undefined; // Don't validate automatically generated pins because they're always "too long"
  // Validate max and min AND regex because max are separate concerns and provide nice error messages
  return compose(
    max(4),
    min(4),
    regex(/^\d{4}$/, 'PIN must be numeric')
  )(value.pin);
};

// eslint-disable-next-line arrow-body-style
const PinField = ({ maxSkips }) => {
  const { strings } = useI18Next();
  // meta.initial: the initial value of the field i.e. pinsMultiPunch
  // is 'undefined' if it was never initialized.
  // useField: It will manage the rerendering of 'pinsMultiPunch'
  // and only rerender if 'pinsMultiPunch' changes i.e. toggling between single/multi BooleanField
  // generatePins = !(undefined) i.e. true. Which will force the pins to generate each time
  const generatePins = !useField('pinsMultiPunch').meta.initial;

  return (
    <React.Fragment>
      <FormReducer>
        {(values, form) => {
          const pins = values.pins;
          if (!pins) return;
          form.batch(() => {
            if (values.pinsPerDayOfWeek == null) {
              form.change('pinsPerDayOfWeek', pins.some(pin => pin.weekday));
            }
            if (values.pinsMultiPunch == null) {
              form.change('pinsMultiPunch', pins.some(pin => pin.skips > 0));
            }
          });
        }}
      </FormReducer>

      <Box mb={5}>
        <Text color='gray.500'>
          {strings('ui.component.pinField.specifyPin')}
        </Text>
        <Flex my={3}>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <RadioButtonGroupField
                name='pinsPerDayOfWeek'
                value={values.pinsPerDayOfWeek || 0}
                options={[
                  { label: strings('ui.component.pinField.field.label.singlePin'), value: 0 },
                  { label: 'Multiple PIN', value: 1 }
                ]}
              />
            )}
          </FormSpy>
        </Flex>
        <FormSpy subscription={{ values: true }}>
          {({ values }) => (
            <Field name='pins' validate={compose(requiredArray, every(validatePin))}>
              {({ input }) => {
                const skips = values.pinsMultiPunch ? Math.min(4, maxSkips) : 0;
                return (
                  <React.Fragment>
                    <PinControl
                      perDayOfWeek={values.pinsPerDayOfWeek}
                      skips={skips}
                      generatePins={generatePins}
                      {...input}
                    />
                    <FieldError name='pins' alwaysShow />
                  </React.Fragment>
                );
              }}
            </Field>
          )}
        </FormSpy>

        <MultiPinField />
      </Box>
    </React.Fragment>
  );
};

PinField.propTypes = {
  maxSkips: PropTypes.number
};

export default PinField;
