/* eslint-disable-next-line no-redeclare */
/* globals window */

import PropTypes from 'prop-types';
import React from 'react';

class RollbarLogger extends React.Component {
  static propTypes = {
    children: PropTypes.any
  };

  state = {
    error: null
  };

  static getDerivedStateFromError(error) {
    const Rollbar = window && window.Rollbar;

    if (Rollbar) window.Rollbar.error(error);

    return { error };
  }

  render() {
    if (!this.state.error) return this.props.children;

    return (
      <div>
        We encountered an error. Please refresh to try again, or <a
        target='_blank'
        rel='noopener noreferrer'
        href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
      >
        Contact Support
      </a>.
      </div>
    );
  }
}

export default RollbarLogger;
