/* eslint consistent-return:0 */
import {
  Box,
  Skeleton,
} from '@chakra-ui/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import ErrorMessage from '../../entities/ErrorMessage';
import {
  BACKGROUND_COLOR,
  BORDER,
  BORDER_COLOR,
  BORDER_RADIUS,
} from '../constants';
import NewFileMeta from '../imageLibrary/types/NewFileMeta.interface';
import SetFileFunction from '../imageLibrary/types/SetFileFunction.interface';
import { useImageWellContext } from '../ImageWellContext';
import {
  Base64ImageUrl,
  FileTypeInfo,
  ImageFile,
} from '../types';
import {
  autoCrop,
  getBase64StringFromBlob,
  getImageFileUrlAsFile,
} from '../../imageWell/imageUtils';
import ImageCropModal from '../../imageWell/upload2/ImageCropModal2';
import withFileType from '../../imageWell/withFileType';
import ImageConfirmModal from './ImageConfirmModal';
import ImagePreviewModal from './ImagePreviewModal';
import ImageUploadPlaceholder2 from './ImageUploadPlaceholder';
import {
  initialState,
  stateReducer,
} from './imageUploadWrapperState';
import uploadImage from './uploadImage';
import validateFileRestrictions from './validateFileRestrictions';

interface ImageUploadWrapperProps {
  children: React.ReactNode;
  onChange: (newValue: string, meta?: NewFileMeta) => void;
  fileType: string;
  fileTypeInfo: FileTypeInfo;
  aspectRatio?: number;
  skipUpload?: boolean;
  isEmpty?: boolean;
  stealth?: boolean;
  captureClick?: boolean;
  handleDrag?: boolean;
  registerSetExternalUrl?: (((setFile: SetFileFunction) => void));
  onCancel?: () => void;
}

const ImageUploadWrapper = ({
  children,
  onChange,
  fileType,
  fileTypeInfo,
  aspectRatio,
  skipUpload,
  isEmpty,
  stealth,
  captureClick,
  handleDrag,
  registerSetExternalUrl,
  onCancel,
}: ImageUploadWrapperProps) => {
  const client = useContext(ClientContext);
  const imageWellContext = useImageWellContext();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const accept = fileTypeInfo?.restrictions.types?.join(',');

  const startCrop = async (base64File: Base64ImageUrl) => {
    // HACK: Let component render once before rendering the Cropper
    // (which can tie up the main thread if the image is very large)
    dispatch({ type: 'cropPrepare', payload: base64File });
    await new Promise(res => setTimeout(res));
    dispatch({ type: 'cropStart', payload: base64File });
  };

  const handleCropDone = (fileDataUrl: Base64ImageUrl) => {
    if (!fileDataUrl) {
      dispatch({
        type: 'cropCancelled',
      });
      return onCancel?.();
    }

    dispatch({
      type: 'cropSuccess',
      payload: fileDataUrl,
    });
    // Slightly wasteful because they both set a lot of the same properties
    return dispatch({
      type: 'confirmStart',
      payload: fileDataUrl,
    });
  };

  const uploadFile = async (imageFile?: File | Blob) => {
    // We can upload it, but we may automatically shrink it
    const resizedBase64Image = await autoCrop(imageFile, fileTypeInfo);

    if (skipUpload) {
      dispatch({
        type: 'uploadSuccess',
        payload: resizedBase64Image,
      });
      onChange(resizedBase64Image, state.meta);
      return;
    }

    dispatch({
      type: 'uploadStart',
      payload: resizedBase64Image,
    });

    const resizedFile = getImageFileUrlAsFile(resizedBase64Image);

    try {
      const imageId = await uploadImage(
        client,
        resizedFile,
        fileType,
        imageWellContext.propertyId,
        imageWellContext.businessId,
        imageWellContext.locationId,
        state.meta?.keywords,
      );
      dispatch({
        type: 'uploadSuccess',
        payload: imageId,
      });

      onChange(imageId, state.meta);
    } catch (error) {
      dispatch({
        type: 'uploadError',
        payload: error,
      });
    }
  };

  const handleNewFile = async (newFile: ImageFile | Blob, meta?: NewFileMeta) => {
    // evaluate restrictions
    let validationResult;
    try {
      validationResult = await validateFileRestrictions(newFile, fileTypeInfo);
    } catch (error) {
      return dispatch({
        type: 'fileInvalid',
        error: error as Error,
      });
    }

    // We just can't upload it at all
    if (!validationResult.canUpload) {
      return dispatch({
        type: 'imageInvalid',
        payload: validationResult,
      });
    }

    dispatch({
      type: 'setMeta',
      payload: meta,
    });

    const base64Url = await getBase64StringFromBlob(newFile);

    // We could upload it, but the user must crop it first
    if (validationResult.cropRequired) {
      return startCrop(base64Url);
    }

    return dispatch({
      type: 'confirmStart',
      payload: base64Url,
    });
  };

  const handleExternalUrl: SetFileFunction = async (src, previewUrl, meta) => {
    dispatch({
      type: 'previewStart',
      payload: previewUrl,
    });

    const result = await fetch(src);
    const blob = await result.blob();

    handleNewFile(blob, meta);
  };

  const confirmCrop = () => {
    dispatch({ type: 'confirmEnd' });
    startCrop(state.ephemeralImageUrl);
  };

  const confirmSave = async () => {
    dispatch({ type: 'confirmEnd' });
    const file = await getImageFileUrlAsFile(state.ephemeralImageUrl);
    return uploadFile(file);
  };

  const confirmCancel = () => {
    dispatch({ type: 'confirmEnd' });
    onCancel?.();
  };

  const onDrop = async (event: DragEvent) => {
    if (handleDrag === false) return;
    event.preventDefault();
    event.stopPropagation();

    if (state.uploading) {
      // Don't let the window handle the drop
      return;
    }

    const file = event.dataTransfer?.files[0];
    if (!file) return;

    await handleNewFile(file);
  };

  const onDragOver = useCallback(
    (e) => {
      if (handleDrag === false) return;

      e.preventDefault();
      e.stopPropagation();
      dispatch({ type: 'dragIn' });
    },
    [],
  );

  const onDragIn = useCallback(
    (e) => {
      if (handleDrag === false) return;
      e.stopPropagation();
      dispatch({ type: 'dragIn' });
    },
    [],
  );

  const onDragOut = useCallback(
    (e) => {
      if (handleDrag === false) return;
      e.stopPropagation();
      dispatch({ type: 'dragOut' });
    },
    [],
  );

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = (event.target.files && event.target.files[0]) || (event.dataTransfer.files[0]);
    if (!file) return;
    handleNewFile(file);
  };

  useEffect(
    () => {
      if (!registerSetExternalUrl) return;
      registerSetExternalUrl(handleExternalUrl);
    },
    [fileTypeInfo],
  );

  if (!fileTypeInfo) {
    return (
      <Skeleton height="100%" />
    );
  }

  // eslint-disable-next-line no-nested-ternary
  const bgColor = state.dragging
    ? 'aliceblue'
    : isEmpty
      ? 'white'
      : BACKGROUND_COLOR;

  return (
    <Box
      position="relative"
      height="100%"
      width="100%"
      minH="0"
      onDragOver={onDragOver}
      onDragEnd={onDragOut}
      onDragLeave={onDragOut}
      onDragEnter={onDragIn}
      onDrop={onDrop}
      backgroundColor={bgColor}
      border={BORDER}
      borderWidth={stealth ? 0 : 2}
      borderColor={state.dragging ? 'blue.400' : BORDER_COLOR}
      borderRadius={BORDER_RADIUS}
      disabled={state.uploading}
      className={state.dragging ? 'state-dragging' : ''}
    >
      {state.previewUrl && (
        <ImagePreviewModal src={state.previewUrl} />
      )}
      {state.confirming && (
        <ImageConfirmModal
          src={state.ephemeralImageUrl}
          onClose={confirmCancel}
          onCrop={confirmCrop}
          onSave={confirmSave}
        />
      )}
      {state.cropping && (
        <ImageCropModal
          src={state.ephemeralImageUrl}
          onDone={handleCropDone}
          fileTypeInfo={fileTypeInfo}
          aspectRatio={aspectRatio}
        />
      )}
      {captureClick
        ? (
          <label>
            {children}
            <input type="file" onChange={handleFileInput} accept={accept} hidden />
          </label>
        )
        : (
          children
        )
      }
      <ImageUploadPlaceholder2
        ephemeralImageUrl={state.ephemeralImageUrl}
      />
      <Box
        position="absolute"
        bottom="0"
        w="100%"
        p="1em"
      >
        <ErrorMessage
          error={state.error}
        />
      </Box>
    </Box>
  );
};

export default withFileType(ImageUploadWrapper);
