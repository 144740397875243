import React, { useState } from 'react';
import { HamburgerIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  MenuButton,
  Menu,
  MenuList,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  Text
} from '@chakra-ui/react';
import { Role } from '../../types/User.interface';

const RoleFilter = ({
  onSubmit,
  selectedRoles,
  roles,
}: {
  onSubmit: (r: string[]) => void,
  selectedRoles: string[],
  roles?: Role[],
}): JSX.Element => {
  const [searchQuery, setSearchQuery] = useState('');
  const [localSelectedRoles, setLocalSelectedRoles] = useState(selectedRoles || []);

  const handleSelectRole = (role: string) => {
    if (localSelectedRoles.includes(role)) {
      setLocalSelectedRoles(localSelectedRoles.filter(item => item !== role));
    } else {
      setSearchQuery('');
      setLocalSelectedRoles([...localSelectedRoles, role]);
    }
  };

  return (
    <Menu>
      {({ onClose }: { onClose: () => void }) => {
        const handleCancel = () => {
          setLocalSelectedRoles(selectedRoles);
          onClose();
        };

        const handleSubmit = () => {
          onSubmit(localSelectedRoles);
          onClose();
        };

        return (
          <>
            <MenuButton
              as={Button}
              minW={['100%', null, '125px']}
              variant='outline'
              leftIcon={<HamburgerIcon />}
            >
              Filter by Role
            </MenuButton>
            <MenuList>
              <Box p={5}>
                <InputGroup>
                  <InputLeftElement color='gray.300'>
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    value={searchQuery}
                    onChange={(e: any) => setSearchQuery(e.target.value)}
                    placeholder='Search'
                  />
                </InputGroup>
                <Text size='md' fontWeight={600} my={5}>Role</Text>
                {roles?.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
                  .map(item => (
                    <Checkbox
                      my={3}
                      display='flex'
                      key={item.id}
                      size='lg'
                      isChecked={localSelectedRoles.includes(item.id)}
                      onChange={() => handleSelectRole(item.id)}
                    >
                      {item.name}
                    </Checkbox>
                  ))}
                <HStack w='100%' mt={8}>
                  <Button
                    variant='outline'
                    w='100%'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='solid'
                    colorScheme='blue'
                    w='100%'
                    onClick={handleSubmit}
                  >
                    Apply
                  </Button>
                </HStack>
              </Box>
            </MenuList>
          </>
        );
      }}

    </Menu >
  );
};

export default RoleFilter;
