/* global window */
import {
  Box,
  Button,
  Center,
  Flex,
  Stack,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { Logo } from '../../chakra/shell/Logo';

const SitePermissionDenied = () => {
  const loDashUrl = 'https://owners.getintheloop.com';

  React.useEffect(() => {
    // Clear url params
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }, []);

  return (
    <Flex direction='column' minH='100vh' minW='100vw' p={8}>
      <Box w='100%'>
        <Logo h='6' />
      </Box>
      <Center flexGrow={1}>
        <Stack spacing={8} alignItems='center'>
          <RiQuestionnaireLine size={50} opacity='50%' />
          <Stack spacing={4} alignItems='center'>
            <Text fontSize='xl' bold>Are you a Local Owner?</Text>
            <Text>We have a new home made just for you!</Text>
          </Stack>
          <Button
            as='a'
            href={loDashUrl}
            colorScheme='blue'
          >
            Redirect me to Local Owner Dashboard
          </Button>
        </Stack>
      </Center>
    </Flex>
  );
};

export default SitePermissionDenied;
