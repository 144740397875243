/* eslint arrow-body-style: 0 */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import usePromise from 'shared/src/hooks/usePromise';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import Menu from 'web-react-ui/src/components/collections/Menu';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import View from 'web-react-ui/src/components/layout/View';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';

import client from '../../../services/client';
import PlanEditForm from './PlanEditForm';

const PlanEditModal = ({ property, history, planId }) => {
  const [, isLoading, pipeline] = usePromise(
    () => {
      const planRepo = client
        .properties.for(property.id)
        .plans;
      if (planId) return planRepo.for(planId).edit();
      return planRepo.initialize();
    },
    [property, planId],
    null,
    true
  );

  const goBack = useCallback(
    () => { history.push('./'); },
    [history]
  );

  const createPlan = useCallback(
    (formFields) => {
      return pipeline.save(formFields)
        .then(goBack);
    },
    [pipeline]
  );

  const localState = pipeline && pipeline.getLocalState();
  const planSettingGroups = localState && localState.plan.settingGroups;

  return (
    <BigModal open={true}>
      <SimpleForm onSubmit={createPlan} initialValues={localState} loading={isLoading}>
        <BigModal.Contents>
          <BigModal.Header className='p1'>
            <div className='flex'>
              <h1>Edit Partnership Plan</h1>
              <div className='mla'>
                <CancelButton onClick={goBack}/>
              </div>
            </div>
          </BigModal.Header>
          <BigModal.Body>
            <View>
              <View.Section narrow>
                <PlanEditForm settingGroups={planSettingGroups}/>
              </View.Section>
              <View.Section narrow>
                <Menu secondary>
                  <Menu.Item fitted primary position='right'>
                    <FormSubmit label='Save'/>
                  </Menu.Item>
                </Menu>
              </View.Section>
            </View>
          </BigModal.Body>
        </BigModal.Contents>
      </SimpleForm>
    </BigModal>
  );
};

PlanEditModal.propTypes = {
  property: PropTypes.object,
  history: PropTypes.object,
  planId: PropTypes.string
};

export default PlanEditModal;
