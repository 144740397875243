import React from 'react';
import { Badge, BadgeProps } from '@chakra-ui/react';

interface Props extends BadgeProps {
  label: string,
}

const colorMap = (label: string) => {
  if (label.startsWith('Platform')) {
    return 'orange.400';
  }
  if (label.startsWith('Property')) {
    return 'purple.400';
  }
  if (label.startsWith('Business')) {
    return 'pink.400';
  }
  if (label.startsWith('Location')) {
    return 'teal.400';
  }
  return 'gray.400';
};

const RoleBadge = ({ label, ...rest }: Props): JSX.Element => (
  <Badge
    py={1}
    px={2.5}
    borderRadius={4}
    bg={colorMap(label)}
    color='white'
    {...rest}
  >
    {label}
  </Badge>
);

export default RoleBadge;
