import React from 'react';
import {
  Flex,
  Box,
  Tr,
  Td,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import OfferPriority from 'web-react-ui/src/components/offers/OfferPriority';
import OfferRating from 'web-react-ui/src/components/offers/OfferRating';
import Offer from '../../types/Offer.interface';
import BusinessCell from './cells/BusinessCell';
import InfoBadge from './cells/InfoBadge';
import OfferListingCell from './cells/OfferListingCell';
import ScheduleDate from './cells/ScheduleDate';
import getOfferType from './utils/getOfferType';

const getOfferStatus = (offer: Offer) => {
  return !offer.enabled ? 'draft' : offer.status;
};

// Prevent click on Link from triggering table row click
const stopPropagation = (event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation();

// eslint-disable-next-line no-shadow
const OfferListItem = (
  { offer, buildHref, hideBusiness }:
    { offer: Offer, buildHref: (offer: Offer) => string, hideBusiness: boolean },
) => {
  const offerHref = buildHref(offer);
  const history = useHistory();
  const onClick = () => history.push(offerHref);

  return (
    <Tr
      onClick={onClick}
      cursor="pointer"
      _hover={{ bg: 'gray.50' }}
    >
      <Td>
        <Link to={offerHref} onClick={stopPropagation}>
          <OfferListingCell offer={offer} />
        </Link>
      </Td>
      {!hideBusiness && (
        <Td verticalAlign="middle">
          <BusinessCell offer={offer} />
        </Td>
      )}
      <Td><OfferPriority priority={offer.priority} /></Td>
      <Td>
        <Flex flexDir="column" align="flex-start">
          <InfoBadge type={getOfferType(offer)} />
          <OfferRating rating={offer.rating} />
        </Flex>
      </Td>
      <Td>
        <Flex flexDir="column" align="flex-end">
          <Box mt={1}>
            <InfoBadge type={getOfferStatus(offer)} />
            {offer.discoverableSchedules.length === 0 && (
              // @ts-ignore
              <InfoBadge type="hidden" ml={2} />
            )}
          </Box>
          <ScheduleDate offer={offer} />
        </Flex>
      </Td>
    </Tr>
  );
};

export default OfferListItem;
