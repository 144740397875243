import React from 'react';
import { Box, Heading, Stack, Flex, Text } from '@chakra-ui/react';
import User from 'web-react-ui/src/types/User.interface';
import { required } from 'web-react-ui/src/reactFinalForm/validators';
import { TextField } from '../../reactFinalForm/fields';


interface Props {
  user: User;
  edit?: boolean;
  canEdit?: boolean | null;
}

const UserProfileInfo = ({ user, edit, canEdit }: Props): JSX.Element => {
  return (
    <Box mb={12}>
      <Heading fontSize='2xl' borderBottom='1px' borderColor='gray.200' pb={3}>Profile</Heading>
      <Stack direction='column' spacing={5} mt={5}>
        <Flex align={edit ? 'flex-start' : 'center'}>
          <Text fontSize='lg' fontWeight={600} mr={10} pt={edit ? 2 : undefined}>Name:</Text>
          {edit ? (
            <TextField name='name' validate={required} disabled={!canEdit} chakra={true} />
          ) : (
            <Text fontSize='lg'>{user.name}</Text>
          )}
        </Flex>
        <Flex align='center'>
          <Text fontSize='lg' fontWeight={600} mr={10}>Email:</Text>
          <Text fontSize='lg'>{user.email}</Text>
        </Flex>
      </Stack>
    </Box>
  );
};

export default UserProfileInfo;
