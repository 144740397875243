/* globals window */
const getSegment = () => {
  if (typeof window === 'undefined') return null;
  return window.analytics;
};

const segmentApi = {
  identify: (userProfile) => {
    const segment = getSegment();
    if (!segment) return false;
    return segment.identify(
      userProfile.id,
      {
        name: userProfile.name,
        email: userProfile.email
      }
    );
  },
  track: (...args) => {
    const segment = getSegment();
    if (!segment) return false;
    return segment.track(...args);
  }
};

export default segmentApi;
