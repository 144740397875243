import PexelsSearchItem from '../types/PexelsSearchItem.interface';

type Items = Array<PexelsSearchItem>;
type Page = {
  next: string,
  helpers: {
    next: () => Promise<{ items: Items, page: Page }>
  }
}

const fetchImages = async ({ query }: { query?: string }): Promise<{ items: Items, page: Page }> => {
  // I wouldn't have thought we'd need to do this, but if we don't, `%` gets passed through without encoding
  const encodedQuery = query && encodeURIComponent(query);
  const result = await fetch(
    `https://api.pexels.com/v1/search?query=${encodedQuery}&per_page=30`,
    {
      // @ts-ignore
      headers: {
        Authorization: process.env.REACT_APP_PEXELS_API_KEY,
      },
    },
  );

  const json = await result.json();

  return {
    items: json.photos,
    page: {
      next: json.next_page,
      helpers: {
        next: () => fetchImages(json.next_page),
      },
    },
  };
};

export { fetchImages };
