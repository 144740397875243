export const saveUserName = (
  user,
  newUserName,
  client,
) => {
  if (user && newUserName !== user.name) {
    return Promise.resolve(client.users.for(user.id).update(user, { name: newUserName }));
  }

  return false;
};

export const saveRoleChanges = (
  user,
  context,
  initialRoles = [],
  newRoles = [],
  client,
) => {
  const { propertyId, businessId, locationId } = context;
  let req = client;
  req = propertyId ? req.properties.for(propertyId) : req;
  req = businessId ? req.businesses.for(businessId) : req;
  req = locationId ? req.locations.for(locationId) : req;

  const invitations = [];
  const demotions = [];

  if (newRoles !== initialRoles) {
    const rolesToAdd = newRoles?.filter(item => !initialRoles?.includes(item)).map((item) => ({ id: item }));
    invitations.push(Promise.resolve(req.users.invite(user, rolesToAdd), false));
    initialRoles?.forEach((item) => {
      if (item && !newRoles?.includes(item)) {
        demotions.push(Promise.resolve(req.users.for(user.id).demote(item)));
      }
    });
  }

  return Promise.all([
    ...invitations,
    ...demotions,
  ]).catch((error) => {
    return Promise.reject(error);
  });
};

export const saveLocationRoleChanges = (
  user,
  context,
  locations,
  locationRoles,
  newLocationRoles,
  client
) => {
  locations.forEach(location => {
    const initialLocationRoles = locationRoles[location.id]?.map(item => item.id) ?? [];
    const newRoles = newLocationRoles[location.id] || [];
    Promise.resolve(saveRoleChanges(
      user,
      { ...context, locationId: location.id },
      initialLocationRoles,
      newRoles.map(item => item.id),
      client
    ));
  });

  return true;
};

export const saveReferralContext = async (
  user,
  propertyId,
  initialReferralContext,
  newReferralContext,
  client,
) => {
  if (user && newReferralContext && newReferralContext !== initialReferralContext) {
    const context = JSON.parse(newReferralContext);
    await client.properties.for(propertyId)
      .users.for(user.id)
      .setReferralContext({ context, dynamic: true });
  }
  return false;
};