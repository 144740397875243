import {
  Button,
  Flex,
} from '@chakra-ui/react';
import type { ComponentProps } from 'react';
import React, { useState } from 'react';
import ImageWell2 from '../ImageWell2';
import ImageThumb from './ImageThumb';

type SharedProps = Omit<ComponentProps<typeof ImageWell2>, 'value' | 'onChange'>

// 🙄
interface ImageCarouselProps extends SharedProps {
  value: Array<string>;
  onChange: (value: Array<string>) => void;
  maxImages?: number;
}

const preventDefault = (e: React.DragEvent<HTMLDivElement>) => { e.preventDefault(); };

const ImageCarousel = ({ value = [], onChange, maxImages = 6, ...rest }: ImageCarouselProps) => {
  const [index, setIndex] = useState(0);

  const handleChange = (newImageValue: string) => {
    const newValue = [...value];
    newValue[index] = newImageValue;

    onChange(newValue);
  };

  const handleThumbClick = (targetIndex: number) => {
    setIndex(targetIndex);
  };

  const handleThumbDelete = (targetIndex: number) => {
    const newValue = [...value];
    newValue.splice(targetIndex, 1);
    if (index > targetIndex) setIndex(v => v - 1);
    onChange(newValue);
  };

  const showThumbs = value.length > 1 || index === 1;
  const showAddMoreButton = index === 0 && value.length === 1;
  const showAddMoreThumb = value.length < maxImages;

  return (
    <Flex
      direction="column"
      gap="1em"
      width="max-content"
      onDrop={preventDefault}
      onDragOver={preventDefault}
    >
      <ImageWell2 value={value[index]} onChange={handleChange} {...rest} />
      {showThumbs && (
        <Flex direction="row" gap="10px" width="max-content">
          {value.map((src, thumbIndex) => (
            <ImageThumb
              src={value[thumbIndex]}
              key={value[thumbIndex]}
              index={thumbIndex}
              onClick={handleThumbClick}
              onDelete={value.length > 1 ? handleThumbDelete : undefined}
              active={index === thumbIndex}
            />
          ))}
          {showAddMoreThumb && (
            <ImageThumb
              index={value.length}
              onClick={handleThumbClick}
              onDelete={handleThumbDelete}
              active={index === value.length}
            />
          )}
        </Flex>
      )}
      {(showAddMoreButton) && (
        <Button
          variant="ghost"
          colorScheme="blue"
          ml="auto"
          isFullWidth={false}
          onClick={() => setIndex(1)}
        >
          Add More Photos
        </Button>
      )}
    </Flex>
  );
};

export default ImageCarousel;
