import { useCallback, useState } from 'react';

const useIncrement = (initialValue = 0) => {
  const [value, setValue] = useState(initialValue);

  const increment = useCallback(() => {
    setValue(value + 1);
  }, [value]);

  return [value, increment];
};

export default useIncrement;
