import PropTypes from 'prop-types';
import React from 'react';
import ImageInfo from './ImageInfo';
import withFileType from './withFileType';

const FileTypeInfo = ({ over, fileTypeInfo }) => {
  if (!fileTypeInfo) return null;

  const { restrictions: { types, dimensions, maxSize } } = fileTypeInfo;
  return (
    <ImageInfo
      type={types}
      dimensions={[{ w: dimensions.minWidth, h: dimensions.minHeight }, {
        w: dimensions.maxWidth,
        h: dimensions.maxHeight
      }]}
      maxSize={maxSize}
      over={over}
    />
  );
};

FileTypeInfo.propTypes = {
  over: PropTypes.bool,
  fileTypeInfo: PropTypes.object
};

export default withFileType(FileTypeInfo);
