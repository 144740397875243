import React from 'react';
import { useField } from 'react-final-form';
import { Checkbox, CheckboxProps } from '@chakra-ui/react';

interface Props extends CheckboxProps {
  name: string;
  children: React.ReactNode;
  value?: string;
  validate?: Array<Function>;
}

const CheckboxField = ({
  name,
  value,
  children,
  validate,
  ...rest
}: Props): JSX.Element => {
  const {
    input: { checked, ...input },
    meta: { error, touched }
  } = useField(name, {
    type: 'checkbox',
    value,
    validate
  });

  return (
    <Checkbox
      {...input}
      isChecked={checked}
      isInvalid={error && touched}
      size='lg'
      style={{ alignItems: 'flex-start' }}
      mb={3}
      {...rest}
    >
      {children}
    </Checkbox>
  );
};

export default CheckboxField;
