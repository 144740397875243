/* eslint-disable-next-line no-redeclare */
import segment from '../../helpers/segment';
/* global window */
import { types } from './types';

const initRollbar = (userResponse) => {
  return window?.rollbar?.configure({
    payload: {
      person: {
        id: userResponse.id,
        email: userResponse.email
      }
    }
  });
};

const authMiddleware = client => store => next => async (action) => {
  const { type } = action;
  switch (type) {
    case types.AUTH_LOG_OUT_REQUEST:
      return client.auth.logout().then(() => {
        window.location.href = '/login';
        return store.dispatch({
          type: types.AUTH_LOG_OUT_SUCCESS
        });
      }).catch((error) => {
        return store.dispatch({
          type: types.AUTH_LOG_OUT_FAILURE,
          error
        });
      });
    case types.AUTH_REFRESH_REQUEST:
      return client
        .auth.profile()
        .then((userResponse) => {
          try {
            initRollbar(userResponse);
          } catch (error) {
            console.warn('Failed to initialize Rollbar', error);
          }
          try {
            segment.identify(userResponse);
          } catch (error) {
            console.warn('Failed to identify with Segment', error);
          }
          return store.dispatch({
            type: types.AUTH_REFRESH_SUCCESS,
            user: userResponse
          });
        }).catch((error) => {
          return store.dispatch({
            type: types.AUTH_REFRESH_FAILURE,
            error
          });
        });
    default:
      return next(action);
  }
};

export default authMiddleware;
