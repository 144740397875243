import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Heading, Image } from '@chakra-ui/react';

import authModule from 'shared/src/modules/auth';

import OktaWidget from 'web-react-ui/src/components/auth/OktaSignIn';
import IsLoggedInRedirect from 'web-react-ui/src/components/login/IsLoggedInRedirect';

import Terms from '../siteFooter/Terms';
import client from '../../services/client';
import LMGlogo from '../../assets/LMGlogo.png';

const LoginContainer = (): JSX.Element => {
  const isLoggedIn = useSelector(state => authModule.selectors.getIsLoggedIn(state));
  if (isLoggedIn) return <IsLoggedInRedirect />;
  return (
    <Box mx='auto'>
      <Image src={LMGlogo} alt="logo" ml='auto' mb={12} w='80px' h='auto' />
      <Heading mb={0} as='h1' fontWeight='regular' fontSize={21}>Log In</Heading>
      <OktaWidget client={client} />
      <Box mt={6} width='100%'>
        <Terms />
      </Box>
    </Box>
  );
};

export default LoginContainer;
