import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Menu from 'web-react-ui/src/components/collections/Menu';
import Checkbox from 'web-react-ui/src/components/modules/Checkbox';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../services/client';


const getBusinessLocations = ({ propertyId, businessId }) => {
  const businesses = propertyId
    ? client.properties.for(propertyId).businesses.for(businessId)
    : client.businesses.for(businessId);

  return businesses.locations.listAdmin();
};

const contains = (list, value) => list.indexOf(value) !== -1;

class BusinessLocationSelector extends Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    property: PropTypes.object,
    business: PropTypes.object
  };

  handleCheck(id, { checked }) {
    const { value } = this.props;
    const newSelection = new Set(value);
    newSelection[checked ? 'add' : 'delete'](id);

    this.props.onChange(Array.from(newSelection));
  }

  render() {
    const { property, business, value } = this.props;
    if (!business) return null;

    return (
      <Request request={getBusinessLocations} params={{ propertyId: property && property.id, businessId: business.id }}>
        {({ loaded, data }) => (
          <WaitFor waitFor={loaded}>
            <Menu vertical fluid>
              {data && data.items && !data.items.length
                && <Menu.Item><em>This Business has no locations</em></Menu.Item>
              }
              {data && data.items.map(o => (
                <Menu.Item key={o.id}>
                  <Checkbox
                    checked={contains(value, o.id)}
                    onChange={(e, checkbox) => this.handleCheck(o.id, checkbox)}
                    label={o.name}
                  />
                </Menu.Item>
              ))}
            </Menu>
          </WaitFor>
        )}
      </Request>
    );
  }
}

export default BusinessLocationSelector;
