import {
  Box,
  Center,
  Image,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';
import iconizeImageUrl from '../../../utils/iconizeImageUrl';

const LibraryImage = ({ imageRefId, loading, url, ephemeralImageUrl, color }
  : { imageRefId?: string, loading?: boolean, url?: string, ephemeralImageUrl?: string, color?: string }) => {
  return (
    <Box w="100%" h="100%" position="relative" borderRadius="md" overflow="hidden" bgColor={color}>
      <Image objectFit="cover" src={ephemeralImageUrl || iconizeImageUrl(url, { size: 100 })} w="100%" h="100%" />
      {
        loading
          ? (
            <Center height="100%" width="100%" bgColor="#FFFFFF55" position="absolute" top="0" left="0">
              <Spinner />
            </Center>
          )
          : null
      }
    </Box>

  );
};

export default LibraryImage;
