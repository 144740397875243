import format from "date-fns/format";
import fr from 'date-fns/locale/fr';
import _ from "lodash";

const ALL_DAYS = [0, 1, 2, 3, 4, 5, 6];
const ALL_HOURS = ['00:00', '24:00'];
const DAY_LABELS = [
  'sun',
  'mon',
  'tues',
  'wed',
  'thurs',
  'fri',
  'sat'
];
const findRanges = list => list.reduce((acc, val) => {
  const last = acc[acc.length - 1];
  if (!last || last[1] + 1 < val) {
    acc.push([val, val]);
  } else {
    last[1] = val;
  }
  return acc;
}, []);

export const formatDate = (dateString, i18nLang) => {
  if (!dateString) {
    return null;
  }
  const [yyyy, mm, dd] = dateString.split('-');
  const d = new Date(yyyy, mm - 1, dd);
  if (i18nLang === 'fr') {
    return format(d, 'MMM d, yyyy', { locale: fr });
  }
  return format(d, 'MMM d, yyyy');
};

export const formatWeekDays = (value, strings) => {
  const sorted = value.sort();
  if (_.isEqual(sorted, ALL_DAYS)) return strings('ui.shared.allDays');
  if (!sorted.length) return strings('ui.shared.noDays');
  return findRanges(sorted)
    .map(([from, to]) => {
      if (from === to) return strings(`ui.shared.${DAY_LABELS[from]}`);
      return `${strings(`ui.shared.${DAY_LABELS[from]}`)} – ${strings(`ui.shared.${DAY_LABELS[to]}`)}`;
    })
    .join(', ');
};

export const formatDateRange = (from, to, i18nLang) => (
  `${formatDate(from, i18nLang)} – ${formatDate(to, i18nLang)}`
);

export const formatTimeRanges = (value = []) => value
  .map(([from, to]) => {
    if (from === ALL_HOURS[0] && to === ALL_HOURS[1]) return null;
    return `${from} – ${to}`;
  })
  .join(', ');
