import { useState, useEffect } from 'react';
import fetchRegions from '../components/collections/regions/fetchRegions';

const useFilteredRegions = (arr) => {
  const [regions, setRegions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const runEffect = async () => {
      try {
        setIsLoading(true);
        if (arr && arr.regionFilters) {
          const [items, included, excluded] = await fetchRegions(arr.regionFilters); // values.regionFilters.filters
          setRegions({ items, included, excluded });
        }
        if (arr && arr.meta && arr.meta.regions) {
          const [items, included, excluded] = await fetchRegions(arr.meta.regions);
          setRegions({ items, included, excluded });
        }
      } catch (err) {
        setError(new Error('Sorry, There was an error loading the map.'));
      }
      setIsLoading(false);
    };
    runEffect();
  }, [arr]);

  return [regions, isLoading, error];
};

export default useFilteredRegions;
