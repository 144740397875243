import React from 'react';
import {
  NumberField,
  TagField
} from '../../reactFinalForm/fields';
import {
  compose,
  int,
  minValue
} from '../../reactFinalForm/validators';
import FilterCategoryField from './FilterCategoryField';
import FilterFlagField from './FilterFlagField';
import FilterSet from './FilterSet';

const FilterTagField = ({ name }) => {
  return (
    <TagField wrapLabel fluid name={name} />
  );
};

const DAY_IN_MINUTES = 60 * 24;

const minutesToDays = minutes => Math.round((minutes || 0) / DAY_IN_MINUTES);

const daysToMinutes = days => Math.round((days || 0) * DAY_IN_MINUTES);

const FilterDaysField = ({ name }) => {
  return (
    <div className='flex aic'>
      <div className='fg1 mr1'>
        <NumberField
          wrapLabel
          name={name}
          validate={compose(minValue(1), int)}
          format={minutesToDays}
          parse={daysToMinutes}
        />
      </div>
      Days
    </div>
  );
};

export const OPERATIONS = {
  inclusion: [
    { text: 'Contains Any Of', value: 'containsAnyOf' },
    { text: 'Contains All Of', value: 'containsAllOf' },
    { text: 'Does Not Contain Any Of', value: 'notContainsAnyOf' },
    { text: 'Does Not Contain All Of', value: 'notContainsAllOf' }
  ],
  days: [
    { text: 'More Than', value: 'moreThan' },
    { text: 'Less Than', value: 'lessThan' }
  ]
};

const rowConfig = {
  targets: [
    { text: 'Offer Flags', value: 'businessOfferFlags' },
    { text: 'Offer Tags', value: 'businessOfferTags' },
    { text: 'Location Tags', value: 'businessOfferLocationTags' },
    { text: 'Business Tag', value: 'businessTags' },
    { text: 'Live For', value: 'businessOfferLiveSince' },
    { text: 'Expires In', value: 'businessOfferExpiresIn' },
    { text: 'Category', value: 'businessCategoryIds' }
  ],
  getOperations: (target) => {
    switch (target) {
      case 'businessTags':
      case 'businessOfferFlags':
      case 'businessOfferTags':
      case 'businessOfferLocationTags':
      case 'businessCategoryIds':
        return OPERATIONS.inclusion;
      case 'businessOfferLiveSince':
      case 'businessOfferExpiresIn':
        return OPERATIONS.days;
      default:
        return [];
    }
  },
  // eslint-disable-next-line react/display-name
  getComponent: (target, operation) => {
    switch (target) {
      case 'businessOfferFlags':
        return FilterFlagField;
      case 'businessTags':
      case 'businessOfferTags':
      case 'businessOfferLocationTags':
        return FilterTagField;
      case 'businessOfferLiveSince':
      case 'businessOfferExpiresIn':
        return FilterDaysField;
      case 'businessCategoryIds':
        return FilterCategoryField;
      default:
        return null;
    }
  }
};

const EmptyView = () => (
  <div className='mb1'>
    <em>All Offers will be included.</em>
  </div>
);

const OfferFilterSet = ({ name }) => {
  return (
    <FilterSet name={name} rowConfig={rowConfig} empty={<EmptyView />} />
  );
};

export default OfferFilterSet;
