import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Collapse
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

export const PerformanceCheckAlert = ({ hasPerformanceError, endDateWarning }) => {
  const { strings } = useI18Next();
  return (
    <Collapse in={hasPerformanceError || endDateWarning}>
      <Alert
        status='warning'
        alignItems='flex-start'
        variant='left-accent'
        borderRadius={6}
      >
        <AlertIcon />
        <Box flex='1'>
          <AlertTitle fontSize={16} lineHeight='24px'>
            {strings('dashboard.component.editOfferForm.alert.performanceCheckAlertTitle')}
          </AlertTitle>
          <AlertDescription display='block' fontSize={16} lineHeight='24px' textColor='gray.700'>
            {hasPerformanceError && strings('dashboard.component.editOfferForm.alert.performanceCheckAlertBody')}
            {' '}
            {endDateWarning && endDateWarning}
          </AlertDescription>
        </Box>
      </Alert>
    </Collapse>
  );
};

PerformanceCheckAlert.propTypes = {
  hasPerformanceError: PropTypes.bool,
  endDateWarning: PropTypes.string
};
