import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import { Field } from '../../reactFinalForm';
import {
  BooleanField,
  DateField,
  TimeField
} from '../../reactFinalForm/fields';
import ToggleGroupField from '../../reactFinalForm/fields/ToggleGroupField';
import {
  compose,
  required,
  requiredArray
} from '../../reactFinalForm/validators';

const validateTimeRange = (startTime, strings) => {
  const timeValidationError = strings('ui.component.collectionScheduleFields.validateTimeRange');

  const [startH, startM] = startTime && startTime.split(':').map(token => parseInt(token, 10));
  const startMinutes = (startH * 60) + startM;
  return (endTime) => {
    const [endH, endM] = endTime && endTime.split(':').map(token => parseInt(token, 10));
    const endMinutes = (endH * 60) + endM;
    if (startMinutes > endMinutes) return timeValidationError;
    return null;
  };
};

const validateDate = startDate => (endDate) => {
  if (endDate < startDate) return 'End date must come after start date';
  return null;
};

const CollectionScheduleFields = ({ name }) => {
  const { strings } = useI18Next();

  const weekDays = [
    { label: strings('ui.shared.weekDays.letrSu'), value: 0 },
    { label: strings('ui.shared.weekDays.letrM'), value: 1 },
    { label: strings('ui.shared.weekDays.letrT'), value: 2 },
    { label: strings('ui.shared.weekDays.letrW'), value: 3 },
    { label: strings('ui.shared.weekDays.letrTh'), value: 4 },
    { label: strings('ui.shared.weekDays.letrF'), value: 5 },
    { label: strings('ui.shared.weekDays.letrS'), value: 6 }
  ];

  return (
    <div className='mt2'>
      <div className='ui grid'>
        <div className='eight wide column'>
          <BooleanField
            radio
            name={`${name}.allDates`}
            label={strings('ui.component.collectionScheduleFields.field.label.allTheTime')}
            checked={v => !!v}
          />
        </div>
        <div className='eight wide column'>
          <BooleanField
            radio
            name={`${name}.allDates`}
            label={strings('ui.component.collectionScheduleFields.field.label.specificDayTime')}
            checked={v => !v}
          />
        </div>
        <div className='sixteen wide column'>
          <Field name={`${name}.allDates`}>
            {({ input: allDatesInput }) => {
              const allDates = allDatesInput.value;
              if (allDates) return null;
              return (
                <div className='ui grid'>
                  <div className='eight wide column'>
                    <DateField
                      name={`${name}.startDate`}
                      label={strings('ui.component.collectionScheduleFields.field.label.startDate')}
                      validate={required}
                      wrapLabel
                    />
                  </div>
                  <div className='eight wide column'>
                    <Field name={`${name}.startDate`}>
                      {/* eslint-disable-next-line no-shadow */}
                      {({ input }) => (
                        <DateField
                          name={`${name}.endDate`}
                          label={strings('ui.component.collectionScheduleFields.field.label.endDate')}
                          validate={compose(required, validateDate(input.value))}
                          wrapLabel
                        />
                      )}
                    </Field>
                  </div>
                </div>
              );
            }}
          </Field>
        </div>
      </div>
      <div className='ui grid'>
        <div className='eight wide column'>
          <BooleanField
            radio
            name={`${name}.allWeekDays`}
            label={strings('ui.component.collectionScheduleFields.field.label.allWeekDays')}
            checked={v => !!v}
          />
        </div>
        <div className='eight wide column'>
          <BooleanField
            radio
            name={`${name}.allWeekDays`}
            label={strings('ui.component.collectionScheduleFields.field.label.specificDaysOfTheWeek')}
            checked={v => !v}
          />
        </div>
        <div className='sixteen wide column'>
          <div className='sixteen wide column'>
            <Field name={`${name}.allWeekDays`}>
              {({ input }) => {
                const setDays = !input.value;
                if (!setDays) return null;
                return (
                  <ToggleGroupField
                    name={`${name}.weekDays`}
                    options={weekDays}
                    validate={requiredArray}
                  />
                );
              }}
            </Field>
          </div>
        </div>
      </div>
      <div className='ui grid'>
        <div className='eight wide column'>
          <BooleanField
            radio
            name={`${name}.allTimes`}
            label={strings('ui.component.collectionScheduleFields.field.label.allHours')}
            checked={v => !!v}
          />
        </div>
        <div className='eight wide column'>
          <BooleanField
            radio
            name={`${name}.allTimes`}
            label={strings('ui.component.collectionScheduleFields.field.label.certainTimes')}
            checked={v => !v}
          />
        </div>
        <div className='sixteen wide column'>
          <Field name={`${name}.allTimes`}>
            {({ input }) => {
              const setTimes = !input.value;
              if (setTimes) {
                return (
                  <div className='ui grid fg1'>
                    <div className='eight wide column'>
                      <TimeField
                        name={`${name}.timeRanges[0][0]`}
                        label={strings('ui.component.collectionScheduleFields.field.label.startTime')}
                        wrapLabel
                        validate={required}
                      />
                    </div>
                    <div className='eight wide column'>
                      <Field name={`${name}.timeRanges[0][0]`}>
                        {({ input }) => ( // eslint-disable-line no-shadow
                          <TimeField
                            name={`${name}.timeRanges[0][1]`}
                            label={strings('ui.component.collectionScheduleFields.field.label.endTime')}
                            wrapLabel
                            validate={compose(required, validateTimeRange(input.value, strings))}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                );
              }
              return null;
            }}
          </Field>
        </div>
      </div>
    </div>
  );
};

CollectionScheduleFields.propTypes = {
  name: PropTypes.string
};

export default CollectionScheduleFields;
