import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import {
  Button,
  IconButton,
  useBreakpointValue
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const CancelButton = (props) => {
  const { strings } = useI18Next();
  const { label = strings('ui.label.cancel'), ...rest } = props;
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  if (isSmallScreen) {
    return <IconButton type="button" icon={<CloseIcon />} isRound {...rest} />;
  }
  return <Button type="button" {...rest}>{label}</Button>;
};

CancelButton.propTypes = {
  label: PropTypes.string
};

export default CancelButton;
