import React from 'react';
import PropTypes from 'prop-types';

import { injectClient } from 'shared/src/components/contexts/ClientContext';

import Request from '../../data/Request';
import WaitFor from '../../data/WaitFor';

const PreviewImage = ({ imageId, imageUrl, client }) => {
  if (!imageId && !imageUrl) {
    return (
      <div
        style={{ height: '200px', borderRadius: '4px 4px 0 0', backgroundSize: 'cover' }}
        className='mla mra bg--gray'
      />
    );
  }
  return (
    <Request
      request={({ imageId, imageUrl }) => { // eslint-disable-line no-shadow
        if (imageUrl) return { url: imageUrl };
        return client.images.for(imageId).details();
      }}
      params={{ imageId, imageUrl }}
      cache="image"
    >
      {({ data, loaded }) => (
        <WaitFor waitFor={loaded}>
          <div
            style={{
              height: '200px',
              borderRadius: '4px 4px 0 0',
              backgroundImage: data && `url(${data.url})`,
              backgroundSize: 'cover'
            }}
            className='mla mra'
          />
        </WaitFor>
      )}
    </Request>
  );
};

PreviewImage.propTypes = {
  imageId: PropTypes.string,
  imageUrl: PropTypes.string,
  client: PropTypes.object
};

export default injectClient(PreviewImage);
