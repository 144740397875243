/* eslint-env browser */
import authOpen from 'web-react-ui/src/components/auth/authOpen';
import admin from '@loopmediagroup/admin-sdk';

const REAL_API_ROOT = process.env.REACT_APP_API_URL;
const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;

const STORAGE_KEY_PREFIX = 'cpw';

const AuthProvider = admin.AuthProvider;
const LocalStorageStore = admin.stores.LocalStorageStore;

const authProvider = new AuthProvider(new LocalStorageStore(STORAGE_KEY_PREFIX, window.localStorage));

const client = new admin.Client({
  baseUrl: REAL_API_ROOT,
  authProvider
});


// FIXME: Figure out where these listeners should be defined
client.on(admin.events.AUTH_EXPIRED, authOpen);


const trackTiming = (method, url, duration, isError) => {
  if (window.gtag) {
    if (window.gtag) {
      window.gtag(
        'event',
        'timing_complete',
        {
          name: isError ? 'api_error' : 'api_request',
          value: duration,
          event_category: method,
          event_label: (url || '').replace(UUID_REGEX, '<id>')
        }
      );
    }
  }
};

client.on(admin.events.REQUEST_SUCCEEDED, ({ method, url, duration }) => {
  trackTiming(method, url, duration, false);
});

client.on(admin.events.REQUEST_FAILED, ({ method, url, duration }) => {
  trackTiming(method, url, duration, true);
});

export default client;
