import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import SelectButton from '../SelectButton';

const RoleTypeSelector = ({
  label,
  roleTypes,
  onClick,
  activeTab
}: {
  label: string,
  roleTypes: string[],
  onClick: (tab: number) => void,
  activeTab: number,
}): JSX.Element => (
  <>
    <Text fontSize='lg' fontWeight={600}>Select</Text>
    <Flex align='center' gap={5} mb={5}>
      <SelectButton
        value={0}
        label={roleTypes[0]}
        onClick={() => onClick(0)}
        isSelected={activeTab === 0}
      />
      <Text fontWeight={500}>OR</Text>
      <SelectButton
        value={1}
        label={roleTypes[1]}
        onClick={() => onClick(1)}
        isSelected={activeTab === 1}
      />
    </Flex>
  </>
);

export default RoleTypeSelector;
