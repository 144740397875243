import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import SuiForm from 'web-react-ui/src/components/collections/Form';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import ImageField from 'web-react-ui/src/components/image/ImageField';
import View from 'web-react-ui/src/components/layout/View';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import { Form } from 'web-react-ui/src/reactFinalForm';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import SelectField from 'web-react-ui/src/reactFinalForm/fields/SelectField';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import {
  compose, max, required, tag
} from 'web-react-ui/src/reactFinalForm/validators';

const statusOptions = [
  {
    text: 'Test',
    value: 'test',
  },
  {
    text: 'Live',
    value: 'live',
  }
];

const PropertyCreateForm = props => (
  <SuiForm onSubmit={props.onSubmit} error>
    <TextField label="Name" fluid name='name' placeholder='Name' validate={required} />

    <TextField
      label="Domain"
      name='subdomain'
      fluid
      placeholder='subdomain'
      validate={compose(
        required,
        tag,
        max(62)
      )}
      inputProps={{ label: `.${process.env.REACT_APP_ROOT_DOMAIN}`, labelPosition: 'right' }}
    />

    <SelectField label="Status" name="status" options={statusOptions} autoSelectFirst />
    <ImageField name='profileImage' fileType='property-profile' label='Profile Image' />
    <ImageField name='logoImage' fileType='property-logo-standard' label='Logo' />
    <ImageField name='splashImage' fileType='property-splash-standard' label='Splash Image' />

    <FormSubmit fluid label='Create Property' />
  </SuiForm>
);

PropertyCreateForm.propTypes = {
  onSubmit: PropTypes.func
};

class PropertyCreateView extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.object,
    isSaving: PropTypes.bool
  };

  render() {
    const {
      onSubmit, onCancel, error, isSaving
    } = this.props;
    return (
      <BigModal open={true}>
        <BigModal.Contents>
          <BigModal.Header className='p1'>
            <BigModal.CloseButton floated='right' onClick={onCancel} />
          </BigModal.Header>
          <BigModal.Body>
            <WaitFor waitFor={!isSaving} wrapContents>
              <View>
                <View.Section narrow>
                  <h3>Add Property</h3>
                  <Form onSubmit={onSubmit}>
                    {({ handleSubmit, errors }) => (
                      <React.Fragment>
                        <PropertyCreateForm onSubmit={handleSubmit} errors={errors} />
                      </React.Fragment>
                    )}
                  </Form>
                  <ErrorMessage error={error} />
                </View.Section>
              </View>
            </WaitFor>
          </BigModal.Body>
        </BigModal.Contents>
      </BigModal>
    );
  }
}

export default PropertyCreateView;
