import React, { useEffect } from 'react';
import { Box, Flex, FormLabel, Select, Heading, Input, Icon, Tooltip, VStack, BoxProps } from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';
import { Field, useField, useForm } from 'react-final-form';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import RadioButtonGroupField from '../../../chakra/forms/RadioButtonGroup/RadioButtonGroupField';
import OFFER_OPTIONS from '../offerOptions';

export const actionOptions = (): { label: string, value: string, info: string, disabled?: boolean }[] => {
  const { strings } = useI18Next();
  return [
    {
      label: strings('ui.component.offerRedeemLimits.actionOptions.inPerson.text'),
      value: 'present',
      info: strings('ui.component.offerRedeemLimits.actionOptions.inPerson.info'),
    },
    {
      label: strings('ui.component.offerRedeemLimits.actionOptions.online.text'),
      value: 'website',
      info: strings('ui.component.offerRedeemLimits.actionOptions.online.info'),
    },
    {
      label: strings('ui.component.offerRedeemLimits.actionOptions.byPhone.text'),
      value: 'call',
      info: strings('ui.component.offerRedeemLimits.actionOptions.byPhone.info'),
      disabled: true,
    },
  ];
};

interface Props extends BoxProps {
  name: string;
}

const RedemptionPunchReward = ({ name, ...boxProps }: Props): JSX.Element => {
  const { strings } = useI18Next();
  const form = useForm();
  const { input } = useField(`${name}.label`, {});
  const selectorTitle = strings('dashboard.component.editOfferForm.field.label.howWillCustomersRedeemReward');
  const tooltipLabel = strings('dashboard.component.editOfferForm.field.label.howWillCustomersRedeemRewardTooltip');

  const inPersonOptions = [
    { label: strings(OFFER_OPTIONS['{{use_reward}}'].text), value: '{{use_reward}}' },
    { label: strings(OFFER_OPTIONS['{{claim_reward}}'].text), value: '{{claim_reward}}' },
    { label: strings(OFFER_OPTIONS['{{enter_now}}'].text), value: '{{enter_now}}' },
    { label: strings(OFFER_OPTIONS['{{redeem_now}}'].text), value: '{{redeem_now}}' }
  ];

  const onlineOptions = [
    { label: strings(OFFER_OPTIONS['{{enter_now}}'].text), value: '{{enter_now}}' },
    { label: strings(OFFER_OPTIONS['{{redeem_now}}'].text), value: '{{redeem_now}}' },
    { label: strings(OFFER_OPTIONS['{{use_reward}}'].text), value: '{{use_reward}}' }
  ];

  const rewardLabelOptions = form.getState().values.flow.vars.action === 'present' ? inPersonOptions : onlineOptions;

  useEffect(() => {
    if (!rewardLabelOptions[0]) return;
    if (rewardLabelOptions.some(item => item.value === input.value)) return;
    form.change(`${name}.label`, rewardLabelOptions[0].value);
  }, [rewardLabelOptions, input]);

  return (
    <Box w='100%' {...boxProps}>
      <Heading size='md' mb={5}>
        {strings('dashboard.component.editOfferForm.field.label.reward')}
      </Heading>
      <VStack spacing={5}>
        <Box w='100%'>
          <FormLabel>
            <Flex align="center">
              {selectorTitle}
              <Tooltip label={tooltipLabel} placement="top" hasArrow>
                <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
              </Tooltip>
            </Flex>
          </FormLabel>
          <RadioButtonGroupField name={`${name}.action`} options={actionOptions()} />
        </Box>

        {form.getState().values.flow.vars.action === 'website' && (
          <Box w='100%'>
            <FormLabel>
              {strings('ui.component.offerRedeemLimits.field.label.url')}
            </FormLabel>
            <Field name={`${name}.url`}>
              {({ input: urlInput }) => (
                <Input
                  type='text'
                  value={urlInput.value}
                  onChange={urlInput.onChange}
                  placeholder={`URL`}
                />
              )}
            </Field>
          </Box>
        )}

        <Box w='100%'>
          <FormLabel>
            <Flex align="center">
              {strings('dashboard.component.editOfferForm.field.label.redeemRewardActionButtonLabel')}
              <Tooltip label='Action Button Label' placement="top" hasArrow>
                <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
              </Tooltip>
            </Flex>
          </FormLabel>
          <Field name={`${name}.label`}>
            {({ input: labelInput }) => {
              return (
                <Select {...labelInput} w='50%'>
                  {rewardLabelOptions.map(item => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))}
                </Select>
              );
            }}
          </Field>
        </Box>
      </VStack>
    </Box>
  );
};

export default RedemptionPunchReward;
