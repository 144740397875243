import { types } from './types';

export const list = propertyId => ({
  type: types.BUSINESS_LIST_REQUEST,
  payload: {
    propertyId
  }
});

export const details = args => ({
  type: types.BUSINESS_DETAILS_REQUEST,
  payload: {
    businessId: args.businessId,
    propertyId: args.propertyId,
  }
});

export const invalidate = () => ({
  type: types.BUSINESS_INVALIDATE,
});
