import { HamburgerIcon } from '@chakra-ui/icons';
import React from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import NavItems from './NavItems';
import NavLogo from './NavLogo';
import SiteMenu from './SiteMenu';

const ResponsiveNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <NavItems direction="row" display={['none', null, 'flex']} />
      <Flex display={['flex', null, 'none']} align="center">
        <IconButton
          color="white"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          size="lg"
          variant="simple"
        />
        <Drawer placement="left" isOpen={isOpen} onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent bg="#475771">
            <DrawerCloseButton color="white" />
            <DrawerHeader>
              <NavLogo />
            </DrawerHeader>
            <DrawerBody onClick={onClose}>
              <NavItems align="left" alignItems={['start', null, 'center']} />
            </DrawerBody>
            <DrawerFooter />
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
};

const Sitenav = () => {
  return (
    <Flex w="100%" minH="68">
      <Box m="1em">
        <NavLogo />
      </Box>
      <ResponsiveNav />
      <Flex marginLeft="auto" alignItems="center">
        <SiteMenu marginLeft="auto" />
      </Flex>
    </Flex>
  );
};

export default Sitenav;
