/**
 * Headless component for managing remote requests
 */
import PropTypes from 'prop-types';
import requestCacheProvider from 'shared/src/helpers/requestCacheProvider';
import { PureComponent } from 'react';
import shallowEqual from '../utils/shallowEqual';

const STATES = {
  idle: 'idle',
  loading: 'loading',
  error: 'error',
  loaded: 'loaded'
};

const defaultDidParamsUpdate = (a, b) => !shallowEqual(a, b);

class Request extends PureComponent {
  static propTypes = {
    request: PropTypes.func,
    params: PropTypes.object,
    didParamsUpdate: PropTypes.func,
    watchRequest: PropTypes.bool,
    cache: PropTypes.string,
    ttl: PropTypes.number
  };

  state = {
    state: STATES.idle,
    version: 0
  };

  componentDidMount() {
    this.doRequest();
  }

  componentDidUpdate(prevProps) {
    const { didParamsUpdate = defaultDidParamsUpdate, watchRequest } = this.props;

    // Only re-request if params or request method have changed
    const requestChanged = watchRequest ? (prevProps.request !== this.props.request) : false;
    if (!didParamsUpdate(this.props.params, prevProps.params) && !requestChanged) return;

    this.doRequest();
  }

  doRequest() {
    const { request } = this.props;
    if (!request) return false;

    this.updateState(STATES.loading);

    const promise = this.getCachedPromise() || this.getUncachedPromise();

    const newVersion = this.state.version + 1;
    this.setState({ version: newVersion });
    const versionedUpdate = this.getVersionedUpdateState(newVersion);

    return promise
      .then(result => versionedUpdate(STATES.loaded, result))
      .catch(error => versionedUpdate(STATES.error, error));
  }

  getVersionedUpdateState(requestVersion) {
    return (state, data) => {
      const { version } = this.state;
      if (version !== requestVersion) return;
      this.updateState(state, data);
    };
  }

  getUncachedPromise() {
    const { request, params, cache } = this.props;
    const promise = Promise.resolve(request(params));

    const cacheInstance = requestCacheProvider.getCache(cache);
    if (cacheInstance) cacheInstance.setItem(params, promise);

    return promise;
  }

  getCachedPromise() {
    const { params, cache, ttl } = this.props;
    const cacheInstance = requestCacheProvider.getCache(cache);
    if (!cacheInstance) return null;
    const cacheResult = cacheInstance.getItem(params, { ttl });
    if (!cacheResult) return null;
    return cacheResult.promise;
  }

  updateState(state, data) {
    switch (state) {
      case STATES.loaded:
        this.setState({
          state,
          data,
          error: null,
          loading: false,
          loaded: true
        });
        break;
      case STATES.error:
        this.setState({
          state,
          error: data,
          loading: false
        });
        break;
      case STATES.loading:
        this.setState({
          state,
          error: null,
          loading: true
        });
        break;
      default:
        this.setState({
          state: STATES.idle,
          data: null,
          error: null,
          loading: false,
          loaded: false
        });
        break;
    }
  }

  render() {
    const { children } = this.props;
    return children(this.state);
  }
}

export default Request;
