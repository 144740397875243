import PropTypes from 'prop-types';
import React from 'react';

import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import {
  offerListViewParamsFactory,
  VIEW_TYPES
} from 'shared/src/helpers/offerListViewParams';

import Input from 'web-react-ui/src/components/elements/Input';
import View from 'web-react-ui/src/components/layout/View';
import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import OffersListTable from 'web-react-ui/src/chakra/offers/OffersListTable';

import client from '../../services/client';

import MyLocationsSelector from '../businesses/MyLocationsSelector';

const toolbarStyles = {
  wrapper: { marginLeft: '-0.5em', marginRight: '-0.5em' },
  search: { minWidth: '60%', paddingLeft: '0.5em', paddingRight: '0.5em' },
  location: { paddingLeft: '0.5em', paddingRight: '0.5em', minWidth: '15em' }
};

const fetchOffers = ({ query, view, locationId, businessId, propertyId }) => {
  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .locations.for(locationId)
    .offers.list({
      query, ...offerListViewParamsFactory(view)
    });
};

const buildHref = (offer) => `./offers/${offer.id}`;

const BusinessOfferListView = ({ businessId, propertyId, relativeLink }) => {
  const [view, setView] = useQuerystringParam('view', VIEW_TYPES.live, { squash: true });
  const [locationId, setLocationId] = useQuerystringParam('location', null, { squash: true });
  const [query, debouncedQueryHandler] = useQueryDebounce(view, propertyId, businessId, locationId);

  const handleViewChange = (e) => {
    setView(e);
  };

  const options = [
    { key: 1, text: 'New', value: VIEW_TYPES.new },
    { key: 2, text: 'Live', value: VIEW_TYPES.live },
    { key: 3, text: 'Expiring Soon', value: VIEW_TYPES.expiring },
    { key: 4, text: 'Expired', value: VIEW_TYPES.expired },
    { key: 5, text: 'Draft', value: VIEW_TYPES.disabled }
  ];

  return (
    <View>
      <View.Section className="mt2">
        <div className="flex aic fww" style={toolbarStyles.wrapper}>
          <Input
            placeholder="Find offer..."
            fluid
            icon="search"
            onChange={e => debouncedQueryHandler(e.target.value)}
            className="fg2 mb1"
            style={toolbarStyles.search}
          />
          <div className="mb1" style={toolbarStyles.location}>
            <MyLocationsSelector onChange={id => setLocationId(id)} locationId={locationId} />
          </div>
          <div className="mla mb1" style={toolbarStyles.location}>
            <Dropdown
              onChange={(e, { value }) => handleViewChange(value)}
              options={options}
              selection
              value={view}
            />
          </div>
        </div>
      </View.Section>
      <View.Section>
        <PagedList fetchList={fetchOffers} params={{
          query, view, locationId, businessId, propertyId
        }}>
          {(offersList) => (
            <OffersListTable
              offersList={offersList}
              noOffersString="No offers"
              buildHref={buildHref}
              hideBusiness
            />
          )}
        </PagedList>
      </View.Section>
    </View>
  );
};

BusinessOfferListView.propTypes = {
  businessId: PropTypes.string,
  propertyId: PropTypes.string,
  relativeLink: PropTypes.string
};

export default BusinessOfferListView;
