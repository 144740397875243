import {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import ContentFilter from 'shared/src/helpers/valuableContentFilter/valuableContentFilter';
import LocalizationWrapper from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizationWrapper';
import isEmpty from 'web-react-ui/src/utils/isEmpty';

const filter = new ContentFilter();

/**
 * Hook used when checking headline, subheadline for performance errors
 * @param formState react-final-form form's values
 */
export const useHasPerformanceError = (formState) => {
  const [hasPerformanceError, setHasPerformanceError] = useState(false);
  const localizationContext = useContext(LocalizationWrapper.context);

  const filterCheck = useMemo(
    () => {
      const isActive = ['headlineLocalized', 'subHeadlineLocalized'].includes(formState.active);
      const valuesEmpty = Boolean(!formState.values.headlineLocalized?.en || !formState.values.subHeadlineLocalized?.en);
      if (!isActive && !valuesEmpty) {
        return filter.check(formState.values, localizationContext);
      }
    },
    [formState.active, formState.values]
  );

  // perform this check immediately on mount so it appears when editing an offer
  useEffect(() => {
    setHasPerformanceError(filterCheck);
  }, []);

  useEffect(() => {
    const isTouched = formState.touched?.headlineLocalized || formState.touched?.subHeadlineLocalized;
    const isActive = ['headlineLocalized', 'subHeadlineLocalized'].includes(formState.active);
    // If the warning is showing, update immediately
    // Alternatively, fix localizable* fields to somehow preserve `touched` status when switching between locales
    if (!isActive && (isTouched || hasPerformanceError)) {
      setHasPerformanceError(filterCheck);
    }
  }, [formState.active, formState.values, filterCheck]);

  return [hasPerformanceError];
};
