import React from 'react';
import { useSelector } from 'react-redux';
import TranslationWrapper from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizationWrapper';

const ConnectedTranslationWrapper = ({ children }) => {
  const locales = useSelector(state => state.property.data.locales);
  return (
    <TranslationWrapper defaultLocale={locales[0]} locales={locales}>
      {children}
    </TranslationWrapper>
  );
};

export default ConnectedTranslationWrapper;
