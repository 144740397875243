import PropTypes from 'prop-types';
import React, { Component } from 'react';

import demoBlurred from '../../assets/stats/Total_Demo_Blurred_01.png';
import AgeChart from './AgeChart';
import GenderChart from './GenderChart';
import './Metrics.css';

class MetricItem extends Component {
  static propTypes = {
    name: PropTypes.string,
    ageData: PropTypes.array,
    genderData: PropTypes.shape({
      male: PropTypes.number,
      female: PropTypes.number
    }),
    total: PropTypes.number,
    restricted: PropTypes.bool
  };

  render() {
    const {
      name,
      ageData,
      genderData,
      restricted
    } = this.props;

    return (
      <div className='fg1 fb0 p1 m1' style={{ minWidth: '0' }}>
        <label className='show--tablet'>{name}</label>
        {restricted
          ? (
            <img className='metrics-funnel__placeholder-demos' src={demoBlurred}/>
          )
          : (
            <React.Fragment>
              <GenderChart genderData={genderData}/>
              <AgeChart ageData={ageData}/>
            </React.Fragment>
          )
        }
      </div>
    );
  }
}

export default MetricItem;
