import React from 'react';

import { Redirect, useLocation } from 'react-router-dom';

const DEFAULT_REDIRECT = '/';

const IsLoggedInRedirect = () => {
  const location = useLocation();
  const fromState = location?.state?.from;

  return <Redirect to={fromState || DEFAULT_REDIRECT} />;
};

export default IsLoggedInRedirect;
