import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Loader from '../components/elements/Loader';
import Dimmer from '../components/modules/Dimmer';

const renderChildren = children => (_.isFunction(children) ? children() : children);

const WaitFor = ({
  waitFor,
  wrapContents,
  inline,
  size,
  suppressSpinner,
  loaderChildren,
  children,
  ...rest
}) => {
  if (waitFor) {
    return (
      <Dimmer.Dimmable style={{ display: inline ? 'inline' : 'block' }} {...rest}>
        {renderChildren(children)}
      </Dimmer.Dimmable>
    );
  }
  if (!wrapContents) {
    return (
      <div className='p1'>
        <Loader active inline='centered' />
      </div>
    );
  }
  return (
    <Dimmer.Dimmable dimmed {...rest}>
      {renderChildren(children)}
      {!suppressSpinner && <Loader active size={size}>{loaderChildren}</Loader>}
      <Dimmer simple inverted />
    </Dimmer.Dimmable>
  );
};

WaitFor.propTypes = {
  waitFor: PropTypes.any,
  wrapContents: PropTypes.bool,
  inline: PropTypes.bool,
  size: PropTypes.string,
  suppressSpinner: PropTypes.bool,
  loaderChildren: PropTypes.element,
  children: PropTypes.node
};

export default WaitFor;
