import {
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import BigModal from '../../../BigModal/BigModal';
import PageBackButton from '../../../chakra/page/PageBackButton';
import withFileType from '../../imageWell/withFileType';
import EmptyImage from '../imagePreview/EmptyImage';
import ImageUploadWrapper from '../ImageUploadWrapper/ImageUploadWrapper';
import { FileTypeInfo } from '../types';

const ImageUploadModal = ({
  onClose,
  onChange,
  fileType,
  fileTypeInfo,
  aspectRatio,
  skipUpload,
  isEmpty,
}: {
  onClose: () => void,
  onChange: (base64Url: string) => void,
  fileType: string,
  fileTypeInfo: FileTypeInfo,
  aspectRatio: number,
  skipUpload: boolean,
  isEmpty: boolean,
}) => {
  return (
    <BigModal open={true} autoFocus={false}>
      <BigModal.Contents>
        <BigModal.Header>
          <PageBackButton onClick={onClose}>Cancel</PageBackButton>
          <Box p="1em">
            <Heading w="100%">Image Library</Heading>
          </Box>
        </BigModal.Header>
        <BigModal.Body>
          <Flex direction="column" height="100%">
            <ImageUploadWrapper
              onChange={onChange}
              fileType={fileType}
              fileTypeInfo={fileTypeInfo}
              aspectRatio={aspectRatio}
              skipUpload={skipUpload}
              isEmpty={isEmpty}
              captureClick={true}
              onCancel={onClose}
            >
              <EmptyImage label="Drop photo here or click to Choose" />
            </ImageUploadWrapper>
          </Flex>
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

export default withFileType(ImageUploadModal);
