/* eslint react/prop-types:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import WaitFor from 'web-react-ui/src/data/WaitFor';
import View from 'web-react-ui/src/components/layout/View';
import Message from 'web-react-ui/src/components/collections/Message';
import InlineSupportLink from 'web-react-ui/src/support/InlineSupportLink';

import propertyModule from '../index';
import { strings } from '../../../i18n';

const withProperty = (WrappedComponent) => {
  class WithProperty extends Component {
    componentDidMount() {
      this.loadData();
    }

    componentDidUpdate() {
      this.loadData();
    }

    loadData() {
      const { property, propertyId, details } = this.props;
      if (!propertyId || (property && property.id === propertyId)) {
        return false;
      }

      return details(propertyId);
    }

    render() {
      const props = this.props;
      if (props.error) {
        return (
          <View>
            <View.Section>
              <Message negative>
                <div>
                  There was an error!
                  <InlineSupportLink label={` ${strings('ui.label.contactSupport')}`} />.
                </div>
              </Message>
            </View.Section>
          </View>
        );
      }

      return (
        <WaitFor waitFor={props.property}>
          <WrappedComponent property={props.property} {...props} />
        </WaitFor>
      );
    }
  }

  WithProperty.propTypes = {
    render: PropTypes.func,
    propertyId: PropTypes.string
  };

  const mapState = state => ({
    error: propertyModule.selectors.property.getError(state),
    property: propertyModule.selectors.property.getData(state)
  });

  const mapDispatch = (dispatch) => {
    return bindActionCreators(propertyModule.actions, dispatch);
  };

  return connect(mapState, mapDispatch)(props => <WithProperty {...props} />);
};

export default withProperty;
