import React from 'react';
import CategoryField from '../../reactFinalForm/fields/CategoryField';

const FilterCategoryField = ({ name }) => {
  return (
    <div>
      <CategoryField name={name} wrapLabel multiSelect />
    </div>
  );
};

export default FilterCategoryField;
