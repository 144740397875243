import {
  Button,
  Flex,
  Heading,
  Image,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import BigModal from '../../../BigModal/BigModal';
import PageBackButton from '../../../chakra/page/PageBackButton';
import {
  BACKGROUND_COLOR,
  BORDER,
  BORDER_COLOR,
  BORDER_RADIUS,
} from '../constants';

const ImageConfirmModal = ({ src, onClose, onSave, onCrop }: {
  src: string,
  onClose: () => void,
  onSave: () => void,
  onCrop: () => void
}) => {
  return (
    <BigModal open={true}>
      <BigModal.Contents>
        <BigModal.Header>
          <PageBackButton onClick={onClose}>Cancel</PageBackButton>
          <Flex w="100%" gap="1em" p="1em">
            <Heading>Add Photo</Heading>
            <Spacer />
            <Button onClick={onCrop}>
              Crop
            </Button>
            <Button onClick={onSave} colorScheme="blue">
              Save
            </Button>
          </Flex>
        </BigModal.Header>
        <BigModal.Body>
          <Image
            src={src}
            h="100%"
            w="100%"
            fit="contain"
            bg={BACKGROUND_COLOR}
            border={BORDER}
            borderColor={BORDER_COLOR}
            borderRadius={BORDER_RADIUS}
          />
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

export default ImageConfirmModal;
