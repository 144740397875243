/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useBreakpointValue
} from '@chakra-ui/react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

const getActionColorScheme = (action) => {
  if (action.positive) return 'green';
  if (action.negative) return 'red';
  return null;
};

const Trigger = ({ size }) => <IconButton aria-label="more..." icon={<BiDotsHorizontalRounded />} size={size} />;
Trigger.propTypes = {
  size: PropTypes.string
};

const ActionType = PropTypes.shape({
  label: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.string,
  negative: PropTypes.bool,
  positive: PropTypes.bool,
  extraProps: PropTypes.object
});


const OverflowMenu = ({
  actions, open, size, context
}) => {
  return (
    <Menu defaultIsOpen={open}>
      <MenuButton as={IconButton} icon={<BiDotsHorizontalRounded />} size={size} />
      <Portal>
        <MenuList>
          {actions.map((a, index) => (
            <MenuItem
              key={index}
              onClick={a.action}
              context={context}
              isDisabled={!a.extraProps && !a.action}
              icon={a.icon}
              color={getActionColorScheme(a)}
              {...a.extraProps}
            >
              {a.label}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

OverflowMenu.propTypes = {
  actions: PropTypes.arrayOf(ActionType),
  open: PropTypes.bool,
  size: PropTypes.string,
  context: PropTypes.object
};

const PrimaryButton = ({ action: ac, buttonSize, context }) => {
  const Component = ac.label
    ? Button
    : IconButton;

  const colorScheme = getActionColorScheme(ac) || 'blue';

  return (
    <Component
      onClick={ac.action}
      colorScheme={colorScheme}
      {
        ...ac.label
          ? { leftIcon: ac.icon }
          : { icon: ac.icon }
      }
      positive={ac.positive}
      negative={ac.negative}
      size={buttonSize}
      context={context}
      {...ac.extraProps}
    >
      {ac.label}
    </Component>
  );
};

PrimaryButton.propTypes = {
  action: ActionType,
  buttonSize: PropTypes.string,
  context: PropTypes.object
};

const ActionMenu = ({
  actions, open, size, suppressPrimary, context, className
}) => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  if (!actions || !actions.length) return null;

  const filteredActions = actions.filter(a => !a.hidden);

  if (!filteredActions.length) return null;

  const primaryAction = filteredActions[0];
  const overflowActions = filteredActions.slice(1);

  const buttonSize = size;

  const singleButton = (!overflowActions.length);

  if (suppressPrimary) {
    return (
      <div className={className}>
        <OverflowMenu
          actions={filteredActions}
          open={open}
          size={buttonSize}
          context={context}
        />
      </div>
    );
  }

  if (singleButton) {
    return <div className={className}><PrimaryButton action={primaryAction} buttonSize={buttonSize} /></div>;
  }

  if (isSmallScreen) {
    return <div className={className}><OverflowMenu actions={filteredActions} open={open} size={buttonSize} /></div>;
  }

  return (
    <ButtonGroup className={className} spacing="1em">
      <PrimaryButton action={primaryAction} buttonSize={buttonSize} />
      <OverflowMenu actions={overflowActions} open={open} size={buttonSize} />
    </ButtonGroup>
  );
};

ActionMenu.propTypes = {
  actions: PropTypes.arrayOf(ActionType),
  open: PropTypes.bool,
  size: PropTypes.string,
  context: PropTypes.object,
  suppressPrimary: PropTypes.bool
};

export default ActionMenu;
