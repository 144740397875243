import React from 'react';
import { Box } from '@chakra-ui/react';

const Card = ({ children }) => {
  return (
    <Box p='5' borderColor='gray.300' borderWidth='1px' borderRadius='lg'>
      {children}
    </Box>
  );
};

export default Card;
