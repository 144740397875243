/* eslint react/prop-types: 0 */
import React from 'react';
import { injectClient } from 'shared/src/components/contexts/ClientContext';
import Request from '../../data/Request';

const withFileType = (Wrapped) => {
  const component = props => (
    <Request
      request={({ fileType }) => props.client.images.getType(fileType)}
      params={{ fileType: props.fileType }}
      cache='fileType'
    >
      {({ data }) => (
        <Wrapped fileTypeInfo={data} {...props}/>
      )}
    </Request>
  );
  component.displayName = `withFileType(${Wrapped.displayName || Wrapped.name})`;
  return injectClient(component);
};

export default withFileType;
