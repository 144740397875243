import { types } from './types';

export const details = id => ({
  type: types.PROPERTY_DETAILS_REQUEST,
  payload: {
    propertyId: id
  },
});

export const list = () => ({
  type: types.PROPERTY_LIST_REQUEST,
});

export const invalidate = () => ({
  type: types.PROPERTY_INVALIDATE
});

export const invalidateAndRefresh = propertyId => ({
  type: types.PROPERTY_INVALIDATE_REFRESH,
  payload: {
    propertyId
  }
});
