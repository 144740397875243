import client from '../services/client';

const loadImage = (item, prop) => {
  if (!item[prop]) return Promise.resolve(item);
  return client.images.for(item[prop]).details()
    .then(image => Object.assign(item, { profileImage: image }));
};

const loadAllImages = (items, prop) => (
  Promise.all(items.map(i => loadImage(i, prop)))
);

const loadAllPagedListImage = (list, prop) => (
  loadAllImages(list.items, prop).then(items => Object.assign({}, list, { items }))
);

export { loadImage };
export { loadAllImages };
export { loadAllPagedListImage };
