import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import businessModule from '../../modules/businesses';
import propertyModule from '../../modules/property';
import client from '../../services/client';
import confirm from '../../services/confirm';
import OfferDetailsView from './OfferDetailsView';


class OfferDetailsContainer extends Component {
  static propTypes = {
    offerId: PropTypes.string,
    property: PropTypes.object,
    business: PropTypes.object,
    history: PropTypes.object
  };

  state = {
    refetchKey: 0
  };

  async fetchStats(params) {
    if (!this.props.offerId) return null;
    const propertyId = this.props.property.id;
    const businessId = this.props.business.id;
    const offerId = this.props.offerId;
    const locationId = params.locationId;
    const isLoyalty = params.isLoyalty;
    const statsRepo = client
      .properties.for(propertyId)
      .businesses.for(businessId)
      .locations.for(locationId)
      .offers.for(offerId)
      .stats;

    try {
      if (isLoyalty) return statsRepo.getLoyaltyLastNDays(params);
      return statsRepo.getLastNDays(params);
    } catch (error) {
      this.setState({ error });
    }

    return null;
  }

  async fetchOffer() {
    const { property, business, offerId } = this.props;
    const propertyId = property.id;
    const businessId = business.id;
    const offer = await client
      .properties.for(propertyId)
      .businesses.for(businessId)
      .offers.for(offerId)
      .details();
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    if (offer.schedules[0]?.endDate < formattedDate) {
      this.setState({ days: 364 });
    }
    return offer;
  }

  fetchTags() {
    const { property, offerId } = this.props;
    return client.tags.list(property.id, client.tags.TYPE_NAMES.offer, offerId)
      .then(tags => tags.items);
  }

  saveTags(newTags) {
    const { property, offerId } = this.props;
    return client.tags.reconcile(property.id, client.tags.TYPE_NAMES.offer, offerId, newTags);
  }

  reloadOffer() {
    this.setState(({ refetchKey }) => ({ refetchKey: refetchKey + 1 }));
  }

  async handleEnable() {
    const { property, offerId, business } = this.props;
    this.setState({ isBusy: true });

    const offerRepo = client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offerId);

    try {
      await offerRepo.checkLimits();
    } catch (error) {
      this.setState({
        error,
        isBusy: false
      });
      return;
    }

    offerRepo.enable()
      .then(
        () => {
          this.reloadOffer();
          this.setState({ isBusy: false });
        },
        error => this.setState({ error, isBusy: false })
      );
  }

  handleDisable() {
    const { property, offerId, business } = this.props;
    this.setState({ isBusy: true });
    client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offerId).disable()
      .then(() => {
        this.reloadOffer();
        this.setState({ isBusy: false });
      });
  }

  handleDelete() {
    const { property, business, offerId } = this.props;
    if (!confirm(`Are you sure you want to delete this offer?`)) return false;
    this.setState({ isBusy: true });
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offerId)
      .delete()
      .then(() => {
        const offerListUrl = `/${property.id}/businesses/${business.id}/offers`;
        this.setState({ redirect: offerListUrl });
      })
      .catch(error => this.setState({ error, isBusy: false }));
  }

  handleDuplicate() {
    const { property, business, offerId } = this.props;
    const offerCreateUrl = `/${property.id}/businesses/${business.id}/offers/new/${offerId}`;
    return this.setState({ redirect: offerCreateUrl });
  }

  handleUnsyndicate() {
    const { property, business, offerId } = this.props;
    if (!confirm(`Are you sure you want to permanently unsyndicate this offer?`)) return false;
    this.setState({ isBusy: true });
    return client
      .properties.for(property.id)
      .businesses.for(business.id)
      .offers.for(offerId)
      .unsyndicate()
      .then(() => {
        const offerListUrl = `/${property.id}/businesses/${business.id}/offers`;
        this.setState({ redirect: offerListUrl });
      })
      .catch((error) => { this.setState({ error, isBusy: false }); });
  }

  handleItemClick = (e, { name }) => {
    const {
      history, property, business, offerId
    } = this.props;
    if (name === 'Stats') history.replace(`/${property.id}/businesses/${business.id}/offers/${offerId}/stats`);
    if (name === 'Details') history.replace(`/${property.id}/businesses/${business.id}/offers/${offerId}`);
  };

  render() {
    const { property, business } = this.props;
    const {
      isBusy, redirect, error, refetchKey
    } = this.state;
    if (redirect) return <Redirect to={redirect} />;
    return (
      <Request request={this.fetchOffer.bind(this)} params={{ refetchKey }}>
        {({ data: offer, error: offerError, loading }) => (
          <div>
            {offerError && <ErrorMessage error={offerError} />}
            <WaitFor waitFor={!loading && !isBusy} wrapContents={offer}>
              {offer && <OfferDetailsView
                offer={offer}
                error={error}
                property={property}
                business={business}
                fetchTags={this.fetchTags.bind(this)}
                fetchStats={this.fetchStats.bind(this)}
                saveTags={this.saveTags.bind(this)}
                handleEnable={this.handleEnable.bind(this)}
                handleDisable={this.handleDisable.bind(this)}
                handleDelete={this.handleDelete.bind(this)}
                handleDuplicate={this.handleDuplicate.bind(this)}
                handleUnsyndicate={this.handleUnsyndicate.bind(this)}
              />}
            </WaitFor>
          </div>
        )}
      </Request>
    );
  }
}

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state),
  business: businessModule.selectors.business.getData(state)
});

export default connect(mapState)(OfferDetailsContainer);
