import {
  Box,
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { PiFileImageThin } from 'react-icons/pi';

const EmptyImage = ({ label }: { label: string }) => {
  return (
    <Box height="100%">
      <Center w="100%" h="100%">
        <VStack color="gray.400">
          <Icon as={PiFileImageThin} boxSize={16} />
          <Text>{label}</Text>
        </VStack>
      </Center>
    </Box>
  );
};

export default EmptyImage;
