import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import SuiForm from '../../components/collections/Form';
import ListSelector from '../../components/ListSelector';

import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

const ListSelectorField = (props) => {
  const {
    name,
    label,
    validate,
    format,
    parse,
    optional,
    suppressError,
    disabled,
    ...rest
  } = props;

  return (
    <Field name={name} validate={validate} format={format} parse={parse}>
      {({ input }) => (
        <SuiForm.Field disabled={disabled}>
          <FieldLabel label={label} optional={optional}/>
          <ListSelector {...input} {...rest} />
          {!suppressError && <FieldError name={name}/>}
        </SuiForm.Field>
      )}
    </Field>
  );
};
ListSelectorField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  format: PropTypes.func,
  parse: PropTypes.func,
  optional: PropTypes.bool,
  suppressError: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ListSelectorField;
