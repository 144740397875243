import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, FormLabel, Icon, Tooltip } from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';
import { useForm } from 'react-final-form';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import RadioButtonGroupField from '../../../chakra/forms/RadioButtonGroup/RadioButtonGroupField';

export const actionOptions = () => {
  const { strings } = useI18Next();
  const form = useForm();
  const offerType = form.getState().values.offerType;
  const offerFlags = form.getState().values.flags;

  const isDisabled = () => {
    if (offerType) {
      return ['limited', 'punchcard'].includes(offerType);
    }

    return offerFlags.some(item => ['limited', 'punchcard'].includes(item));
  };

  return [
    {
      label: strings('ui.component.offerRedeemLimits.actionOptions.inPerson.text'),
      value: 'present',
      info: strings('ui.component.offerRedeemLimits.actionOptions.inPerson.info'),
    },
    {
      label: strings('ui.component.offerRedeemLimits.actionOptions.byPhone.text'),
      value: 'call',
      info: strings('ui.component.offerRedeemLimits.actionOptions.byPhone.info'),
      disabled: isDisabled(),
    },
    {
      label: strings('ui.component.offerRedeemLimits.actionOptions.online.text'),
      value: 'website',
      info: strings('ui.component.offerRedeemLimits.actionOptions.online.info'),
      disabled: isDisabled(),
    }
  ];
};

const RedemptionSelector = ({ name, ...boxProps }) => {
  const { strings } = useI18Next();
  const form = useForm();
  const offerType = form.getState().values.offerType;
  const selectorTitle = offerType === 'punchcard'
    ? strings('dashboard.component.editOfferForm.field.label.howWillCustomersUsePunch')
    : strings('dashboard.component.editOfferForm.field.label.howWillCustomersUse');
  const tooltipLabel = offerType === 'punchcard'
    ? strings('dashboard.component.editOfferForm.field.label.howWillCustomersUsePunchTooltip')
    : strings('dashboard.component.editOfferForm.field.label.howWillCustomersUseTooltip');

  const formActionName = offerType === 'punchcard'
    ? `${name}.punchAction` // this doesn't actually do anything for punch cards!
    : `${name}.action`;

  return (
    <Box {...boxProps}>
      <FormLabel>
        <Flex align="center">
          {selectorTitle}
          <Tooltip label={tooltipLabel} placement="top" hasArrow>
            <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
          </Tooltip>
        </Flex>
      </FormLabel>
      <RadioButtonGroupField name={formActionName} options={actionOptions()} />
    </Box>
  );
};

RedemptionSelector.propTypes = {
  name: PropTypes.string,
};

export default RedemptionSelector;
