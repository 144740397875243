import { Spinner } from '@chakra-ui/react';
import React from 'react';

import useClient from 'shared/src/hooks/useClient';
import useRequest from 'shared/src/hooks/useRequest';

import OfferRating from './OfferRating';

type Client = {
  access: {
    checkPermissions: Function
  };
  properties: {
    for: Function
  };
};

type GetOfferRatingParams = {
  offerId: string;
  propertyId: string;
  businessId: string;
  locationId: string | undefined
}

const getOfferRating = async (
  { client, offerId, propertyId, businessId, locationId }: GetOfferRatingParams & { client: Client },
) => {
  if (!propertyId) return null;
  if (!businessId) return null;
  if (!offerId) return null;

  // Check for permissions because we don't have a /self/property/business/location/offers/search yet
  // So we might try to load ratings for the wrong Location
  // Ultimately, this should be fixed by removing all access-checking shenanigans in the offers-repo
  const hasPermission = await client.access.checkPermissions(
    { propertyId, businessId, locationId },
    locationId ? ['property.business.location.offer.search'] : ['property.business.offer.search'],
  );

  if (!hasPermission) return null;

  if (locationId) {
    return client
      .properties.for(propertyId)
      .businesses.for(businessId)
      .locations.for(locationId)
      .offers.for(offerId)
      .rating.get();
  }

  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .offers.for(offerId)
    .rating.get();
};


const GetOfferRating = ({ offerId, propertyId, businessId, locationId }: GetOfferRatingParams) => {
  const client = useClient();

  const ratingRequest = useRequest(
    getOfferRating,
    {
      client,
      offerId,
      propertyId,
      businessId,
      locationId,
    },
  );

  if (ratingRequest.loading) return <Spinner />;
  return <OfferRating rating={ratingRequest.result?.rating} />;
};

export default GetOfferRating;
