/* global window */
import { parse } from 'mini-querystring';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { injectClient } from 'shared/src/components/contexts/ClientContext';
import WaitFor from '../../data/WaitFor';

import { makeToast } from '../toasts';
import SitePermissionDenied from './SitePermissionDenied';
import SitePermissionRedirect from './SitePermissionRedirect';

const parseQuery = str => parse(str.replace(/^[?#]/, ''));
const getOktaCode = () => parseQuery(window.location.search).code;
const getAuthStateParam = () => {
  const stateJson = parseQuery(window.location.search).state;
  try {
    return JSON.parse(stateJson);
  } catch (err) {
    // Ignore parse error and return default
  }
  return {};
};
const getRedirectPath = () => getAuthStateParam().redirectTo;
const getError = () => parseQuery(window.location.search).error;
const getErrorDescription = () => {
  try {
    return parseQuery(window.location.search).error_description.replace(/\+/g, ' ');
  } catch (err) {
    return '';
  }
};

const IGNORE_PATHS = [
  '/auth',
  '/apps'
];

class AuthReceiver extends Component {
  state = {
    authenticating: false,
    permissionError: null
  };

  authenticate() {
    const { client, sitePermission, redirectToOwnerDashboard } = this.props;
    const code = getOktaCode();
    const REDIRECT_URL = `${window.location.protocol}//${window.location.host}`;

    this.setState({ authenticating: true });
    client.auth.oauthSetCode(code, REDIRECT_URL)
      .then(async () => {
        try {
          const skipAccessCheck = window.localStorage.getItem('lmg:SkipAccessCheck')
            || (await client.access.checkPermissions({}, ['*user.admin.access']));
          if (skipAccessCheck) {
            return this.setState({ authenticating: false, authenticated: true });
          }
        } catch {
          // ok, I guess we check
        }

        // HACK: Gross way to check Local Owner Dashboard permission on login _before_ checking anything else...
        if (redirectToOwnerDashboard) {
          const isLocalOwner = await client.access.checkPermissions({}, ['*user.owner.access']);
          if (isLocalOwner) {
            this.setState({
              isLocalOwner: true
            });
            return client.auth.logout();
          }
        }

        if (!sitePermission) return this.setState({ authenticating: false, authenticated: true });

        const hasPermission = (await client.access.checkPermissions({}, [sitePermission]))
          || (await client.access.checkPermissions({}, ['*user.admin.access']));
        if (!hasPermission) {
          this.setState({
            authenticating: true,
            authenticated: false,
            permissionError: 'User does not have permissions to access this site'
          });

          return client.auth.logout();
        }

        return this.setState({ authenticating: false, authenticated: true });
      });

    return null;
  }

  render() {
    const { children } = this.props;

    if (IGNORE_PATHS.find(route => location.pathname.indexOf(route) === 0)) return children;

    const code = getOktaCode();
    const redirectTo = getRedirectPath();
    const error = getError();
    const errorDescription = getErrorDescription();

    if (this.state.isLocalOwner) {
      return <SitePermissionRedirect />;
    }

    if (this.state.permissionError) {
      return <SitePermissionDenied />;
    }

    if (error) {
      makeToast({
        title: 'Login Error',
        message: `${error}: ${errorDescription}`,
        type: 'negative',
        icon: 'lock'
      });
    }

    if (!code) {
      return children;
    }

    if (this.state.authenticating) {
      return <WaitFor />;
    }

    if (!this.state.authenticated) {
      return this.authenticate();
    }

    if (redirectTo) {
      window.location.replace(redirectTo);
      return null;
    }

    return children;
  }
}

AuthReceiver.propTypes = {
  client: PropTypes.object,
  sitePermission: PropTypes.string,
  redirectToOwnerDashboard: PropTypes.bool
};

export default injectClient(AuthReceiver);
