
import { NAME } from './types';

export const business = {
  getIsPending: state => state[NAME].isPending,
  getData: state => state[NAME].data,
  getError: state => state[NAME].error,
};

export const businesses = {
  getIsPending: state => state[NAME].isPending,
  getData: state => state[NAME].data,
  getError: state => state[NAME].error,
};
