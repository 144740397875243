import {
  useContext,
  useEffect,
  useState
} from 'react';

import ClientContext from 'shared/src/components/contexts/ClientContext';

const usePermissions = ({ propertyId, businessId, locationId }, permissions) => {
  const client = useContext(ClientContext);
  const [granted, setGranted] = useState(null);

  useEffect(() => {
    client
      .access.checkPermissions({ propertyId, businessId, locationId }, permissions)
      .then(setGranted);
  }, [propertyId, businessId, locationId, permissions]);

  return granted;
};

export default usePermissions;
