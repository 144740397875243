/* global window */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useField } from 'react-final-form';
import { TextField } from '../../reactFinalForm/fields';
import SimpleSelectField from '../../reactFinalForm/fields/SimpleSelectField';
import RemoveButton from './RemoveButton';
import useAutoSelectFirst from './useAutoSelectFirst';


const EmptyComponent = ({ name }) => (
  <TextField name={name} wrapLabel readOnly format={JSON.stringify} />
);

const FilterTargetSelect = ({ options, name, ...rest }) => {
  useAutoSelectFirst(name, options, true);
  const { input } = useField(name);

  const option = useMemo(
    () => options.find(o => o.value === input.value)
    , [input.value, options]
  );

  if (!option) return <TextField name={name} disabled />;

  return (
    <SimpleSelectField options={options} name={name} {...rest} />
  );
};

const FilterOperationSelect = ({ options, name }) => {
  useAutoSelectFirst(name, options);
  if (!options.length) return <TextField name={name} disabled />;
  return (
    <SimpleSelectField options={options} name={name} />
  );
};

const ComponentErrorHandler = ({ name, reset }) => {
  const { input } = useField(name);
  useEffect(
    () => {
      // reset value
      input.onChange(undefined);
      // reset error boundary
      reset();
    },
    []
  );

  return (
    <h1>{name}</h1>
  );
};

class ComponentErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  resetError() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      return <ComponentErrorHandler name={this.props.name} reset={this.resetError.bind(this)} />;
    }

    return this.props.children;
  }
}

const FilterRow = ({
  name,
  config: { targets, getOperations, getComponent },
  onRemoveRow,
  context
}) => {
  const { input: targetInput } = useField(`${name}.target`);
  const { input: operationInput } = useField(`${name}.operation`);
  const { input: argsInput } = useField(`${name}.args`);

  // Target Input
  const targetOptions = targets || [];
  const targetConfig = targetOptions.find(target => target.value === targetInput.value);
  const readOnly = !targetConfig;

  // Operation Input
  const operationOptions = useMemo(
    () => getOperations(targetInput.value) || [],
    [targetInput.value]
  );

  // Component
  const Component = useMemo(
    () => getComponent(targetInput.value, operationInput.value) || EmptyComponent,
    [targetInput.value, operationInput.value]
  );

  const handleRemoveRow = useCallback(
    () => {
      const shouldRemove = !readOnly
        || window.confirm('Are you sure you want to remove this expression?\nIt cannot be added back as-is.');
      if (shouldRemove) onRemoveRow();
    }
  );

  return (
    <div className='c-filter-row'>
      <div className='ui two column doubling grid'>
        <div className='column'>
          <div className='ui two column grid'>
            <div className='eight wide column'>
              <FilterTargetSelect options={targetOptions} name={`${name}.target`} />
            </div>
            <div className='eight wide column'>
              <FilterOperationSelect options={operationOptions} name={`${name}.operation`} />
            </div>
          </div>
        </div>

        <div className='column'>
          <div className='ui two column grid'>
            <div className='fourteen wide column'>
              <ComponentErrorBoundary name={`${name}.args`}>
                <Component name={`${name}.args`} context={context} />
              </ComponentErrorBoundary>
            </div>
            <div className='two wide column asc' style={{ padding: '0' }}>
              <RemoveButton type='button' onClick={handleRemoveRow} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterRow;
