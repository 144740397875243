import { types } from './types';

const defaultState = {
  isPending: false,
  data: null,
  error: null
};

export default (state = defaultState, action) => {
  const { payload, error, type } = action;
  const { data } = payload || {};

  switch (type) {
    case types.PROPERTY_DETAILS_REQUEST:
      return { ...state, data: null, isPending: true };
    case types.PROPERTY_DETAILS_SUCCESS:
      return { ...state, isPending: false, data };
    case types.PROPERTY_DETAILS_FAILURE:
      return { ...state, isPending: false, error };
    case types.PROPERTY_INVALIDATE:
      return { ...state, data: null };
    default:
      return state;
  }
};
