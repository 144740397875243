import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EmptyList extends Component {
  static propTypes = {
    message: PropTypes.string
  };

  render() {
    return (
      <em className='item item--disabled'>
        <div className='pv1'>
          {this.props.message}
        </div>
      </em>
    );
  }
}

export default EmptyList;
