import { PiFileImageThin } from 'react-icons/pi';
import {
  AddIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Circle,
  CloseButton,
  Icon,
  IconButton,
  Image,
  Skeleton,
} from '@chakra-ui/react';
import React, {
  ComponentProps,
  useContext,
} from 'react';
import ClientContext from 'shared/src/components/contexts/ClientContext';
import useRequest from 'shared/src/hooks/useRequest';
import { BORDER_RADIUS } from '../constants';

interface ImageThumbProps {
  src?: string;
  index: number;
  onClick: (index: number) => void;
  onDelete?: (index: number) => void;
  active: boolean;
}

const cachePredicate = ([src]: [string]) => {
  return !src.startsWith('data:');
};

const ImageThumb = ({ src, index, onClick, onDelete, active }: ImageThumbProps) => {
  const client = useContext(ClientContext);
  const imageRequest = useRequest(
    // eslint-disable-next-line no-shadow
    async ([src]: [src: string, client: any]) => {
      if (!src) return null;
      if (src.startsWith('data:')) return src;
      const imageDetails = await client.images.for(src).details();
      return imageDetails.url;
    },
    [src],
    {
      cache: 'image',
      cachePredicate,
    },
  );

  const showDelete = !active && src && onDelete;

  return (
    <Box position="relative" width="100px" height="75px">
      <Button
        opacity={active ? 0.75 : 1}
        border="2px solid"
        borderColor={active ? 'blue.200' : 'transparent'}
        borderRadius={BORDER_RADIUS}
        width="100%"
        height="100%"
        padding="0"
        onClick={() => onClick(index)}
        aria-label={`Image ${index}`}
        bg={active ? 'aliceblue' : 'none'}
        overflow="hidden"
      >
        {
          src
            ? (
              <Skeleton isLoaded={!imageRequest.loading} height="100%" width="100%">
                <Image objectFit="cover" src={imageRequest.result as string} w="100%" h="100%" />
              </Skeleton>
            )
            : (
              <Box borderColor="gray.200" borderWidth={active ? 2 : 0}>
                <Center h="100%">
                  {
                    active
                      ? (
                        <Icon as={PiFileImageThin} boxSize={8} />
                      )
                      : (
                        <Circle size="40px" bg="gray.200">
                          <AddIcon />
                        </Circle>
                      )
                  }
                </Center>
              </Box>
            )
        }
      </Button>
      {showDelete && <DeleteButton onClick={() => onDelete(index)} />}
    </Box>
  );
};

const DeleteButton = (props: ComponentProps<typeof CloseButton>) => {
  return (
    <IconButton
      bg="white"
      icon={<CloseIcon />}
      borderRadius="100%"
      position="absolute"
      top="0.5em"
      right="0.5em"
      size="sm"
      opacity="0.8"
      _hover={{
        opacity: 1,
      }}
      {...props}
    />
  );
};

export default ImageThumb;
