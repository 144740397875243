import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import {
  transport,
  useQuerystringParam,
} from 'shared/src/hooks/useQuerystringParam';
import useQueryDebounce from 'shared/src/hooks/useQueryDebounce';
import usePagedList from 'shared/src/hooks/usePagedList';
import { AutoPagination } from 'web-react-ui/src/chakra/Pagination';

import Business from 'web-react-ui/src/types/Business.interface';
import Property from 'web-react-ui/src/types/Property.interface';
import Input from 'web-react-ui/src/components/elements/Input';
import Label from 'web-react-ui/src/components/elements/Label';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';

import {
  List,
  Item,
  Empty,
  Loading,
} from 'web-react-ui/src/chakra/List';

import client from '../../services/client';

const BusinessListItem = ({
  business,
  propertyId,
}: {
  business: Business,
  propertyId: string
}): JSX.Element => (
  <Item
    as={Link}
    to={`/${propertyId}/businesses/${business.id}`}
    justify="space-between"
    align="center"
  >
    <Flex align="center">
      <Box mr={4}>
        <ProfileImage imageId={business.profileImage} name={business.name} />
      </Box>
      <Text
        fontWeight={500}
        fontSize="lg"
        color="black"
        m={0}
      >
        {business.name}
      </Text>
      <Box ml="auto">
        {(!business.isEnabled) && <Label>Disabled</Label>}
      </Box>
    </Flex>
    <ArrowForwardIcon w={5} color="gray.500" ml="auto" />
  </Item>
);

const fetchBusinesses = ({
  property,
  query,
  enabled,
}: {
  property: Property,
  query?: string,
  enabled?: boolean,
}) => {
  return client
    .properties.for(property.id)
    .businesses.list({ query, enabled });
};

function BusinessList({ property }: { property: Property }): JSX.Element {
  const [enabled, setEnabled] = useQuerystringParam('enabled', true, { transport: transport.boolean, squash: true });
  const [query, debouncedQueryHandler] = useQueryDebounce(property);
  const propertyId = property?.id;

  const businessList = usePagedList(fetchBusinesses, { property, query, enabled });
  return (
    <div>
      <Box
        borderBottom="1px"
        borderColor="gray.300"
        py={5}
        bg="gray.50"
      >
        <Container maxW={992}>
          <Flex align="center">
            <Button as={Link}
                    colorScheme="blue"
                    to={`/${property?.id}/businesses/add`}
                    ml="auto"
            >
              Add Business
            </Button>
          </Flex>
        </Container>
      </Box>
      <Box>
        <Container mt={5} maxW={992}>
          <HStack w="100%">
            <Input
              type="search"
              fluid
              defaultValue={query}
              onChange={(e: any) => debouncedQueryHandler(e.target.value)}
              icon="search"
              placeholder="Business Name"
              className="fg1"
            />
            <ButtonGroup isAttached ml={1}>
              <Button isActive={enabled} onClick={() => setEnabled(true)}>Enabled</Button>
              <Button isActive={!enabled} onClick={() => setEnabled(false)}>Disabled</Button>
            </ButtonGroup>
          </HStack>
        </Container>
        <Container maxW={992} mt={5}>
          <List mb="4">
            <Empty isEmpty={businessList.empty}>No Businesses</Empty>
            <Loading isLoading={businessList.loading} />
            {!businessList.loading && businessList.items?.map((business: Business) => (
              <BusinessListItem
                key={business.id}
                business={business}
                propertyId={propertyId}
              />
            ))}
          </List>
          <AutoPagination pagedList={businessList} />
        </Container>
      </Box>
    </div>
  );
}

export default BusinessList;
export { BusinessList as Presenter };
