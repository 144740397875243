/* eslint camelcase: 0, class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import View from 'web-react-ui/src/components/layout/View';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import client from '../../services/client';
import AddIntegrationView from './AddIntegrationView';

class AddIntegrationContainer extends Component {
  static propTypes = {
    propertyId: PropTypes.string,
    history: PropTypes.object
  };

  state = {};

  handleSubmit(values) {
    const { propertyId } = this.props;

    const { corsDomains, ...newIntegration } = values;
    newIntegration.corsDomains = ['browser', 'pixel'].includes(newIntegration.sdk)
      ? corsDomains
        .split(/\s*[\r\n]+\s*/)// Deal with not having a nice list input component yet...
        .filter(Boolean)
      : []; // Non-browser, non-pixel keys can't have allowed CORS domains

    return client
      .properties.for(propertyId)
      .integrations.create(newIntegration)
      .then((integration) => {
        this.setState({ integration });
        return null;
      });
  }

  backToIntegrations() {
    this.props.history.push('./');
  }

  render() {
    return (
      <BigModal open={true}>
        <SimpleForm onSubmit={this.handleSubmit.bind(this)}>
          <BigModal.Contents>
            <BigModal.Body>
              <View>
                <View.Section fluid>
                  <BigModal.CloseButton floated='right' onClick={this.backToIntegrations.bind(this)} />
                  <AddIntegrationView
                    backToIntegrations={this.backToIntegrations.bind(this)}
                    onSubmit={this.handleSubmit.bind(this)}
                    integration={this.state.integration}
                  />
                </View.Section>
              </View>
            </BigModal.Body>
          </BigModal.Contents>
        </SimpleForm>
      </BigModal>
    );
  }
}

export default AddIntegrationContainer;
