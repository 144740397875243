import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-final-form';
import { AiOutlineWarning } from 'react-icons/ai';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import segment from 'shared/src/helpers/segment';

export const PerformanceCheckModal = ({ onClose, isOpen }) => {
  const { strings } = useI18Next();
  const form = useForm();
  const formValues = form.getState().values;

  const handleClose = () => {
    if (!formValues.id && formValues.enabled) {
      form.change('enabled', false);
    }

    onClose(false);
  };

  const handleSubmit = () => {
    onClose(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex alignItems='center'>
            <Icon as={AiOutlineWarning} h={8} w={8} color='orange.400' mr={2} />
            <Text fontSize='20px' fontWeight={700}>
              {strings('ui.modal.performance.offerPerformanceWarning')}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text fontSize='16px' fontWeight={600}>
            {strings('ui.modal.performance.headlinesWarning')}
          </Text>
          <Divider my={8} />
          <Heading mb={2} fontSize='16px' fontWeight={600}>
            {strings('ui.modal.performance.currentOffer')}
          </Heading>
          <Text fontSize='16px' mb={2}>
            {strings('ui.modal.performance.headline', {
              value:
                formValues.headline
                || formValues.headlineLocalized?.en
                || formValues.headlineLocalized?.fr
            })}
          </Text>
          <Text fontSize='16px' mb={6}>
            {strings('ui.modal.performance.subheadline', {
              value: formValues.subHeadline
                || formValues.subHeadlineLocalized?.en
                || formValues.subHeadlineLocalized?.fr
            })}
          </Text>
          <Text mb={4} fontSize='16px' fontWeight={600}>
            {strings('ui.modal.performance.consumerTipsTitle')}
          </Text>
          <UnorderedList ml={8}>
            <ListItem fontSize='16px' fontWeight={300}>
              {strings('ui.modal.performance.includeDiscountPhrase')}
            </ListItem>
            <ListItem fontSize='16px' fontWeight={300}>
              {strings('ui.modal.performance.describeProduct')}
            </ListItem>
          </UnorderedList>
        </ModalBody>
        <ModalFooter>
          <Button
            variant='ghost'
            onClick={() => {
              segment.track('valuable_offer_warning__publish');
              handleSubmit();
            }}
            mr={4} p={8}>
            <Text textColor='gray.400' fontSize='16' fontWeight={600}>
              {strings('ui.modal.performance.publishAnyway')}
            </Text>
          </Button>
          <Button
            colorScheme='blue'
            mr={3}
            onClick={() => {
              segment.track('valuable_offer_warning__edit');
              handleClose();
            }}
            p={8}
          >
            <Text fontSize='16' fontWeight={600}>
              {strings('ui.modal.performance.continueEditing')}
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

PerformanceCheckModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  formValues: PropTypes.object
};
