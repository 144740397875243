import { BoxProps, Flex } from '@chakra-ui/react';
import * as React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

interface Props extends BoxProps {
  active?: boolean;
  icon?: React.ReactNode;
  label?: string;
  hover?: string;
  href?: string;
}

const NavItem = ({ active, icon, label, bg, color, hover, ...rest }: Props) => (
  <Flex
    w="100%"
    px={3}
    py={2}
    cursor="pointer"
    userSelect="none"
    rounded="md"
    transition="all 0.2s"
    bg={active ? 'blue.700' : bg ?? undefined}
    layerStyle={['defaultFont.size']}
    fontWeight={500}
    color={active ? 'white' : color ?? 'gray.600'}
    _hover={{
      bg: hover ?? 'blue.700',
      color: 'white'
    }}
    _active={{
      bg: 'blue.600',
      color: 'white'
    }}
    {...rest}
  >
    <Flex align="center" color='currentcolor' mr={3}>
      {icon}
    </Flex>
    <Flex align="center" flex='1' fontWeight='inherit'>
      {label}
    </Flex>
  </Flex>
);

interface NavLinkItemProps extends BoxProps {
  to: string;
  exact?: boolean;
  icon?: React.ReactNode;
  label: React.ReactNode;
  activeItem?: boolean;
  isActive?: boolean;
  hover?: string;
}

const NavLinkItem = ({ to, exact, activeItem, ...rest }: NavLinkItemProps) => {
  const active = activeItem || Boolean(useRouteMatch({
    path: to,
    exact: exact ?? true
  }));

  return (
    <NavLink to={to} exact={exact}>
      <NavItem {...rest} active={active} />
    </NavLink>
  );
};

export {
  NavItem,
  NavLinkItem
};
