import React from 'react';
import I18NextContext from 'shared/src/components/contexts/I18NextContext';
import Label from '../elements/Label';
import Rating from '../modules/Rating';

const OFFER_RATING_LABELS = {
  poor: 'ui.component.offerRatingPlaceholder.getRatingLabel.poor',
  ok: 'ui.component.offerRatingPlaceholder.getRatingLabel.ok',
  good: 'ui.component.offerRatingPlaceholder.getRatingLabel.good',
  great: 'ui.component.offerRatingPlaceholder.getRatingLabel.great',
  waiting: 'ui.component.offerRatingPlaceholder.getRatingLabel.waiting',
};

function getRatingLabel(ratingVal: number | undefined, strings: Function): string {
  switch (ratingVal) {
    case 1:
      return strings(OFFER_RATING_LABELS.poor);
    case 2:
      return strings(OFFER_RATING_LABELS.ok);
    case 3:
      return strings(OFFER_RATING_LABELS.good);
    case 4:
      return strings(OFFER_RATING_LABELS.great);
    default:
      return strings(OFFER_RATING_LABELS.waiting);
  }
}

const OfferRating = ({ rating }: { rating?: number }) => {
  const normalizedRating = (rating === null || rating === undefined)
    ? 0
    : Math.ceil(rating * 4);

  return (
    <div className="tooltip" style={{ display: 'flex' }}>
      <Rating rating={normalizedRating} maxRating={5} disabled />
      <I18NextContext.Consumer>
        {({ strings }: { strings: Function }) => (
          <Label className="tooltiptext">
            {getRatingLabel(normalizedRating, strings)}
          </Label>
        )}
      </I18NextContext.Consumer>
    </div>

  );
};

export default OfferRating;
