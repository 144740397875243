import PropTypes from 'prop-types';

import React, {
  useMemo,
  useState
} from 'react';

const TranslationContext = React.createContext({
  isDefaultLocale: true,
  locales: ['en'],
  defaultLocale: 'en',
  locale: 'en'
});

const LocalizationWrapper = ({
  children,
  locales = ['en'],
  defaultLocale = locales[0],
  initialLocale = defaultLocale
}) => {
  const [locale, setLocale] = useState(initialLocale);

  const contextValue = useMemo(
    () => ({
      locale,
      setLocale,
      defaultLocale,
      isDefaultLocale: locale === defaultLocale,
      locales
    }),
    [locale, defaultLocale, locales]
  );

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
};

LocalizationWrapper.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string),
  defaultLocale: PropTypes.string,
  initialLocale: PropTypes.string
};

// Export context
LocalizationWrapper.context = TranslationContext;

export default LocalizationWrapper;
