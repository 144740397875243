import {
  Box,
  Checkbox as ChakraCheckbox,
  Stack,
  Text
} from '@chakra-ui/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import List from '../components/elements/List';
import Checkbox from '../components/modules/Checkbox';

const DEFAULT_ID_KEY = 'id';
const DEFAULT_LABEL_KEY = 'label';

const DEFAULT_GET_ITEM_VALUE_FACTORY = key => item => item[key];
const DEFAULT_COMPARE_FACTORY = getItemValue => (selectedItem, optionItem) => selectedItem === getItemValue(optionItem);

class ListSelector extends Component {
  static propTypes = {
    options: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    onChange: PropTypes.func,
    idKey: PropTypes.string,
    labelKey: PropTypes.string,
    descriptionKey: PropTypes.string,
    getItemValue: PropTypes.func,
    compare: PropTypes.func,
    chakra: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isChecked = this.isChecked.bind(this);

    const { idKey = DEFAULT_ID_KEY, getItemValue, compare } = props;

    this.getItemValue = getItemValue || DEFAULT_GET_ITEM_VALUE_FACTORY(idKey);
    this.compare = compare || DEFAULT_COMPARE_FACTORY(this.getItemValue);
  }

  handleClick(event, { option }) {
    const { value, onChange } = this.props;
    const fieldValue = value || [];
    const isChecked = this.isChecked(option);

    onChange(isChecked
      ? _.filter(fieldValue, item => !this.compare(item, option))
      : fieldValue.concat(this.getItemValue(option)));
  }

  handleChange(event) {
    const { options, idKey = DEFAULT_ID_KEY } = this.props;
    const option = options.find(op => op[idKey] === event.target.value);
    return this.handleClick(event, { option });
  }

  isChecked(option) {
    const { value = [] } = this.props;
    return _.some(value, selectedItem => this.compare(selectedItem, option));
  }

  render() {
    const {
      options, idKey = DEFAULT_ID_KEY, labelKey = DEFAULT_LABEL_KEY, descriptionKey, chakra
    } = this.props;

    if (chakra) {
      return (
        <Stack direction='column' spacing={5}>
          {options?.map((op) => {
            const isChecked = this.isChecked(op);
            const description = _.get(op, descriptionKey);
            const key = op[idKey];
            return (
              <ChakraCheckbox value={key} alignItems='top' isChecked={isChecked} key={key} onChange={this.handleChange}>
                <Box mt='-1' mb='1'>
                  <Text fontWeight='bold'>{op[labelKey]}</Text>
                </Box>
                {description && <Box>{description}</Box>}
              </ChakraCheckbox>
            );
          })}
        </Stack>
      );
    }

    return (
      <List selection relaxed>
        {options && options.map((op) => {
          const isChecked = this.isChecked(op);
          const description = _.get(op, descriptionKey);
          const key = op[idKey];
          return (
            <List.Item key={key} onClick={this.handleClick} option={op} active={isChecked}>
              <List.Content floated='right'>
                <Checkbox toggle checked={isChecked} />
              </List.Content>
              <List.Content>
                <List.Header>{op[labelKey]}</List.Header>
                {description && <List.Description>{description}</List.Description>}
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    );
  }
}

export default ListSelector;
