/* eslint max-len: 0, arrow-body-style: 0 */
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
  useState
} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  transport,
  useQuerystringParam
} from 'shared/src/hooks/useQuerystringParam';

import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Button from 'web-react-ui/src/components/elements/Button';
import Divider from 'web-react-ui/src/components/elements/Divider';
import Label from 'web-react-ui/src/components/elements/Label';
import List from 'web-react-ui/src/components/elements/List';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import './PropertySettingsGeneral.css';
import propertyModule from '../../../modules/property';
import client from '../../../services/client';

const PlanItem = ({ plan, property, actions }) => {
  return (
    <List.Item key={plan.id}>
      <List.Content className='p1 flex aic'>
        <Link className='fg1 flex aic' to={`/${property.id}/property-settings/business-management/plans/${plan.id}`}>
          <List.Header>
            {plan.name}
          </List.Header>
          {plan.discoverable || <Label className='mla'>HIDDEN</Label>}
        </Link>
        <ActionMenu className='ml1' actions={actions} context={plan} suppressPrimary />
      </List.Content>
    </List.Item>
  );
};

PlanItem.propTypes = {
  plan: PropTypes.object,
  property: PropTypes.object,
  actions: PropTypes.array
};

function PropertySettingsManagement({ property }) {
  const [enabled, setEnabled] = useQuerystringParam(
    'enabled',
    true,
    { transport: transport.boolean, squash: true }
  );

  const [refetchId, setRefetchId] = useState(0);

  const [isPromoting, setIsPromoting] = useState(false);

  const [promoteError, setPromoteError] = useState(null);

  const listPlans = useCallback(
    ({ showEnabled }) => {
      return client
        .properties.for(property.id)
        .plans.search({ enabled: showEnabled, discoverable: null });
    },
    [property]
  );

  const promote = useCallback(
    (plan) => {
      setIsPromoting(true);
      setPromoteError(null);
      client
        .properties.for(property.id)
        .plans.for(plan.id)
        .prioritize()
        .then(
          () => {
            setRefetchId(refetchId + 1);
          },
          setPromoteError
        )
        .then(() => setIsPromoting(false));
    },
    [client, property, setIsPromoting]
  );

  const planActions = useMemo(
    () => [{
      label: 'Make Default',
      action: (_, item) => promote(item.context),
      hidden: !enabled
    }],
    [promote, enabled]
  );

  return (
    <View>
      <View.Section className='mt3'>
        <div className='flex aic'>
          <h3 className="mb0">Plan Management</h3>
          <Button.Group className='flex aic mla'>
            <Button active={enabled} onClick={() => setEnabled(true)}>Enabled</Button>
            <Button active={!enabled} onClick={() => setEnabled(false)}>Disabled</Button>
          </Button.Group>
        </div>
        <Divider className='mt0' />
        <PagedList fetchList={listPlans} params={{ showEnabled: enabled, refetchId }}>
          {({
            items, loading, isEmpty, loaded
          }) => (
            <WaitFor waitFor={!loading && !isPromoting} wrapContents={loaded}>
              <ErrorMessage error={promoteError} />
              {isEmpty && <div className='pv1'><em>No plans found</em></div>}
              <List divided selection>
                {items.map(plan => (
                  <PlanItem
                    key={plan.id}
                    plan={plan}
                    property={property}
                    actions={planActions}
                  />
                ))}
              </List>
            </WaitFor>
          )}
        </PagedList>
        <Button
          as={Link}
          basic
          className='text'
          floated='right'
          to={`/${property.id}/property-settings/business-management/plans/add`}
        >
          + Add Feature Plan
        </Button>
      </View.Section>
    </View>
  );
}

PropertySettingsManagement.propTypes = {
  property: PropTypes.object
};

export { PropertySettingsManagement as Presenter };

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state)
});

export default connect(mapState)(PropertySettingsManagement);
