import {
  Select,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { NavGroup } from '../../../chakra/shell/NavGroup';
import { NavItem } from '../../../chakra/shell/NavItem';

const Sidebar = ({ index, onChange }: { index: number, onChange: (idx: number) => void }) => {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  if (isSmallScreen) {
    return (
      <Select value={index} onChange={e => onChange(parseInt(e.target.value, 10))}>
        <option value={0}>My Images</option>
        <option value={2}>Stock Photos</option>
      </Select>
    );
  }
  return (
    <VStack>
      <NavGroup>
        <NavItem whiteSpace="nowrap" active={index === 0} onClick={() => onChange(0)} label="My Images" />
        <NavItem whiteSpace="nowrap" active={index === 2} onClick={() => onChange(2)} label="Stock Photos" />
      </NavGroup>
    </VStack>
  );
};

export default Sidebar;
