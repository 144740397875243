/* eslint-disable quote-props */

/*
  This file is to account for any of the labels that were set before we had Localization.
  These labels used to be just plain 'english' text ie. 'Use Now'
  But now they get set as a replacement token that gets localized on the server like {{book_online}}.
  So for example, we need 'Enter Now', so it automatically updates to {{enter_now}}
  when those 'pre-label' offers get updated.
*/

export default {
  'Use Now': '{{use_now}}',
  'Redeem': '{{redeem}}',
  'Call To Book': '{{call_to_book}}',
  'Call To Order': '{{call_to_order}}',
  'Call For Info': '{{call_for_info}}',
  'Buy Online': '{{buy_online}}',
  'Book Online': '{{book_online}}',
  'Order Online': '{{order_online}}',
  'Learn More': '{{learn_more}}',
  'Enter Now': '{{enter_now}}',
  'Use Reward': '{{use_reward}}',
  'Claim Reward': '{{claim_reward}}',
  'Redeem Now': '{{redeem_now}}',
  'Earn Punch': '{{earn_punch}}',
  'Check In': '{{check_in}}',
  'Buy Now': '{{buy_now}}'
};
