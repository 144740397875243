import { CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonGroup,
  Wrap
} from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

const defaultPredicate = (a, b) => a === b;

const RadioButtonGroup = ({
  options = [],
  value,
  setValue,
  disabled,
  display = 'block',
  predicate = defaultPredicate
}) => {
  return (
    <Wrap display={display} spacing={3}>
      {options.map((o) => {
        const isSelected = predicate(value, o.value);
        return (
          <Button
            key={o.value}
            bg='white'
            borderColor='gray.200'
            borderWidth='1px'
            _active={{
              bg: 'blue.50',
              borderColor: 'blue.500'
            }}
            _hover={{
              bg: 'blue.50'
            }}
            onClick={() => setValue(o.value)}
            isActive={isSelected}
            disabled={disabled || o.disabled}
            leftIcon={isSelected && <CheckIcon />}
          >
            {o.label}
          </Button>
        );
      })}
    </Wrap>
  );
};

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  predicate: PropTypes.func,
};

export default RadioButtonGroup;
