import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, FormLabel, Heading, Icon, Text, Tooltip, VStack } from '@chakra-ui/react';
import { useField, useForm } from 'react-final-form';
import { FaInfoCircle } from 'react-icons/fa';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import AddSectionButton from '../../../chakra/forms/RevealField/AddSectionButton';
import { NumberField } from '../../../reactFinalForm/fields';
import SimpleSelectField from '../../../reactFinalForm/fields/SimpleSelectField';
import RemoveSectionButton from '../../../chakra/forms/RevealField/RemoveSectionButton';
import RevealField from '../../../chakra/forms/RevealField/RevealField';
import { compose, minValue, required } from '../../../reactFinalForm/validators';

const formatUserLimitResetValue = v => (v || 'lifetime');
const parseUserLimitResetValue = str => (str === 'lifetime' ? null : str);

const RedemptionLimits = ({ name }) => {
  const { strings } = useI18Next();
  const { input: globalLimitInput } = useField(`${name}.globalLimit`);
  const { input: useGlobalLimitInput } = useField('useGlobalLimit');
  const form = useForm();

  useEffect(() => {
    if (globalLimitInput?.value && !useGlobalLimitInput?.value) {
      useGlobalLimitInput.onChange(true);
    }
  }, []);

  const resetWindows = [
    { text: strings('ui.component.offerRedeemLimits.resetWindows.hour'), value: "hour" },
    { text: strings('ui.component.offerRedeemLimits.resetWindows.day'), value: "day" },
    { text: strings('ui.component.offerRedeemLimits.resetWindows.week'), value: "week" },
    { text: strings('ui.component.offerRedeemLimits.resetWindows.month'), value: "month" },
    { text: strings('ui.component.offerRedeemLimits.resetWindows.lifetime'), value: 'lifetime' }
  ];

  return (
    <Box w='100%'>
      <Flex align='center' mb={5}>
        <Heading size="md">{strings('dashboard.component.editOfferForm.field.label.limits')}</Heading>
        <Tooltip
          label={strings('dashboard.component.editOfferForm.tooltip.setPerMemberLimits')}
          placement='top'
          hasArrow
        >
          <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
        </Tooltip>
      </Flex>

      <VStack w='100%' spacing={5}>
        <Flex w='100%' align='center' justify='space-between'>
          <Text>
            {strings('ui.component.offerRedeemLimits.offerValidation.field.label.limit')}
          </Text>
          <Box w='33%'>
            <NumberField
              name={`${name}.userLimit`}
              validate={compose(required, minValue(1))}
              chakra
            />
          </Box>
          <Text>
            {strings('ui.component.offerRedeemLimits.offerValidation.field.label.perPersonPer')}
          </Text>
          <Box w='33%'>
            <SimpleSelectField
              name={`${name}.userLimitResetGranularity`}
              options={resetWindows}
              format={formatUserLimitResetValue}
              parse={parseUserLimitResetValue}
              chakra
            />
          </Box>
        </Flex>
        <Box w='100%'>
          <AddSectionButton name='useGlobalLimitInput'>
            {strings('dashboard.component.editOfferForm.field.label.setMaximumQuantity')}
          </AddSectionButton>
          <RevealField name='useGlobalLimitInput'>
            <FormLabel>
              <Flex justify='space-between' align='center'>
                <Flex align='center'>
                  {strings('dashboard.component.editOfferForm.field.label.maximumQuantity')}
                  <Tooltip
                    label={strings('dashboard.component.editOfferForm.field.label.maximumQuantityTooltip')}
                    placement='top'
                    hasArrow
                  >
                    <span><Icon as={FaInfoCircle} color='gray.200' ml={5} /></span>
                  </Tooltip>
                </Flex>
                <RemoveSectionButton
                  name='useGlobalLimitInput'
                  onRemove={() => form.change(`${name}.globalLimit`, undefined)}
                />
              </Flex>
            </FormLabel>
            <NumberField name={`${name}.globalLimit`} wrapLabel chakra />
          </RevealField>
        </Box>
      </VStack>
    </Box>
  );
};

RedemptionLimits.propTypes = {
  name: PropTypes.string,
};

export default RedemptionLimits;
