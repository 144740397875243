import {
  Center,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';
import { BORDER_RADIUS } from '../constants';
import { useImageWellContext } from '../ImageWellContext';

const ImageUploadPlaceholder2 = ({ ephemeralImageUrl }: {
  ephemeralImageUrl?: string,
}) => {
  const imageWellContext = useImageWellContext();
  if (!ephemeralImageUrl) return null;

  return (
    <Center
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bgImage={ephemeralImageUrl}
      bgSize={imageWellContext.cover ? 'cover' : 'contain'}
      bgRepeat="no-repeat"
      bgPosition="center"
      borderRadius={BORDER_RADIUS}
    >
      <Center height="100%" width="100%" bgColor="#FFFFFF55">
        <Spinner />
      </Center>
    </Center>
  );
};

export default ImageUploadPlaceholder2;
