import PropTypes from 'prop-types';
import React, {
  useContext,
  useMemo
} from 'react';
import SimpleForm from '../../SimpleForm';
import LocalizationWrapper from './LocalizationWrapper';

const composeFormValidators = (...validators) => (values) => {
  let result = null;
  validators.every((fn) => {
    if (!fn) return true; // continue to next validator
    result = fn(values);
    return !result;
  });
  return result;
};

const LocalizableSimpleForm = ({ validateLocale, validate, ...rest }) => {
  const localizationContext = useContext(LocalizationWrapper.context);

  const composedValidate = useMemo(
    () => composeFormValidators(validate, validateLocale && validateLocale(localizationContext)),
    [validate, validateLocale, localizationContext]
  );

  return (
    <SimpleForm validate={composedValidate} {...rest}/>
  );
};

LocalizableSimpleForm.propTypes = {
  validateLocale: PropTypes.func,
  validate: PropTypes.func
};

export default LocalizableSimpleForm;
