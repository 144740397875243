/**
 * Cache entries are of form
 * promise: any -- the value of this entry
 * updatedAt: integer -- timestamp when the value was set
 * invalid: boolean -- if the cached value has been invalidated
 */

const normalizeKey = (key) => {
  if (typeof key === 'string') return key;
  return JSON.stringify(key);
};

class RequestCache {
  constructor() {
    this.cache = {};
  }

  /**
   * Set cache item
   * @param key: (string | Object) - unique key for request
   * @param promise
   */
  setItem(key, promise) {
    const cacheEntry = {
      promise,
      invalid: false,
      updatedAt: null,
      pending: true
    };

    this.cache[normalizeKey(key)] = cacheEntry;

    promise.then(
      (res) => {
        // Store date promise is actually resolved
        cacheEntry.updatedAt = Date.now();
        cacheEntry.pending = false;
        return res;
      },
      (rej) => {
        // Don't cache rejected promises
        cacheEntry.invalid = true;
        cacheEntry.pending = false;
        return Promise.reject(rej);
      }
    );
  }

  /**
   * Get cache entry
   * @param key: (string | Object) - unique key for request
   * @param ttl: number - maximum age for cache entry to be considered fresh
   * @returns { value: Promise } | null
   */
  getItem(key, { ttl = Infinity } = {}) {
    const cacheEntry = this.cache[normalizeKey(key)];
    if (!cacheEntry) return null;
    if (cacheEntry.invalid) return null;

    if (cacheEntry.pending) return cacheEntry;

    const now = Date.now();
    if (ttl < (now - cacheEntry.updatedAt)) return null;

    return cacheEntry;
  }
}

export default RequestCache;
