import PropTypes from 'prop-types';
import React from 'react';
import SuiImage from '../elements/Image';

const previewFrameStyle = {
  position: 'relative',
  width: '100%',
  border: '1px solid #eeeeee'
};

function RawImagePreview({ dataUrl, height, backgroundColor = 'transparent' }) {
  return (
    <div style={Object.assign({ height, backgroundColor }, previewFrameStyle)}>
      <SuiImage src={dataUrl} centered rounded style={{ maxHeight: '100%' }} />
    </div>
  );
}

RawImagePreview.propTypes = {
  dataUrl: PropTypes.string,
  height: PropTypes.number,
  backgroundColor: PropTypes.string
};

export default RawImagePreview;
