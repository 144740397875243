import PropTypes from 'prop-types';
import React, { Component } from 'react';
import iPhoneImage from '../../assets/iphone-8.png';
import PreviewImage from '../image/PreviewImage';

class CollectionPreview extends Component {
  static propTypes = {
    collectionName: PropTypes.string,
    image: PropTypes.string
  };

  render() {
    const { collectionName, image } = this.props;

    return (
      <div style={{ position: 'relative', maxWidth: '100%' }}>
        <img style={{ maxWidth: '100%' }} src={iPhoneImage} alt='iPhoneImage'/>
        <div
          style={{
            position: 'absolute',
            top: '17%',
            left: '24px',
            right: '18px',
            maxWidth: '336px'
          }}
        >
          <PreviewImage imageId={image}/>
          <div style={{ position: 'absolute', top: '165px', left: '20px' }}>
            <h1 style={{ color: 'white' }}>{collectionName}</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default CollectionPreview;
