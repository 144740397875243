import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { useField } from 'react-final-form';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const noop = () => {};

const RemoveSectionButton = ({ name, onRemove = noop, ...rest }) => {
  const { strings } = useI18Next();
  const { input } = useField(name);
  if (!input.value) return null;
  return <Button
    variant='ghost'
    onClick={() => {
      onRemove();
      input.onChange(false);
    }}
    {...rest}
  >
    {strings('ui.component.userItem.remove')}
  </Button>;
};

RemoveSectionButton.propTypes = {
  name: PropTypes.string
};

export default RemoveSectionButton;
