import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

const getRedeemAction = (offerFlowVars, strings) => {
  switch (offerFlowVars.action) {
    case 'present':
      return strings('ui.component.offerRedeemLimitsInfo.redeemAction.person');
    case 'website':
      return strings('ui.component.offerRedeemLimitsInfo.redeemAction.website');
    case 'call':
      return strings('ui.component.offerRedeemLimitsInfo.redeemAction.call');
    default:
      return null;
  }
};

const OfferRedeemLimitsInfo = ({ offerFlowVars }) => {
  const { strings } = useI18Next();
  const redeemAction = getRedeemAction(offerFlowVars, strings);
  const userLimitReset = offerFlowVars.userLimitResetGranularity;

  return (
    <React.Fragment>
      <div>{redeemAction}</div>
      {!!offerFlowVars.isLimited && (
        <div>
          {offerFlowVars.userLimit ? (
            <div>
              {strings('ui.component.offerRedeemLimitsInfo.limit')}&nbsp;{offerFlowVars.userLimit}&nbsp;
              {strings('ui.component.offerRedeemLimitsInfo.perPersonPer')}&nbsp;
              {userLimitReset || strings('ui.component.offerRedeemLimitsInfo.lifetime')}.
            </div>
          ) : (
            null
          )}
          {offerFlowVars.globalLimit ? (
            <div>{offerFlowVars.globalLimit} {strings('ui.component.offerRedeemLimitsInfo.totalAvailable')}</div>
          ) : (
            null
          )}
        </div>
      )}
      {offerFlowVars.punches ? (
        <div>
          <div>
            {strings('ui.component.offerRedeemLimitsInfo.numOfPunches')}&nbsp;
            {offerFlowVars.punches}
          </div>
          {!!offerFlowVars.punchUnfrozenAfter && (
            <div>
              {strings('ui.component.offerRedeemLimitsInfo.limitOncePer')}&nbsp;
              {offerFlowVars.punchUnfrozenAfter}&nbsp;
              {strings('ui.component.offerRedeemLimitsInfo.hours')}
            </div>
          )}
        </div>
      ) : (
        null
      )}
    </React.Fragment>
  );
};

OfferRedeemLimitsInfo.propTypes = {
  offerFlowVars: PropTypes.object
};

export default OfferRedeemLimitsInfo;
