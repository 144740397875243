import React, { useState } from 'react';
import {
  Box, Button, Flex, Heading, Modal, ModalContent,
  ModalOverlay, Stack, StackDivider, Text
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';

import { TextField } from '../../reactFinalForm/fields';
import SimpleForm from '../../reactFinalForm/SimpleForm';
import CheckboxField from '../../reactFinalForm/fields/CheckboxField';
import { Role } from '../../types/User.interface';
import { required } from '../../reactFinalForm/validators';
import ErrorMessage from '../../components/entities/ErrorMessage';
import Location from '../../types/Location.interface';
import LocationRolesField from './LocationRolesField';
import RoleTypeSelector from './RoleTypeSelector';

const AddUserView = ({
  isOpen,
  onClose,
  roleSectionHeading,
  primaryRoles,
  secondaryRoles,
  roleTypes,
  locations,
  type,
  onSubmit,
  isLoading,
  error,
}: {
  isOpen: boolean,
  onClose: () => void,
  roleSectionHeading: string,
  primaryRoles?: Role[],
  secondaryRoles?: Role[],
  roleTypes?: string[],
  locations?: Location[],
  type: string,
  onSubmit: (values: Record<string, any>) => void,
  isLoading: boolean,
  error?: string,
}): JSX.Element => {
  const [activeTab, setActiveTab] = useState(0);
  const subheading = roleTypes?.[activeTab];

  const locationsByRegion = locations?.reduce((acc: Record<string, any>, item: Location) => {
    if (!acc[item.address.country]) {
      acc[item.address.country] = {};
    }
    if (!acc[item.address.country][item.address.state]) {
      acc[item.address.country][item.address.state] = [];
    }
    acc[item.address.country][item.address.state].push(item);
    return acc;
  }, {});

  return (
    <Modal size='full' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box p={10}>
          <Flex>
            <Button colorScheme='blue' variant='link' onClick={onClose} leftIcon={<ChevronLeftIcon />}>Cancel</Button>
          </Flex>

          <SimpleForm onSubmit={onSubmit}>
            <Flex align='center' justify='space-between' my={10}>
              <Heading>Add User</Heading>
              <Button colorScheme='blue' type='submit' isLoading={isLoading}>Save</Button>
            </Flex>

            {error && (
              <ErrorMessage error={error} />
            )}

            <Heading pb={3} my={5} fontSize='2xl' borderBottom='1px' borderColor='gray.300'>Profile</Heading>
            <Stack direction='column' spacing={5}>
              <TextField label='Name' name='name' wrapLabel={true} validate={required} parse={str => str.trim()} chakra />
              <TextField
                label='Email'
                name='email'
                wrapLabel={true}
                validate={required}
                parse={str => str.trim()} chakra
              />
            </Stack>
            <Heading
              pb={3}
              mt={10}
              mb={3}
              fontSize='2xl'
              style={{ textTransform: 'capitalize' }}
              borderBottom='1px'
              borderColor='gray.300'
            >
              {roleSectionHeading}
            </Heading>

            {secondaryRoles && roleTypes && (
              <RoleTypeSelector
                label='Select'
                roleTypes={roleTypes}
                onClick={setActiveTab}
                activeTab={activeTab}
              />
            )}

            <Box p={5} borderRadius={8} border='1px' borderColor='gray.200'>
              <Heading fontSize='xl' mb={5}>{subheading}</Heading>
              {activeTab === 0 && primaryRoles?.map(role => (
                <Stack direction='column' spacing={5} key={role.id}>
                  <CheckboxField name='roles' value={role.id}>
                    <Box alignSelf='flex-start'>
                      <Text fontSize='lg' fontWeight={500}>{role.name}</Text>
                      <Text>{role.description}</Text>
                    </Box>
                  </CheckboxField>
                </Stack>
              ))}
              {activeTab === 1 && secondaryRoles && locationsByRegion && (
                <Stack direction='column' divider={<StackDivider />} spacing={5}>
                  {Object.entries(locationsByRegion).map(
                    ([country, locationsByState]: [country: string, locationsByState: Record<string, any>]) => (
                      <Box key={country}>
                        <Heading fontSize='3xl' mb={3}>{country}</Heading>
                        <Stack direction='column' divider={<StackDivider />} spacing={5}>
                          {Object.entries(locationsByState).map(
                            ([state, locations]: [state: string, locations: Location[]]) => (
                              <Box key={state}>
                                <Heading fontSize='2xl' mb={3}>{state}</Heading>
                                <Stack spacing={5}>
                                  {locations.map((location: Location) => (
                                    <CheckboxField name={`locations.${location.id}`} key={location.id}>
                                      <LocationRolesField location={location} roles={secondaryRoles} />
                                    </CheckboxField>
                                  ))}
                                </Stack>
                              </Box>
                            ))}
                        </Stack>
                      </Box>
                    ))}
                </Stack>
              )}
            </Box>
          </SimpleForm>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default AddUserView;
