import Downshift from 'downshift';
import React from 'react';
import PropTypes from 'prop-types';

class MultiDownshift extends React.Component {
  static propTypes = {
    defaultSelectedItem: PropTypes.any,
    selectedItems: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    compare: PropTypes.func,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    render: PropTypes.func
  };

  static defaultProps = {
    compare: (selected, item) => selected === item
  };

  state = { selectedItems: [] };

  componentDidMount() {
    const { defaultSelectedItem } = this.props;
    if (defaultSelectedItem) this.setState({ selectedItems: defaultSelectedItem });
  }

  // FIXME: Gross hack to have 'controlled' input
  // Refactor this to depend entirely on this.props.value instead of this.state.selectedItems
  componentDidUpdate(prevProps) {
    if (prevProps.selectedItems !== this.props.selectedItems) {
      this.setState({ selectedItems: this.props.selectedItems });
    }
  }

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true
        };
      default:
        return changes;
    }
  };

  handleSelection = (selectedItem, downshift) => {
    const { compare } = this.props;

    const callOnChange = () => {
      const { onSelect, onChange } = this.props;
      const { selectedItems } = this.state;
      if (onSelect) {
        onSelect(selectedItems, this.getStateAndHelpers(downshift));
      }
      if (onChange) {
        onChange(selectedItems, this.getStateAndHelpers(downshift));
      }
    };

    if (this.state.selectedItems.some(item => compare(selectedItem, item))) {
      this.removeItem(selectedItem, callOnChange);
    } else {
      this.addSelectedItem(selectedItem, callOnChange);
    }
  };

  removeItem = (item, cb) => {
    const { compare } = this.props;
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: selectedItems.filter(i => !compare(i, item))
      }),
      cb
    );
  };

  addSelectedItem(item, cb) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [...selectedItems, item]
      }),
      cb
    );
  }

  getRemoveButtonProps = ({ onClick, item, ...props } = {}) => ({
    onClick: (e) => {
      // TODO: use something like downshift's composeEventHandlers utility instead
      if (onClick) onClick(e);
      e.stopPropagation();
      this.handleSelection(item);
    },
    ...props
  });

  getStateAndHelpers(downshift) {
    const { selectedItems } = this.state;
    const { getRemoveButtonProps, removeItem } = this;
    return {
      getRemoveButtonProps,
      removeItem,
      selectedItems,
      ...downshift
    };
  }

  render() {
    const { render, children = render, ...props } = this.props;
    // TODO: compose together props (rather than overwriting them) like downshift does
    return (
      <Downshift
        {...props}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
      >
        {downshift => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

export default MultiDownshift;
