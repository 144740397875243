import PropTypes from 'prop-types';
import React, { Component } from 'react';

import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import BigModal from '../../../BigModal/BigModal';
import Input from '../../elements/Input';
import ImageLibrary from './ImageLibrary';

class ImageLibraryModal extends Component {
  static propTypes = {
    onSetView: PropTypes.func,
    open: PropTypes.bool,
    onSetOpen: PropTypes.func,
    property: PropTypes.string,
    business: PropTypes.string,
    location: PropTypes.string,
    onChange: PropTypes.func,
    fileType: PropTypes.string
  };

  state = {
    query: '',
    sharedImageScope: false
  };

  handleImageScope(event) {
    this.setState({ sharedImageScope: event.target.value === 'true' });
  }

  handleQuery(_, { value }) {
    this.setState({ query: value });
  }

  render() {
    const {
      open, property, business, location,
      onSetOpen, onSetView, onChange, fileType
    } = this.props;
    const { query, sharedImageScope } = this.state;

    const hasMultipleScopes = !!business;

    return (
      <BigModal open={open}>
        <BigModal.Contents>
          <BigModal.Header className="p1">
            <div className="flex aic">
              {hasMultipleScopes && (
                <div className="ui form mr1">
                  <I18NextContext.Consumer>
                    {({ strings }) => (
                      <select value={this.state.sharedImageScope} onChange={this.handleImageScope.bind(this)}>
                        <option value="false">{strings('ui.component.imageLibraryModal.myImages')}</option>
                        <option value="true">{strings('ui.component.imageLibraryModal.sharedImages')}</option>
                      </select>
                    )}
                  </I18NextContext.Consumer>
                </div>
              )}

              <I18NextContext.Consumer>
                {({ strings }) => (
                  <Input
                    onChange={this.handleQuery.bind(this)}
                    placeholder={strings('ui.component.imageLibraryModal.searchPlaceholder')}
                    className="mr1" />
                )}
              </I18NextContext.Consumer>

              <BigModal.CloseButton style={{ marginLeft: 'auto' }} onClick={() => onSetOpen(false)} />
            </div>
          </BigModal.Header>
          <BigModal.Body>
            <ImageLibrary
              onSelect={onChange} query={query} property={property}
              fileType={fileType}
              business={sharedImageScope ? undefined : business}
              location={sharedImageScope ? undefined : location}
              onUploadClick={() => onSetView('upload')}
            />
          </BigModal.Body>
        </BigModal.Contents>
      </BigModal>
    );
  }
}

export default ImageLibraryModal;
