// TODO: Separate out image loading by ID into reusable Component and compose it with ImageRenderer in here.
import React from 'react';
import PropTypes from 'prop-types';

import { injectClient } from 'shared/src/components/contexts/ClientContext';

import Request from '../../data/Request';
import WaitFor from '../../data/WaitFor';
import iconizeImageUrl from '../../utils/iconizeImageUrl';
import ImageRenderer from './ImageRenderer';

const fetchImageFactory = client => ({ imageId }) => {
  if (!imageId) return null;
  return client.images.for(imageId).details();
};

const ProfileImage = (props) => {
  const { imageId, client, ...rest } = props;
  return (
    <Request request={fetchImageFactory(client)} params={{ imageId }} cache="image">
      {({ data, loading }) => (
        <div style={{ display: 'inline-block', position: 'relative' }}>
          <WaitFor waitFor={!loading} inline wrapContents>
            <ImageRenderer imageUrl={iconizeImageUrl(data && data.url)} {...rest} />
          </WaitFor>
        </div>
      )}
    </Request>
  );
};

ProfileImage.propTypes = {
  imageId: PropTypes.string,
  client: PropTypes.object,
};

export default injectClient(ProfileImage);
