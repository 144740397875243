import React, {
  Component,
  Fragment
} from 'react';
import PropTypes from 'prop-types';

import { injectClient } from 'shared/src/components/contexts/ClientContext';

import Request from '../../data/Request';
import WaitFor from '../../data/WaitFor';
import SuiImage from '../elements/Image';

const previewFrameStyle = {
  position: 'relative',
  width: '100%',
  border: '1px solid #eeeeee'
};

class ImagePreview extends Component {
  constructor(props) {
    super(props);
    this.fetchPreview = this.fetchPreview.bind(this);
  }

  fetchPreview({ imageId }) {
    if (!imageId) return null;
    return this.props.client.images.for(imageId).details();
  }

  render() {
    const { imageId, height, backgroundColor = 'transparent' } = this.props;

    return (
      <Request request={this.fetchPreview} params={{ imageId }} cache="image">
        {({ data, loaded }) => (
          <div style={Object.assign({ height, backgroundColor }, previewFrameStyle)}>
            <WaitFor waitFor={loaded} inline>
              {data && (
                <Fragment>
                  <SuiImage src={data && data.url} centered rounded style={{ maxHeight: '100%' }} />
                </Fragment>
              )}
            </WaitFor>
          </div>
        )}
      </Request>
    );
  }
}

ImagePreview.propTypes = {
  imageId: PropTypes.string,
  client: PropTypes.object,
  height: PropTypes.number,
  backgroundColor: PropTypes.string
};

export default injectClient(ImagePreview);
