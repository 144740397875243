import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import SuiForm from '../../components/collections/Form';
import Input from '../../components/elements/Input';
import { compose } from '../validators';
import FieldError from './FieldError';
import FieldLabel from './FieldLabel';

const TIME_REGEX = /^$|^(\d?\d)(?::(\d\d))?\s*(am|AM|pm|PM)?$/;
const TIME_VALIDATION_MESSAGE = 'Time must be hh:mm (24 hour)';

const validateTime = (str) => {
  if (!str) return null;
  const match = TIME_REGEX.exec(str);
  if (!match) return TIME_VALIDATION_MESSAGE;

  const [, hh, mm] = match;
  if (parseInt(hh, 10) > 23) return TIME_VALIDATION_MESSAGE;
  if (parseInt(mm, 10) > 59) return TIME_VALIDATION_MESSAGE;
  return null;
};

const parseTime = (str) => {
  const [, hh, mm = '00', mer] = TIME_REGEX.exec(str) || [];
  if (mer) {
    let hour = parseInt(hh, 10);
    const minute = mm;
    const meridian = mer.toLowerCase();
    if (meridian === 'pm' && hour < 12) hour += 12;
    return `${hour}:${minute}`;
  }
  return str;
};

const TimeField = (props) => {
  const {
    name,
    validate,
    label,
    wrapLabel,
    type = 'time',
    disabled
  } = props;

  const composedValidate = validate ? compose(validateTime, validate) : validateTime;

  return (
    <Field name={name} validate={composedValidate} parse={parseTime} type={type}>
      {({ input }) => (
        <SuiForm.Field disabled={disabled}>
          <FieldLabel label={label} wrapLabel={wrapLabel} disabled={disabled}>
            <Input
              placeholder="hh:mm"
              {...input}
            />
            <FieldError name={name} />
          </FieldLabel>
        </SuiForm.Field>
      )}
    </Field>
  );
};
TimeField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validate: PropTypes.func,
  wrapLabel: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default TimeField;
