import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { injectClient } from 'shared/src/components/contexts/ClientContext';
import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import Request from '../../data/Request';
import shallowEqual from '../../utils/shallowEqual';
import Button from '../elements/Button';

const List = ({ list, children }) => {
  const items = (list && list.items) || [];
  const isEmpty = !items.length;
  return children({ items, isEmpty });
};

List.propTypes = {
  list: PropTypes.shape({
    items: PropTypes.array,
    page: PropTypes.object
  }),
  children: PropTypes.func
};

const Pager = ({
  list,
  onNext,
  onPrev,
  strings
}) => {
  const nextPage = _.get(list, 'page.next');
  const prevPage = _.get(list, 'page.prev');

  if (!nextPage && !prevPage) return null;

  return (
    <div>
      <Button type="button" disabled={!prevPage} onClick={() => onPrev(prevPage)}>
        {strings('ui.label.prev')}
      </Button>
      <Button type="button" disabled={!nextPage} onClick={() => onNext(nextPage)}>
        {strings('ui.label.next')}
      </Button>
    </div>
  );
};

Pager.propTypes = {
  list: PropTypes.shape({
    items: PropTypes.array,
    page: PropTypes.object
  }),
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  strings: PropTypes.func.isRequired
};

class PagedList extends Component {
  static propTypes = {
    fetchList: PropTypes.func,
    params: PropTypes.any,
    client: PropTypes.object
  };

  state = {
    request: null
  };

  componentDidMount() {
    const { fetchList } = this.props;
    this.setState(() => ({
      request: fetchList
    }));
  }

  componentDidUpdate(prevProps) {
    const { params, fetchList } = this.props;
    if (!shallowEqual(prevProps.params, params)) {
      this.setState(() => ({
        request: fetchList
      }));
    }
  }

  next(list) {
    this.setState({
      request: () => this.props.client.pagination.next(list)
    });
  }

  prev(list) {
    this.setState({
      request: () => this.props.client.pagination.prev(list)
    });
  }

  render() {
    const { children, params } = this.props;
    const { request } = this.state;

    return (
      <Request request={request} params={params} watchRequest>
        {({
          data, loading, loaded, error
        }) => (
          <React.Fragment>
            <List list={data}>
              {({ items, isEmpty }) => children({
                items,
                isEmpty,
                data,
                loading,
                loaded,
                error,
                // compat with usePagedList
                // TODO: Replace the guts with `usePagedList`
                settled: !loading && loaded,
                empty: loaded && !items?.length
              })}
            </List>
            <div className="mt2">
              <I18NextContext.Consumer>
                {({ strings }) => (
                  <Pager
                    list={data}
                    onNext={this.next.bind(this, data)}
                    onPrev={this.prev.bind(this, data)}
                    strings={strings}
                  />
                )}
              </I18NextContext.Consumer>
            </div>
          </React.Fragment>
        )}
      </Request>
    );
  }
}

const PagedListWithClient = injectClient(PagedList);
PagedListWithClient.List = List;
PagedListWithClient.Pager = Pager;
export default PagedListWithClient;
