/* eslint-disable arrow-body-style, no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Table from '../collections/Table';

import './PinTable.css';

const pinsType = PropTypes.arrayOf(PropTypes.shape({
  pin: PropTypes.string,
  skips: PropTypes.number,
  weekday: PropTypes.string
}));

const PinCell = ({ ...props }) => <Table.Cell textAlign='center' className='c-pin-table__pin' {...props}/>;

const MultiDowPinTable = ({ pins }) => {
  const { strings } = useI18Next();

  const rows = _.chain(pins)
    .groupBy(pin => pin.skips || 0)
    .map(groupPins => _.groupBy(groupPins, 'weekday'))
    .toPairs()
    .value();
  return (
    <Table basic="very" celled striped columns='8' fixed unstackable>
      <Table.Header className='c-pin-table__header'>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.sunday')) || 'Sunday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.monday')) || 'Monday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.tuesday')) || 'Tuesday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.wednesday')) || 'Wednesday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.thursday')) || 'Thursday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.friday')) || 'Friday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.saturday')) || 'Saturday'}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body className='c-table__body--header-col'>
        {rows.map(([skips, days]) => (
          <Table.Row key={skips}>
            <Table.Cell className='c-pin-table__header-col'>
              {`${+skips + 1} ${(strings('ui.component.pinTable.punch')) || 'Punch'}`}
            </Table.Cell>
            <PinCell>{days.Sun && days.Sun[0].pin}</PinCell>
            <PinCell>{days.Mon && days.Mon[0].pin}</PinCell>
            <PinCell>{days.Tue && days.Tue[0].pin}</PinCell>
            <PinCell>{days.Wed && days.Wed[0].pin}</PinCell>
            <PinCell>{days.Thu && days.Thu[0].pin}</PinCell>
            <PinCell>{days.Fri && days.Fri[0].pin}</PinCell>
            <PinCell>{days.Sat && days.Sat[0].pin}</PinCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

MultiDowPinTable.propTypes = ({ pins: pinsType });


const MultiPinTable = ({ pins }) => {
  const { strings } = useI18Next();

  const punches = _.chain(pins).groupBy(pin => pin.skips || 0).toPairs().value();
  return (
    <Table basic="very" celled collapsing padded striped columns={2} unstackable>
      <Table.Body className='c-table__body--header-col'>
        {punches.map(([skips, groupPins]) => (
          <Table.Row key={skips}>
            <Table.Cell>
              {`${+skips + 1} ${(strings('ui.component.pinTable.punch')) || 'Punch'}`}
            </Table.Cell>
            <PinCell>{groupPins[0].pin}</PinCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

MultiPinTable.propTypes = ({ pins: pinsType });

const DowPinTable = ({ pins }) => {
  const { strings } = useI18Next();

  const days = _.chain(pins)
    .groupBy('weekday')
    .value();

  return (
    <Table basic="very" celled collapsing padded columns={7} unstackable>
      <Table.Header className='c-pin-table__header'>
        <Table.Row>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.sunday')) || 'Sunday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.monday')) || 'Monday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.tuesday')) || 'Tuesday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.wednesday')) || 'Wednesday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.thursday')) || 'Thursday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.friday')) || 'Friday'}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {(strings('ui.component.pinTable.saturday')) || 'Saturday'}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <PinCell>{days.Sun && days.Sun[0].pin}</PinCell>
          <PinCell>{days.Mon && days.Mon[0].pin}</PinCell>
          <PinCell>{days.Tue && days.Tue[0].pin}</PinCell>
          <PinCell>{days.Wed && days.Wed[0].pin}</PinCell>
          <PinCell>{days.Thu && days.Thu[0].pin}</PinCell>
          <PinCell>{days.Fri && days.Fri[0].pin}</PinCell>
          <PinCell>{days.Sat && days.Sat[0].pin}</PinCell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

DowPinTable.propTypes = ({ pins: pinsType });


const BasicPinTable = ({ pins }) => {
  return (
    <Table basic="very" celled collapsing padded striped>
      <Table.Body>
        <Table.Row><PinCell>{pins[0].pin}</PinCell></Table.Row>
      </Table.Body>
    </Table>
  );
};

BasicPinTable.propTypes = ({ pins: pinsType });


const PinTable = ({ pins }) => {
  if (!(pins && pins.length)) return null;

  const isMulti = pins.some(pin => pin.skips > 0);
  const isDOW = pins.some(pin => pin.weekday);

  return isMulti
    ? isDOW
      ? <MultiDowPinTable pins={pins}/>
      : <MultiPinTable pins={pins}/>
    : isDOW
      ? <DowPinTable pins={pins}/>
      : <BasicPinTable pins={pins}/>;
};

PinTable.propTypes = ({ pins: pinsType });


export default PinTable;
