/* eslint camelcase: 0, class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Message from 'web-react-ui/src/components/collections/Message';
import View from 'web-react-ui/src/components/layout/View';
import Metrics from 'web-react-ui/src/components/metrics/Metrics';
import MetricsLoyalty from 'web-react-ui/src/components/metrics/MetricsLoyalty';
import MetricsLoyaltyTotal from 'web-react-ui/src/components/metrics/MetricsLoyaltyTotal';
import MetricsTimeSeries from 'web-react-ui/src/components/metrics/MetricsTimeSeries';
import TimeRangeSelector from 'web-react-ui/src/components/metrics/TimeRangeSelector';

import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import businessModule from '../../modules/businesses';
import propertyModule from '../../modules/property';

import client from '../../services/client';

class BusinessOverview extends Component {
  static propTypes = {
    business: PropTypes.object,
    property: PropTypes.object
  };

  state = {
    days: 30
  };

  fetchStats(params) {
    const propertyId = this.props.property.id;
    const businessId = this.props.business.id;
    const statsRepo = client
      .properties.for(propertyId)
      .businesses.for(businessId)
      .stats;
    return Promise.all([
      statsRepo.getLastNDays(params),
      statsRepo.getLoyaltyLastNDays(params)
    ])
      .catch(error => this.setState({ error }));
  }

  handleSetRange(params) {
    this.setState({ days: params.days });
  }

  render() {
    return (
      <Request
        request={this.fetchStats.bind(this)}
        params={{ days: this.state.days }}
      >
        {({ data, loading, error }) => {
          const [stats, loyaltyStats] = data || [];
          return (
            <WaitFor waitFor={!loading} wrapContents>
              <View>
                <View.Section>
                  {error && <Message error>{error.message}</Message>}
                  {this.state.error && <Message error>{this.state.error.message}</Message>}
                  <div className='flex fww'>
                    <div style={{ width: '75%' }}/>
                    <div className='fg1 mt2 m1' style={{ minWidth: '200px' }}>
                      <TimeRangeSelector days={this.state.days} handleSetRange={this.handleSetRange.bind(this)}/>
                    </div>
                  </div>
                  <h2>Total</h2>
                  <Metrics data={stats}/>
                  <h2>Loyalty</h2>
                  <MetricsLoyaltyTotal propertyId={this.props.property.id} businessId={this.props.business.id}/>
                  <MetricsLoyalty data={loyaltyStats}/>
                  <MetricsTimeSeries data={stats}/>
                </View.Section>
              </View>
            </WaitFor>
          );
        }}
      </Request>
    );
  }
}

const mapState = state => ({
  property: propertyModule.selectors.property.getData(state),
  business: businessModule.selectors.business.getData(state)
});

export default connect(mapState)(BusinessOverview);
