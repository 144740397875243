import React, { useContext } from 'react';

interface ImageWellContextType {
  cover?: boolean;
  fileType?: string;
  propertyId?: string;
  businessId?: string;
  locationId?: string;
  aspectRatio?: number;
}

const ImageWellContext = React.createContext<ImageWellContextType>({});

export const useImageWellContext = () => useContext(ImageWellContext);

export default ImageWellContext;
