import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Image, Text } from '@chakra-ui/react';
import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import iPhoneImage from '../../assets/iphone-8.png';
import PreviewImage from '../image/PreviewImage';

import OFFER_OPTIONS from '../offers/offerOptions';

class Preview extends Component {
  static propTypes = {
    offer: PropTypes.object,
    image: PropTypes.string
  };

  render() {
    const { offer, image } = this.props;
    // eslint-disable-next-line max-len
    const buttonLabel = offer?.flow?.vars?.label; // ie. {{use_now}}, 'Live Your Best Life'...
    const usableSchedule = ((offer && offer.schedules) || []).find(schedule => schedule.type === 'usable');
    // eslint-disable-next-line max-len
    const syndicatedOfferEnd = ` until ${offer && offer.discoverableSchedules && offer.discoverableSchedules[0] && offer.discoverableSchedules[0].endDate}`;
    const promoCode = offer?.promoCode;
    const legal = offer?.legal;

    const flags = offer.flags;
    const isLimited = flags?.includes('limited');
    const isPunch = flags?.includes('punchcard');
    const isExclusive = flags?.includes('exclusive') && !isLimited;
    return (
      <I18NextContext.Consumer>
        {({ strings }) => (
          <div style={{ display: 'table-row' }}>
            <Image w='100%' h='auto' src={iPhoneImage} alt='' />
            <div
              style={{
                position: 'absolute',
                top: '17%',
                left: '24px',
                right: '18px',
                maxWidth: '336px'
              }}
            >
              <PreviewImage imageId={image} imageUrl={offer.heroImageUrl} />
              <div>
                {isLimited && (
                  <div style={{ position: 'absolute', top: '180px' }}>
                    <label
                      style={{
                        padding: '5px 10px',
                        backgroundColor: '#ff9200',
                        color: 'white'
                      }}>{strings('ui.component.offerPreview.limitedQty')}
                    </label>
                  </div>
                )}
                {isExclusive && (
                  <div style={{ position: 'absolute', top: '180px' }}>
                    <label
                      style={{
                        padding: '5px 10px',
                        backgroundColor: '#BA1AD7',
                        color: 'white'
                      }}>{strings('ui.component.offerPreview.exclusive')}
                    </label>
                  </div>
                )}
                {isPunch && (
                  <div style={{ position: 'absolute', top: '180px' }}>
                    <label
                      style={{
                        padding: '5px 10px',
                        backgroundColor: '#7DABFC',
                        color: 'white'
                      }}>{strings('ui.component.offerPreview.punchCard')}
                    </label>
                  </div>
                )}
                <div className='m1'>
                  <Heading fontSize='lg'>{offer && offer.headline}</Heading>
                  <Text fontSize='lg'>{offer && offer.subHeadline}</Text>
                  {offer.schedules && usableSchedule?.endDate && (
                    <div className='flex brdr--b mb1'>
                      <small className='mla text--muted'>
                        {strings('ui.component.offerPreview.until')} {usableSchedule.endDate}
                      </small>
                    </div>
                  )}
                  {!offer.schedules && (
                    <div className='flex brdr--b mb1'>
                      <small className='mla text--muted'>{syndicatedOfferEnd}</small>
                    </div>
                  )}
                  <p>{offer && offer.details}</p>
                  {legal && (
                    <div className='mt1'>
                      <h6 className='mb0'>{strings('ui.label.termsAndConditions', false)}</h6>
                      <p>{legal}</p>
                    </div>
                  )}
                  {promoCode && (
                    <div className='flex'>
                      <div className='flex mla mra mt1'>
                        <p className='mr1'>{strings('ui.component.offerPreview.useOfferCode')}</p>
                        <h6 className='mt0'>{promoCode}</h6>
                      </div>
                    </div>
                  )}
                  {buttonLabel && (
                    <div
                      className='fluid tac p1'
                      style={{
                        backgroundColor: '#206BEE',
                        color: '#FFF',
                        borderRadius: '3px',
                        textTransform: 'uppercase',
                        fontWeight: '600'
                      }}
                    >
                      {OFFER_OPTIONS[buttonLabel] ? strings(OFFER_OPTIONS[buttonLabel].text) : buttonLabel}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </I18NextContext.Consumer>
    );
  }
}
class OfferPreview extends Component {
  static propTypes = {
    offer: PropTypes.object,
    image: PropTypes.string
  };

  render() {
    const { offer, image } = this.props;

    return (
      <Box display='table' position='relative' maxW='100%'>
        <Preview offer={offer} image={image} />
      </Box>
    );
  }
}

export default OfferPreview;
