import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import I18NextContext from 'shared/src/components/contexts/I18NextContext';

import FileTypeInfo from './FileTypeInfo';
import ImagePreview from './ImagePreview';
import { getBase64StringFromBlob } from './imageUtils';
import ImageWellModal from './ImageWellModal';
import RawImagePreview from './RawImagePreview';

const wellStyle = {
  padding: '0.5em'
};

class ImageWell extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fileType: PropTypes.string,
    height: PropTypes.number,
    clearable: PropTypes.bool,
    enableCrop: PropTypes.bool,
    skipUpload: PropTypes.bool,
    property: PropTypes.string,
    business: PropTypes.string,
    location: PropTypes.string,
    dashboard: PropTypes.bool,
    backgroundColor: PropTypes.string,
    showImageGuidelines: PropTypes.bool,
    aspectRatio: PropTypes.number
  };

  static defaultProps = {
    enableCrop: true,
    height: 300
  };

  state = {
    open: false
  };

  setOpen(open) {
    this.setState({
      open,
      // HACK: Clear initialImage whenever we update the open state so it doesn't stick around after drag and drop
      initialImage: null
    });
  }

  handleChange(id) {
    const { onChange } = this.props;
    if (onChange) onChange(id);
    this.setOpen(false);
  }

  async handleDrop(event) {
    event.preventDefault();

    const file = event.dataTransfer.files[0];

    const base64Url = await getBase64StringFromBlob(file);

    this.setState({
      initialImage: base64Url,
      open: true
    });
  }

  render() {
    const {
      value,
      fileType,
      height,
      clearable,
      enableCrop,
      skipUpload,
      property,
      business,
      location,
      dashboard,
      backgroundColor,
      showImageGuidelines,
      aspectRatio
    } = this.props;
    const { open, initialImage } = this.state;
    return (
      <I18NextContext.Consumer>
        {({ strings }) => (
          <div>
            <div
              className="flex fdc aic"
              style={wellStyle}
              onDrop={this.handleDrop.bind(this)}
              onDragOver={e => e.preventDefault()}
            >

              <div
                className="flex fdc aic"
                style={{ position: 'relative', width: '100%' }}
                onClick={() => this.setOpen(true)}
              >
                {skipUpload
                  ? <RawImagePreview dataUrl={value} height={height} backgroundColor={backgroundColor}/>
                  : <ImagePreview imageId={value} height={height} backgroundColor={backgroundColor}/>
                }
                <FileTypeInfo fileType={fileType} over={!!(height || value)}/>
              </div>
              <div className="mv1">
                {clearable && (
                  <Button
                    basic
                    type="button"
                    color="red"
                    onClick={this.handleChange.bind(this)}
                  >
                    {strings('ui.component.imageWell.deleteImage')}
                  </Button>
                )}

                <Button
                  type="button"
                  onClick={() => this.setOpen(true)}
                >
                  {strings('ui.component.imageWell.chooseImage')}
                </Button>
              </div>
            </div>
            {open && (
              <ImageWellModal
                open={open}
                onChange={this.handleChange.bind(this)}
                onSetOpen={this.setOpen.bind(this)}
                fileType={fileType}
                clearable={clearable}
                enableCrop={enableCrop}
                skipUpload={skipUpload}
                property={property}
                business={business}
                location={location}
                dashboard={dashboard}
                initialImage={initialImage}
                showImageGuidelines={showImageGuidelines}
                aspectRatio={aspectRatio}
              />
            )}
          </div>
        )}
      </I18NextContext.Consumer>
    );
  }
}

export default ImageWell;
