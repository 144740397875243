import Offer from '../../../types/Offer.interface';

export const getOfferType = (offer: Offer) => {
  if (offer.flags?.includes('punchcard')) {
    return 'punchcard';
  }
  if (offer.flags?.includes('limited')) {
    return 'limited';
  }
  if (offer.flags?.includes('exclusive')) {
    return 'exclusive';
  }

  return null;
};

export default getOfferType;
