import PropTypes from 'prop-types';
import React from 'react';
import TimeSeries from './TimeSeries';

const MetricsTimeSeries = ({ data }) => (<TimeSeries data={data && data.timeSeries} height={400}/>);

MetricsTimeSeries.propTypes = {
  data: PropTypes.object
};

export default MetricsTimeSeries;
