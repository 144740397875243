/* eslint class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import BigModal from 'web-react-ui/src/BigModal/BigModal';

import Input from 'web-react-ui/src/components/elements/Input';
import List from 'web-react-ui/src/components/elements/List';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import View from 'web-react-ui/src/components/layout/View';
import Dropdown from 'web-react-ui/src/components/modules/Dropdown';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import client from '../../../services/client';
import SyndicatedOfferListItem from '../../offers/SyndicatedOfferListItem';

class OfferSyndicationReview extends Component {
  static propTypes = {
    redirectTo: PropTypes.string,
    propertyId: PropTypes.string
  };

  state = {
    redirect: false,
    approvedOffers: [],
    declinedOffers: [],
    isBusy: false
  };

  handleCancel() {
    this.setState({ redirect: this.props.redirectTo });
  }

  fetchOffers() {
    const { propertyId } = this.props;
    return client
      .properties.for(propertyId)
      .offers.list({ hasContract: true, state: ['PENDING'], status: ['live'] });
  }

  approveOffer(offerId) {
    const { propertyId } = this.props;
    const { approvedOffers } = this.state;
    this.setState({ isBusy: true });

    return client
      .properties.for(propertyId)
      .offers.for(offerId).syndicate()
      .then(() => this.setState({ approvedOffers: approvedOffers.concat(offerId), isBusy: false }));
  }

  declineOffer(offerId) {
    const { propertyId } = this.props;
    const { declinedOffers } = this.state;
    this.setState({ isBusy: true });

    return client
      .properties.for(propertyId)
      .offers.for(offerId).blacklist()
      .then(() => this.setState({ declinedOffers: declinedOffers.concat(offerId), isBusy: false }));
  }

  render() {
    const { propertyId } = this.props;
    const {
      redirect, approvedOffers, declinedOffers, isBusy
    } = this.state;
    const options1 = [
      {
        key: 'recent',
        value: 'recent',
        text: 'Most Recent'
      },
      {
        key: 'old',
        value: 'old',
        text: 'Oldest'
      }
    ];

    const options2 = [
      {
        key: 'all',
        value: 'all',
        text: 'All'
      },
      {
        key: 'food',
        value: 'food',
        text: 'Restaurant'
      },
      {
        key: 'spa',
        value: 'spa',
        text: 'Spa'
      },
      {
        key: 'clothes',
        value: 'clothes',
        text: 'Clothing Store'
      },
      {
        key: 'health',
        value: 'health',
        text: 'Health'
      },
      {
        key: 'fitness',
        value: 'fitness',
        text: 'Fitness'
      }
    ];

    if (redirect) return <Redirect to={redirect}/>;

    return (
      <BigModal open>
        <BigModal.Contents>
          <BigModal.Header className='p1'>
            <BigModal.CloseButton className='p1' floated='right' onClick={this.handleCancel.bind(this)}/>
          </BigModal.Header>
          <BigModal.Body>
            <View>
              <View.Section>
                <div>
                  <h1>Available Offers</h1>
                </div>
                <div className='flex mt3'>
                  <div className='fg3 mr1'>
                    <Input
                      fluid
                      placeholder='Search Offers'
                      type='search'
                      icon='search'
                    />
                  </div>
                  <div className='fg1 flex'>
                    <Dropdown
                      className='mr1'
                      placeholder='Sort By'
                      search
                      fluid
                      selection
                      options={options1}
                    />
                    <Dropdown
                      placeholder='Filter by Category'
                      search
                      fluid
                      selection
                      options={options2}
                    />
                  </div>
                </div>
              </View.Section>
              <View.Section>
                <PagedList fetchList={this.fetchOffers.bind(this)}>
                  {({
                    items, isEmpty, loading, error
                  }) => (
                    <WaitFor waitFor={!loading && !isBusy} wrapContents>
                      <List celled selection>
                        {isEmpty && (
                          <h1 className='text--muted'>
                            There are currently no offers from external applications that need approval.
                          </h1>
                        )}
                        {items
                          .filter(offer => !approvedOffers.includes(offer.id) && !declinedOffers.includes(offer.id))
                          .map(offer => (
                            <SyndicatedOfferListItem
                              offer={offer}
                              key={offer.id}
                              propertyId={propertyId}
                              isBusy={isBusy}
                              approveOffer={this.approveOffer.bind(this)}
                              declineOffer={this.declineOffer.bind(this)}
                              path={`${offer.id}`}
                            />
                          ))}
                        <ErrorMessage error={error}/>
                      </List>
                    </WaitFor>
                  )}
                </PagedList>
              </View.Section>
            </View>
          </BigModal.Body>
        </BigModal.Contents>
      </BigModal>
    );
  }
}

export default OfferSyndicationReview;
