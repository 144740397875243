import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'shared/src/locales/en.json';

// These are the Supported Platform Languages
// TODO: Move out of i18n -- this should JUST be for the Portal Localization
// NOT for defining platform-wide settings like this.
export const LANGUAGE_OPTIONS = [
  {
    key: 'english',
    text: 'English',
    value: 'en'
  },
  {
    key: 'french',
    text: 'French',
    value: 'fr'
  }
];

// We EXPLICITLY DO NOT support French as a UI language on the
// Customer Portal at this time.
const resources = {
  en: {
    translation: en
  }
};

i18next
  .use(initReactI18next) // pass the i18next instance to react-i18next.
  .use(LanguageDetector)
  .init({
    resources,
    detection: {
      // order and from where user language should be detected.
      order: ['localStorage', 'navigator'], // browser lng will be used before localStorage
      lookupLocalStorage: 'i18nextLngPortal',
      caches: ['localStorage'], // cache user language on
      excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
    },
    fallbackLng: 'en', // if user computer language is not on the list of available languages
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false
    }
  });

export function strings(key, options = {}) {
  return i18next.t(key, options);
}

export function lookupExists(key, options = {}) {
  return i18next.exists(key, options);
}

export default i18next;
