import {
  Flex,
  Heading,
  Td,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import Offer from '../../../types/Offer.interface';

const BusinessCell = ({ offer }: { offer: Offer }) => {
  return (
    <Flex flexDir="column" justify="flex-start">
      <Heading fontSize="lg">{offer.business.name}</Heading>
      <Text fontSize="lg" mt={2}>
        {offer.locations.length === 1 ? offer.locations[0].name : `${offer.locations.length} locations`}
      </Text>
    </Flex>
  );
};

export default BusinessCell;
